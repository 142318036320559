import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Loading from "../../components/Loading";
import Divider from "@mui/material/Divider";
import House from "../../images/svg/hmrc-page/black-house.svg";
import {capitalize} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {EditModal} from "../../components/Pages/AutomaticEnrolment/EditModal";
import {findEmployer, updateDefaultPensionSettings} from "../../actions/Employer";

const renderGrids = (component1, component2) => {
	return (
		<Grid
			container
			alignItems={"center"}
			justifyContent={"space-between"}
			className={"information-item-grid"}
		>
			<Grid>
				{component1}
			</Grid>
			<Grid>
				{component2}
			</Grid>
		</Grid>
	);
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const AutomaticEnrolmentSettings = ({
	me = () => {},
	user = {},
	user_loading = false,
	updateDefaultPensionSettings = () => {},
	employer_loading = false,
	employer = {},
	findEmployer = () => {},
}): JSX.Element => {

	const [showEditModal, setShowEditModal] = useState(false);
	const [employerRecord, setEmployerRecord] = useState({});

	useEffect(() => {
		if (!user.id) {
			me();
		}
		findEmployer(["pension_scheme"]);
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [me, user, findEmployer]);

	useEffect(() => {
		setEmployerRecord(employer);
	},[employer]);

	if (user_loading || employer_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"auto-enrolment-page"}
		>
			<StyleAppBar
				showBackButton={true}
				backLink={"/main/auto-enrolment-menu"}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Auto Enrolment"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							justifyContent={"space-between"}
							alignItems={"center"}
						>
							<StyleTypography
								fontSize={40}
								fontSizeMedium={30}
								fontWeight={"bold"}
							>
								Automatic Enrolment
							</StyleTypography>
							<Grid
								sx={{
									paddingTop: "10px",
									paddingBottom: "30px"
								}}
							>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={"#ABAEB7"}
								>
									Setup your workplace pension for auto enrolment
								</StyleTypography>
							</Grid>
						</Grid>
						<Divider className={"divider"}/>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
							className={"information-container"}
							direction={"row"}
						>
							<Grid
								xl={5.18}
								lg={5.18}
								md={5.18}
								sm={5.18}
								xs={5.18}
								item
								container
								className={"left-container"}
							>
								<Grid
									container
									justifyContent={"flex-end"}
									sx={{
										paddingBottom: "1.527vw"
									}}
								>
									<Grid sx={{cursor: "pointer"}} onClick={() => setShowEditModal(true)}>
										<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={16} fontSizeMedium={13} color={"black"}>
											Edit
											<EditIcon className={"icon"}/>
										</StyleTypography>
									</Grid>
								</Grid>
								<Divider className={"divider"}/>
								{
									renderGrids(
										<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
											Pension scheme
										</StyleTypography>,
										<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
											{capitalize(employer?.pension_scheme?.pension_scheme_selected ?? "") ?? "N/A"}
										</StyleTypography>
									)
								}
								<Divider className={"divider"}/>
								{
									renderGrids(
										<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
											Pension Type
										</StyleTypography>,
										<Grid>
											<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
												{
													employer?.pension_scheme?.relief_at_source ?
														"Relief at source"
														:
														"Salary sacrifice"
												}
											</StyleTypography>
										</Grid>
									)
								}
								<Divider className={"divider"}/>
								{
									renderGrids(
										<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
											Pension calculation
										</StyleTypography>,
										<Grid>
											<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
												{
													employer?.pension_scheme?.pensionable_earnings ?
														"Pensionable earnings"
														:
														"Qualifying earnings"
												}
											</StyleTypography>
										</Grid>
									)
								}
								<Divider className={"divider"}/>
								{
									renderGrids(
										<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
											Employee contribution
										</StyleTypography>,
										<Grid>
											<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
												{employer?.pension_scheme?.employee_rate_percentage ?? "N/A"} %
											</StyleTypography>
										</Grid>
									)
								}
								<Divider className={"divider"}/>
								{
									renderGrids(
										<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
											Employer contribution
										</StyleTypography>,
										<Grid>
											<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
												{employer?.pension_scheme?.employer_rate_percentage ?? "N/A"} %
											</StyleTypography>
										</Grid>
									)
								}
							</Grid>

							<Grid
								xl={5.18}
								lg={5.18}
								md={5.18}
								sm={5.18}
								xs={5.18}
								item
								container
								sx={{
									marginLeft: "36px"
								}}
								className={"right-container"}
								alignItems={"flex-start"}
								alignSelf={"flex-start"}
							>
								<Grid container className={"information-item-grid"}>
									<Grid sx={{lineHeight: 2.3, marginRight: '20px'}}>
										<img
											alt={"logo"}
											src={House}
										/>
									</Grid>
									<Grid item xs={true}>
										<Grid>
											<StyleTypography fontSize={22} fontSizeMedium={16} color={"black"} fontWeight={"bold"}>
												Workplace pension
											</StyleTypography>
										</Grid>
										<Grid>
											<StyleTypography fontSize={18} fontSizeMedium={13} color={"#AAAEB7"}>
												Set up your mandatory workplace pension for automatic enrolment
											</StyleTypography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<EditModal
				open={showEditModal}
				setOpen={setShowEditModal}
				employer={employer}
				employerRecord={employerRecord}
				setEmployerRecord={setEmployerRecord}
				updateDefaultPensionSettings={updateDefaultPensionSettings}
			/>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Employer,
	} = state;

	return {
		...Profile,
		...Employer,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	updateDefaultPensionSettings: (data) => dispatch(updateDefaultPensionSettings(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutomaticEnrolmentSettings)