import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import StyleTypography from "../../StyledComponents/StyleTypography";
import DialogContent from "@mui/material/DialogContent";
import {Checkbox} from "@mui/material";
import Button from "@mui/material/Button";


export const ExportEmployeeDataModal = ({
    open = false,
    setOpen = () => {},
}) => {
	return (
		<Dialog
			open={open}
			id={"export-employee-data-modal"}
			onClose={() => {
				setOpen(false);
			}}
		>
			<Grid>
				<DialogTitle>
					<StyleTypography
						fontSize={35}
						fontSizeMedium={25}
						color={"#0160FD"}
						fontWeight={"bold"}
					>
						Export employee data
					</StyleTypography>
				</DialogTitle>
				
				<DialogContent>
					<StyleTypography
						fontSize={16}
						fontSizeMedium={11}
						color={"#AAAEB7"}
					>
						We will provide you with a CSV containing all data
					</StyleTypography>
					
					<Grid
						id={"export-employee-modal-content"}
						container
						direction={"row"}
						alignItems={"flex-start"}
					>
						<Grid
							item
						>
							<Checkbox/>
						</Grid>
						<Grid
							id={"export-all-data"}
							item
						>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								fontWeight={"bold"}
								color={"#000"}
							>
								Export all data
							</StyleTypography>
							
							<StyleTypography
								fontSize={14}
								fontSizeMedium={10}
								color={"#AAAEB7"}
							>
								We will provide you with a CSV containing all data
							</StyleTypography>
						</Grid>
					</Grid>
					
					<Grid
						id={"export-employees-data-buttons"}
						container
						spacing={4}
						// direction={"row"}
					>
						<Grid
							item
							sm={12}
							xs={12}
							md={6}
							lg={6}
							xl={6}
						>
							<Button
								className={"default-black-button"}
							>
								<StyleTypography
									fontSize={16}
									fontSizeMedium={11}
									color={"#fff"}
								>
									Export
								</StyleTypography>
							</Button>
						</Grid>
						
						
						<Grid
							item
							sm={12}
							xs={12}
							md={6}
							lg={6}
							xl={6}
						>
							<Button
								className={"default-white-button"}
								onClick={() => {
									setOpen(false);
								}}
							>
								<StyleTypography
									fontSize={16}
									fontSizeMedium={11}
									color={"#000"}
								>
									Cancel
								</StyleTypography>
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
				
			</Grid>
		
		</Dialog>
	);
}