import {CREATE_UPDATE_TEMPLATE, DOWNLOAD_JOURNAL, GET_JOURNAL_TEMPLATE,} from "./types";
import Request from "../../utils/Request";
import FileDownload from "js-file-download";
import * as XLSX from "xlsx";
import {toast} from "react-toastify";

export const createPayrollTemplate = (data, setEditTemplate, setStep) => dispatch => {
	dispatch({
		type: CREATE_UPDATE_TEMPLATE,
		payroll_journal_loading: true,
	});

	return new Request.post('/api/employers/journal-template/create', data)
		.then(response => {
			dispatch({
				type: CREATE_UPDATE_TEMPLATE,
				payroll_journal_loading: false,
				payroll_journal_template: response.data.data,
			});
			setEditTemplate(false);
			setStep(1);
			toast.success('Template updated successfully');
		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: CREATE_UPDATE_TEMPLATE,
				payroll_journal_loading: false,
			});
		})
};

export const getPayrollTemplate = () => dispatch => {
	dispatch({
		type: GET_JOURNAL_TEMPLATE,
		payroll_journal_loading: true,
	});

	return new Request.get('/api/employers/journal-template/get')
		.then(response => {
			dispatch({
				type: GET_JOURNAL_TEMPLATE,
				payroll_journal_loading: false,
				payroll_journal_template: response.data.data.custom_template,
				regular_template: response.data.data.regular_template,
			});

		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: GET_JOURNAL_TEMPLATE,
				payroll_journal_loading: false,
			});
		})
}

export const downloadJournal = (data) => dispatch => {
	dispatch({
		type: DOWNLOAD_JOURNAL,
		payroll_journal_loading: true,
	});

	return new Request.post('/api/employers/journal-template/download-journal', data)
		.then(response => {
			const worksheet = XLSX.utils.json_to_sheet(response.data.data);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
			const excelFileContent = XLSX.write(workbook, {type: "buffer", bookType: "xlsx"});

			FileDownload(
				excelFileContent,
				`payroll-journal.xlsx`
			);

			dispatch({
				type: DOWNLOAD_JOURNAL,
				payroll_journal_loading: false,
			});

		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: DOWNLOAD_JOURNAL,
				payroll_journal_loading: false,
			});
		})
}