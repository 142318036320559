import Grid from "@mui/material/Grid";
import {InputLabel, MenuItem, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {NewEmployeeWithP45} from "./NewEmployeeWithP45";
import {NewEmployeeWithoutP45} from "./NewEmployeeWithoutP45";
import {ExistingEmployeeWizard} from "./ExistingEmployeeWizard";

function _renderStarterType (employeeInformation, setEmployeeInformation, ni_categories, nicCategoryValues, setNicCategoryValues) {
	switch (employeeInformation.starter_type) {
		case "new_with_p45":
			return (
				<NewEmployeeWithP45
					employeeInformation={employeeInformation}
					setEmployeeInformation={setEmployeeInformation}
				/>
			)
		case "new_without_p45":
			return (
				<NewEmployeeWithoutP45
					employeeInformation={employeeInformation}
					setEmployeeInformation={setEmployeeInformation}
				/>
			);
		case "existing_employee":
			return (
				<ExistingEmployeeWizard
					employeeInformation={employeeInformation}
					setEmployeeInformation={setEmployeeInformation}
					ni_categories={ni_categories}
					nicCategoryValues={nicCategoryValues}
					setNicCategoryValues={setNicCategoryValues}
				/>
			);
		default:
			return null;
	}
}

export const StarterDetails = ({
	employeeInformation,
	setEmployeeInformation,
	ni_categories,
	nicCategoryValues,
	setNicCategoryValues,
}) => {

	return (
		<Grid
			item
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			container
			spacing={4}
			paddingTop={2}
		>

			{/*Starter Type*/}
			<Grid
				xl={4}
				lg={4}
				md={4}
				sm={12}
				xs={12}
				item
			>
				<InputLabel
					className={"default-input-label"}
				>
					Starter Type*
				</InputLabel>
				<FormControl
					fullWidth
				>
					<Select
						className={"default-select"}
						id={"select-title"}
						MenuProps={{
							PaperProps: {
								style: {
									maxHeight: 200, // Adjust the maximum height of the list
								},
							},
						}}
						value={employeeInformation?.starter_type ?? " "}
						onChange={(event) => {
							if (event.target.value === "new_with_p45 ") {
								setEmployeeInformation({
									...employeeInformation,
									starter_type: event.target.value,
									starter_declaration: "B",
								});
							} else {
								setEmployeeInformation({
									...employeeInformation,
									starter_type: event.target.value,
									starter_declaration: null,
								});
							}

						}}
					>
						<MenuItem
							key={'select-title'}
							value={" "}
							disabled
						>
							Select
						</MenuItem>
						<MenuItem
							key={'new_with_p45'}
							value={"new_with_p45"}
						>
							New employee with P45
						</MenuItem>
						<MenuItem
							key={'new_without_p45'}
							value={"new_without_p45"}
						>
							New employee without P45
						</MenuItem>
						<MenuItem
							key={'existing_employee'}
							value={"existing_employee"}
						>
							Existing employee
						</MenuItem>
					</Select>
				</FormControl>
			</Grid>

			{_renderStarterType(employeeInformation, setEmployeeInformation, ni_categories, nicCategoryValues, setNicCategoryValues)}
		</Grid>
	)

};