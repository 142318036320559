import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Chip, InputLabel, MenuItem, Select } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGb from 'date-fns/locale/en-GB';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useState } from 'react';
import { format, isDate } from 'date-fns';
import Help from '../../../../../images/svg/help-icon.svg';
import StyleButton from "../../../../StyledComponents/StyleButton";

export const InnerStepZero = ({ stepTwo, setStepTwo, basicSetup }) => {
	const [isValidDate, setIsValidDate] = useState(true);

	return (
		<Grid
			direction={'column'}
			container
			id={'inner-step-zero'}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={'main-title-container'}
			>
				<Typography className={'main-title'}>Automatic Enrolment</Typography>
				<Typography className={'main-subtitle'}>
					Automatic Enrolment is a UK government initiative that requires
					employers to enrol eligible employees into a qualifying workplace
					pension scheme automatically. As an employer, you are legally obliged
					to provide a workplace pension scheme for your employees and make
					contributions to it. Learn more at <a href={"https://www.thepensionsregulator.gov.uk/en/business-advisers/automatic-enrolment-guide-for-business-advisers/duties-for-new-employers"} target={"_blank"} rel={"noreferrer"}>The Pensions Regulator</a>.
				</Typography>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={'body-container'}
			>
				<Typography className={'main-subtitle'}>
					Eligible employees who meet certain criteria are automatically
					enrolled into the scheme, but they have the option to opt-out if they
					choose to do so. Minimum contribution rates for employers and
					employees are set by law, but you can increase them if want to.
					Employers must communicate with their employees and keep accurate
					records to ensure compliance with the legislation.
				</Typography>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={'body-container-0'}
				container
			>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					container
				>
					<InputLabel className={'default-input-label'}>
						Staging date*
					</InputLabel>
					<img
						src={Help}
						alt={'help'}
						style={{
							width: '0.885vw',
							height: '0.885vw',
							marginLeft: '7px',
							cursor: 'pointer',
						}}
						onClick={() => {
							window.open(
								'https://help.thepensionsregulator.gov.uk/faq/staging_date/New-Staging',
								'_blank'
							);
						}}
					/>
				</Grid>

				<Grid
					xl={5.5}
					lg={4.5}
					md={4.5}
					sm={4.5}
					xs={4.5}
					item
				>
					<LocalizationProvider
						dateAdapter={AdapterDateFns}
						adapterLocale={enGb}
					>
						<DesktopDatePicker
							fullWidth
							className={'date-picker'}
							value={stepTwo.staging_date}
							onChange={(newValue) => {
								setStepTwo({
									...stepTwo,
									staging_date: newValue,
								});
							}}
							onError={(reason) => {
								setIsValidDate(!reason);
							}}
							slotProps={{ field: { readOnly: true } }}
							enableAccessibleFieldDOMStructure
						/>
					</LocalizationProvider>
				</Grid>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={'body-container-1'}
			>
				<Typography className={'body-subtitle-1'}>
					Have you already set up an Automatic Enrolment pension?
				</Typography>
				<RadioGroup
					row
					value={stepTwo.has_pension_scheme}
					onChange={(e) => {
						setStepTwo({
							...stepTwo,
							has_pension_scheme: e.target.value === 'true',
							pension_selection: '',
							pension_collection_date: ' ',
						});
					}}
				>
					<FormControlLabel
						value={true}
						control={<Radio />}
						label="Yes"
					/>
					<FormControlLabel
						value={false}
						control={<Radio />}
						label="No"
					/>
				</RadioGroup>
			</Grid>

			{stepTwo.has_pension_scheme === true && (
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					className={'body-container-2'}
				>
					<Typography className={'body-subtitle-1'}>
						What is your current Automatic Enrolment provider?
					</Typography>
					<Grid
						item
						className={'body-sub-container'}
					>
						<Grid
							xl={5.5}
							lg={4.5}
							md={4.5}
							sm={4.5}
							xs={4.5}
							item
						>
							<FormControl fullWidth>
								<Select
									className={'default-select'}
									id={'current-pension-provider'}
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: 200, // Adjust the maximum height of the list
											},
										},
									}}
									value={stepTwo.current_pension_provider}
									onChange={(e) => {
										setStepTwo({
											...stepTwo,
											current_pension_provider: e.target.value,
											other_pension_provider: '',
											pension_selection: '',
											pension_collection_date: ' ',
										});
									}}
								>
									<MenuItem
										key={'select-day'}
										value={' '}
									>
										Select your current provider
									</MenuItem>
									<MenuItem
										key={'Aviva'}
										value={'Aviva'}
									>
										Aviva
									</MenuItem>
									<MenuItem
										key={'Aegon'}
										value={'Aegon'}
									>
										Aegon
									</MenuItem>
									<MenuItem
										key={'Nest'}
										value={'Nest'}
									>
										Nest
									</MenuItem>
									<MenuItem
										key={'People"s Pension'}
										value={'People"s Pension'}
									>
										People's Pension
									</MenuItem>
									<MenuItem
										key={'Smart-Pension'}
										value={'Smart Pension'}
									>
										Smart Pension
									</MenuItem>
									<MenuItem
										key={'Now-Pension'}
										value={'Now Pension'}
									>
										Now Pension
									</MenuItem>
									<MenuItem
										key={'Other'}
										value={'Other'}
									>
										Other
									</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>

					{stepTwo.current_pension_provider === 'Other' && (
						<Grid
							item
							className={'body-sub-container-2-1'}
						>
							<InputLabel className={'default-input-label'}>
								Provider name*
							</InputLabel>
							<Grid
								xl={5.5}
								lg={4.5}
								md={4.5}
								sm={4.5}
								xs={4.5}
								item
							>
								<TextField
									className={'default-text-field'}
									value={stepTwo.other_pension_provider}
									onChange={(e) => {
										setStepTwo({
											...stepTwo,
											other_pension_provider: e.target.value,
										});
									}}
									placeholder={'Pension provider name'}
									fullWidth
								/>
							</Grid>
						</Grid>
					)}

					{stepTwo.current_pension_provider === 'Other' && (
						<Grid
							item
							className={'body-sub-container-2-2'}
						>
							<InputLabel className={'default-input-label'}>
								You Employer ID with your current pension provider*
							</InputLabel>
							<Grid
								xl={5.5}
								lg={4.5}
								md={4.5}
								sm={4.5}
								xs={4.5}
								item
							>
								<TextField
									className={'default-text-field'}
									value={stepTwo.current_employer_id}
									onChange={(e) => {
										setStepTwo({
											...stepTwo,
											current_employer_id: e.target.value,
										});
									}}
									placeholder={'Type your current Employer ID'}
									fullWidth
								/>
							</Grid>
						</Grid>
					)}
				</Grid>
			)}

			{(stepTwo.has_pension_scheme === false ||
				stepTwo.current_pension_provider !== ' ') && (
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					className={'body-container-3'}
				>
					<Typography className={'body-subtitle-1'}>
						Select the pension you want to use for Automatic Enrolment
					</Typography>
					<RadioGroup
						onChange={(e) => {
							setStepTwo({
								...stepTwo,
								paid_version: e.target.value !== 'collegia',
								pension_selection: e.target.value,
								pension_collection_date: ' ',
							});
						}}
						value={stepTwo.pension_selection}
					>
						<FormControlLabel
							value={'collegia'}
							control={<Radio />}
							label={
								<Typography className={'radio-label'}>
									Go with Collegia Pension - Free Option
									<br />
									<span className={'radio-subtitle'}>
										Collegia is the award-winning Free, Digital and Sustainable
										automatic enrolment pension.
										<br />
										100% Automatic Enrolment compliance guaranteed.
									</span>
								</Typography>
							}
						/>
						<FormControlLabel
							value={'custom'}
							disabled={true}
							control={<Radio />}
							label={
								<Typography className={'radio-label-disabled'}>
									{stepTwo.has_pension_scheme === true
										? stepTwo.other_pension_provider === ''
											? `I want to go with ${stepTwo.current_pension_provider} - Paid Option`
											: `I want to go with ${stepTwo.other_pension_provider} - Paid Option`
										: 'I want to go with another one - Paid Option'}
									<Chip
										label="Available soon"
										sx={{
											backgroundColor: '#0160FD',
											color: '#FFFFFF',
											marginLeft: '10px',
										}}
									/>
									<br />
									<span className={'radio-subtitle-disabled'}>
										In this case we cannot guarantee 100% compliance with
										Automatic Enrolment and you will be responsible
										<br />
										for making sure that pension uploads are performed in a
										timely manner.
									</span>
								</Typography>
							}
						/>
					</RadioGroup>
				</Grid>
			)}

			{stepTwo.pension_selection === 'collegia' && (
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					className={'body-container-4'}
				>
					<Typography className={'body-subtitle-1'}>
						Select the date you want pension contributions to be collected
					</Typography>
					<Grid
						item
						className={'body-sub-container'}
					>
						<Grid
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={6}
							item
						>
							<Typography className={'main-subtitle'}>
								All outstanding pension contributions are collected once a month
								through Direct Debit. You can select which day of the month
								they’ll be collected.
							</Typography>
							<InputLabel className={'default-input-label'}>
								Select date
							</InputLabel>
							<FormControl fullWidth>
								<Select
									className={'default-select'}
									id={'select-day'}
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: 200, // Adjust the maximum height of the list
											},
										},
									}}
									value={stepTwo.pension_collection_date}
									onChange={(e) => {
										setStepTwo({
											...stepTwo,
											pension_collection_date: e.target.value,
										});
									}}
								>
									<MenuItem
										key={'select-day'}
										value={' '}
									>
										Select a day
									</MenuItem>
									{[...Array(14).keys()].map(item => (
										<MenuItem key={item} value={item + 9}>
											{item + 9}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
			)}

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={'body-container-4'}
			>
				<Typography className={'body-subtitle-1'}>
					We need to setup a Direct Debit to collect pension contributions from your bank account.<br/>
					How would you like to sign your Direct Debit?
				</Typography>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					container
					className={'button-container'}
				>
					<Grid item>
						<StyleButton
							disabled={
								stepTwo.has_pension_scheme === null ||
								(stepTwo.pension_selection !== 'custom' &&
									stepTwo.pension_collection_date === ' ') ||
								stepTwo.staging_date === null ||
								!isValidDate
							}
							onClick={() => {
								stepTwo.pension_selection === 'custom'
									? setStepTwo({
										...stepTwo,
										inner_step: 2,
									})
									: basicSetup({
										employers: {
											method:'open_banking',
											step: 'automatic_enrolment',
											has_pension_scheme: stepTwo.has_pension_scheme,
											current_pension_provider:
												stepTwo.current_pension_provider === 'Other'
													? stepTwo.other_pension_provider
													: stepTwo.current_pension_provider,
											pension_selection: stepTwo.pension_selection,
											paid_version: stepTwo.paid_version,
											collection_day: stepTwo.pension_collection_date,
											staging_date: isDate(stepTwo.staging_date)
												? format(stepTwo.staging_date, 'yyyy-MM-dd')
												: '',
										},
									});
							}}
						>
							Via Open Banking
						</StyleButton>
					</Grid>
					<Grid item marginLeft={2}>
						<StyleButton
							isWhiteButton={true}
							disabled={
								stepTwo.has_pension_scheme === null ||
								(stepTwo.pension_selection !== 'custom' &&
									stepTwo.pension_collection_date === ' ') ||
								stepTwo.staging_date === null ||
								!isValidDate
							}
							onClick={() => {
								setStepTwo({
									...stepTwo,
									inner_step: 1,
								});
								window.scrollTo(0, 0);
							}}
						>
							Sign manually
						</StyleButton>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
