import Grid from "@mui/material/Grid";
import {Chip, InputLabel, MenuItem, Select} from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import enGb from "date-fns/locale/en-GB";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {format} from "date-fns";
import React, {useMemo, useState} from "react";
import countryList from "react-select-country-list";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {matchIsValidTel, MuiTelInput} from "mui-tel-input";
import arrow from '../../../images/svg/arrow-diagonal.svg'
import {DirectorModal} from "./DirectorModal";
import StyleTypography from "../../StyledComponents/StyleTypography";
import {formatDateFromBackend, parseDate, validateEmail} from "../../../utils/Helpers";

/**
 * @param employeeRecord
 * @param setEmployeeRecord
 * @param employee
 * @param updateEmployee
 * @param ni_categories
 * @returns {JSX.Element}
 * @constructor
 */
export const General = (
	{
		employeeRecord = {
			forename: "",
			surname: "",
			title: "",
			gender: "",
			birthdate: "",
			national_insurance_number: "",
			ni_category: "",
			email: "",
			address: {
				address_line_2: "",
				address_line_1: "",
				city: "",
				postal_code: "",
				country: "",
			},
			telephone: "",
			join_date: null,
			payroll_id: "",
			hmrc_id: "",
			employment_ended_date: null,
		},
		setEmployeeRecord,
		employee,
		updateEmployee,
		ni_categories,
	}
): JSX.Element => {

	const countries = useMemo(() => countryList().getData(), []);
	const [openDirectorModal, setOpenDirectorModal] = useState(false);
	const ninValidator = /^([ACEHJLMOPRSWXY][A-CEGHJ-NPR-TW-Z]|B[A-CEHJ-NPR-TW-Z]|G[ACEGHJ-NPR-TW-Z]|[KT][A-CEGHJ-MPR-TW-Z]|N[A-CEGHJL-NPR-SW-Z]|Z[A-CEGHJ-NPR-TW-Y])[0-9]{6}[A-D ]$/i;
	return (
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			container
			item
			justifyContent={"space-between"}
			id={'general'}
		>
			{
				employeeRecord.is_director && (
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
						container
						sx={{
							marginBottom: '40px'
						}}
					>
						<Grid
							onClick={() => {
								setOpenDirectorModal(true);
							}}
						>
							{
								employeeRecord.active_director_detail?.end_date != null ?
									<Chip
										label={"Directorship will end at the end of the current tax year"}
										className={"mui-chip-general"}
									/>
									:
									<Chip
										label={"Director appointed"}
										className={"mui-chip-general"}
									/>
							}
						</Grid>
					</Grid>
				)
			}
			<Grid
				xl={5}
				lg={5}
				md={5}
				sm={12}
				xs={12}
				container
				justifyContent={"space-between"}
				spacing={3}
				item
			>
				{/*Forename*/}
				<Grid
					xl={5.5}
					lg={5.5}
					md={5.5}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Forename
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						value={employeeRecord?.forename}
						onChange={(e) => {
							employeeRecord.forename = e.target.value;
							setEmployeeRecord(employeeRecord);
						}}
					/>
				</Grid>

				{/*Surname*/}
				<Grid
					xl={5.5}
					lg={5.5}
					md={5.5}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Surname
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						value={employeeRecord?.surname}
						onChange={(e) => {
							employeeRecord.surname = e.target.value;
							setEmployeeRecord(employeeRecord);
						}}
					/>
				</Grid>

				{/*Title*/}
				<Grid
					xl={5.5}
					lg={5.5}
					md={5.5}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Title
					</InputLabel>
					<FormControl
						fullWidth
					>
						<Select
							className={"default-select"}
							id={"select-title"}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 200, // Adjust the maximum height of the list
									},
								},
							}}
							value={employeeRecord?.title}
							onChange={(e) => {
								employeeRecord.title = e.target.value;
								setEmployeeRecord(employeeRecord);
							}}
						>
							<MenuItem
								key={'Mr'}
								value={"Mr"}
							>
								Mr
							</MenuItem>
							<MenuItem
								key={'Miss'}
								value={"Miss"}
							>
								Miss
							</MenuItem>
							<MenuItem
								key={'Mrs'}
								value={"Mrs"}
							>
								Mrs
							</MenuItem>
							<MenuItem
								key={'Ms'}
								value={"Ms"}
							>
								Ms
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>

				{/*Gender*/}
				<Grid
					xl={5.5}
					lg={5.5}
					md={5.5}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Gender
					</InputLabel>
					<FormControl
						fullWidth
					>
						<Select
							className={"default-select"}
							id={"select-gender"}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 200, // Adjust the maximum height of the list
									},
								},
							}}
							value={employeeRecord?.gender ?? ""}
							onChange={(e) => {
								employeeRecord.gender = e.target.value;
								setEmployeeRecord(employeeRecord);
							}}
						>
							<MenuItem
								key={'Male'}
								value={"M"}
							>
								Male
							</MenuItem>
							<MenuItem
								key={'Female'}
								value={"F"}
							>
								Female
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>

				{/*Birthdate*/}
				<Grid
					xl={5.5}
					lg={5.5}
					md={5.5}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Birthdate
					</InputLabel>
					<LocalizationProvider
						dateAdapter={AdapterDateFns}
						adapterLocale={enGb}
					>
						<DesktopDatePicker
							className={"date-picker"}
							value={parseDate(employeeRecord?.birthdate)}
							onChange={(newValue) => {
								employeeRecord.birthdate = newValue
								setEmployeeRecord(employeeRecord);
							}}
							minDate={new Date('1900-01-01')}
							maxDate={new Date()}
							slotProps={{ field: { readOnly: true } }}
							enableAccessibleFieldDOMStructure
						/>
					</LocalizationProvider>
				</Grid>

				{/*NI Number*/}
				<Grid
					xl={5.5}
					lg={5.5}
					md={5.5}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						NI Number
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						value={employeeRecord.national_insurance_number ?? ""}
						onChange={(e) => {
							employeeRecord.national_insurance_number = e.target.value;
							setEmployeeRecord(employeeRecord);
						}}
						error={!ninValidator.test(employeeRecord.national_insurance_number) && (employeeRecord.national_insurance_number !== "" && employeeRecord.national_insurance_number !== null)}
					/>
				</Grid>

				{/*NI Category*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						NI Category
					</InputLabel>
					<FormControl
						fullWidth
					>
						<Select
							className={"default-select"}
							id={"select-gender"}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 200, // Adjust the maximum height of the list
										maxWidth: 300,
										overflowX: "scroll"
									},
								},
							}}
							value={employeeRecord?.ni_category ?? " "}
							onChange={(e) => {
								employeeRecord.ni_category = e.target.value;
								setEmployeeRecord(employeeRecord);
							}}
						>
							<MenuItem
								key={"select"}
								value={" "}
								disabled
								sx={{
									fontSize: '0.9028vw'
								}}
							>
								Select
							</MenuItem>
							{
								ni_categories.map((category) => {
									return (
										<MenuItem
											key={category.id}
											value={category.category}
											sx={{
												fontSize: '0.9028vw'
											}}
										>
											{category.category} - {category.description}
										</MenuItem>
									)
								})
							}
						</Select>
					</FormControl>
				</Grid>

				{/*Join Date*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Employment Start Date
					</InputLabel>
					<LocalizationProvider
						dateAdapter={AdapterDateFns}
						adapterLocale={enGb}
					>
						<DesktopDatePicker
							className={"date-picker"}
							value={parseDate(employeeRecord?.join_date)}
							onChange={(newValue) => {
								setEmployeeRecord({
									...employeeRecord,
									join_date: newValue,
								});
							}}
							slotProps={{ field: { readOnly: true } }}
							enableAccessibleFieldDOMStructure
						/>
					</LocalizationProvider>
				</Grid>

				{/*Payroll ID*/}
				<Grid
					xl={5.5}
					lg={5.5}
					md={5.5}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Payroll ID
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						value={employeeRecord?.payroll_id ?? ""}
						onChange={(e) => {
							employeeRecord.payroll_id = e.target.value;
							setEmployeeRecord(employeeRecord);
						}}
						error={employeeRecord.payroll_id === "" || employeeRecord.payroll_id === null}
					/>
				</Grid>

				{
					employeeRecord.employment_ended_date &&
						<Grid
							xl={5.5}
							lg={5.5}
							md={5.5}
							sm={12}
							xs={12}
							item
						>
							<InputLabel
								className={"default-input-label"}
							>
								Terminated Date
							</InputLabel>
							<TextField
								fullWidth
								className={"default-text-field"}
								value={formatDateFromBackend(employeeRecord?.employment_ended_date)}
								disabled={true}
							/>
						</Grid>
				}

				{/*Set director*/}
				{
					!employeeRecord.is_director && (
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
						>
							<Grid
								onClick={() => setOpenDirectorModal(true)}
							>
								<StyleTypography
									fontSize={20}
									fontSizeMedium={16}
									color={"#000000"}
									className={'hover'}
								>
									Set as director
									<img
										src={arrow}
										alt={""}
										className={'arrow-general'}
									/>
								</StyleTypography>
							</Grid>

						</Grid>
					)
				}

			</Grid>

			<Grid
				xl={5}
				lg={5}
				md={5}
				sm={12}
				xs={12}
				container
				justifyContent={"space-between"}
				spacing={3}
				item
			>

				{/*Email*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Email
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						value={employeeRecord?.email}
						onChange={(e) => {
							employeeRecord.email = e.target.value;
							setEmployeeRecord(employeeRecord);
						}}
						error={!validateEmail(employeeRecord.email)}
						helperText={!validateEmail(employeeRecord.email) ? "Please enter a valid email!" : ""}
					/>
				</Grid>

				{/*Telephone*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Telephone
					</InputLabel>
					<MuiTelInput
						className={"default-phone-input"}
						defaultCountry={"GB"}
						flagSize={"medium"}
						forceCallingCode
						value={employeeRecord?.telephone ?? ""}
						error={
							!matchIsValidTel(employeeRecord?.telephone ?? "") &&
							employeeRecord.telephone !== "" && employeeRecord.telephone !== null
						}
						onChange={(value) => {
							employeeRecord.telephone = value;
							setEmployeeRecord(employeeRecord);
						}}
					/>
				</Grid>

				{/*Address Line 1*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Address Line 1
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						value={employeeRecord?.address?.address_line_1 ?? ''}
						onChange={(e) => {
							setEmployeeRecord({
								...employeeRecord,
								address: { ...employeeRecord.address, address_line_1: e.target.value },
							});
						}}
					/>
				</Grid>

				{/*Address Line 2*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Address Line 2
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						value={employeeRecord?.address?.address_line_2 ?? ''}
						onChange={(e) => {
							setEmployeeRecord({
								...employeeRecord,
								address: { ...employeeRecord.address, address_line_2: e.target.value },
							});
						}}
					/>
				</Grid>

				{/*City*/}
				<Grid
					xl={5.5}
					lg={5.5}
					md={5.5}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						City
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						value={employeeRecord?.address?.city ?? ""}
						onChange={(e) => {
							setEmployeeRecord({
								...employeeRecord,
								address: { ...employeeRecord.address, city: e.target.value },
							});
						}}
					/>
				</Grid>

				{/*Postal Code*/}
				<Grid
					xl={5.5}
					lg={5.5}
					md={5.5}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Postcode
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						value={employeeRecord?.address?.postal_code ?? ""}
						onChange={(e) => {
							setEmployeeRecord({
								...employeeRecord,
								address: { ...employeeRecord.address, postal_code: e.target.value },
							});
						}}
					/>
				</Grid>

				{/*Country*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Country
					</InputLabel>
					<Autocomplete
						id={"country-select-demo"}
						className={"default-select"}
						options={countries}
						getOptionLabel={(option) => option.label !== null ? option.label : "Select a country"}
						renderOption={(props, option) => (
							<Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
								<img
									loading="lazy"
									width="20"
									src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
									srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
									alt=""
								/>
								{option.label}
							</Box>
						)}
						renderInput={(params) => (
							<TextField
								{...params}
								inputProps={{
									...params.inputProps,
									autoComplete: 'new-password', // disable autocomplete and autofill
								}}
							/>
						)}
						value={countries.filter(country => country.label === employeeRecord.address?.country)[0] ?? null}
						onChange={(event, newValue) => {
							employeeRecord.address.label = newValue.label;
							setEmployeeRecord(employeeRecord);
						}}
					/>
				</Grid>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				className={"employee-record-buttons"}
			>
				<Grid item>
					<Button
						className={"default-black-button employee-record-buttons-save"}
						disabled={
							employeeRecord.forename === "" ||
							employeeRecord.surname === "" ||
							employeeRecord.email === "" ||
							!validateEmail(employeeRecord.email) ||
							employeeRecord.title === "" ||
							employeeRecord.gender === "" ||
							employeeRecord.birthdate === "" ||
							employeeRecord.ni_category === null ||
							(employeeRecord.national_insurance_number !== "" && employeeRecord.national_insurance_number !== null && !ninValidator.test(employeeRecord.national_insurance_number))
						}
						onClick={() => {
							updateEmployee(employee.safe_id, {
								employees: {
									'action': 'general',
									forename: employeeRecord.forename,
									surname: employeeRecord.surname,
									email: employeeRecord.email,
									title: employeeRecord.title,
									gender: employeeRecord.gender,
									birthdate: format(parseDate(employeeRecord.birthdate),"yyyy-MM-dd"),
									national_insurance_number: employeeRecord.national_insurance_number,
									address: employeeRecord.address,
									ni_category: employeeRecord.ni_category,
									telephone: employeeRecord.telephone,
									join_date: format(parseDate(employeeRecord.join_date),"yyyy-MM-dd"),
									payroll_id: employeeRecord.payroll_id,
									hmrc_id: employeeRecord.hmrc_id,
								}
							});
						}}
					>
						Save
					</Button>
				</Grid>
				<Grid
					item
					sx={{
						marginLeft: '1vw',
					}}
				>
					<Button
						className={"default-white-button employee-record-buttons-cancel"}
						onClick={() => {
							setEmployeeRecord(employee);
						}}
					>
						Discard
					</Button>
				</Grid>
			</Grid>

			<DirectorModal
				open={openDirectorModal}
				setOpen={setOpenDirectorModal}
				employee={employee}
				updateEmployee={updateEmployee}
				setEmployeeRecord={setEmployeeRecord}
				employeeRecord={employeeRecord}
			/>
		</Grid>
	);
}