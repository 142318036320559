import Grid from "@mui/material/Grid";
import React, {useEffect, useReducer} from "react";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Loading from "../../components/Loading";
import StyleTable from "../../components/StyledComponents/StyleTable";
import {getEmployerPaymentSummary} from "../../actions/EmployerPaymentSummary";
import {InputLabel, MenuItem, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {findEmployer} from "../../actions/Employer";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsTaxesFilings = ({
	me = () => {},
	getEmployerPaymentSummary = () => {},
	employer_payment_summaries = [],
	employer_payment_summary_loading,
	user = {
		id: null,
	},
	user_loading,
	employer = {},
	findEmployer = () => {},
}): JSX.Element => {

	const initialState = {
		taxYear: "",
		fromTaxMonth: 1,
		toTaxMonth: 12
	};

	function reducer(state, action) {
		switch (action.type) {
			case 'setTaxYear':
				return { ...state, taxYear: action.payload };
			case 'setFromTaxMonth':
				return { ...state, fromTaxMonth: action.payload };
			case 'setToTaxMonth':
				return { ...state, toTaxMonth: action.payload };
			default:
				throw new Error();
		}
	}

	const [state, dispatch] = useReducer(reducer, initialState);


	const taxMonths = [
		{
			label: "Tax month 1",
			value: 1,
		},
		{
			label: "Tax month 2",
			value: 2,
		},
		{
			label: "Tax month 3",
			value: 3,
		},
		{
			label: "Tax month 4",
			value: 4,
		},
		{
			label: "Tax month 5",
			value: 5,
		},
		{
			label: "Tax month 6",
			value: 6,
		},
		{
			label: "Tax month 7",
			value: 7,
		},
		{
			label: "Tax month 8",
			value: 8,
		},
		{
			label: "Tax month 9",
			value: 9,
		},
		{
			label: "Tax month 10",
			value: 10,
		},
		{
			label: "Tax month 11",
			value: 11,
		},
		{
			label: "Tax month 12",
			value: 12,
		},
	]

	const columns = [
		{
			name: "tax_month",
			label: "Tax Month",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			},
		},
		{
			name: "total_paye",
			label: "Total PAYE",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			},
		},
		{
			name: "gross_ni_payable",
			label: "Gross National Insurance",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			}
		},
		{
			name: "claimed_employment_allowance",
			label: "Claimed Employment Allowance",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			}
		},
		{
			name: "total_statutory_recoveries",
			label: "Total Statutory Recoveries",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			}
		},
		{
			name: "apprentice_levy",
			label: "Apprentice Levy",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			}
		},
		{
			name: "cis_deductions",
			label: "CIS Deductions",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			}
		},
		{
			name: "total_amount_due",
			label: "Amount Due",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			}
		},
	];

	useEffect(() => {
		me();
		findEmployer(["employer_tax_years.tax_year"])

		dispatch({ type: 'setTaxYear', payload: employer.tax_year_id });

		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [me, employer.tax_year_id, findEmployer]);

	useEffect(() => {
		getEmployerPaymentSummary({
			"employers": {
				from_tax_month: state.fromTaxMonth,
				to_tax_month: state.toTaxMonth,
				tax_year_id: state.taxYear
			}
		});
	}, [getEmployerPaymentSummary, state.fromTaxMonth, state.toTaxMonth, state.taxYear]);

	if (employer_payment_summary_loading || user_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"main-page"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Reports"}
						expanded={false}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
							columnSpacing={2}
						>
							{/*Tax Year*/}
							<Grid
								item
								xl={4}
								lg={4}
								md={4}
								sm={4}
								xs={4}
							>
								<InputLabel
									className={"default-input-label"}
								>
									Tax Year
								</InputLabel>
								<FormControl
									fullWidth
									sx={{
										marginTop: '10px',
									}}
								>
									<Select
										className={"default-select"}
										id={"select-gender"}
										MenuProps={{
											PaperProps: {
												style: {
													maxHeight: 200, // Adjust the maximum height of the list
												},
											},
										}}
										value={state.taxYear}
										onChange={(e) => dispatch({ type: 'setTaxYear', payload: e.target.value })}
									>
										{
											employer?.employer_tax_years?.map(tax_year => (
												<MenuItem
													key={tax_year.id}
													value={tax_year.tax_year_id}
												>
													{tax_year.tax_year.formated_tax_year}
												</MenuItem>
											))
										}
									</Select>
								</FormControl>
							</Grid>

							{/*From tax month*/}
							<Grid
								item
								xl={4}
								lg={4}
								md={4}
								sm={4}
								xs={4}
							>
								<InputLabel
									className={"default-input-label"}
								>
									From tax month
								</InputLabel>
								<FormControl
									fullWidth
									sx={{
										marginTop: '10px',
									}}
								>
									<Select
										className={"default-select"}
										id={"select-gender"}
										MenuProps={{
											PaperProps: {
												style: {
													maxHeight: 200, // Adjust the maximum height of the list
												},
											},
										}}
										value={state.fromTaxMonth}
										onChange={(event) => {
											dispatch({ type: 'setFromTaxMonth', payload: event.target.value })
										}}
									>
										{
											taxMonths.map(taxMonth => (
												<MenuItem
													key={taxMonth.value}
													value={taxMonth.value}
												>
													{taxMonth.label}
												</MenuItem>
											))
										}
									</Select>
								</FormControl>
							</Grid>

							{/*To tax month*/}
							<Grid
								item
								xl={4}
								lg={4}
								md={4}
								sm={4}
								xs={4}
							>
								<InputLabel
									className={"default-input-label"}
								>
									To tax month
								</InputLabel>
								<FormControl
									fullWidth
									sx={{
										marginTop: '10px',
									}}
								>
									<Select
										className={"default-select"}
										id={"select-gender"}
										MenuProps={{
											PaperProps: {
												style: {
													maxHeight: 200, // Adjust the maximum height of the list
												},
											},
										}}
										value={state.toTaxMonth}
										onChange={(event) => {
											dispatch({ type: 'setToTaxMonth', payload: event.target.value })
										}}
									>
										{
											taxMonths.map(taxMonth => (
												<MenuItem
													key={taxMonth.value}
													value={taxMonth.value}
												>
													{taxMonth.label}
												</MenuItem>
											))
										}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							className={"team-member-table-container"}
						>
							<StyleTable
								title={"Payment Summary"}
								columns={columns}
								data={employer_payment_summaries ?? []}
								options={{
									selectableRowsHeader: false,
									selectableRows: "none",
									responsive: "simple",
									enableNestedDataAccess: ".",
									selectToolbarPlacement: "none",
									searchAlwaysOpen: false,
									print: false,
									download: true,
									search: false,
									viewColumns: true,
									elevation: 0,
									filter: false,
									searchProps: {
										placeholder: "Search pay runs",
										classes: {
											input: "default-text-field"
										}
									},
									rowsPerPage: 13,
								}}
								removeFooter={true}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		EmployerPaymentSummary,
		Employer,
	} = state;

	return {
		...Profile,
		...EmployerPaymentSummary,
		...Employer,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	getEmployerPaymentSummary: (data) => dispatch(getEmployerPaymentSummary(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTaxesFilings)