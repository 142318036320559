import React from "react";
import Routes from "./utils/Routes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
	<div>
		<ToastContainer
			hideProgressBar={true}
			position={"top-center"}
			toastStyle={{
				borderRadius: '0px',
				fontFamily: 'Plus Jakarta Sans, sans-serif',
				width: '600px',
			}}
			theme={"dark"}
		/>
		<Routes/>
	</div>
)

export default App;