import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button, Checkbox} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import {useState} from "react";

export const InnerStepTwo = ({
	stepTwo,
	setStepTwo,
	basicSetup,
}) => {

	const [disabled, setDisabled] = useState(true);

	return (
		<Grid
			direction={"column"}
			container
			id={"inner-step-two"}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={"main-title-container"}
			>
				<Typography
					className={"main-title"}
				>
					Automatic Enrolment
				</Typography>
				<Typography
					className={"main-subtitle"}
				>
					Automatic Enrolment is a UK government initiative that requires employers to enrol eligible employees into a qualifying workplace pension scheme automatically.
					As an employer, you are legally obliged to provide a workplace pension scheme for your employees and make contributions to it. Learn more at <a href={"https://www.thepensionsregulator.gov.uk/en/business-advisers/automatic-enrolment-guide-for-business-advisers/duties-for-new-employers"} target={"_blank"} rel={"noreferrer"}>The Pensions Regulator</a>.
				</Typography>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={"body-container"}
			>
				<Typography
					className={"main-subtitle"}
				>
					When using the Collegia Pension, FreePayroll is 100% free. If you want to use another pension provider, there’s a cost associated to it.
					Also, please remember that with another pension scheme we cannot guarantee 100% compliance, as we do with the Collegia Pension.
				</Typography>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={"warning-container"}
			>
				<Grid
					item
					container
					className={"warning-grid"}
					alignItems={"center"}
				>
					<Typography
						className={"warning-subtitle"}
					>
						You will pay £5 per employee per calendar month
					</Typography>
				</Grid>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={"body-container-2"}
			>
				<Typography
					className={"subtitle"}
				>
					If you switch to the Collegia Pension in the future, you will stop paying.
				</Typography>
				<FormControlLabel
					control={
						<Checkbox
							defaultValue={false}
							icon={<CheckBoxOutlineBlankSharpIcon/>}
							checkedIcon={<CheckBoxSharpIcon/>}
							onChange={(e) => {
								setDisabled(!e.target.checked);
							}}
						/>
					}
					label={"I’m ok with paying for payroll software."}
				/>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				className={"button-grid"}
			>
				<Grid
					item
				>
					<Button
						className={"default-black-button button-height"}
						disabled={disabled}
						onClick={() => {
							basicSetup({
								"employers": {
									step: "automatic_enrolment",
									has_pension_scheme: stepTwo.has_pension_scheme,
									current_pension_provider: stepTwo.current_pension_provider === "Other" ? stepTwo.other_pension_provider : stepTwo.current_pension_provider,
									pension_selection: stepTwo.pension_selection,
									paid_version: stepTwo.paid_version,
								}
							});
						}}
					>
						CONTINUE WITH PAID VERSION
					</Button>
				</Grid>
				<Grid
					item
					sx={{
						marginLeft: '1vw',
					}}
				>
					<Button
						className={"default-white-button button-height"}
						onClick={() => {
							setStepTwo({
								...stepTwo,
								has_pension_scheme: false,
								pension_selection: "collegia",
								inner_step: 0,
							});
						}}
					>
						USE COLLEGIA AND HAVE IT FOR FREE
					</Button>
				</Grid>
			</Grid>

		</Grid>
	);
}