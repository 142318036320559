import { Grid } from "@mui/material";
import { StyleAppBar } from "../StyleAppBar";
import { LeftMenu } from "../LeftMenu";
import {me} from "../../actions/Profile";
import {connect} from "react-redux";
import {useEffect} from "react";
import {clsx} from "clsx";

function Layout({
	children,
	backLink,
	showBackButton,
	activePage,
	leftMenuFixed = false,
	expanded = true,
	activeSubpage = "",
	isOnboarding = false,
	lockMenuItems = [],
	user,
	me = () => {},
	isLoggedLayout = true,
	custom = false,
	customOnClick = () => {},
}) {

	useEffect(() => {
		if (!isLoggedLayout) return;
		me();
	}, [isLoggedLayout, me]);

	return (
		<Grid container id={"default-layout"}>
			<StyleAppBar
				showBackButton={showBackButton}
				backLink={backLink}
				user={user}
				custom={custom}
				customOnClick={customOnClick}
			/>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"default-content-container"}
			>
				{
					isLoggedLayout && (
						<Grid xl={3} lg={3} md={3} sm={3} xs={3} item>
							<LeftMenu
								activePage={activePage}
								isFixed={leftMenuFixed}
								expanded={expanded}
								activeSubpage={activeSubpage}
								isOnboarding={isOnboarding}
								lockMenuItems={lockMenuItems}
							/>
						</Grid>
					)
				}

				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{ flex: 1 }}
				>
					<Grid
						container
						className={clsx({
							"content-container": isLoggedLayout,
							"content-container-not-logged": !isLoggedLayout,
						})}
					>
						{children}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
	} = state;

	return {
		...Profile,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout)


