import SignUp from "./SignUp";
import { applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import Profile from "./Profile";
import Employer from "./Employer";
import BanksMandates from "./BanksMandates";
import PaySchedule from "./PaySchedule";
import Employees from "./Employees";
import PayScheduleRun from "./PayScheduleRun";
import PayItem from "./PayItem";
import EnhancedPolicy from "./EnhancedPolicy";
import ForgotPassword from "./ForgotPassword";
import SickLeave from "./SickLeave";
import Reports from "./Reports";
import EmployerPaymentSummary from "./EmployerPaymentSummary";
import PayrollJournal from "./PayrollJournal";
import AutoEnrolment from "./AutoEnrolment";
import Tutorial from "./Tutorials";

const reducers = combineReducers({
	SignUp,
	Profile,
	Employer,
	BanksMandates,
	PaySchedule,
	Employees,
	PayScheduleRun,
	PayItem,
	EnhancedPolicy,
	ForgotPassword,
	SickLeave,
	Reports,
	EmployerPaymentSummary,
	PayrollJournal,
	AutoEnrolment,
	Tutorial,
});

export default configureStore({ reducer: reducers }, applyMiddleware(thunk));
