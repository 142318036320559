import {resolveReduxState} from "../../utils/Helpers";
import {
	BASIC_SETUP,
	COMPLETE_ONBOARDING, CREATE_ADMIN,
	CREATE_EMPLOYER,
	CREATE_PAY_ITEM, CREATE_SALARY_ITEM,
	DELETE_PAY_ITEM, GET_EMPLOYER, UPDATE_AUTOMATIC_EPS, UPDATE_EMPLOYER_PENSION_SETTINGS,
	UPDATE_PAY_ITEM
} from "../../actions/Employer/types";

const initial_state = {
	basic_setup_loading: false,
	already_have_collegia_account: false,
	employer_loading: false,
	employer: {},
	pay_item_loading: false,
	admins: [],
	error: false,
	error_message: "",
}

const Employer = (state = initial_state, action = false) => {
	if (action) {
		switch(action.type) {

			case BASIC_SETUP:
			case COMPLETE_ONBOARDING:
			case CREATE_EMPLOYER:
			case CREATE_PAY_ITEM:
			case UPDATE_PAY_ITEM:
			case DELETE_PAY_ITEM:
			case UPDATE_EMPLOYER_PENSION_SETTINGS:
			case GET_EMPLOYER:
			case CREATE_SALARY_ITEM:
			case CREATE_ADMIN:
			case UPDATE_AUTOMATIC_EPS:
				return resolveReduxState(state, action);

			default:
				return state;
		}
	}

	return state;
}

export default Employer;