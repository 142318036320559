import Grid from "@mui/material/Grid";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import React from "react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {differenceInDays, parseISO} from "date-fns";
import {capitalize} from "@mui/material";


function _renderStyledTypography(text1, text2) {
	return (
		<Grid
			container
			direction={"row"}
			className={"sick-leave-summary-description-text"}
			width={"100%"}
		>
			<Grid item>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={13}
					color={"black"}
				>
					{text1}
				</StyleTypography>
			</Grid>
			<Grid sx={{ flexGrow: 1 }}></Grid>
			<Grid item>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={13}
					color={"black"}
					fontWeight={"bold"}
				>
					{text2}
				</StyleTypography>
			</Grid>

		</Grid>
	);
}
/**
 * @returns {JSX.Element}
 * @constructor
 */
export const SickLeaveSummary = (
	{
		employee = {},
		sickLeaveDetails= {},
		setStep = () => {},
		setSickLeaveDetails = () => {},
		createSickLeaveEmployee = () => {},
		days_unavailable = 0,
		waiting_days = 0,
		payable_days = 0,
	}
): JSX.Element => {
	return (
		<Grid
			className={"sick-leave-summary"}
			width={"100%"}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<StyleTypography
					fontSize={40}
					fontSizeMedium={30}
					fontWeight={'bold'}
					component={"h1"}
				>
					Summary
				</StyleTypography>
			</Grid>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={13}
					color={"#ABAEB7"}
				>
					Confirm the details below before saving.
				</StyleTypography>
			</Grid>

			<Grid
				container
				className={"sick-leave-summary-description-content"}
				width={"100%"}
			>
				<Grid width={"100%"}>
					<Grid
						justifyContent={"space-between"}
						container
						direction={"row"}
						className={"sick-leave-summary-description-title-margin"}
						width={"100%"}
					>
						<StyleTypography
							fontSize={25}
							fontSizeMedium={18}
							color={"black"}
							fontWeight={"bold"}
						>
							Absence
						</StyleTypography>
						<Grid>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								color={"#ABAEB7"}
							>
								Edit
							</StyleTypography>
						</Grid>
					</Grid>
					<Grid>
						{_renderStyledTypography("First sick day", sickLeaveDetails?.first_sick_day)}
						{_renderStyledTypography("Last sick day", sickLeaveDetails?.last_sick_day ?? "N/A")}
						{_renderStyledTypography("Days unavailable", days_unavailable)}
						<Grid>
							<Divider className={"sick-leave-summary-divider"}/>
						</Grid>
					</Grid>
				</Grid>
				<Grid width={"100%"}>
					<Grid
						justifyContent={"space-between"}
						container
						direction={"row"}
						className={"sick-leave-summary-description-title-margin"}
						width={"100%"}
					>
						<StyleTypography
							fontSize={25}
							fontSizeMedium={18}
							color={"black"}
							fontWeight={"bold"}
						>
							Employment
						</StyleTypography>
						<Grid>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								color={"#ABAEB7"}
							>
								Edit
							</StyleTypography>
						</Grid>
					</Grid>
					<Grid>
						{_renderStyledTypography("Average weekly earnings", "£ " + sickLeaveDetails?.average_weekly_earnings)}
						{_renderStyledTypography("Working pattern",
							Object.entries(employee.working_pattern).filter(item => item[1] === true).map(
								(item, index) => index === 0 ? capitalize(item[0]) :  ", " + capitalize(item[0])
							))
						}
						<Grid>
							<Divider className={"sick-leave-summary-divider"}/>
						</Grid>
					</Grid>
				</Grid>
				<Grid width={"100%"}>
					<Grid
						justifyContent={"space-between"}
						container
						direction={"row"}
						className={"sick-leave-summary-description-title-margin"}
					>
						<StyleTypography
							fontSize={25}
							fontSizeMedium={18}
							color={"black"}
							fontWeight={"bold"}
						>
							Statutory Sick Pay
						</StyleTypography>
						{
							<Grid>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={"#ABAEB7"}
								>
									Edit
								</StyleTypography>
							</Grid>
						}
					</Grid>
					{
						(sickLeaveDetails?.average_weekly_earnings < 123 || differenceInDays(parseISO(sickLeaveDetails?.last_sick_day),parseISO(sickLeaveDetails?.first_sick_day)) < 3) ? (
							<Grid>
								<StyleTypography
									fontSize={20}
									fontSizeMedium={15}
									color={"black"}
									fontWeight={"bold"}
								>
									{employee?.name} is not eligible for Statutory Sick Pay
								</StyleTypography>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={12}
									color={"black"}
								>
									Employee’s must earn an average of at least £123 per week and have been ill for at
									least 4 days in a row (including non-working days) to qualify. This leave will still
									reduce employee pay.
								</StyleTypography>
							</Grid>
						) : (
							<Grid>
								{_renderStyledTypography("Eligibility", "Eligible")}
								{_renderStyledTypography("Waiting days", waiting_days)}
								{_renderStyledTypography("Payable days", payable_days)}
							</Grid>
						)
					}
					<Grid>
						<Divider className={"sick-leave-summary-divider"}/>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				direction={"row"}
			>
				<Button
					className={"sick-leave-summary-continue-button"}
					onClick={() => {
						createSickLeaveEmployee({
							employees: {
								employee_id: employee.id,
								first_sick_day: sickLeaveDetails.first_sick_day,
								last_sick_day: sickLeaveDetails.last_sick_day,
								work_week: employee.working_pattern,
								average_weekly_earnings: sickLeaveDetails.average_weekly_earnings,
								payable_days: payable_days === "N/A" ? null : payable_days,
								waiting_days: waiting_days === "N/A" ? null : waiting_days,
								deducted_days: days_unavailable === "N/A" ? null : days_unavailable,
								last_sick_day_radio: sickLeaveDetails.last_sick_day_radio,
								period_start_date: employee?.pay_schedule?.active_pay_run?.period_start_date,
								period_end_date: employee?.pay_schedule?.active_pay_run?.period_end_date,
							}
						});
					}}
				>
					<StyleTypography
						color={"white"}
						fontWeight={"bold"}
						fontSize={16}
						fontSizeMedium={13}
					>
						Save and close
					</StyleTypography>
				</Button>

				<Button
					className={"sick-leave-summary-discard-button"}
					onClick={() => {
						setSickLeaveDetails({
							first_sick_day: null,
							last_sick_day: null,
							last_sick_day_radio: false,
							average_weekly_earnings: 0.00,
						});
						setStep(0);
					}}
				>
					<StyleTypography
						color={"#000"}
						fontWeight={"bold"}
						fontSize={16}
						fontSizeMedium={13}
					>
						Discard
					</StyleTypography>
				</Button>
			</Grid>
		</Grid>
	)
}