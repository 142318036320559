import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Icon } from '@collegia-partners/ui-kit';


export default function Check({ value, caseError, error, message }) {
	const [status, setStatus] = useState(
		'inactive',
	);

	useEffect(() => {
		if (!value || !value.length) {
			setStatus('inactive');
			return;
		}

		if (error.includes(caseError)) {
			setStatus('error');
			return;
		}

		setStatus('checked');
	}, [value, caseError, error]);

	return (
		<div className={styles.Wrapper}>
			<Icon
				iconName={
					status === 'checked'
						? 'check'
						: status === 'inactive'
							? 'gray-circle'
							: status
				}
				className={styles.Icon}
			/>
			<span className={`${styles.Message} ${styles[status]}`}>
				{message}
			</span>
		</div>
	);
}
