import {
	CREATE_LEAD,
	CREATE_USER,
	CREATE_USER_FAILURE,
	SEARCH_COMPANIES,
	SEARCH_DIRECTORS,
	VERIFY_COMPANY,
	VERIFY_EMAIL
} from "./types";
import Request from "../../utils/Request";
import {toast} from "react-toastify";

export const createLead = (data) => dispatch => {
	dispatch({
		type: CREATE_LEAD,
		signup_loading: true
	});
	if (data) {
		return new Request.post('/api/leads',data)
			.then(response => {
				dispatch({
					type: CREATE_LEAD,
					lead: {
						lead_id: response.data.id
					},
					signup_loading: false,
				});
			})
			.catch(error => {
				dispatch({
					type: CREATE_LEAD,
					signup_loading: false
				});
				throw error;
			})
	}
};

export const createUser = (data) => dispatch => {
	dispatch({
		type: CREATE_USER,
		signup_loading: true
	});
	if (data) {
		return new Request.post('/api/employers/users',data)
			.then(response => {
				if (response.status === 201) {
					localStorage.clear();
					localStorage.setItem('fpEmployerToken', response.data.token);

					window.location.href = '/onboarding/dashboard';
				}
			})
			.catch(error => {
				dispatch({
					type: CREATE_USER_FAILURE,
					signup_loading: false
				});
				console.error(error);
			})
	}
}

export const signUpCollegia = (data) => dispatch => {
	dispatch({
		type: CREATE_USER,
		signup_loading: true,
		signup_error_message: "",
		signup_error_code: "",
	});
	if (data) {
		return new Request.post('/api/employers/users/signup-with-collegia',data)
			.then(response => {
				if (response.status === 201) {
					localStorage.clear();
					localStorage.setItem('fpEmployerToken', response.data.token);

					window.location.href = '/onboarding/dashboard';
				}
				dispatch({
					type: CREATE_USER_FAILURE,
					signup_loading: false
				});
			})
			.catch(error => {
				if (error.response.status === 400) {
					toast.error("Invalid email or password");
				}
				dispatch({
					type: CREATE_USER_FAILURE,
					signup_loading: false
				});
				console.error(error)
			})
	}
}

export const verifyEmail = (data) => dispatch => {
	if (data) {
		new Request.post("/api/employers/users/verify-email", data)
			.then(response => {
				dispatch({
					type: VERIFY_EMAIL,
					signup_error: false,
					signup_error_message: ""
				});
			})
			.catch(e => {
				dispatch({
					type: VERIFY_EMAIL,
					signup_error: true,
					signup_error_message: e.response.data.email
				});
				console.error(e);
			})
	}
}

export const verifyCompany = (data) => dispatch => {
	if (data) {
		dispatch({
			type: VERIFY_COMPANY,
			signup_loading: true
		});

		new Request.get(`api/employers/verify-company/${data.employers.company_number}`)
			.then(response => {
				dispatch({
					type: VERIFY_COMPANY,
					signup_loading: false,
					signup_error: false,
					signup_error_message: "",
					can_proceed: response.data.can_proceed,
				});
			})
			.catch(e => {
				dispatch({
					type: VERIFY_EMAIL,
					signup_loading: false,
					signup_error: true,
				});
				console.error(e);
			})
	}
}

export const searchCompanies = (data) => dispatch => {
	if (data) {
		dispatch({
			type: SEARCH_COMPANIES,
			signup_loading: true
		});

		new Request.post("api/employers/search-companies", data)
			.then(response => {
				dispatch({
					type: SEARCH_COMPANIES,
					signup_loading: false,
					companies: response.data
				});
			})
			.catch(e => {
				dispatch({
					type: SEARCH_COMPANIES,
					signup_loading: false
				});
				console.error(e);
			});
	}
}

export const getDirectors = (data) => dispatch => {
	if (data) {
		dispatch({
			type: SEARCH_DIRECTORS,
			signup_loading: true
		});

		new Request.post("api/employers/get-directors", data)
			.then(response => {
				dispatch({
					type: SEARCH_DIRECTORS,
					signup_loading: false,
					directors: response.data
				});
			})
			.catch(e => {
				dispatch({
					type: SEARCH_DIRECTORS,
					signup_loading: false,
				});
				console.error(e);
			});
	}
}