import Grid from "@mui/material/Grid";
import React from "react";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Divider from "@mui/material/Divider";
import StyleButton from "../../components/StyledComponents/StyleButton";
import WhiteAndBlackWarning from "../../images/svg/white-and-black-warning.svg";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const CloseTaxYearWarning = ({
	setStep = {},
	push = () => {},
}): JSX.Element => {
	const pageText = [
		{
			title: "P60 Distribution:",
			subTitle: "We will generate and send P60 forms to all employees. These forms summarise the total pay, " +
				"tax, and National Insurance contributions for the entire tax year. You will also be able to view " +
				"their P60s directly in their profiles within the FreePayroll system.",
		},
		{
			title: "Final EPS Submission:",
			subTitle: "We will submit the final Employer Payment Summary (EPS) to HMRC. This submission informs HMRC " +
				"that all payroll activities for the current tax year are complete, and it includes any adjustments " +
				"such as statutory payments or recoverable amounts.",
		},
	// 	{
	// 		title: "Final FPS Submission:",
	// 		subTitle: "A final Full Payment Submission (FPS) will also be sent to HMRC. This report details the pay and deductions for each employee for the last payroll of the tax year and confirms that this is the final FPS for the year.",
	// 	}
	];

	return (
		<Grid
			container
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<Grid>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={30}
						fontWeight={'bold'}
						component={'h1'}
					>
						Closing the Tax Year is Irreversible
					</StyleTypography>
				</Grid>
				<Grid className={'sub-title-container'} maxWidth={"85%"}>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={14}
						color={'#ABAEB7'}
					>
						You are about to close the current tax year. Please note that this action is
						irreversible, and once completed, no further changes can be made within FreePayroll.
						Here’s what will happen:
					</StyleTypography>
				</Grid>
			</Grid>

			<Divider className={'divider details-divider'}/>
			<Grid maxWidth={"85%"} container>
				{
					pageText?.map((item, index) => (
						<Grid
							key={index}
							marginBottom={index !== item?.length ? "30px" : "0px"}
						>
							<StyleTypography
								fontSize={22}
								fontSizeMedium={16}
								color={'black'}
								fontWeight={"bold"}
							>
								{item?.title}
							</StyleTypography>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={14}
								color={'#ABAEB7'}
							>
								{item?.subTitle}
							</StyleTypography>
						</Grid>
					))
				}
			</Grid>
			<Grid
				className={"warning-background"}
				alignContent={"center"}
				alignItems={"center"}
				container
				direction={"row"}
			>
				<Grid item xs={1}>
					<img
						className={"white-and-black-warning"}
						src={WhiteAndBlackWarning}
						alt={"white-and-black-warning"}
					/>
				</Grid>

				<Grid item xs={11}>
					<StyleTypography
						fontSize={16}
						fontSizeMedium={12}
						color={'white'}
					>
						If you need to make any corrections to payments made during this tax year, you will need
						to do so outside of FreePayroll, as we do not allow the rollback of closed tax years.
						Before closing the tax year, please ensure all pay schedules have been completed and any
						necessary adjustments have been made. Once the tax year is closed, no further amendments
						can be made within FreePayroll.
					</StyleTypography>
				</Grid>
			</Grid>

			<Grid
				container
			>
				<Grid item marginRight={"20px"} sm={3}>
					<StyleButton
						onClick={() => setStep(1)}
						customStyle={{height: "3.5vw"}}
					>
						<StyleTypography
							fontSize={16}
							fontSizeMedium={12}
							color={'white'}
							fontWeight={"bold"}
						>
							Continue
						</StyleTypography>
					</StyleButton>
				</Grid>
				<Grid
					item
					sm={3}
					paddingBottom={"80px"}
				>
					<StyleButton
						isWhiteButton={true}
						onClick={() => push("/main")}
						customStyle={{height: "3.5vw"}}
					>
						<StyleTypography
							fontSize={16}
							fontSizeMedium={12}
							color={'black'}
						>
							Cancel
						</StyleTypography>
					</StyleButton>
				</Grid>
			</Grid>
		</Grid>
	);
}
export default CloseTaxYearWarning
