import {
	GET_EPS_REPORT,
	GET_FPS_REPORT,
} from "./types";
import Request from "../../utils/Request";
import {toast} from "react-toastify";
import {RUN_PAYROLL} from "../PayScheduleRun/types";

export const getFpsReport = () => dispatch => {

	dispatch({
		type: GET_FPS_REPORT,
		report_loading: true,
		fps_report: [],
	});

	return new Request.get(`/api/employers/reports/fps`, )
		.then(response => {
			dispatch({
				type: GET_FPS_REPORT,
				report_loading: false,
				fps_report: response?.data?.data,
			});
		})
		.catch(error => {
			console.error(error);
			if (error.response.status !== 500) {
				toast.error('Something went wrong! Please contact support.');
			}
			dispatch({
				type: GET_FPS_REPORT,
				report_loading: false,
				fps_report: [],
			});
		})
};

export const resubmitFps = (data) => dispatch => {

	dispatch({
		type: GET_FPS_REPORT,
		report_loading: true,
	});

	return new Request.post(`/api/employers/resubmit-fps`,data)
		.then(response => {
			dispatch({
				type: GET_FPS_REPORT,
				report_loading: false,
			});
			window.location.reload();
			toast.success('FPS Resubmitted Successfully');
		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: GET_FPS_REPORT,
				report_loading: false,
			});
		})
};

export const submitEPS = (data) => dispatch => {

	dispatch({
		type: GET_EPS_REPORT,
		report_loading: true,
	});

	return new Request.post(`/api/employers/submit-manually-eps`,data)
		.then(response => {
			dispatch({
				type: GET_EPS_REPORT,
				report_loading: false,
			});
			window.location.reload();
			toast.success('EPS Resubmitted Successfully');
		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: GET_EPS_REPORT,
				report_loading: false,
			});
		})
};

export const getEpsReport = () => dispatch => {

	dispatch({
		type: GET_EPS_REPORT,
		report_loading: true,
		eps_reports: [],
	});

	return new Request.get(`/api/employers/reports/eps`, )
		.then(response => {
			dispatch({
				type: GET_EPS_REPORT,
				report_loading: false,
				eps_reports: response?.data?.data,
			});
		})
		.catch(error => {
			console.error(error);
			if (error.response.status !== 500) {
				toast.error('Something went wrong! Please contact support.');
			}
			dispatch({
				type: GET_EPS_REPORT,
				report_loading: false,
				eps_reports: [],
			});
		})
};

export const downloadEPS = (data) => dispatch => {
	dispatch({
		type: RUN_PAYROLL,
		pay_schedule_run_loading: true
	});
	if (data) {
		return new Request.post('/api/employers/download-eps',data, { responseType: 'blob' })
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'eps_file');
				document.body.appendChild(link);
				link.click();

				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});

			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
			})
	}
};

export const downloadFPS = (data) => dispatch => {
	dispatch({
		type: RUN_PAYROLL,
		pay_schedule_run_loading: true
	});
	if (data) {
		return new Request.post('/api/employers/download-fps',data, { responseType: 'blob' })
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'fps_file1');
				document.body.appendChild(link);
				link.click();

				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});

			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
			})
	}
};

export const downloadP45 = (data) => dispatch => {
	dispatch({
		type: RUN_PAYROLL,
		pay_schedule_run_loading: true
	});
	if (data) {
		return new Request.post('/api/employees/p45/download',data, { responseType: 'blob' })
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'p45_file');
				document.body.appendChild(link);
				link.click();

				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});

			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
			})
	}
};