import React from 'react';
import Button from "@mui/material/Button";

const StyleButton = ({
	color = '#fff',
	backgroundColor = '#000',
	onClick,
	isWhiteButton = false,
	isBlueButton = false,
	isRedButton = false,
	border = '1px solid #000',
	children,
	disabled = false,
	customStyle = {},
	...props
}) => {
	if (isWhiteButton) {
		color = '#000';
		backgroundColor = '#fff';
	}

	if (isBlueButton) {
		color = '#fff';
		backgroundColor = '#0160FD';
		border = '1px solid #0160FD';
	}

	if (isRedButton) {
		color = '#fff';
		backgroundColor = '#FF0000';
		border = '1px solid #FF0000';
	}

	if (disabled) {
		backgroundColor = '#e1e1e1';
		color = '#939393';
		border = '1px solid #cccccc';
	}

	const styles = {
		backgroundColor,
		color,
		borderRadius: 0,
		border,
		textTransform: 'capitalize',
		paddingRight: 20,
		paddingLeft: 20,
		...customStyle,
	};


	return (
		<Button
			fullWidth
			style={styles}
			onClick={onClick}
			{...props}
			disabled={disabled}
		>
			{children}
		</Button>
	);
};

export default StyleButton;