import FullLogoBlack from "../images/svg/Logos/new-fp-logo-blakc-blue-horizontal.svg";
import React, {useCallback} from "react";
import {FreepayrollButton, FreepayrollTextField, Typography} from "@collegia-partners/ui-kit";
import {z} from "zod";
import Request from "../utils/Request";
import {toast} from "react-toastify";
import {zodResolver} from "@hookform/resolvers/zod";
import {useForm} from "react-hook-form";
import Check from "../components/common/Check/index";
import {useSearchParams} from "react-router-dom";

function PublicHeader() {
	return (
		<div className="header">
			<img src={FullLogoBlack} alt="FreePayroll" className="logo" />
			<button className="button-circle">?</button>
		</div>
	);
}

const formSchema = z
	.object({
		password: z
			.string()
			.min(8, 'MIN_LENGTH')
			.regex(/[A-Za-z]/, 'LETTER_REQUIRED')
			.regex(/[0-9]/, 'NUMBER_REQUIRED'),
		passwordRepeat: z.string(),
	})
	.refine((data) => data.password === data.passwordRepeat, {
		path: ['passwordRepeat'],
		message: 'Passwords are not the same',
	});

const ResetPassword = () => {
	
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
		watch,
	} = useForm({
		mode: 'onChange',
		resolver: zodResolver(formSchema),
	});
	
	const [searchParams] = useSearchParams();
	
	const onSubmit = useCallback(async () => {
		const token = searchParams.get('token');
		await toast.promise(
			Request.post(`api/employers/users/update-password/${token}`, {
				"users": {
					"password": watch('password')
				}
			}).then(() => {
				setTimeout(() => {
					window.location.href = '/login';
				}, 1000);
			}),
			{
				pending: 'Changing password...',
				success: 'Password changed successfully',
				error: 'An error occurred while changing password, please contact support',
			}
		);
	}, [searchParams, watch]);
	
	const password = watch('password');
	
	return (
		<div>
			<PublicHeader />
			<div style={{
				display: "flex",
				flexDirection: "column",
				justifySelf: "center",
				gap: "3vw",
				width: "fit-content"
			}}>
				<div>
					<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
						Set a new password
					</Typography>
					<Typography variant={"body"} size={"medium"} color={"black"}>
						Please create a new password for your account.
					</Typography>
				</div>
				<form
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '0.781vw'
					}}
					onSubmit={handleSubmit(onSubmit)}
				>
					<FreepayrollTextField
						fieldType="password"
						label="Set password"
						{...register('password')}
						error={!!errors.password}
					/>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '0.781vw'
						}}
					>
						<Check
							value={password}
							error={errors.password?.message || ''}
							caseError="MIN_LENGTH"
							message="Use 8 or more characters."
						/>
						<Check
							value={password}
							error={errors.password?.message || ''}
							caseError="LETTER_REQUIRED"
							message="Use a minimum of one letter."
						/>
						<Check
							value={password}
							error={errors.password?.message || ''}
							caseError="NUMBER_REQUIRED"
							message="Use a minimum of one number."
						/>
					</div>
					<FreepayrollTextField
						fieldType="password"
						label="Confirm password"
						{...register('passwordRepeat')}
						error={!!errors.passwordRepeat}
						helperText={errors.passwordRepeat?.message}
					/>
					<div style={{
						display: "flex",
						flexDirection: "column",
						gap: "1vw",
					}}>
						<FreepayrollButton
							variant={"primary"}
							size={"medium"}
							isLoading={isSubmitting}
						>
							Change Password
						</FreepayrollButton>
						<FreepayrollButton
							variant={"white-button"}
							size={"medium"}
							isLoading={isSubmitting}
						>
							Back to login
						</FreepayrollButton>
					</div>
				</form>
			</div>
		</div>
	);
}

export default ResetPassword;