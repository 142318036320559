import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import Loading from "../../components/Loading";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Divider from "@mui/material/Divider";
import Warning from "../../images/svg/ReportsIcon/p60-warning.svg";
import DownloadIcon from "../../images/svg/ReportsIcon/download-icon.svg";

const renderGrids = (index) => {

	switch (index) {
		case 0:
			return (
				<Grid
					item
					className={"reports-p60-last-year"}
				>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={12}
						color={"white"}
					>
						Available in April, 2024
					</StyleTypography>
				</Grid>
			);
		default:
			return (
				<Grid
					item
					justifyContent={"space-between"}
				>
					<Grid container direction={"row"} alignItems={"center"}>
						<Grid item paddingRight={"10px"}>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={12}
								color={"black"}
								fontWeight={"bold"}
							>
								Download all
							</StyleTypography>
						</Grid>
						<Grid item className={"mouse-pointer"}>
							<img
								className={"img"}
								src={DownloadIcon}
								alt={"Download"}
							/>
						</Grid>
					</Grid>

				</Grid>
			);
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsP60 = ({
	me = () => {},
	user = {},
	user_loading = false,
}): JSX.Element => {

	const [reportsDownloads] = useState([
		"2023/2024",
		"2022/2023",
		"2021/2022"
	]);

	useEffect(() => {
		if (!user.id) {
			me();
		}
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [me, user?.id]);

	if (user_loading) {
		return <Loading/>;
	}
	return (
		<Grid
			container
			justifyContent={"center"}
			id={"reports-p60"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={""}
						activeSubpage={""}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
						className={"reports-p60-item"}
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							justifyContent={"space-between"}
							alignItems={"center"}
						>
							<StyleTypography
								fontSize={40}
								fontSizeMedium={30}
								fontWeight={"bold"}
							>
								P60s
							</StyleTypography>
							<StyleTypography
								fontSize={13}
								color={"#ABAEB7"}
							>
								All employees must be given a P60 at the end of each tax year. It details the tax that <br/>
								has been paid by an employee on their earnings.
							</StyleTypography>
							<Grid
								paddingTop={"30px"}
								paddingBottom={"30px"}
								width={"85%"}
							>
								<Divider/>
							</Grid>
							<Grid
								className={"reports-p60-description"}
								container direction={"row"}
								alignItems={"center"}
							>
								<Grid item xs={0.6}>
									<img
										className={"reports-p60-description-warning-image"}
										src={Warning}
										alt={"Warning"}
									/>
								</Grid>
								<Grid item>
									<StyleTypography
										fontSize={18}
										fontSizeMedium={13}
										color={"black"}
										fontWeight={"bold"}
									>
										Your P6Os will be automatically sent to your employees on the 20th April, 2023 for the 2022/23 tax year.
									</StyleTypography>
								</Grid>
							</Grid>
							<Grid
								className={"reports-p60-download-title"}
							>
								<StyleTypography
									fontSize={25}
									fontSizeMedium={18}
									color={"black"}
									fontWeight={"bold"}
								>
									Download
								</StyleTypography>
							</Grid>
							<Grid
								className={"reports-p60-download-sub-title"}
							>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={"#ABAEB7"}
								>
									You can download individual P6O documents by visiting individual employee profiles.
								</StyleTypography>
							</Grid>
							{
								reportsDownloads.length > 0 &&
								<Grid
									className={"reports-p60-item-map"}
								>
									{
										reportsDownloads.map((download, index) => {
											return (
												<Grid
													key={index}
													value={download}
												>
													<Grid
														container
														direction={"row"}
														justifyContent={"space-between"}
														alignItems={"center"}
													>
														<Grid
															item
														>
															<StyleTypography
																fontSize={18}
																fontSizeMedium={13}
																color={"black"}
																fontWeight={index === 0 ? "bold" : ""}
															>
																{download}
															</StyleTypography>
														</Grid>
														{renderGrids(index)}
														{
															reportsDownloads.length-1 !== index
																? <Grid
																	className={"reports-p60-item-map-divider"}
																>
																	<Divider/>
																</Grid>
																: ""
														}
													</Grid>
												</Grid>
											)
										})
									}
								</Grid>
							}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
	} = state;

	return {
		...Profile,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsP60)