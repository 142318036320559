import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";

function validateFields(
	typeOfPayItem = "",
	payItemBasicDetails = {},
	payItemRecurringDetails = {},
) {
	if (typeOfPayItem === "New One-off Pay Item") {
		if (payItemBasicDetails?.payItemName.length === 0 || payItemBasicDetails?.paymentType.length === 0) {
			return true;
		}
	} else if (typeOfPayItem === "New Recurring Pay Item") {
		if (
			payItemBasicDetails?.payItemName.length === 0 ||
			payItemBasicDetails?.paymentType.length === 0 ||
			payItemRecurringDetails?.payrollAmount === 0.00 ||
			payItemRecurringDetails?.payrollAmount.length === 0 ||
			(payItemRecurringDetails?.duration === false && payItemRecurringDetails?.untilCancelled === false) ||
			(payItemRecurringDetails?.duration === true && payItemRecurringDetails?.payrolls === 0) ||
			payItemRecurringDetails?.perPayroll === " "
		) {
			return true;
		}
	} else {
		return false;
	}
}

function returnPaymentType(paymentType) {
	let splitPaymentType = paymentType.split(" ");
	return splitPaymentType[1].charAt(0).toUpperCase() + splitPaymentType[1].slice(1);
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayItemCheckBox = ({
	typeOfPayItem = "",
	oneOff = false,
	payItemBasicDetails = {},
	payItemRecurringDetails = {},
	setStep = () => {
	},
	setPayItemBasicDetails = () => {
	},
	setPayItemRecurringDetails = () => {
	},
	createPayItem = () => {
	},
	updatePayItem = () => {
	},
}): JSX.Element => {
	return (
		<Grid
			container
		>
			{
				oneOff && <Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					container
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<Grid>
						<StyleTypography
							fontSize={40}
							fontSizeMedium={30}
							fontWeight={"bold"}
						>
							Pay item: {payItemBasicDetails?.payItemName}
						</StyleTypography>
					</Grid>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
							alignItems={"center"}
						>
							<Grid
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								item
								paddingTop={"10px"}
							>
								<StyleTypography
									fontSize={16}
									fontSizeMedium={13}
									color={"#ABAEB7"}
								>
									One-off pay items can be applied and reuse in every payroll for every employee.
								</StyleTypography>
							</Grid>
							<Grid
								className={"pay-items-divider"}
							>
								<Divider/>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						container
						direction={"row"}
						className={"employee-month-award"}
						onClick={() => {
							setStep(1);
						}}
					>
						<Grid
							item
							xs={9}
						>
							<StyleTypography
								fontSize={18}
								fontWeight={"bold"}
								color={"black"}
								fontSizeMedium={13}
							>
								{payItemBasicDetails?.payItemName}
							</StyleTypography>
							<StyleTypography
								fontSize={16}
								color={"#ABAEB7"}
								fontSizeMedium={12}
							>
								{payItemBasicDetails?.paymentType}
							</StyleTypography>
						</Grid>
						<Grid
							item
							xs={3}
						>
							<Grid
								container
								direction={"row"}
								alignItems={"center"}
								justifyContent={"center"}
							>
								<Grid item xs={8}>
									<StyleTypography
										fontSize={16}
										fontSizeMedium={12}
										color={"black"}
									>
										Change
									</StyleTypography>
								</Grid>
								<Grid item xs={4} paddingTop={"3px"}>
									<ArrowForwardIosIcon className={"arrow-icon"}/>
								</Grid>
							</Grid>

						</Grid>
					</Grid>
				</Grid>
			}
			<Grid>
				<Grid container direction={"row"} >
					<Grid item paddingRight={"5px"}>
						<StyleTypography
							fontSize={16}
							color={"black"}
							fontSizeMedium={12}
							fontWeight={'bold'}
						>
							Pay items
						</StyleTypography>
					</Grid>
					<Grid item>
						<StyleTypography
							fontSize={16}
							fontSizeMedium={12}
							color={"#AAAEB7"}
						>
							( Select all that apply )
						</StyleTypography>
					</Grid>
				</Grid>
				<Grid>
					<Grid className={"pay-items-margin-check-box"} container>
						<FormControlLabel
							control={
								<Checkbox
									value={payItemBasicDetails?.taxable}
									checked={
										payItemBasicDetails.paymentType === "Net deduction" ||
										payItemBasicDetails.paymentType === "Net addition"
											? false
											: payItemBasicDetails?.taxable
									}
									icon={<CheckBoxOutlineBlankSharpIcon/>}
									checkedIcon={<CheckBoxSharpIcon/>}
									onChange={() =>
										setPayItemBasicDetails({
											...payItemBasicDetails,
											taxable: !payItemBasicDetails?.taxable,
										})
									}
									disabled={
										payItemBasicDetails.paymentType === "Net deduction" ||
										payItemBasicDetails.paymentType === "Net addition"
									}
								/>
							}
							label={
								<StyleTypography
									color={"black"}
									fontSize={16}
									fontSizeMedium={12}
									className={"checkbox-label"}
								>
									Taxable <br/>
									<span
										className={"span-check-box-style"}
									>
										We will include this amount in the total sum used to calculate income tax.
									</span>
								</StyleTypography>
							}
						/>
					</Grid>
					<Grid container  className={"pay-items-margin-check-box"}>
						<FormControlLabel
							control={
								<Checkbox
									checked={
										payItemBasicDetails.paymentType === "Net deduction" ||
										payItemBasicDetails.paymentType === "Net addition"
											? false
											: payItemBasicDetails?.subjectNationalInsurance
									}
									value={payItemBasicDetails?.subjectNationalInsurance}
									icon={<CheckBoxOutlineBlankSharpIcon/>}
									disabled={
											payItemBasicDetails.paymentType === "Net deduction" ||
											payItemBasicDetails.paymentType === "Net addition"
									}
									checkedIcon={<CheckBoxSharpIcon/>}
									onChange={() => {
										setPayItemBasicDetails({
											...payItemBasicDetails,
											subjectNationalInsurance: !payItemBasicDetails?.subjectNationalInsurance,
										})
									}}
								/>
							}
							label={
								<StyleTypography
									color={"black"}
									fontSize={16}
									fontSizeMedium={12}
								>
									Subject to National Insurance <br/>
									<span
										className={"span-check-box-style"}
									>
										We will include this amount in the total sum used to calculate national insurance.
									</span>
								</StyleTypography>
							}
						/>
						<Grid container className={"pay-items-margin-check-box"}>
							<FormControlLabel
								control={
									<Checkbox
										value={payItemBasicDetails?.pensionable}
										checked={
											payItemBasicDetails.paymentType === "Net deduction" ||
											payItemBasicDetails.paymentType === "Net addition"
												? false
												: payItemBasicDetails?.pensionable
										}
										icon={<CheckBoxOutlineBlankSharpIcon/>}
										checkedIcon={<CheckBoxSharpIcon/>}
										onChange={() =>
											setPayItemBasicDetails({
												...payItemBasicDetails,
												pensionable: !payItemBasicDetails?.pensionable
											})
										}
										disabled={
											payItemBasicDetails.paymentType === "Net deduction" ||
											payItemBasicDetails.paymentType === "Net addition"
										}
									/>
								}
								label={
									<StyleTypography
										color={"black"}
										fontSize={16}
										fontSizeMedium={12}
										className={"checkbox-label"}
									>
										Pensionable <br/>
										<span
											className={"span-check-box-style"}
										>
													We will include this amount in the total sum used to calculate pension contributions.
												</span>
									</StyleTypography>
								}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					className={"pay-items-margin-check-box"}
					container
					direction={"row"}
					spacing={2}
					marginTop={"10px"}
					paddingBottom={"100px"}
				>
					<Grid item>
						<Button
							className={"save-button"}
							variant={"contained"}
							fullWidth
							disabled={
								validateFields(
									typeOfPayItem,
									payItemBasicDetails,
									payItemRecurringDetails,
								)
							}
							onClick={() =>
								payItemBasicDetails?.id
									? updatePayItem(payItemBasicDetails?.id, {
										"pay_item": {
											name: payItemBasicDetails?.payItemName,
											is_gross: payItemBasicDetails?.paymentType === "Gross addition" ||
												payItemBasicDetails?.paymentType === "Gross deduction",
											is_net: payItemBasicDetails?.paymentType === "Net addition" ||
												payItemBasicDetails?.paymentType === "Net deduction",
											type: returnPaymentType(payItemBasicDetails?.paymentType),
											before_tax:
												payItemBasicDetails.paymentType === "Net addition"
													|| payItemBasicDetails.paymentType === "Net deduction"
														? false
														: payItemBasicDetails?.taxable,
											before_ni:
												payItemBasicDetails.paymentType === "Net addition" ||
													payItemBasicDetails.paymentType === "Net deduction"
														? false
														: payItemBasicDetails?.subjectNationalInsurance,
											is_benefit_in_kind: payItemBasicDetails?.is_benefit_in_kind,
											before_pension:
												payItemBasicDetails.paymentType === "Net addition" ||
												payItemBasicDetails.paymentType === "Net deduction"
													? false
													: payItemBasicDetails?.pensionable,
											is_recurring: typeOfPayItem !== "New One-off Pay Item",
											is_per_payroll_amount: payItemRecurringDetails?.perPayroll?.length > 0
												? payItemRecurringDetails?.perPayroll === "Per Payroll"
												: false,
											is_per_hour_amount:  payItemRecurringDetails?.perPayroll?.length > 0
												? payItemRecurringDetails?.perPayroll === "Per Hour"
												: false,
											amount: payItemRecurringDetails?.payrollAmount === 0.00
												? null
												: payItemRecurringDetails?.payrollAmount,
											fixed_duration: payItemRecurringDetails?.duration,
											number_of_payrolls: payItemRecurringDetails?.payrolls === 0
												? null
												: payItemRecurringDetails?.payrolls,
											is_custom: true,
											account_code:payItemBasicDetails?.payItemCode,
										}
									})
									: createPayItem({
										"pay_item": {
											name: payItemBasicDetails?.payItemName,
											account_code:payItemBasicDetails?.payItemCode,
											is_gross: payItemBasicDetails?.paymentType === "Gross addition" ||
												payItemBasicDetails?.paymentType === "Gross deduction",
											is_net: payItemBasicDetails?.paymentType === "Net addition"
												|| payItemBasicDetails?.paymentType === "Net deduction",
											type: returnPaymentType(payItemBasicDetails?.paymentType),
											before_tax: payItemBasicDetails?.taxable,
											before_ni: payItemBasicDetails?.subjectNationalInsurance,
											is_benefit_in_kind: payItemBasicDetails?.is_benefit_in_kind,
											before_pension: payItemBasicDetails?.pensionable,
											is_recurring: typeOfPayItem !== "New One-off Pay Item",
											is_per_payroll_amount: payItemRecurringDetails?.perPayroll?.length > 0
												? payItemRecurringDetails?.perPayroll === "Per Payroll"
												: false,
											is_per_hour_amount:  payItemRecurringDetails?.perPayroll?.length > 0
												? payItemRecurringDetails?.perPayroll === "Per Hour"
												: false,
											amount: payItemRecurringDetails?.payrollAmount === 0.00
												? null
												: payItemRecurringDetails?.payrollAmount,
											fixed_duration: payItemRecurringDetails?.duration,
											number_of_payrolls: payItemRecurringDetails?.payrolls === 0
												? null
												: payItemRecurringDetails?.payrolls,
											is_custom: true,
										}
									})
							}
						>
							<StyleTypography
								fontSize={14}
								color={"white"}
								fontWeight={"bold"}
								fontSizeMedium={12}
							>
								Save & Close
							</StyleTypography>
						</Button>
					</Grid>
					<Grid item>
						<Button
							className={"cancel-button"}
							variant={"contained"}
							fullWidth
							disabled={
								false
							}
							onClick={() => {
								setStep(0);
								setPayItemBasicDetails({
									payItemName: "",
									paymentType: " ",
									taxable: false,
									subjectNationalInsurance: false,
									is_benefit_in_kind: false,
									pensionable: false,
									payItemCode: "",
								});
								setPayItemRecurringDetails({
									payrollAmount: 0.00,
									perPayroll: " ",
									duration: true,
									payrolls: 0,
									untilCancelled: false,
								});

							}}
						>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={12}
								fontWeight={'bold'}
								color={"black"}
							>
								Cancel
							</StyleTypography>
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>

	);
}

export default PayItemCheckBox