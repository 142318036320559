import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Loading from "../../components/Loading";
import Divider from "@mui/material/Divider";
import StyleTable from "../../components/StyledComponents/StyleTable";
import {downloadBreakdown, downloadInvoice, getPensionPayments} from "../../actions/AutoEnrolment";
import {formatDateFromBackend} from "../../utils/Helpers";
import {Tab, Tabs} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const AutomaticEnrolmentPayments = ({
	me = () => {},
	user = {},
	user_loading = false,
	pension_payments = [],
	getPensionPayments = () => {},
	pension_payments_loading = false,
	downloadBreakdown = () => {},
	downloadInvoice = () => {},
}): JSX.Element => {

	useEffect(() => {
		if (!user.id) {
			me();
		}

		getPensionPayments();
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [me, user, getPensionPayments]);
	const [tabValue, setTabValue] = useState(0);
	const [popOver, setPopOver] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);

	const handleMenuClose = () => {
		setPopOver(false);
		setAnchorEl(null);
		setSelectedRow(null);
	};

	const columns = [
		{
			name: "export_date",
			label: "export_date",
			options: {
				filter: false,
				sort: false,
				display: false,
			},
		},
		{
			name: "schedule_id",
			label: "schedule_id Date",
			options: {
				filter: false,
				sort: false,
				display: false,
			},
		},
		{
			name: "json_agg",
			label: "json_agg Date",
			options: {
				filter: false,
				sort: false,
				display: false,
			},
		},
		{
			name: "payment_date",
			label: "Payment Date",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{formatDateFromBackend(value)}
					</StyleTypography>
				)
			},
		},
		{
			name: "status",
			label: "Payment Status",
			options: {
				filter: true,
				sort: false,
				hint: "Scheduled: Payment is scheduled to be made on the payment date. The payment amount is not final on this status." +
					"Exported: Payment has been exported to BACS and the payment amount is final on this status." +
					"Accepted: Payment has been paid to the pension provider." +
					"Rejected: Payment has been rejected. Please contact the pension provider for more information.",
				customBodyRender: (value) => value && (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value}
					</StyleTypography>
				)
			},
		},
		{
			name: "total_employees",
			label: "N of Employees",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => value && (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value}
					</StyleTypography>
				)
			},
		},
		{
			name: "total_contributions",
			label: "Payment Amount",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => {
					return (
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={"#000000"}
							fontWeight={"bold"}
						>
							{value}
						</StyleTypography>
					);
				}
			},
		},
		{
			name: "total_employee_contribution",
			label: "Total Employee Contribution",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => {
					return (
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={"#000000"}
							fontWeight={"bold"}
						>
							{value}
						</StyleTypography>
					);
				}
			},
		},
		{
			name: "total_employer_amount",
			label: "Total Employer Contribution",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => {
					return (
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={"#000000"}
							fontWeight={"bold"}
						>
							{value}
						</StyleTypography>
					);
				}
			},
		},
		{
			name: "id",
			label: "Actions",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) =>
					<Grid>
						<IconButton
							onClick={(event) => {
								setAnchorEl(event.currentTarget);
								setPopOver(true);
								setSelectedRow(tableMeta.rowData);
							}}
						>
							<MoreHorizIcon/>
						</IconButton>
						<Menu
							className={"team-member-menu"}
							open={popOver}
							anchorEl={anchorEl}
							onClose={() => {
								handleMenuClose();
							}}
						>
							<MenuItem
								className={"team-member-menu-item"}
								onClick={() => {
									if (selectedRow[0] !== null) {
										downloadBreakdown({
											ae: {
												export_date: selectedRow[0],
											}
										});
									} else {
										downloadBreakdown({
											ae: {
												schedule_id: selectedRow[1],
											}
										});
									}
									handleMenuClose();
								}}
							>
								<StyleTypography
									fontSize={15}
									fontSizeMedium={13}
									color={"#000"}
								>
									Download payment breakdown
								</StyleTypography>
							</MenuItem>
							<MenuItem
								className={"team-member-menu-item"}
								onClick={() => {
									downloadInvoice({
										ae: {
											data: selectedRow[2],
											total_value: selectedRow[6],
											date: selectedRow[3],
										}
									});
									handleMenuClose();
								}}
								disabled={selectedRow !== null && selectedRow[4] === "Upcoming"}
							>
								<StyleTypography
									fontSize={15}
									fontSizeMedium={13}
									color={"#000"}
								>
									Download invoice
								</StyleTypography>
							</MenuItem>
						</Menu>
					</Grid>
			}
		},
	];

	if (user_loading || pension_payments_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"auto-enrolment-page"}
		>
			<StyleAppBar
				showBackButton={true}
				backLink={"/main/auto-enrolment-menu"}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Auto Enrolment"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							justifyContent={"space-between"}
							alignItems={"center"}
						>
							<StyleTypography
								fontSize={40}
								fontSizeMedium={30}
								fontWeight={"bold"}
							>
								Pension payments
							</StyleTypography>
							<Grid
								sx={{
									paddingTop: "10px",
									paddingBottom: "30px"
								}}
							>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={"#ABAEB7"}
								>
									Here you can view all your pension payments, download invoices and
									download payment breakdown.
								</StyleTypography>
							</Grid>
						</Grid>
						<Divider className={"divider"}/>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
							className={"information-container"}
							direction={"row"}
						>
							<Grid
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								item
								container
							>
								{/*Total contributions under opt out*/}
								<Grid
									item
									xs={3}
									sx={{
										padding: "5px",
										border: "1px solid #E0E0E0",
									}}
									marginRight={2}
								>
									<StyleTypography
										fontSize={20}
										fontSizeMedium={15}
										color={"#000000"}
									>
										Total pension contributions<br/>
										under opt-out
									</StyleTypography>
									<StyleTypography
										fontSize={24}
										fontSizeMedium={18}
										fontWeight={"bold"}
									>
										£ {pension_payments?.under_opt_out?.total_contributions ?? 0.00}
									</StyleTypography>
								</Grid>

								{/*Total employees under opt out*/}
								<Grid
									item
									xs={3}
									sx={{
										padding: "5px",
										border: "1px solid #E0E0E0",
									}}
									marginRight={2}
								>
									<StyleTypography
										fontSize={20}
										fontSizeMedium={15}
										color={"#000000"}
									>
										Total employees<br/>
										under opt-out
									</StyleTypography>
									<StyleTypography
										fontSize={24}
										fontSizeMedium={18}
										fontWeight={"bold"}
									>
										{pension_payments?.under_opt_out?.total_employees ?? 0}
									</StyleTypography>
								</Grid>
							</Grid>

							<Grid
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								item
								container
							>
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
								>
									<Tabs
										className={"tabs-basic-setup"}
										value={tabValue}
										onChange={(event, newValue) => setTabValue(newValue)}
									>
										<Tab
											label={"Upcoming payments"}
											value={0}
										/>
										<Tab
											label={"Past payments"}
											value={1}
										/>
									</Tabs>
								</Grid>
								{
									tabValue === 0 && (
										<StyleTable
											data={pension_payments?.upcoming_payments ?? []}
											columns={columns}
											options={{
												rowsPerPage: 7,
											}}
										/>
									)
								}
								{
									tabValue === 1 && (
										<StyleTable
											data={pension_payments?.past_payments ?? []}
											columns={columns}
											options={{
												rowsPerPage: 7,
											}}
										/>
									)
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		AutoEnrolment,
	} = state;

	return {
		...Profile,
		...AutoEnrolment,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	getPensionPayments: () => dispatch(getPensionPayments()),
	downloadBreakdown: (data) => dispatch(downloadBreakdown(data)),
	downloadInvoice: (data) => dispatch(downloadInvoice(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutomaticEnrolmentPayments)