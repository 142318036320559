import React, {useCallback, useEffect, useReducer, useState} from "react";
import {connect} from "react-redux";
import {findPaySchedule, updatePaySchedule} from "../../../actions/PaySchedule";
import Loading from "../../../components/Loading";
import {useParams} from "react-router-dom";
import {resolveTaxPeriodAndPeriodDates} from "../../../utils/Helpers";
import {findEmployer} from "../../../actions/Employer";
import Layout from "../../../components/common/Layout";
import {Divider, FreepayrollButton, Typography} from "@collegia-partners/ui-kit";
import {
	PayScheduleConfirmDialog
} from "../../../components/Pay Schedules/PayScheduleSettings/PayScheduleConfirmDialog";
import {
	GeneralFields,
	RequireClientApproval, UponApprovalClosePayroll,
} from "../../../components/Pay Schedules/PayScheduleSettings";

function StepsPaySchedule ({
   step = 0,
   schedule = {},
   setSchedule = () => {},
   minDate,
   maxDate,
   shouldDisableField,
   isBureau = false,
}) {
	switch (step) {
		case 0:
			return (
				<GeneralFields
					scheduleName={schedule.name}
					setScheduleName={(e) => {
						schedule.name = e.target.value;
						setSchedule(schedule);
					}}
					payFrequency={schedule.frequency_code}
					setPayFrequency={(option) => {
						if (!option) return;
						schedule.frequency_code = option.value;
						schedule.week_payday = null;
						schedule.month_payday = null;
						schedule.custom_payday = null;
						schedule.starting_period_end_date = null;
						setSchedule(schedule);
					}}
					payDay={schedule.custom_payday || schedule.week_payday || schedule.month_payday.toString()}
					setPayDay={(option) => {
						if (!option) return;
						if (schedule.frequency_code === "W1" || schedule.frequency_code === "W2" || schedule.frequency_code === "W4") {
							schedule.week_payday = option.value;
							schedule.custom_payday = null;
							schedule.month_payday = null;
						} else {
							if (option.value === "Last Day of Month") {
								schedule.custom_payday = option.value;
								schedule.month_payday = null;
								schedule.week_payday = null;
							} else {
								schedule.month_payday = option.value;
								schedule.custom_payday = null;
								schedule.week_payday = null;
							}
						}
						schedule.starting_period_end_date = null;
						setSchedule(schedule);
					}}
					minDate={minDate}
					maxDate={maxDate}
					payDate={schedule.starting_period_end_date}
					setPayDate={(value) => {
						schedule.starting_period_end_date = value.format("YYYY-MM-DD");
						schedule.taxPeriod = resolveTaxPeriodAndPeriodDates(
							schedule?.frequency_code,
							value,
							schedule?.custom_payday === "Last Day of Month"
						)["tax_period"];
						setSchedule(schedule);
					}}
					dayRateMethod={schedule.day_rate_method}
					setDayRateMethod={(option) => {
						if (!option) return;
						schedule.day_rate_method = option.value;
						setSchedule(schedule);
					}}
					isAutomaticPaySchedule={schedule.automatic_pay_schedule}
					setIsAutomaticPaySchedule={(value) => {
						schedule.automatic_pay_schedule = value;
						if (!value) {
							schedule.submission_date_offset = null;
							schedule.require_client_authorisation = false;
							schedule.close_upon_approval = false;
							schedule.authoriser_name = "";
							schedule.authoriser_email = "";
						}
						setSchedule(schedule);
					}}
					submissionDateOffset={schedule.submission_date_offset}
					setSubmissionDateOffset={(value) => {
						schedule.submission_date_offset = value;
						setSchedule(schedule);
					}}
					shouldDisableField={shouldDisableField}
					isBureau={isBureau}
				/>
			);
		case 1:
			return (
				<RequireClientApproval
					requireClientApproval={schedule.require_client_authorisation}
					authorizerName={schedule.authoriser_name}
					authorizerEmail={schedule.authoriser_email}
					setRequireClientApproval={(value) => {
						schedule.require_client_authorisation = value;
						if (!value) {
							schedule.authoriser_name = "";
							schedule.authoriser_email = "";
							schedule.close_upon_approval = false;
						}
						setSchedule(schedule);
					}}
					setAuthorizerName={(e) => {
						schedule.authoriser_name = e.target.value;
						setSchedule(schedule);
					}}
					setAuthorizerEmail={(e) => {
						schedule.authoriser_email = e.target.value;
						setSchedule(schedule);
					}}
				/>
			);
		case 2:
			return (
				<UponApprovalClosePayroll
					submitPayrollUponClientApproval={schedule.close_upon_approval}
					setSubmitPayrollUponClientApproval={(value) => {
						schedule.close_upon_approval = value;
						setSchedule(schedule);
					}}
				/>
			);
		default:
			return;
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const EditPaySchedule = ({
     findPaySchedule = () => {},
     user,
     pay_schedule_loading,
     pay_schedule,
     updatePaySchedule = () => {},
     employer,
     findEmployer = () => {},
}): JSX.Element => {
	const [step, setStep] = useState(0);
	const [scheduleRecord, setScheduleRecord] =  useReducer(
		(state, updates) => ({
			...state,
			...updates,
		}),
		{
		name: "",
		frequency_code: "",
		week_payday: "",
		month_payday: "",
		custom_payday: "",
		starting_period_end_date: "",
		day_rate_method: "",
		automatic_pay_schedule: false,
		submission_date_offset: null,
		require_client_authorisation: false,
		close_upon_approval: false,
		authoriser_name: "",
		authoriser_email: "",
	});
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const scheduleId = useParams().scheduleId;
	
	const onNext = useCallback(() => {
		if (user?.is_bureau_user) {
			if (step === 0 && !scheduleRecord.automatic_pay_schedule ) {
				setOpenConfirmDialog(true);
			} else if (step === 1 && !scheduleRecord?.require_client_authorisation) {
				setOpenConfirmDialog(true);
			} else if (step === 2) {
				setOpenConfirmDialog(true);
			} else {
				setStep(step + 1);
			}
		} else {
			setOpenConfirmDialog(true);
		}
	},[scheduleRecord.automatic_pay_schedule, scheduleRecord?.require_client_authorisation, step, user?.is_bureau_user]);
	const onBack = useCallback(() => {
		if (step === 0) {
			return;
		}
		setStep(step - 1);
	}, [step]);
	
	function disable(schedule) {
		if (step === 0) {
			return schedule?.name?.length === 0 ||
				schedule?.frequency_code?.length === 0 ||
				(!schedule?.week_payday && !schedule?.custom_payday && !schedule?.month_payday) ||
				(schedule?.automatic_pay_schedule && schedule?.submission_date_offset === null) ||
				schedule?.starting_period_end_date === null
		} else if (step === 1) {
			return (
				schedule?.require_client_authorisation &&
				(
					!schedule.authoriser_name ||
					!schedule.authoriser_email
				)
			)
		}
	}
	
	useEffect(() => {
		findEmployer(["tax_year"]);
	}, [findEmployer]);
	
	useEffect(() => {
		if (scheduleId) {
			findPaySchedule(scheduleId, ['not_draft_pay_run']);
		}
	}, [findPaySchedule, scheduleId]);
	
	useEffect(() => {
		setScheduleRecord(pay_schedule);
	}, [setScheduleRecord, pay_schedule]);
	
	if (pay_schedule_loading) {
		return (
			<Loading/>
		);
	}
	
	return (
		<Layout
			custom={true}
			customOnClick={onBack}
			activePage={"Company"}
			activeSubpage={"Pay Schedules"}
			showBackButton={true}
		>
			<div style={{ display: "flex", flexDirection: "column", gap: "2vw", width: "100%" }}>
				<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
					Edit pay schedule
				</Typography>
				<Divider color={"gray"}/>
				<StepsPaySchedule
					step={step}
					schedule={scheduleRecord}
					setSchedule={setScheduleRecord}
					minDate={employer?.tax_year?.from}
					maxDate={employer?.tax_year?.to}
					shouldDisableField={typeof pay_schedule?.not_draft_pay_run?.id === "number"}
					isBureau={user?.is_bureau_user}
				/>
				<div style={{ width: '15vw'}}>
					<FreepayrollButton
						variant={"primary"}
						size={"medium"}
						onClick={onNext}
						disabled={disable(scheduleRecord)}
						className={"next-button-pay-schedule"}
					>
						{
							user?.is_bureau_user ? "Next" : "Save"
						}
					</FreepayrollButton>
				</div>
			</div>
			
			<PayScheduleConfirmDialog
				open={openConfirmDialog}
				setOpen={setOpenConfirmDialog}
				schedule={scheduleRecord}
				updatePaySchedule={updatePaySchedule}
				isOnboarding={false}
				isBureau={user?.is_bureau_user}
				isUpdate={true}
			/>
		</Layout>
	
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		PaySchedule,
		Employer,
	} = state;
	
	return {
		...Profile,
		...PaySchedule,
		...Employer,
	}
}

const mapDispatchToProps = dispatch => ({
	findPaySchedule: (id, relations = []) => dispatch(findPaySchedule(id, relations)),
	updatePaySchedule: (id, data, push) => dispatch(updatePaySchedule(id, data, push)),
	findEmployer: (id, relations = []) => dispatch(findEmployer(id, relations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPaySchedule)