import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import Loading from "../../components/Loading";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DateIcon from "../../images/svg/ReportsIcon/date-icon.svg";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsP11 = ({
	me = () => {},
	user = {},
	user_loading = false,
}): JSX.Element => {
	const [reportsDownloads] = useState([
		"2023/2024",
		"2022/2023",
		"2021/2022"
	]),
		[switchHrmc, setSwitchHrmc] = useState(false),
		[switchEmployee, setSwitchEmployee] = useState(false);
	useEffect(() => {
		if (!user.id) {
			me();
		}
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [me, user?.id]);

	if (user_loading) {
		return <Loading/>;
	}
	return (
		<Grid
			container
			justifyContent={"center"}
			id={"reports-p11"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={""}
						activeSubpage={""}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
						className={"reports-p11-item"}
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							justifyContent={"space-between"}
							alignItems={"center"}
						>
							<StyleTypography
								fontSize={40}
								fontSizeMedium={30}
								fontWeight={"bold"}
							>
								P11Ds and P11D(b)
							</StyleTypography>
							<StyleTypography
								fontSize={13}
								color={"#ABAEB7"}
							>
								Upload and manage your P11D reportable benefits.
							</StyleTypography>
							<Grid
								paddingTop={"30px"}
								paddingBottom={"30px"}
								width={"85%"}
							>
								<Divider/>
							</Grid>
							<Grid>
								{
									reportsDownloads.length > 0 &&
									<Grid className={"reports-p60-years"}>
										{
											reportsDownloads.map((download, index) => {
												return (
													<Grid
														key={index}
													>
														<Grid
															container
															direction={"row"}
															justifyContent={"space-between"}
															alignItems={"center"}
														>
															<Grid
																item
															>
																<Grid container direction={"row"}>
																	<Grid marginRight={"17px"}>
																		<img
																			className={"reports-p60-years-img"}
																			src={DateIcon}
																			alt={"Date"}
																		/>
																	</Grid>
																	<Grid>
																		<StyleTypography
																			fontSize={18}
																			color={"black"}
																			fontWeight={"bold"}
																			fontSizeMedium={15}
																		>
																			{download}
																		</StyleTypography>
																	</Grid>
																</Grid>
															</Grid>
															<Grid>
																<ArrowForwardIosIcon className={"reports-p60-arrow"}/>
															</Grid>
														</Grid>
														{
															reportsDownloads.length-1 !== index
																? <Grid
																	paddingTop={"20px"}
																	paddingBottom={"20px"}
																	width={"100%"}
																>
																	<Divider/>
																</Grid>
																: ""
														}
													</Grid>
												)
											})
										}
									</Grid>
								}
								<Grid
									className={"reports-p11-item-setting-title-margin"}
								>
									<StyleTypography
										fontSize={25}
										fontSizeMedium={20}
										color={"black"}
										fontWeight={"bold"}
									>
										Settings
									</StyleTypography>
									<StyleTypography
										fontSize={18}
										fontSizeMedium={13}
										color={"#ABAEB7"}
									>
										Control if FreePayroll will automatically submit documents to HMRC on your behalf.
									</StyleTypography>
								</Grid>
								<Grid
									className={"reports-p60-box-border"}
								>
									<Grid>
										<Grid
											container
											direction={"row"}
											justifyContent={"space-between"}
										>
											<Grid item>
												<StyleTypography
													fontSize={18}
													fontSizeMedium={13}
													color={"black"}
													fontWeight={"bold"}
												>
													Automatically submit P11Ds to HMRC
												</StyleTypography>
											</Grid>
											<Grid item>
												<FormControlLabel
													className={"reports-p11-switch-1"}
													control={
														<Switch
															onChange={() => {
																setSwitchHrmc(!switchHrmc);
															}}
															checked={switchHrmc}
														/>
													}
													label={""}
												/>
											</Grid>
										</Grid>
										<Grid width={"80%"}>
											<StyleTypography
												fontSize={16}
												fontSizeMedium={12}
												color={"#ABAEB7"}
											>
												If enabled, FreePayroll will automatically send copies of each employee’s P11Ds to. HMRC on your behalf at the end of the tax year.
											</StyleTypography>
										</Grid>
									</Grid>
									<Grid
										className={"reports-p11-divider"}
									>
										<Divider/>
									</Grid>
									<Grid>
										<Grid
											container
											direction={"row"}
											justifyContent={"space-between"}
										>
											<Grid item>
												<StyleTypography
													fontSize={18}
													color={"black"}
													fontWeight={"bold"}
													fontSizeMedium={13}
												>
													Automatically send P11Ds to employees
												</StyleTypography>
											</Grid>
											<Grid item>
												<FormControlLabel
													className={"reports-p11-switch-1"}
													control={
														<Switch
															onChange={() => {
																setSwitchEmployee(!switchEmployee);
															}}
															checked={switchEmployee}
														/>
													}
													label={""}
												/>
											</Grid>
										</Grid>
										<Grid width={"80%"}>
											<StyleTypography
												fontSize={16}
												color={"#ABAEB7"}
												fontSizeMedium={12}
											>
												If enabled, FreePayroll will automatically send copies of each employee’s P11D to them by email at the end of the tax year.
											</StyleTypography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
	} = state;

	return {
		...Profile,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsP11)