import React, {useState} from "react";
import {
	Typography,
	Divider,
	Pagination,
} from "@collegia-partners/ui-kit";
import EmployeePayrollBreakdown from "./EmployeePayrollBreakdown";
import Skeleton from "../Skeleton";
import {formatMoney} from "../../utils/Helpers";

type PayrollTableProps = {
	employeePayRuns: [];
	payrollMode: "RUN_PAYROLL" | "VIEW_PAYROLL" | "PAYROLL_APPROVAL",
	isLoading: boolean;
	paginationParams: {
		page: number;
		rowsPerPage: number;
		lastPage: number;
	};
	setPaginationParams: (params: {page: number, rowsPerPage: number, lastPage: number}) => void;
}

export const PayrollTable = ({
	employeePayRuns = [],
	payrollMode,
	isLoading,
	paginationParams,
	setPaginationParams,
}: PayrollTableProps): JSX.Element => {

	const [openEmployeeBreakdown, setOpenEmployeeBreakdown] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState({});

	return (
		<div id={'TableContainer'}>
			<EmployeePayrollBreakdown
				payrollMode={payrollMode}
				selectedEmployee={selectedEmployee}
				open={openEmployeeBreakdown}
				onClose={() => setOpenEmployeeBreakdown(false)}
			/>
			<div className={"TableRowHeaderContainer"}>
				<div className={"TableRowHeader"}>
					<Typography
						variant="body"
						size="medium"
						color="primary"
						weight="bold"
					>
						Employee
					</Typography>
					<Typography
						variant="body"
						size="medium"
						color="primary"
						weight="bold"
					>
						Payroll ID
					</Typography>
					<Typography
						variant="body"
						size="medium"
						color="primary"
						weight="bold"
					>
						Gross Pay
					</Typography>
					<Typography
						variant="body"
						size="medium"
						color="primary"
						weight="bold"
					>
						Net Pay
					</Typography>
				</div>
				<Divider color="light-gray"/>
			</div>
			{
				isLoading &&
				<>
					{
						Array.from({ length: 5 }).map((_, index) => (
							<div className={"TableRowContainer"} key={index}>
								<div className={"TableRow"}>
									<Skeleton width={'90%'} height={'3vw'} />
									<Skeleton width={'90%'} height={'3vw'} />
									<Skeleton width={'90%'} height={'3vw'} />
									<Skeleton width={'100%'} height={'3vw'} />
								</div>
								<Divider color="light-gray" />
							</div>
						))
					}
				</>
			}
			{employeePayRuns.map((item) => (
				<div
					className={"TableRowContainer"}
					key={item.id}
					onClick={() => {
						setSelectedEmployee(item);
						setOpenEmployeeBreakdown(true);
					}}
				>
					<div className={"TableRow"}>
						<Typography
							variant="body"
							size="medium"
							color="black"
							weight="bold"
						>
							{item.employee.name}
						</Typography>
						<Typography
							variant="body"
							size="medium"
							color="black"
							weight="bold"
						>
							{item.employee.payroll_id}
						</Typography>
						<Typography
							variant="body"
							size="medium"
							color="black"
							weight="bold"
						>
							{formatMoney(item.gross_income_for_tax ?? 0)}
						</Typography>
						<Typography
							variant="body"
							size="medium"
							color="black"
							weight="bold"
						>
							{formatMoney(item.net_pay ?? 0)}
						</Typography>
					</div>
					<Divider color="light-gray"/>
				</div>
			))}
			<Pagination paginationParams={paginationParams} setPaginationParams={setPaginationParams}  />
		</div>
	);
};
