import Grid from "@mui/material/Grid";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import Switch from "@mui/material/Switch";

export const StudentLoanCard = (
	{
		title = "",
		subtitle = "",
		annual_threshold = 0,
		repay_percentage = 0,
		active = false,
		setEmployeeRecord,
		employeeRecord,
		index,
	}
) => {

	return (
		<Grid
			className={"pay-item-scheme-card"}
			container
		>
			<Grid
				item
				container
				xs={10}
				sm={10}
				md={10}
				lg={10}
				xl={10}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
				>
					<StyleTypography
						fontSize={20}
						fontSizeMedium={15}
						fontWeight={"bold"}
						color={"#000"}
					>
						{title}
					</StyleTypography>
				</Grid>

				<Grid
					className={"pay-item-scheme-card-subtitle"}
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
				>
					<StyleTypography
						fontSize={16}
						fontSizeMedium={12}
						color={"#C7C9CF"}
					>
						{subtitle}
					</StyleTypography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
				>
					<StyleTypography
						fontSize={16}
						fontSizeMedium={12}
						color={"#000"}
					>
						Annual threshold: <b>£ {parseFloat(annual_threshold)
						.toFixed(2)
						.replace(/\d(?=(\d{3})+\.)/g, '$&,')}</b>
					</StyleTypography>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
				>
					<StyleTypography
						fontSize={16}
						fontSizeMedium={12}
						color={"#000"}
					>
						Repay percentage: <b>{repay_percentage * 100}%</b>
					</StyleTypography>
				</Grid>
			</Grid>

			<Grid
				item
				container
				xs={2}
				sm={2}
				md={2}
				lg={2}
				xl={2}
				alignItems={"center"}
				justifyContent={"center"}
			>
				<Switch
					checked={active}
					onChange={(e) => {
						// First, create a copy of the student_loans array
						const updatedLoans = [...employeeRecord.student_loans];

						// Update the active property of the specific loan
						updatedLoans[index] = {
							...updatedLoans[index],
							active: e.target.checked,
						};

						// Set the updated student_loans array into the employeeRecord state
						setEmployeeRecord({
							...employeeRecord,
							student_loans: updatedLoans,
						});
					}}
				/>
			</Grid>
		</Grid>
	);
}