import {resolveReduxState} from "../../utils/Helpers";
import {
	CREATE_SICK_EMPLOYEE,
	DELETE_SICK_EMPLOYEE,
} from "../../actions/SickLeave/types";

const initial_state = {
	sick_leave_loading: false,
	step_end: 0,
	waiting_days: 0,
	payable_days: 0,
	days_unavailable: 0,
}

const SickLeave = (state = initial_state, action = false) => {
	if (action) {
		switch(action.type) {
			case CREATE_SICK_EMPLOYEE:
			case DELETE_SICK_EMPLOYEE:
				return resolveReduxState(state, action);
			default:
				return state;
		}
	}
	return state;
}

export default SickLeave;