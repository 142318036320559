import {
	CREATE_SICK_EMPLOYEE,
	DELETE_SICK_EMPLOYEE,
} from "./types";
import Request from "../../utils/Request";
import {toast} from "react-toastify";

export const createSickLeaveEmployee = (data) => dispatch => {

	dispatch({
		type: CREATE_SICK_EMPLOYEE,
		sick_leave_loading: true,
	});

	return new Request.post(`/api/employees/sick-leave/create`, data)
		.then(response => {
			dispatch({
				type: CREATE_SICK_EMPLOYEE,
				sick_leave_loading: false,
			});
		})
		.catch(error => {
			console.error(error);
			if (error.response.status !== 500) {
				toast.error('Something went wrong! Please contact support.');
			}
			dispatch({
				type: CREATE_SICK_EMPLOYEE,
				sick_leave_loading: false,
			});
		})
};

export const deleteSickLeaveEmployee = (data) => dispatch => {

	dispatch({
		type: DELETE_SICK_EMPLOYEE,
		sick_leave_loading: true,
	});

	return new Request.post(`/api/employees/sick-leave/delete`, data)
		.then(response => {
			dispatch({
				type: DELETE_SICK_EMPLOYEE,
				sick_leave_loading: false,
			});
		})
		.catch(error => {
			console.error(error);
			if (error.response.status !== 500) {
				toast.error('Something went wrong! Please contact support.');
			}
			dispatch({
				type: DELETE_SICK_EMPLOYEE,
				sick_leave_loading: false,
			});
		})
};

export const reviewSickLeave = (data) => dispatch => {

	dispatch({
		type: CREATE_SICK_EMPLOYEE,
		sick_leave_loading: true,
	});

	return new Request.post(`/api/employees/sick-leave/review`, data)
		.then(response => {
			dispatch({
				type: CREATE_SICK_EMPLOYEE,
				sick_leave_loading: false,
				waiting_days: response.data.data.waiting_days,
				payable_days: response.data.data.payable_days,
				days_unavailable: response.data.data.days_unavailable,
				step_end: 3,
			});
		})
		.catch(error => {
			console.error(error);
			if (error.response.status !== 500) {
				toast.error('Something went wrong! Please contact support.');
			}
			dispatch({
				type: CREATE_SICK_EMPLOYEE,
				sick_leave_loading: false,
			});
		})
};