import {resolveReduxState} from "../../utils/Helpers";
import {
	CREATE_PAY_ITEM,
	DELETE_PAY_ITEM,
	UPDATE_PAY_ITEM,
	GET_PAY_ITEMS,
	GET_SYSTEM_PAY_ITEMS,
	GET_STUDENT_LOAN_PLANS,
	UPDATE_EMPLOYEE_STUDENT_LOAN,
	GET_EMPLOYEE_ACTIVE_STUDENT_LOANS,
	CLEAR_STUDENT_LOAN, FIND_PAY_ITEM
} from "../../actions/PayItem/types";

const initial_state = {
	pay_item_loading: false,
	pay_items: [],
	system_pay_items: [],
	student_loan_plans: [],
	employee_active_student_loans: [],
	pay_item: {},
}

const PayItem = (state = initial_state, action = false) => {
	if (action) {
		switch(action.type) {
			case CREATE_PAY_ITEM:
			case UPDATE_PAY_ITEM:
			case DELETE_PAY_ITEM:
			case GET_PAY_ITEMS:
			case GET_SYSTEM_PAY_ITEMS:
			case GET_STUDENT_LOAN_PLANS:
			case UPDATE_EMPLOYEE_STUDENT_LOAN:
			case GET_EMPLOYEE_ACTIVE_STUDENT_LOANS:
			case CLEAR_STUDENT_LOAN:
			case FIND_PAY_ITEM:
				return resolveReduxState(state, action);

			default:
				return state;
		}
	}

	return state;
}

export default PayItem;