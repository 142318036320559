import {resolveReduxState} from "../../utils/Helpers";
import {
	DOWNLOAD_EPS,
	DOWNLOAD_FPS,
	GET_EPS_REPORT,
	GET_FPS_REPORT,
} from "../../actions/Reports/types";

const initial_state = {
	report_loading: false,
	eps_reports: [],
	fps_report: [],
}

const PayItem = (state = initial_state, action = false) => {
	if (action) {
		switch(action.type) {
			case GET_FPS_REPORT:
			case GET_EPS_REPORT:
			case DOWNLOAD_FPS:
			case DOWNLOAD_EPS:
				return resolveReduxState(state, action);
			default:
				return state;
		}
	}

	return state;
}

export default PayItem;