import {resolveReduxState} from "../../utils/Helpers";
import {
	CREATE_ENHANCED_POLICY,
	DELETE_ENHANCED_POLICY,
	UPDATE_ENHANCED_POLICY
} from "../../actions/EnhancedPolicy/types";

const initial_state = {
	enhanced_policy_loading: false,
}

const PayItem = (state = initial_state, action = false) => {
	if (action) {
		switch(action.type) {
			case CREATE_ENHANCED_POLICY:
			case DELETE_ENHANCED_POLICY:
			case UPDATE_ENHANCED_POLICY:
				return resolveReduxState(state, action);

			default:
				return state;
		}
	}

	return state;
}

export default PayItem;