import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	downloadPayrollReport,
	downloadPayslips,
	reopenPayroll,
	sendPayslip,
} from "../../actions/PayScheduleRun";
import { findEmployer } from "../../actions/Employer";
import {
	formatDateFromBackendWithTime,
} from "../../utils/Helpers";
import Layout from "../../components/common/Layout";
import {FreepayrollDropbox, Icon, Typography, Chip, FreepayrollButton} from "@collegia-partners/ui-kit";
import FPTable from "../../components/common/FPTable/FPTable";
import {useListPayrolls} from "../../hooks/payrolls";
import {renderPayRunStatusColor} from "./PayrollActive";
import {ReopenPayRun} from "../../components/Pages/ReopenPayrun";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollHistoryPage = ({
	reopenPayroll = () => {},
	sendPayslip = () => {},
	downloadPayslips = () => {},
	findEmployer = () => {},
	employer = {},
	downloadPayrollReport = () => {},
}): JSX.Element => {
	const push = useNavigate();
	const [openModal, setOpenModal] = useState(false),
		[payrollId, setPayrollId] = useState(null);

	const columns = [
		{
			text: "Schedule Name",
			key: "name",
			width: '1.5fr',
			hasOrderBy: true,
		},
		{
			text: "Tax Period",
			key: "tax_period",
			hasOrderBy: true,
		},
		{
			text: "Pay Date",
			key: "period_end_date",
			customRender: (row, index) => (
				<Typography
					variant="body"
					size="medium"
					key={index}
					color={"black"}
				>
					{formatDateFromBackendWithTime(row.period_end_date)}
				</Typography>
			),

		},
		{
			text: "Status",
			key: "status",
			customRender: (row, index) => (
				<Chip key={index} color={renderPayRunStatusColor(row.status)} >
					{row.status}
				</Chip>
			),
		},
		{
			text: "Actions",
			width: '1.5fr',
			customRender: (row, index) => (
				<div style={{ display: "flex", flexDirection: "row", gap: "3.8vw" }}>
					<div style={{width: "fit-content" }}>
						<FreepayrollButton
							onClick={() => {
								setPayrollId(row.id);
								setOpenModal(true);
							}}
							size={"small"}
							variant={"red"}
							disabled={employer?.account_locked || !row.can_be_rolled_back}
						>
							Reopen Payroll
						</FreepayrollButton>
					</div>
					<FreepayrollDropbox
						target={
							<button
								style={{all: "unset", cursor: "pointer"}}
							>
								<Icon
									iconName="dots-horizontal"
									className={"EmployeeSummaryIcon"}
								/>
							</button>
						}
						key={index}
						className={"EmployeeSummaryActions"}
					>
						<button
							onClick={() => push(`/main/view-payroll/${btoa(row.id)}`)}
							className={"EmployeeSummaryAction"}
						>
							View Payroll
						</button>
						<button
							onClick={() => {
								sendPayslip({
									pay_schedule_runs: {
										bulk_send: true,
										id: row.id,
									},
								});
							}}
							className={"EmployeeSummaryAction"}
						>
							Email all payslips
						</button>
						<button
							onClick={() => {
								downloadPayslips({
									pay_schedule_runs: {
										action: "payrun_download",
										id: row.id,
									},
								});
							}}
							className={"EmployeeSummaryAction"}
						>
							Download all payslips
						</button>
						<button
							onClick={() => {
								downloadPayrollReport({
									pay_schedule_runs: {
										id: row.id,
									},
								});
							}}
							className={"EmployeeSummaryAction"}
						>
							Download payroll report
						</button>
					</FreepayrollDropbox>
				</div>
			),
		},
	];

	useEffect(() => {
		findEmployer(["employer_tax_years"]);
	}, [findEmployer]);

	return (
		<Layout
			activePage={"Payrolls"}
			activeSubpage={"History"}
			showBackButton={false}
		>
			<div style={{width: "100%", display: "flex", flexDirection: "column", gap: "2.57vw"}}>
				<div
					style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
					<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
						Submitted Payrolls
					</Typography>
				</div>
				<div>
					<FPTable
						headers={columns}
						hookName={useListPayrolls}
						hookKey={'payrolls'}
						hookParams={{
							mode: 'history',
						}}
						paginate={true}
					/>
				</div>
			</div>
			<ReopenPayRun
				open={openModal}
				setOpen={setOpenModal}
				reopenPayroll={reopenPayroll}
				payrollId={payrollId}
				setPayrollId={setPayrollId}
			/>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const { PayScheduleRun, Employer } = state;

	return {
		...PayScheduleRun,
		...Employer,
	};
};

const mapDispatchToProps = (dispatch) => ({
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	reopenPayroll: (data, push) => dispatch(reopenPayroll(data, push)),
	sendPayslip: (data) => dispatch(sendPayslip(data)),
	downloadPayslips: (data) => dispatch(downloadPayslips(data)),
	downloadPayrollReport: (data) => dispatch(downloadPayrollReport(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollHistoryPage);
