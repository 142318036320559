import Grid from "@mui/material/Grid";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StyleTypography from "../../StyledComponents/StyleTypography";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {SalaryModal} from "./SalaryModal";
import {useState} from "react";
import {PayScheduleModal} from "./PayScheduleModal";

function getEmploymentName(employmentType: string): string {
	switch (employmentType) {
		case 'salaried':
			return 'Salaried';
		case 'hourly':
			return 'Hourly';
		default:
			return '';
	}
}

function getTypeOfPay(salary): string {
	switch (salary.employment_type) {
		case 'salaried':
			return parseFloat(salary.annual_salary ?? 0).toLocaleString('en-GB', {
				style: 'currency',
				currency: 'GBP',
			}) + ' / year';
		case 'hourly':
			return parseFloat(salary.hourly_salary ?? 0).toLocaleString('en-GB', {
				style: 'currency',
				currency: 'GBP',
			}) + ' / hour';
		default:
			return '';
	}
}


export const Salary = ({
   employeeRecord,
   setEmployeeRecord,
   employee,
   updateEmployee,
}): JSX.Element => {

	const [openSalaryModal, setOpenSalaryModal] = useState(false),
		[openPayScheduleModal, setOpenPayScheduleModal] = useState(false);
	return (
		<Grid
			xl={12}
			lg={12}
			md={11}
			sm={12}
			xs={12}
			container
			item
			justifyContent={"space-between"}
			id={'salary'}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={'first-title-grid'}
			>
				<StyleTypography
					fontSize={30}
					fontSizeMedium={25}
					fontWeight={'bold'}
					component={"h1"}
				>
					Modify employee pay schedule
				</StyleTypography>
				<StyleTypography
					fontSize={30}
					fontSizeMedium={25}
					fontWeight={'bold'}
					component={"h1"}
				>
					Define employee base salary
				</StyleTypography>
			</Grid>

			<Grid
				xl={5.5}
				lg={5.5}
				md={5.5}
				sm={5.5}
				xs={5.5}
				item
				container
				alignSelf={"flex-start"}
			>
				{/*Pay Schedule Grid*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					container
					className={'pay-schedule-default'}
					alignItems={"center"}
					onClick={() => setOpenPayScheduleModal(true)}
				>
					<Grid>
						<CalendarMonthIcon/>
					</Grid>
					<Grid
						sx={{
							marginLeft: '20px',
						}}
					>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={14}
							color={'#000000'}
							fontWeight={'bold'}
						>
							{employee.pay_schedule.name}
						</StyleTypography>
					</Grid>
					<Grid
						xl={true}
						lg={true}
						md={true}
						sm={true}
						xs={true}
						item
						textAlign={'end'}
						sx={{
							marginRight: '20px'
						}}
					>
						<KeyboardArrowRightIcon/>
					</Grid>
				</Grid>

				{/*Salary Grid*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					container
					className={'salary-default'}
					alignItems={"center"}
					onClick={() => setOpenSalaryModal(true)}
				>
					<Grid
						sx={{
							marginLeft: '20px',
						}}
					>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={14}
							color={'#000000'}
							fontWeight={'bold'}
						>
							{getEmploymentName(employee.salary.employment_type)}
						</StyleTypography>
					</Grid>
					<Grid
						xl={true}
						lg={true}
						md={true}
						sm={true}
						xs={true}
						item
						textAlign={'end'}
						sx={{
							marginRight: '20px',
						}}
					>
						{
							employee.salary && (
								<StyleTypography
									fontSize={18}
									fontSizeMedium={14}
									color={'#000000'}
									fontWeight={'bold'}
								>
									{getTypeOfPay(employee.salary)}
								</StyleTypography>
							)
						}
					</Grid>
					<Grid
						sx={{
							marginRight: '20px'
						}}
					>
						<KeyboardArrowRightIcon/>
					</Grid>
				</Grid>

			</Grid>

			<SalaryModal
				open={openSalaryModal}
				setOpen={setOpenSalaryModal}
				setEmployeeRecord={setEmployeeRecord}
				employeeRecord={employeeRecord}
				updateEmployee={updateEmployee}
				employee={employee}
			/>

			<PayScheduleModal
				open={openPayScheduleModal}
				setOpen={setOpenPayScheduleModal}
				setEmployeeRecord={setEmployeeRecord}
				employeeRecord={employeeRecord}
				employee={employee}
				updateEmployee={updateEmployee}
			/>

		</Grid>
	);
}