import {StyleAppBar} from "../../components/StyleAppBar";
import Grid from "@mui/material/Grid";
import {LeftMenu} from "../../components/LeftMenu";
import React, {useEffect, useReducer, useState} from "react";
import {me} from "../../actions/Profile";
import {createAdmin, findEmployer} from "../../actions/Employer";
import {connect} from "react-redux";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Loading from "../../components/Loading";
import DialogTitle from "@mui/material/DialogTitle";
import {Chip, CircularProgress, DialogContent} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {matchIsValidTel, MuiTelInput} from "mui-tel-input";
import {validateEmail} from "../../utils/Helpers";
import {verifyFullName} from "../../components/Pages/SignUp/CreateAccountCard";
import StyleTable from "../../components/StyledComponents/StyleTable";

const CompanyProfile = ({
	user = {
		id: null,
	},
	me = () => {},
	employer_loading = false,
	employer = {
		users: []
	},
	createAdmin = () => {},
	error = false,
	error_message = "",
	findEmployer = () => {},
}): JSX.Element => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const [newAdmin, setNewAdmin] = useReducer(
		(state, updates) => ({...state, ...updates}),
		{
			name: "",
			email: "",
			phone: "",
		}
	);

	const handleModalClose = () => {
		setNewAdmin({
			name: "",
			email: "",
			phone: "",
		});
		setDialogOpen(false);
	}

	useEffect(() => {
		me();
		findEmployer(['users'])
	}, [me, findEmployer])

	const columns = [
		{
			name: "name",
			label: "Name",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => (
					<StyleTypography
						fontSize={15}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value}
					</StyleTypography>
				)
			},
		},
		{
			name: "email",
			label: "Email",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => (
					<StyleTypography
						fontSize={15}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value}
					</StyleTypography>
				)
			},
		},
		{
			name: "id",
			label: " ",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => value === user?.id && (
					<Chip
						label={"You"}
						sx={{
							backgroundColor: "#0160FD",
							color: "#FFFFFF",
							fontSize: "10px"
						}}
					/>
				)
			},
		},
	];

	if (employer_loading) {
		return <Loading/>
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"onboarding-dashboard-page"}
		>
			<StyleAppBar
				user={user}
				employer={employer}
			/>
			<Grid
				xl={11}
				lg={11}
				md={11}
				sm={11}
				xs={11}
				item
				container
				id={"main-content-container"}
			>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					<LeftMenu
						activePage={"settings"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							className={"team-member-table-container"}
						>
							<StyleTable
								data={employer?.users ?? []}
								columns={columns}
								title={"Users"}
								addCustomButtons={true}
								options={{
									serverSide: false,
									filter: false,
								}}
								removeFooter={true}
								customButtons={
									<>
										<Button
											id={'black-button'}
											sx={{
												marginLeft: "10px",
											}}
											onClick={() => {
												setDialogOpen(true);
											}}
										>
											<StyleTypography
												fontSize={16}
												fontSizeMedium={12}
												color={"#fff"}
											>
												+ Add admin
											</StyleTypography>
										</Button>
									</>
								}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Dialog
				open={dialogOpen}
				onClose={() => handleModalClose()}
				id={"admin-dialog"}
			>
				<DialogTitle>
					<StyleTypography
						fontSize={35}
						fontSizeMedium={25}
						fontWeight={"bold"}
						component={"h2"}
					>
						Admin User
					</StyleTypography>
				</DialogTitle>

				<DialogContent>
					<StyleTypography
						fontSize={16}
						fontSizeMedium={11}
						color={"#AAAEB7"}
					>
						Add a new user
					</StyleTypography>

					<Grid
						id={"admin-form-container"}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Full Name*
						</InputLabel>
						<FormControl
							fullWidth
						>
							<TextField
								className={"default-text-field admin-dialog-text-field"}
								fullWidth
								onChange={(e) => setNewAdmin({
									name: e.target.value
								})}
								error={verifyFullName(newAdmin.name)}
								value={newAdmin.name}
							/>
						</FormControl>

						<InputLabel
							className={"default-input-label"}
						>
							Email*
						</InputLabel>
						<FormControl
							fullWidth
						>
							<TextField
								className={"default-text-field admin-dialog-text-field"}
								fullWidth
								onChange={(e) => setNewAdmin({
									email: e.target.value
								})}
								value={newAdmin.email}
								helperText={
									error ?
										error_message :
										newAdmin.email !== "" &&
										!validateEmail(newAdmin.email) &&
										"Invalid Email"
								}
								error={
									newAdmin.email !== "" &&
									(!validateEmail(newAdmin.email) ||
									error)
								}
							/>
						</FormControl>

						<InputLabel
							className={"default-input-label"}
						>
							Telephone
						</InputLabel>
						<MuiTelInput
							className={"default-phone-input admin-dialog-text-field"}
							defaultCountry={"GB"}
							flagSize={"medium"}
							forceCallingCode
							value={newAdmin.phone}
							error={
								!matchIsValidTel(newAdmin.phone) &&
								newAdmin.phone !== ""
							}
							onChange={(value) => setNewAdmin({
								phone: value,
							})}
						/>
					</Grid>

					<Grid
						container
						id={"admin-dialog-buttons-container"}
						spacing={2}
					>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={4}
							xl={4}
						>
							<Button
								className={"default-black-button"}
								onClick={() => createAdmin({
									"employers": {
										...newAdmin
									}
								})}
								disabled={
									employer_loading ||
									!validateEmail(newAdmin.email) ||
									!matchIsValidTel(newAdmin.phone)
								}
							>
								<StyleTypography
									fontSize={16}
									fontSizeMedium={11}
									color={"#fff"}
									fontWeight={"bold"}
								>
									{employer_loading ? <CircularProgress/> : "Add Admin"}
								</StyleTypography>
							</Button>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={4}
							xl={4}
						>
							<Button
								className={"default-white-button"}
								onClick={() => handleModalClose()}
								disabled={employer_loading}
							>
								<StyleTypography
									fontSize={16}
									fontSizeMedium={11}
									color={"#000"}
									fontWeight={"bold"}
								>
									Cancel
								</StyleTypography>
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Employer
	} = state;

	return {
		...Profile,
		...Employer
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	createAdmin: (data) => dispatch(createAdmin(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);