import PayrollHistory from "./PayrollHistory";
import PayrollArchive from "./PayrollArchive";
import PayrollActive from "./PayrollActive";
import RunPayroll from "./RunPayroll";
import ViewPayroll from "./ViewPayroll";
import CompletedPayroll from "./CompletedPayroll";
import PayrollApproval from "./PayrollApproval";
import {CompletedPayrollApproval} from "./CompletedPayrollApproval";

const PayrollPages = {
	PayrollHistory,
	PayrollArchive,
	PayrollActive,
	RunPayroll,
	ViewPayroll,
	CompletedPayroll,
	PayrollApproval,
	CompletedPayrollApproval,
}

export default PayrollPages;