import {
	CLEAR_STUDENT_LOAN,
	CREATE_PAY_ITEM,
	DELETE_PAY_ITEM, FIND_PAY_ITEM,
	GET_EMPLOYEE_ACTIVE_STUDENT_LOANS,
	GET_PAY_ITEMS,
	GET_STUDENT_LOAN_PLANS,
	GET_SYSTEM_PAY_ITEMS,
	UPDATE_EMPLOYEE_STUDENT_LOAN,
	UPDATE_PAY_ITEM,
} from "./types";
import Request from "../../utils/Request";
import {toast} from "react-toastify";

export const createPayItem = (data) => dispatch => {
	dispatch({
		type: CREATE_PAY_ITEM,
		pay_item_loading: true
	});
	if (data) {
		Request.post('/api/employers/pay-items/create', data)
			.then(response => {
				dispatch({
					type: CREATE_PAY_ITEM,
					pay_item_loading: false
				});
				window.location.href = '/main/company/pay-items';
			})
			.catch(error => {
				console.error(error);
				toast.error(error.response.data.message);
				dispatch({
					type: CREATE_PAY_ITEM,
					pay_item_loading: false
				});
			})
	}
};

export const updatePayItem = (id, data) => dispatch => {
	dispatch({
		type: UPDATE_PAY_ITEM,
		pay_item_loading: true
	});
	if (data && id) {
		Request.put(`/api/employers/pay-items/update/${id}`, data)
			.then(response => {
				dispatch({
					type: UPDATE_PAY_ITEM,
					pay_item_loading: false
				});
				window.location.href = '/main/company/pay-items';
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: UPDATE_PAY_ITEM,
					pay_item_loading: false
				});
			})
	}
};

export const deletePayItem = (id) => dispatch => {
	dispatch({
		type: DELETE_PAY_ITEM,
		pay_item_loading: true
	});
	if (id) {
		Request.delete('/api/employers/pay-item', id)
			.then(response => {
				dispatch({
					type: DELETE_PAY_ITEM,
					pay_item_loading: false
				});
				window.location.href = '/main/company/pay-items';
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: DELETE_PAY_ITEM,
					pay_item_loading: false
				});
			})
	}
};

export const getPayItems = () => dispatch => {
	dispatch({
		type: GET_PAY_ITEMS,
		pay_item_loading: true,
	});

	return new Request.get('/api/employers/pay-items/get')
		.then(response => {
			if (response.data.success) {
				dispatch({
					type: GET_PAY_ITEMS,
					pay_item_loading: false,
					pay_items: response.data.data,
				});
			}

		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: GET_PAY_ITEMS,
				pay_item_loading: false,
				pay_items: false,
			});
		})
};

export const getSystemPayItems = () => dispatch => {
	dispatch({
		type: GET_SYSTEM_PAY_ITEMS,
		pay_item_loading: true,
	});
	
	return new Request.get('/api/employers/pay-items/system-pay-items')
		.then(response => {
			dispatch({
				type: GET_PAY_ITEMS,
				pay_item_loading: false,
				system_pay_items: response.data.data,
			});
			
		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: GET_PAY_ITEMS,
				pay_item_loading: false,
				system_pay_items: false,
			});
		})
};

export const getStudentLoanPlans = (data) => dispatch => {
	dispatch({
		type: GET_STUDENT_LOAN_PLANS,
		pay_item_loading: true,
	});
	
	return new Request.post('/api/employers/pay-items/student-loan-plans', data)
		.then(response => {
			dispatch({
				type: GET_STUDENT_LOAN_PLANS,
				pay_item_loading: false,
				student_loan_plans: response.data.data,
			});
		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: GET_STUDENT_LOAN_PLANS,
				pay_item_loading: false,
				student_loan_plans: false,
			});
		})
};

export const getEmployeeActiveStudentLoans = (data) => dispatch => {
	dispatch({
		type: GET_EMPLOYEE_ACTIVE_STUDENT_LOANS,
		pay_item_loading: true,
	});
	
	return new Request.post('/api/employers/pay-items/employee-active-student-loans', data)
		.then(response => {
			dispatch({
				type: GET_EMPLOYEE_ACTIVE_STUDENT_LOANS,
				pay_item_loading: false,
				employee_active_student_loans: response.data.data,
			});
		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: GET_EMPLOYEE_ACTIVE_STUDENT_LOANS,
				pay_item_loading: false,
				employee_active_student_loans: false,
			});
		})
};

export const updateEmployeeStudentLoan = (data) => dispatch => {
	if (data) {
		dispatch({
			type: UPDATE_EMPLOYEE_STUDENT_LOAN,
			pay_item_loading: true,
		});
		
		Request.post("/api/employers/pay-items/update-employee-student-loan", data)
			.then(response => {
				dispatch({
					type: UPDATE_EMPLOYEE_STUDENT_LOAN,
					pay_item_loading: false,
				});
				toast.success("Student Loan updated successfully.")
				dispatch(getStudentLoanPlans(data));
				dispatch(getEmployeeActiveStudentLoans(data));
			})
			.catch(e => {
				dispatch({
					type: UPDATE_EMPLOYEE_STUDENT_LOAN,
					pay_item_loading: false,
				});
				toast.error(e.response.data.message);
				console.error(e);
			});
	}
}

export const clearStudentLoan = () => dispatch => {
	dispatch({
		type: CLEAR_STUDENT_LOAN,
		system_pay_items: [],
		student_loan_plans: [],
		employee_active_student_loans: [],
	})
}

export const findPayItem = (id) => dispatch => {
	if (id) {
		dispatch({
			type: FIND_PAY_ITEM,
			pay_item_loading: true,
			pay_item: {},
		});
		return new Request.get(`/api/employers/pay-items/find/${id}`)
			.then(response => {
				if (response.data.success) {
					dispatch({
						type: FIND_PAY_ITEM,
						pay_item_loading: false,
						pay_item: response.data.data,
					});
				}

			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: FIND_PAY_ITEM,
					pay_item_loading: false,
					pay_items: {},
				});
			})
	}

};