import Grid from "@mui/material/Grid";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import StyleTypography from "../../StyledComponents/StyleTypography";
import FormControl from "@mui/material/FormControl";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import enGb from "date-fns/locale/en-GB";
import {format, parseISO} from "date-fns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import StyleButton from "../../StyledComponents/StyleButton";
import {useState} from "react";
import CloseIcon from "@mui/icons-material/Close";

export const DirectorModal = ({
	open,
	setOpen,
	employeeRecord,
	setEmployeeRecord,
	employee,
	updateEmployee,
}): JSX.Element => {

	const [editDirector, setEditDirector] = useState(false);

	return (
		<Dialog
			onClose={() => {
				setOpen(false);
				setEditDirector(false);
			}}
			open={open}
			maxWidth={'sm'}
			id={"salary-modal"}
		>
			<DialogTitle>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					container
					item
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					<Grid>
						{
							(employee.is_director && !editDirector) ?
								<StyleTypography
									fontSize={30}
									fontSizeMedium={26}
									fontWeight={'bold'}
								>
									Edit director details
								</StyleTypography>
								:
								<StyleTypography
									fontSize={30}
									fontSizeMedium={26}
									fontWeight={'bold'}
								>
									Set director details
								</StyleTypography>
						}

					</Grid>
					<Grid>
						<CloseIcon
							sx={{
								fontSize: '1.6vw',
								cursor: 'pointer'
							}}
							className={'dialog-close-button'}
							onClick={() => {
								setEmployeeRecord(employee);
								setOpen(false);
							}}
						/>
					</Grid>
				</Grid>
				{
					(employeeRecord.active_director_detail?.locked && employee.is_director) && (
						<Grid
							item
							container
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							spacing={2}
						>
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
							>
								<StyleTypography
									fontSize={16}
									fontSizeMedium={14}
									color={'#FF0000'}
								>
									You cannot edit this director's details as it has already<br/> been submitted to HMRC this tax year.
								</StyleTypography>
							</Grid>
						</Grid>
					)
				}
			</DialogTitle>
			<DialogContent>
				{
					(employee.is_director && !editDirector) ?
						<Grid
							item
							container
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							spacing={2}
						>
							{/*Date appointed as director*/}
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
							>
								<InputLabel
									className={"default-input-label"}
								>
									Date appointed as director: <br/><strong>{
										employeeRecord.active_director_detail?.start_date ?
											format(parseISO(employeeRecord.active_director_detail?.start_date),'dd/MM/yyyy')
											:
											""
									}</strong>
								</InputLabel>
							</Grid>

							{/*Calculation method*/}
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
							>
								<InputLabel
									className={"default-input-label"}
								>
									National insurance calculation method: <br/>
									<strong>{employeeRecord.active_director_detail?.cumulative_calculation ? 'Standard annual calculation' : 'Alternative calculation'}</strong>
								</InputLabel>
							</Grid>
						</Grid>
						:
						<Grid
							item
							container
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							spacing={2}
						>
							{/*Date appointed as director*/}
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
							>
								<InputLabel
									className={"default-input-label"}
								>
									Date appointed as director
								</InputLabel>
								<LocalizationProvider
									dateAdapter={AdapterDateFns}
									adapterLocale={enGb}
								>
									<DesktopDatePicker
										className={"date-picker"}
										onChange={(newValue) => {
											if (newValue instanceof Date && !isNaN(newValue.getTime())) {
												if (employeeRecord && !employeeRecord.active_director_detail) {
													employeeRecord.active_director_detail = {}; // Initialize it as an empty object
												}
												let offset = newValue.getTimezoneOffset();
												let adjustedDate = new Date(newValue.getTime() - (offset*60*1000));
												const updatedEmployeeRecord = {
													...employeeRecord,
													active_director_detail: {
														...employeeRecord.active_director_detail,
														start_date: adjustedDate.toISOString().split('T')[0]
													}
												};
												setEmployeeRecord(updatedEmployeeRecord);
											}
										}}
										disabled={employeeRecord.active_director_detail?.locked}
										value={parseISO(employeeRecord?.active_director_detail?.start_date)}
									/>
								</LocalizationProvider>
							</Grid>

							{/*Calculation method*/}
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
							>
								<InputLabel
									className={"default-input-label"}
								>
									National insurance calculation method
								</InputLabel>
								<FormControl
									fullWidth
									sx={{
										marginTop: '10px',
									}}
								>
									<Select
										className={"default-select"}
										id={"select-gender"}
										MenuProps={{
											PaperProps: {
												style: {
													maxHeight: 200, // Adjust the maximum height of the list
												},
											},
										}}
										disabled={employeeRecord.active_director_detail?.locked}
										value={employeeRecord.active_director_detail?.cumulative_calculation}
										onChange={(event) => {
											const updatedEmployeeRecord = {
												...employeeRecord,
												active_director_detail: {
													...employeeRecord.active_director_detail,
													cumulative_calculation: event.target.value
												}
											};
											setEmployeeRecord(updatedEmployeeRecord);
										}}
									>
										<MenuItem
											key={'select-title'}
											value={" "}
											disabled
										>
											Select
										</MenuItem>
										<MenuItem
											key={'true'}
											value={true}
										>
											Standard annual method
										</MenuItem>
										<MenuItem
											key={'false'}
											value={false}
										>
											Alternative method
										</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
				}
			</DialogContent>
			<DialogActions>
				{
					(employee.is_director && !editDirector) ?
						<Grid
							item
							container
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							spacing={2}
						>
							<Grid
								item
								xl={6}
								lg={6}
								md={6}
								sm={6}
								xs={6}
							>
								<StyleButton
									onClick={() => {
										setEditDirector(!editDirector);
									}}
									disabled={employeeRecord.active_director_detail?.locked}
								>
									<StyleTypography
										fontSize={16}
										fontSizeMedium={14}
										color={'#FFF'}
									>
										Edit
									</StyleTypography>
								</StyleButton>
							</Grid>

							{
								employeeRecord.active_director_detail?.end_date === null && (
									<Grid
										item
										xl={6}
										lg={6}
										md={6}
										sm={6}
										xs={6}
									>
										<StyleButton
											isWhiteButton={true}
											onClick={() => {
												updateEmployee(employee.safe_id, {
													employees: {
														'action': 'director_details',
														'is_director': false,
													}
												});
											}}
										>
											<StyleTypography
												fontSize={16}
												fontSizeMedium={14}
												color={'#000000'}
											>
												End directorship
											</StyleTypography>
										</StyleButton>
									</Grid>
								)
							}

						</Grid>
						:
						<Grid
							item
							container
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							spacing={2}
						>
							<Grid
								item
								xl={6}
								lg={6}
								md={6}
								sm={6}
								xs={6}
							>
								<StyleButton
									onClick={() => {
										updateEmployee(employee.safe_id, {
											employees: {
												'action': 'director_details',
												'is_director': 'true',
												'director_details': {
													'start_date': employeeRecord.active_director_detail?.start_date,
													'cumulative_calculation': employeeRecord.active_director_detail?.cumulative_calculation,
												}
											}
										});
									}}
								>
									Save
								</StyleButton>
							</Grid>

							<Grid
								item
								xl={6}
								lg={6}
								md={6}
								sm={6}
								xs={6}
							>
								<StyleButton
									isWhiteButton={true}
									onClick={() => {
										setEditDirector(false);
										setOpen(false);
									}}
								>
									Cancel
								</StyleButton>
							</Grid>

						</Grid>

				}
			</DialogActions>
		</Dialog>
	)
}