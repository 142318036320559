import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";

const calculateFontSizeLarge = (size) => {
	return `${(100 * size) / 1920}vw`;
};

const calculateFontSizeMedium = (size) => {
	return `${(100 * size) / 1440}vw`;
};

const StyleTypography = ({
	fontSize = 30,
	fontSizeMedium = 20,
	color = "#0160FD",
	fontWeight = "normal",
	textAlign = "",
	component = "p",
	className = "",
	display = "inherit",
	children,
	onClick = false,
}) => {
	const [fontSizeFinal, setFontSizeFinal] = useState("");

	useEffect(() => {
		if (window.matchMedia("(max-width: 1536px)").matches) {
			setFontSizeFinal(calculateFontSizeMedium(fontSizeMedium));
		} else {
			setFontSizeFinal(calculateFontSizeLarge(fontSize));
		}

		const handleResize = () => {
			if (window.matchMedia("(max-width: 1536px)").matches) {
				setFontSizeFinal(calculateFontSizeMedium(fontSizeMedium));
			} else {
				setFontSizeFinal(calculateFontSizeLarge(fontSize));
			}
		};

		window.addEventListener("resize", handleResize);

		// Cleanup the event listener
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [fontSize, fontSizeMedium]);

	const styles = {
		fontSize: fontSizeFinal,
		color,
		fontWeight,
	};

	if (textAlign !== "") {
		styles.textAlign = textAlign;
	}

	return (
		<Typography
			style={styles}
			component={component}
			className={className}
			display={display}
			onClick={onClick ? onClick : null}
		>
			{children}
		</Typography>
	);
};

export default StyleTypography;
