import Grid from "@mui/material/Grid";
import React from "react";
import WarningIcon from "../images/svg/ReportsIcon/p60-warning.svg";
import StyleTypography from "./StyledComponents/StyleTypography";

export const WarningBox = ({
	text = '',
	textColor = '#000000',
	boxColor = '#F8F9FD',
	link = <></>,
}) => {

	return (
		<Grid
			container
			id={"warning-box"}
			direction={"row"}
			alignItems={"center"}
			sx={{
				backgroundColor: boxColor
			}}
		>
			<Grid
				item
				className={"warning-box-margin-right"}
			>
				<img
					className={"warning-icon"}
					src={WarningIcon}
					alt={"WarningIcon"}
				/>
			</Grid>
			<Grid
				xs={true}
				item
			>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={13}
					color={textColor}
					fontWeight={"bold"}
				>
					{text}
					{link}
				</StyleTypography>
			</Grid>
		</Grid>
	);
}