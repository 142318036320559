import Grid from "@mui/material/Grid";
import React from "react";
import {Typography, Modal} from "@collegia-partners/ui-kit";
import StyleButton from "../../StyledComponents/StyleButton";


const DayRateMethodDialog = ({
	openInfoModal = false,
	setOpenInfoModal = () => {},
}): JSX.Element => {
	return (
		<Modal isOpen={openInfoModal} onClose={() => setOpenInfoModal(false)} size={"lg"}>
			<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
				Day rate method
			</Typography>
			<Grid container>
				<Grid
					item
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
				>
					The "day rate" method is a way of calculating payment for work done on a daily basis. It is used to calculate the pay for:
					<ul>
						<li>New starters that join mid period</li>
						<li>Leavers that leave in the middle of a period</li>
						<li>Statutory schedules ( sickness, maternity, and paternity)</li>
					</ul>
				</Grid>

				{/*Yearly working days*/}
				<Grid
					item
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
				>
					<strong>Yearly working days:</strong>
					<ul>
						<li style={{ marginBottom: '20px' }}>This method uses how many working days there are in a year (52 weeks * number of working days in work pattern).</li>
						<li>
							Example: A team member has a maternity leave start date of 18th June. The pay period runs from 1st to 31st July 2023. They are on a £45,000.00 salary,
							have a use work pattern day rate method, and the company is set to use a calendar year calculation. Their work pattern has Monday, Tuesday, Thursday and Friday as working days.
							Their pay for July will be calculated as 45,000.00 / (4 working days per week * 52 weeks per year) * 9 days worked.
						</li>
					</ul>
				</Grid>

				{/*Calendar month*/}
				<Grid
					item
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
				>
					<strong>Calendar month:</strong>
					<ul>
						<li style={{ marginBottom: '20px' }}>This method uses how many working days there are in the pay period.</li>
						<li>
							Example: A team member has a maternity leave start date of 18th June. The pay period runs from 1st to 31st July 2023. They are on a £45,000.00 salary,
							have a use work pattern day rate method, and the company is set to use a pay period calculation. Their work pattern has Monday, Tuesday, Thursday and
							Friday as working days. Their pay for July will be calculated as 45,000.00 / 12 periods / 17 working days in the period * 9 days worked.
						</li>
					</ul>
				</Grid>

				<Grid
					marginTop={3}
					item
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
				>
					<Grid
						item
						xs={4}
					>
						<StyleButton
							onClick={() => setOpenInfoModal(false)}
						>
							I understood
						</StyleButton>
					</Grid>

				</Grid>
			</Grid>
		</Modal>
	);
}

export default DayRateMethodDialog;