import Details from "./Details";
import AddPaySchedule from "./Pay Schedules/AddPaySchedule";
import PayItems from "./PayItems";
import PaySchedules from "./Pay Schedules/ListPaySchedules/PaySchedules";
import Salaries from "./Salaries";
import CompanyProfile from "./CompanyProfile";
import EnhancedPolicy from "./EnhancedPolicy";
import Allowances from "./Allowances";
import EditPaySchedule from "./Pay Schedules/EditPaySchedule";
import Statutories from "./Statutories";
import ListPayItems from "./ListPayItems";

const CompanyPages = {
	Details,
	AddPaySchedule,
	EditPaySchedule,
	PaySchedules,
	PayItems,
	ListPayItems,
	Salaries,
	CompanyProfile,
	EnhancedPolicy,
	Allowances,
	Statutories,
}

export default CompanyPages;