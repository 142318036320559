import Grid from "@mui/material/Grid";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle, InputLabel,
} from "@mui/material";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import React from "react";
import TextField from "@mui/material/TextField";
import {CurrencyInputCustom} from "../../../StyledComponents/CurrencyInputCustom";
import StyleButton from "../../../StyledComponents/StyleButton";

export const EmploymentAllowanceModal = ({
	open,
	setOpen,
	employerRecord,
	setEmployerRecord,
	employer,
	updateEmploymentAllowanceSettings,
}): JSX.Element => {

	return (
		<Dialog
			onClose={() => setOpen(false)}
			open={open}
			fullWidth={true}
			maxWidth={'sm'}
			id={"salary-modal"}
		>
			<DialogTitle>
				<StyleTypography
					fontSize={35}
					fontSizeMedium={28}
					fontWeight={'bold'}
				>
					Employment Allowance
				</StyleTypography>
				<StyleTypography
					fontSize={22}
					fontSizeMedium={16}
					color={'#000000'}
				>
					If you have already claimed Employment Allowance in the current tax year, add the claimed amount here. Otherwise, leave it at £ 0.00.
				</StyleTypography>
			</DialogTitle>
			<DialogContent>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
				>
					<Grid
						item
						xl={7}
						lg={7}
						md={7}
						sm={7}
						xs={7}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Allowance already claimed
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							InputProps={{
								endAdornment: "£",
								inputComponent: CurrencyInputCustom,
								inputProps: {prefix: ""},
							}}
							value={employerRecord.active_employer_ytd_figure?.employment_allowance_used}
							onChange={(e) => {
								setEmployerRecord({
									...employerRecord,
									active_employer_ytd_figure: {
										...employerRecord.active_employer_ytd_figure,
										employment_allowance_used: e.target.value,
									}
								})
							}}
						/>
					</Grid>
					<Grid
						item
						xl={7}
						lg={7}
						md={7}
						sm={7}
						xs={7}
						marginTop={2}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Remaining allowance
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							InputProps={{
								endAdornment: "£",
								inputComponent: CurrencyInputCustom,
								inputProps: {prefix: ""},
							}}
							disabled
							value={employer.active_employer_ytd_figure?.employment_allowance - employerRecord.active_employer_ytd_figure?.employment_allowance_used}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<StyleButton
							onClick={() => {
								setEmployerRecord(employer);
								setOpen(false);
							}}
							isWhiteButton={true}
						>
							Cancel
						</StyleButton>
					</Grid>

					{
						!employer.employment_allowance && (
							<Grid
								item
								xl={6}
								lg={6}
								md={6}
								sm={6}
								xs={6}
							>
								<StyleButton
									fullWidth
									onClick={() => {
										updateEmploymentAllowanceSettings({
											"employers": {
												employment_allowance: true,
												employment_allowance_used: employerRecord.active_employer_ytd_figure.employment_allowance_used,
												employment_allowance_remaining: employer.active_employer_ytd_figure.employment_allowance - employerRecord.active_employer_ytd_figure.employment_allowance_used,

											}
										});
										setOpen(false);
									}}
								>
									Enable
								</StyleButton>
							</Grid>
						)
					}

				</Grid>
			</DialogActions>
		</Dialog>
	)
}