import React, {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Layout from "../../components/common/Layout";
import PayrollContent from "../../components/PayrollContent/PayrollContent";
import Request from "../../utils/Request";
import {toast} from "react-toastify";
import {formatDateFromBackend} from "../../utils/Helpers";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollApproval = (): JSX.Element => {

	const id = useParams().token;

	const [tabIndex, setTabIndex] = useState(0);

	const [searchText, setSearchText] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [paginationParams, setPaginationParams] = useState({
		page: 1,
		rowsPerPage: 10,
		lastPage: 1,
		from: 1,
		to: 10
	});
	const { page } = paginationParams;
	const timeoutSet = React.useRef(null);
	const [isLoading, setIsLoading] = useState(true);

	const [employeePayRuns, setEmployeePayRuns] = useState([]);
	const [payrollSummary, setPayrollSummary] = useState({});
	const [payScheduleRun, setPayScheduleRun] = useState({});
	const push = useNavigate();

	const fetchPayrollData = useCallback(async () => {
		setIsLoading(true); // Set loading state to true before the request
		setEmployeePayRuns([]); // Clear the employee pay runs
		try {
			const { data } = await Request.get(`api/employers/view-payroll-approval?token=${id}&search=${searchText}&page=${page}`);

			setPaginationParams({
				page: data.data.current_page,
				rowsPerPage: data.data.per_page,
				lastPage: data.data.last_page,
				from: data.data.from,
				to: data.data.to,
				total: data.data.total
			});
			setEmployeePayRuns(data.data.data); // Update state with fetched data
			setPayrollSummary(data.payroll_summary);
			setPayScheduleRun(data.pay_schedule_run);
		} catch (e) {
			if (e.response?.status === 404) {
				push("/login");
				toast.error("No payroll data found, please contact support");
			} else if (e.response?.status === 422) {
				push("/payroll-approval-completed/" + id);
				toast.error(e.response.data.message);
			}
			else {
				toast.error("An error occurred while fetching payroll data, please contact support");
			}
			console.error(e); // Handle errors
		} finally {
			setIsLoading(false); // Set loading state to false after the request
		}
	},[id, page, push, searchText]);

	useEffect(() => {
		fetchPayrollData();
	}, [fetchPayrollData]);

	useEffect(() => {
		if (timeoutSet.current) clearTimeout(timeoutSet.current);

		timeoutSet.current = setTimeout(() => {
			setSearchText(searchValue);
			setPaginationParams(prevState => ({
				...prevState,
				page: 1
			}));
		}, 500);
	}, [searchValue]);

	return (
		<Layout
			showBackButton={false}
			isLoggedLayout={false}
		>
			<PayrollContent
				tabIndex={tabIndex}
				onChangeTabs={(event, newValue) => {
					setTabIndex(newValue);
				}}
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				taxPeriod={payScheduleRun.tax_period}
				payDate={formatDateFromBackend(payScheduleRun.period_end_date)}
				taxYear={payScheduleRun.tax_year?.formated_tax_year}
				payrollMode={"PAYROLL_APPROVAL"}
				payrollSummary={payrollSummary}
				employeePayRuns={employeePayRuns}
				isLoading={isLoading}
				payrollId={id}
				paginationParams={paginationParams}
				setPaginationParams={setPaginationParams}
				payScheduleRun={payScheduleRun}
			/>
		</Layout>
	);
}

export default PayrollApproval;