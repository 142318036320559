import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import StyleTypography from "../../StyledComponents/StyleTypography";
import {Alert, InputLabel, MenuItem, Select} from "@mui/material";
import Grid from "@mui/material/Grid";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayScheduleDeleteDialog = ({
	openDeleteDialog = false,
	setOpenDeleteDialog = () => {},
	deletePaySchedule = () => {},
	selectedPaySchedule = {},
	setSelectedPaySchedule = () => {},
	paySchedules = [],
}): JSX.Element => {

	const [newPayScheduleId, setNewPayScheduleId] = React.useState(undefined);

	return (
		<>
			<Dialog
				onClose={() => {
					setSelectedPaySchedule({});
					setOpenDeleteDialog(false);
					setNewPayScheduleId(undefined);
				}}
				open={openDeleteDialog}
				fullWidth={true}
				maxWidth={"sm"}
			>
				<DialogTitle>
					<StyleTypography
						fontSize={35}
						fontSizeMedium={28}
						fontWeight={'bold'}
					>
						Confirm delete
					</StyleTypography>
				</DialogTitle>
				<DialogContent>
					<StyleTypography
						fontSize={25}
						fontSizeMedium={20}
						color={"black"}
					>
						Do you really want to remove <strong>{selectedPaySchedule?.name}</strong>?
					</StyleTypography>
					<Grid
						marginTop={2}
					>
						{
							selectedPaySchedule?.employees_count > 0 ? (
								<Alert severity="error">
									Before deleting this pay schedule, you need to reassign all employees to
									another pay schedule. If you still didn't create another pay schedule,
									please do it first.
								</Alert>
							) : (
								<Alert severity="error">
									This action is irreversible. All data related to this pay schedule will be lost.
								</Alert>
							)
						}

					</Grid>

					{
						selectedPaySchedule?.employees_count > 0 && (
							<Grid
								marginTop={2}
							>
								<InputLabel
									className={"default-input-label"}
								>
									Select a pay schedule to reassign all employees
								</InputLabel>
								<Select
									className={"default-text-field"}
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: 400, // Adjust the maximum height of the list
											},
										},
									}}
									value={newPayScheduleId ?? undefined}
									onChange={(e) =>
										setNewPayScheduleId(e.target.value)
									}
									fullWidth
								>
									{
										paySchedules.map((schedule) => schedule.id !== selectedPaySchedule.id && (
											<MenuItem
												value={schedule.id}
												key={schedule.id}
											>
												{schedule.name}
											</MenuItem>
										))
									}
								</Select>

							</Grid>
						)
					}

				</DialogContent>
				<DialogActions>
					<Button
						className={"default-black-button"}
						onClick={() => {
							deletePaySchedule(
								selectedPaySchedule.id, {
									'pay_schedules': {
										new_pay_schedule_id: newPayScheduleId
									},
								}
							);
							setSelectedPaySchedule({});
							setOpenDeleteDialog(false);
							setNewPayScheduleId(undefined);
						}}
						disabled={!newPayScheduleId && selectedPaySchedule.employees_count > 0}
					>
						Yes
					</Button>
					<Button
						className={"default-white-button"}
						onClick={() => {
							setSelectedPaySchedule({});
							setOpenDeleteDialog(false);
							setNewPayScheduleId(undefined);
						}}
					>
						No
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default PayScheduleDeleteDialog