import Grid from "@mui/material/Grid";
import StyleTypography from "../../StyledComponents/StyleTypography";
import React from "react";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import {CurrencyInputCustom} from "../../StyledComponents/CurrencyInputCustom";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import enGb from "date-fns/locale/en-GB";
import {addWeeks, format, parseISO, previousSunday, subWeeks} from "date-fns";
import Button from "@mui/material/Button";

/**
 * @param user
 * @param employee
 * @param employeeRecord
 * @param setEmployeeRecord
 * @returns {JSX.Element}
 * @constructor
 */
export const MaternityLeave = (
	{
		employee = {},
		employeeRecord = {
			parental_leave: {
				expected_due_date: "",
				average_weekly_earnings: "",
				is_pensionable: false,
				include_enhanced_policy: false,
				is_maternity: false,
				is_paternity: false,
				first_start_date: "",
				first_end_date: "",
			},
		},
		setEmployeeRecord = () => {
		},
		addParentalStatutory = () => {
		},
	}
): JSX.Element => {

	return (
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			container
			item
			justifyContent={"space-between"}
			id={'maternity-paternity'}
		>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				className={"maternity-paternity-container"}
				justifyContent={"space-between"}
			>
				<Grid
					xs={5.16}
					item
				>
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
					>
						<StyleTypography
							fontSize={40}
							fontSizeMedium={30}
							fontWeight={'bold'}
							component={"h1"}
						>
							Maternity leave
						</StyleTypography>
					</Grid>

					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
						paddingBottom={"30px"}
					>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={"#ABAEB7"}
						>
							Setup a statutory leave scheme for the employee and let FreePayroll calculate the payments.
						</StyleTypography>
					</Grid>

					{/*Expected due date*/}
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
					>
						<InputLabel
							className={"default-input-label"}
						>
							Expected due date
						</InputLabel>
						<LocalizationProvider
							dateAdapter={AdapterDateFns}
							adapterLocale={enGb}
						>
							<DesktopDatePicker
								className={"date-picker"}
								value={parseISO(employeeRecord.parental_leave?.expected_due_date)}
								onChange={(newValue) => {
									if (employeeRecord && !employeeRecord.parental_leave) {
										employeeRecord.parental_leave = {}; // Initialize it as an empty object
									}
									employeeRecord.parental_leave.expected_due_date = format(newValue, 'yyyy-MM-dd');
									employeeRecord.parental_leave.first_start_date = null;
									employeeRecord.parental_leave.first_end_date = null;
									setEmployeeRecord(employeeRecord);
								}}
								slotProps={{field: {readOnly: true}}}
								enableAccessibleFieldDOMStructure
							/>
						</LocalizationProvider>
					</Grid>

					{/*Leave start and end date*/}
					<Grid
						container
						direction={"row"}
						spacing={4}
						paddingTop={"30px"}
						paddingBottom={"30px"}
					>
						<Grid item xs={6}>
							<InputLabel
								className={"default-input-label"}
							>
								Leave start date
							</InputLabel>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								adapterLocale={enGb}
							>
								<DesktopDatePicker
									className={"date-picker"}
									value={parseISO(employeeRecord?.parental_leave?.first_start_date)}
									onChange={(newValue) => {
										if (employeeRecord && !employeeRecord.parental_leave) {
											employeeRecord.parental_leave = {}; // Initialize it as an empty object
										}
										employeeRecord.parental_leave.first_start_date = format(newValue, 'yyyy-MM-dd')
										setEmployeeRecord(employeeRecord);
									}}
									slotProps={{field: {readOnly: true}}}
									enableAccessibleFieldDOMStructure
									minDate={subWeeks(previousSunday(parseISO(employeeRecord.parental_leave?.expected_due_date)), 11)}
									maxDate={parseISO(employeeRecord?.parental_leave?.expected_due_date)}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={6}>
							<InputLabel
								className={"default-input-label"}
							>
								Leave end date
							</InputLabel>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								adapterLocale={enGb}
							>
								<DesktopDatePicker
									className={"date-picker"}
									value={parseISO(employeeRecord?.parental_leave?.first_end_date)}
									onChange={(newValue) => {
										if (newValue instanceof Date && !isNaN(newValue.getTime())) {
											if (employeeRecord && !employeeRecord.parental_leave) {
												employeeRecord.parental_leave = {}; // Initialize it as an empty object
											}
											employeeRecord.parental_leave.first_end_date = newValue.toISOString().split('T')[0];
											setEmployeeRecord(employeeRecord);
										}
									}}
									slotProps={{field: {readOnly: true}}}
									enableAccessibleFieldDOMStructure
									minDate={parseISO(employeeRecord?.parental_leave?.first_start_date)}
									maxDate={addWeeks(parseISO(employeeRecord?.parental_leave?.first_start_date), 52)}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>

					{/*Average weekly earnings*/}
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
						paddingBottom={"20px"}
					>
						<StyleTypography
							color={"black"}
							fontSize={18}
							fontSizeMedium={13}
							fontWeight={"bold"}
						>
							Average weekly earnings
						</StyleTypography>
						<Grid
							paddingBottom={"10px"}
						>
							<StyleTypography
								color={"#AAAEB7"}
								fontSize={16}
								fontSizeMedium={12}
							>
								FreePayroll can only calculate the average weekly earnings, if we have at least 52 weeks
								of data.
								Otherwise, you will need to input manually.
							</StyleTypography>
						</Grid>
						<TextField
							className={"default-text-field"}
							value={employeeRecord.parental_leave?.average_weekly_earnings}
							onChange={(e) => {
								if (!employeeRecord.parental_leave) {
									employeeRecord.parental_leave = {}; // Initialize it as an empty object
								}
								employeeRecord.parental_leave.average_weekly_earnings = e.target.value;
								setEmployeeRecord(employeeRecord);
							}}
							fullWidth
							InputProps={{
								inputComponent: CurrencyInputCustom,
								inputProps: {prefix: "£"},
							}}
						/>
					</Grid>

					<Button
						className={"maternity-paternity-button"}
						fullWidth
						onClick={() => addParentalStatutory({
							employees: {
								'statutory_type': 'maternity',
								'employee_safe_id': employeeRecord.safe_id,
								is_paternity: false,
								is_maternity: true,
								expected_due_date: employeeRecord.parental_leave?.expected_due_date,
								first_start_date: employeeRecord.parental_leave?.first_start_date,
								first_end_date: employeeRecord.parental_leave?.first_end_date,
								average_weekly_earnings: employeeRecord.parental_leave.average_weekly_earnings,
							}
						})}
						disabled={!employeeRecord.parental_leave?.expected_due_date || !employeeRecord.parental_leave?.first_start_date || !employeeRecord.parental_leave?.first_end_date || !employeeRecord.parental_leave?.average_weekly_earnings}
					>
						Add leave
					</Button>
				</Grid>
				<Grid
					xs={5.16}
					item
					className={"statutory-style"}
				>
					<StyleTypography
						fontSize={22}
						fontSizeMedium={16}
						fontWeight={"bold"}
						color={"black"}
					>
						Statutory pay claims
					</StyleTypography>
					<Grid marginTop={"10px"}>
						<StyleTypography
							fontSize={16}
							fontSizeMedium={12}
							color={"#AAAEB7"}
						>
							If you have paid any Statutory Maternity Pay or
							Statutory Paternity Pay, We will claim 92% of it from HMRC.
							If you qualify for small Employer Relief, it will be 103%.
						</StyleTypography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}