import {resolveReduxState} from "../../utils/Helpers";
import {
	SEND_CODE,
	CONFIRM_CODE,
	CHANGE_PASSWORD,
} from "../../actions/ForgotPassword/types";

const initial_state = {
	forgot_password_loading: false,
	button_loading: false,
	message: "",
	reduxStep: 0,
}

const ForgotPassword = (state = initial_state, action = false) => {
	if (action) {
		switch(action.type) {
			case SEND_CODE:
			case CONFIRM_CODE:
			case CHANGE_PASSWORD:
				return resolveReduxState(state, action);
			default:
				return state;
		}
	}

	return state;
}

export default ForgotPassword;