import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { me } from "../../actions/Profile";
import { LeftMenu } from "../../components/LeftMenu";
import { StyleAppBar } from "../../components/StyleAppBar";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Loading from "../../components/Loading";
import Divider from "@mui/material/Divider";
import House from "../../images/svg/hmrc-page/black-house.svg";
import { findEmployer, updateApprenticeLevy, updateEmploymentAllowanceSettings } from "../../actions/Employer";
import { ApprenticeModal } from "../../components/Pages/Company/Allowances/ApprenticeModal";
import { EmploymentAllowanceModal } from "../../components/Pages/Company/Allowances/EmploymentAllowanceModal";
import StyleButton from "../../components/StyledComponents/StyleButton";
import { formatMoney } from "../../utils/Helpers";

const renderGrids = (component1, component2, render_extra = false, component3 = false) => {
	return (
		<Grid
			container
			alignItems={"center"}
			justifyContent={"space-between"}
			className={"information-item-grid"}
		>
			<Grid>
				{component1}
			</Grid>
			<Grid>
				{component2}
			</Grid>
			{
				render_extra && (
					<Grid
						item
						xs={12}
					>
						{component3}
					</Grid>
				)
			}
		</Grid>
	);
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const Allowances = ({
	me = () => { },
	user = {},
	user_loading = false,
	employer_loading = false,
	employer = {},
	findEmployer = () => { },
	updateApprenticeLevy = () => { },
	updateEmploymentAllowanceSettings = () => { },
}): JSX.Element => {

	const [employerRecord, setEmployerRecord] = useState({
		apprentice_levy_due: false,
		employment_allowance: false,
	});

	const [openApprenticeModal, setOpenApprenticeModal] = useState(false);
	const [openEAModal, setOpenEAModal] = useState(false);

	useEffect(() => {
		me();
		findEmployer(['active_employer_ytd_figure']);

		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [findEmployer, me]);

	useEffect(() => {
		if (employer?.id) {
			setEmployerRecord(employer);
		}

		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [user, employer, setEmployerRecord]);

	if (user_loading || employer_loading) {
		return <Loading />;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"company-allowances-page"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
				employer={employer}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Company"}
						activeSubpage={"Allowances"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							justifyContent={"space-between"}
							alignItems={"center"}
						>
							<StyleTypography
								fontSize={40}
								fontSizeMedium={27}
								fontWeight={"bold"}
							>
								Allowances
							</StyleTypography>
						</Grid>
						<Divider className={"divider"} />
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
							className={"information-container"}
							direction={"row"}
						>
							<Grid
								xl={5.18}
								lg={5.18}
								md={5.18}
								sm={5.18}
								xs={5.18}
								item
								container
								alignItems={"flex-start"}
								alignSelf={"flex-start"}
								className={"left-container"}
							>
								{
									renderGrids(
										<StyleTypography textAlign={"start"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
											Employment allowance
										</StyleTypography>,
										<StyleButton onClick={() => setOpenEAModal(true)} customStyle={{ width: "95px" }}>
											<StyleTypography fontWeight={"bold"} fontSize={16} fontSizeMedium={12} color={"#FFF"}>
												{employer.employment_allowance ? "Stop" : "Enable"}
											</StyleTypography>
										</StyleButton>,
										employer.employment_allowance === true,
										<StyleTypography fontSize={16} fontSizeMedium={12} color={"#AAAEB7"}>
											Remaining allowance: {formatMoney(employer.active_employer_ytd_figure?.employment_allowance_remaining)}
										</StyleTypography>
									)
								}
								<Divider className={"divider"} />
								{
									renderGrids(
										<StyleTypography textAlign={"start"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
											Apprenticeship Levy
										</StyleTypography>,
										<StyleButton onClick={() => setOpenApprenticeModal(true)} customStyle={{ width: "95px" }}>
											<StyleTypography fontWeight={"bold"} fontSize={16} fontSizeMedium={12} color={"#fff"}>
												{employer.apprentice_levy_due ? "Stop" : "Enable"}
											</StyleTypography>
										</StyleButton>,
										employer.apprentice_levy_due === true,
										<StyleTypography fontSize={16} fontSizeMedium={12} color={"#AAAEB7"}>
											Allowance per year: £{employer.active_employer_ytd_figure?.apprentice_allowance}
										</StyleTypography>
									)
								}
							</Grid>

							<Grid
								xl={5.18}
								lg={5.18}
								md={5.18}
								sm={5.18}
								xs={5.18}
								item
								container
								sx={{
									marginLeft: "36px"
								}}
								className={"right-container"}
								alignItems={"flex-start"}
								alignSelf={"flex-start"}
							>
								<Grid container className={"information-item-grid"}>
									<Grid sx={{ lineHeight: 2.3, marginRight: '20px' }}>
										<img
											alt={"logo"}
											src={House}
										/>
									</Grid>
									<Grid item xs={true}>
										<Grid>
											<StyleTypography fontSize={22} fontSizeMedium={16} color={"black"} fontWeight={"bold"}>
												Employment allowance
											</StyleTypography>
										</Grid>
										<Grid>
											<StyleTypography fontSize={18} fontSizeMedium={13} color={"#AAAEB7"}>
												An allowance of £5,000 per tax year is available to offset against your Class 1 NICs liability.
											</StyleTypography>
										</Grid>
									</Grid>
								</Grid>
								<Divider className={"divider"} />
								<Grid container className={"information-item-grid"}>
									<Grid sx={{ lineHeight: 2.3, marginRight: '20px' }}>
										<img
											alt={"logo"}
											src={House}
										/>
									</Grid>
									<Grid item xs={true}>
										<Grid>
											<StyleTypography fontSize={22} fontSizeMedium={16} color={"black"} fontWeight={"bold"}>
												Apprenticeship Levy
											</StyleTypography>
										</Grid>
										<Grid>
											<StyleTypography fontSize={18} fontSizeMedium={13} color={"#AAAEB7"}>
												The Apprenticeship Levy is a levy on UK employers to fund new apprenticeships. An allowance of £15,000 per tax year is available to offset against your payments.
											</StyleTypography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<ApprenticeModal
				open={openApprenticeModal}
				setOpen={setOpenApprenticeModal}
				employerRecord={employerRecord}
				setEmployerRecord={setEmployerRecord}
				employer={employer}
				updateApprenticeLevy={updateApprenticeLevy}
			/>
			<EmploymentAllowanceModal
				open={openEAModal}
				setOpen={setOpenEAModal}
				employerRecord={employerRecord}
				setEmployerRecord={setEmployerRecord}
				employer={employer}
				updateEmploymentAllowanceSettings={updateEmploymentAllowanceSettings}
			/>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Employer,
	} = state;

	return {
		...Profile,
		...Employer,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	updateEmploymentAllowanceSettings: (data) => dispatch(updateEmploymentAllowanceSettings(data)),
	updateApprenticeLevy: (data) => dispatch(updateApprenticeLevy(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Allowances)