import Grid from "@mui/material/Grid";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import React from "react";
import {InputAdornment, InputLabel} from "@mui/material";
import TextField from "@mui/material/TextField";
import WarningWhiteIcon from "../../../../images/svg/SickLeave/warning-white.svg";
import InterrogationWhiteIcon from "../../../../images/svg/SickLeave/interrogation-white.svg";
import {CurrencyInputCustom} from "../../../StyledComponents/CurrencyInputCustom";
import Button from "@mui/material/Button";

/**
 * @returns {JSX.Element}
 * @constructor
 */
export const SickLeaveWeeklyEarnings = (
	{
		employee = {},
		sickLeaveDetails = {},
		setStep = () => {},
		setSickLeaveDetails = () => {},
		reviewSickLeaveEmployee = () => {},
	}
): JSX.Element => {
	return (
		<Grid
			className={"sick-leave-weekly-earnings"}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<StyleTypography
					fontSize={40}
					fontSizeMedium={30}
					fontWeight={'bold'}
					component={"h1"}
				>
					Confirm {employee?.name} average weekly earnings
				</StyleTypography>
			</Grid>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={13}
					color={"#ABAEB7"}
				>
					We don’t have enough information to calculate {employee?.name} average weekly earnings. Enter an amount below to check for SSP eligibility.
				</StyleTypography>
			</Grid>

			<Grid className={"sick-leave-weekly-earnings-average-weekly-margin"}>
				<InputLabel
					className={"default-input-label"}
				>
					Average weekly earnings
				</InputLabel>
				<TextField
					fullWidth
					className={"sick-leave-weekly-earnings-text-field-input"}
					onChange={(event) => {
						setSickLeaveDetails({
							average_weekly_earnings: event.target.value
						});
					}}
					InputProps={{
						startAdornment: <InputAdornment position={"start"}>
							<StyleTypography fontSize={16} fontSizeMedium={13} color={"black"} fontWeight={"bold"}>
								£
							</StyleTypography>
						</InputAdornment>,
						inputComponent: CurrencyInputCustom,
						inputProps: {prefix: ""},
					}}
					value={sickLeaveDetails.average_weekly_earnings}
				/>
			</Grid>

			{
				parseFloat(sickLeaveDetails.average_weekly_earnings) < 123 && (
					<Grid
						className={"sick-leave-weekly-earnings-statutory-sick-pay-box"}
						container
						direction={"row"}
						alignItems={"center"}
					>
						<Grid item className={"sick-leave-weekly-earnings-statutory-sick-pay-margin-left"}>
							<img
								className={"sick-leave-weekly-earnings-statutory-sick-pay-img"}
								src={WarningWhiteIcon}
								alt={"WarningWhiteIcon"}
							/>
						</Grid>
						<Grid
							item
						>
							<Grid>
								<StyleTypography
									fontSize={18}
									fontWeight={"bold"}
									fontSizeMedium={13}
									color={"white"}
								>
									{employee?.name} not eligible for Statutory Sick Pay
								</StyleTypography>
							</Grid>
							<Grid>
								<StyleTypography
									color={"white"}
									fontSize={15}
									fontSizeMedium={12}
								>
									Employee’s must earn an average of at least £123 per week to qualify. Recording this leave will still reduce employee pay.
								</StyleTypography>
							</Grid>
						</Grid>
					</Grid>
				)
			}

			<Grid
				className={"sick-leave-weekly-earnings-statutory-need-help-box"}
				container
				direction={"row"}
				alignItems={"center"}
			>
				<Grid item className={"sick-leave-weekly-earnings-statutory-need-help-margin-left"}>
					<img
						className={"sick-leave-weekly-earnings-statutory-need-help-img"}
						src={InterrogationWhiteIcon}
						alt={"WarningWhiteIcon"}
					/>
				</Grid>
				<Grid
					item
				>
					<Grid>
						<StyleTypography
							fontSize={18}
							fontWeight={"bold"}
							fontSizeMedium={13}
							color={"black"}
						>
							Need help calculating average weekly earnings?
						</StyleTypography>
					</Grid>
					<Grid>
						<StyleTypography
							color={"black"}
							fontSize={15}
							fontSizeMedium={12}
						>
							Learn more in our help center article.
						</StyleTypography>
					</Grid>
				</Grid>
				<Grid sx={{ flexGrow: 1 }}></Grid>
				<Grid >
					<Button
						className={"sick-leave-weekly-earnings-statutory-need-help-button"}
						variant={"contained"}
						fullWidth
						onClick={() => {}}
					>
						<StyleTypography
							color={"white"}
							fontSize={16}
							fontWeight={"bold"}
							fontSizeMedium={12}
						>
							Help center
						</StyleTypography>
					</Button>
				</Grid>
			</Grid>

			<Grid
				container
				direction={"row"}
			>
				<Button
					className={"sick-leave-weekly-earnings-statutory-continue-button"}
					onClick={() => {
						reviewSickLeaveEmployee({
							employees: {
								employee_id: employee.id,
								first_sick_day: sickLeaveDetails.first_sick_day,
								last_sick_day: sickLeaveDetails.last_sick_day,
								work_week: employee.working_pattern,
								last_sick_day_radio: sickLeaveDetails.last_sick_day_radio,
							}
						});
					}}
				>
					<StyleTypography
						color={"white"}
						fontWeight={"bold"}
						fontSize={16}
						fontSizeMedium={13}
					>
						Continue
					</StyleTypography>
				</Button>

				<Button
					className={"sick-leave-weekly-earnings-statutory-discard-button"}
					onClick={() => {
						setSickLeaveDetails({
							first_sick_day: null,
							last_sick_day: null,
							last_sick_day_radio: false,
							average_weekly_earnings: 0.00,
						});
						setStep(0);
					}}
				>
					<StyleTypography
						color={"#000"}
						fontWeight={"bold"}
						fontSize={16}
						fontSizeMedium={13}
					>
						Discard
					</StyleTypography>
				</Button>
			</Grid>
		</Grid>
	)
}