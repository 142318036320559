import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {InputAdornment, InputLabel} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import {CurrencyInputCustom} from "../../../StyledComponents/CurrencyInputCustom";

export const NewEmployeeWithP45 = ({
    employeeInformation,
    setEmployeeInformation,
}): JSX.Element => {

	const regexTaxCode = /^(?:[CS])?(([1-9][0-9]{0,5}[LMNPTY])|(BR)|(0T)|(NT)|(D[0-8])|([K][1-9][0-9]{0,5}))$/;

	return (
		<>
			{/*Tax Information*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				spacing={4}
			>
				{/*Tax Code*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Tax Code*
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								tax_code: event.target.value,
							});
						}}
						error={!regexTaxCode.test(employeeInformation.tax_code)}
						value={employeeInformation.tax_code}
					/>
				</Grid>

				{/*Tax Basis*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
					container
				>
					<InputLabel
						className={"default-input-label"}
					>
						Tax Basis*
					</InputLabel>
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
					>
						<RadioGroup
							row
							onChange={(event) => {
								setEmployeeInformation({
									...employeeInformation,
									is_cumulative: event.target.value,
								});
							}}
							value={employeeInformation.is_cumulative}
						>
							<FormControlLabel
								value={'true'}
								control={<Radio />}
								label={
									<StyleTypography
										fontSize={18}
										fontSizeMedium={13}
										color={'#000000'}
									>
										Cumulative
									</StyleTypography>
								}
							/>
							<FormControlLabel
								value={'false'}
								control={<Radio />}
								label={
									<StyleTypography
										fontSize={18}
										fontSizeMedium={13}
										color={'#000000'}
									>
										Non-Cumulative (W1/M1)
									</StyleTypography>
								}
							/>
						</RadioGroup>
					</Grid>
				</Grid>
			</Grid>

			{/*Previous taxable and tax*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				spacing={4}
			>
				{/*Taxable salary to date*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Previous taxable salary*
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								ytd_figure: {
									...employeeInformation.ytd_figure,
									previous_employment_taxable_pay: event.target.value,
								},
							});
						}}
						InputProps={{
							endAdornment: <InputAdornment position={"end"}>
								<StyleTypography fontSize={16} color={"black"} fontWeight={"bold"}>
									£
								</StyleTypography>
							</InputAdornment>,
							inputComponent: CurrencyInputCustom,
							inputProps: {prefix: ""},
						}}
						value={employeeInformation.ytd_figure?.previous_employment_taxable_pay}
					/>
				</Grid>

				{/*Tax paid to date*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Previous tax paid*
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								ytd_figure: {
									...employeeInformation.ytd_figure,
									previous_employment_tax_deducted: event.target.value,
								},
							});
						}}
						InputProps={{
							endAdornment: <InputAdornment position={"end"}>
								<StyleTypography fontSize={16} color={"black"} fontWeight={"bold"}>
									£
								</StyleTypography>
							</InputAdornment>,
							inputComponent: CurrencyInputCustom,
							inputProps: {prefix: ""},
						}}
						value={employeeInformation.ytd_figure?.previous_employment_tax_deducted}
					/>
				</Grid>
			</Grid>
		</>
	);
}