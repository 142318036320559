import Grid from "@mui/material/Grid";
import React, { useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { me } from "../../actions/Profile";
import { LeftMenu } from "../../components/LeftMenu";
import { StyleAppBar } from "../../components/StyleAppBar";
import Loading from "../../components/Loading";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Divider from "@mui/material/Divider";
import StyleButton from "../../components/StyledComponents/StyleButton";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import FormControlLabel from "@mui/material/FormControlLabel";
import { createPayrollTemplate, downloadJournal, getPayrollTemplate } from "../../actions/PayrollJournal";
import StyleTable from "../../components/StyledComponents/StyleTable";
import { getPayScheduleRuns } from "../../actions/PayScheduleRun";
import StyleTextField from "../../components/StyledComponents/StyleTextfield";

const renderCheckBox = (aggregateItems, dispatch) => {
	return aggregateItems.map(aggItem => aggItem.extra ? (
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			item
		>
			<FormControlLabel
				control={
					<Checkbox
						checked={aggItem.show}
						icon={<CheckBoxOutlineBlankSharpIcon />}
						checkedIcon={<CheckBoxSharpIcon />}
						onChange={() => {
							if (
								aggItem.parentId === "Total NIC Payable" ||
								aggItem.parentId === "Total Pension Contributions Payable" ||
								aggItem.parentId === "Total Income Tax Payable"
							) {
								dispatch({
									type: 'toggleParentItemNIC',
									payload: { id: aggItem.parentId },
								});
								dispatch({
									type: 'toggleAggregateItemNIC',
									payload: { parentId: aggItem.parentId, id: aggItem.id },
								});
							} else {
								dispatch({
									type: 'toggleAggregateItem',
									payload: { parentId: aggItem.parentId, id: aggItem.id },
								});
							}

						}}
					/>
				}
				label={
					<StyleTypography
						fontSize={20}
						fontSizeMedium={13}
						color={"black"}
						fontWeight={"bold"}
					>
						{aggItem.title}
					</StyleTypography>
				}
			/>
		</Grid>
	) : null);
};

const renderAccountCode = (item, dispatch) => {

	return (
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			item
			container
			className={'account-codes-container'}
			justifyContent={"space-between"}
			alignItems={"center"}
		>
			<Grid
				className={'account-codes-title'}
			>
				<StyleTypography
					fontSize={20}
					fontSizeMedium={13}
					color={"black"}
					fontWeight={"bold"}
				>
					{item.title}
				</StyleTypography>
			</Grid>
			<Grid>
				<StyleTextField
					value={item.accountCode}
					onChange={(event) => {
						dispatch({
							type: 'updateAccountCode',
							payload: {
								parentId: item.parentId,  // Assuming you have a parentId property to identify parent item
								id: item.id,
								accountCode: event.target.value,
							},
						})
					}}
				/>
			</Grid>
		</Grid>
	)
}

const renderAggregatedAccountCode = (aggregateItems, dispatch) => {
	return aggregateItems.map(aggItem => aggItem.show ? (
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			item
			container
			className={'account-codes-container'}
			justifyContent={"space-between"}
			alignItems={"center"}
		>
			<Grid
				className={'account-codes-title'}
			>
				<StyleTypography
					fontSize={20}
					fontSizeMedium={13}
					color={"black"}
					fontWeight={"bold"}
				>
					{aggItem.title}
				</StyleTypography>
			</Grid>
			<Grid>
				<StyleTextField
					value={aggItem.accountCode}
					onChange={(event) => {
						dispatch({
							type: 'updateAccountCode',
							payload: {
								parentId: aggItem.parentId,  // Assuming you have a parentId property to identify parent item
								id: aggItem.id,
								accountCode: event.target.value,
							},
						})
					}}
				/>
			</Grid>
		</Grid>
	) : null);
};

const renderSteps = (step, extraItems, dispatch, setStep, editTemplate, setEditTemplate, createPayrollTemplate) => {
	if (step === 1) {
		return (
			<>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={30}
						fontWeight={"bold"}
					>
						Select any items you want to <br />
						show separately in your journal
					</StyleTypography>
					<Grid
						paddingTop={"30px"}
						paddingBottom={"30px"}
						width={"85%"}
					>
						<Divider />
					</Grid>
				</Grid>
				<Grid
					xl={6}
					lg={6}
					md={6}
					sm={6}
					xs={6}
					item
					container
				>
					{
						extraItems.aggregatedItems.map(item => {
							return renderCheckBox(item.aggregate, dispatch);
						})
					}
					<Grid
						xs={3}
						item
					>
						<StyleButton
							onClick={() => setStep(2)}
						>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={14}
								color={'#FFFFFF'}
							>
								Next
							</StyleTypography>
						</StyleButton>
					</Grid>
					<Grid
						xs={3}
						item
						marginLeft={'12px'}
					>
						<StyleButton
							onClick={() => setEditTemplate(!editTemplate)}
							isWhiteButton={true}
						>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={14}
								color={'black'}
							>
								Cancel
							</StyleTypography>
						</StyleButton>
					</Grid>
				</Grid>
			</>
		);
	} else {
		return (
			<>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={30}
						fontWeight={"bold"}
					>
						Account codes
					</StyleTypography>
					<Grid
						paddingTop={"30px"}
						paddingBottom={"30px"}
						width={"85%"}
					>
						<Divider />
					</Grid>
				</Grid>
				<Grid
					xl={6}
					lg={6}
					md={6}
					sm={6}
					xs={6}
					item
					container
				>
					{
						extraItems.aggregatedItems.map(item => {
							return (
								<>
									{item.show && renderAccountCode(item, dispatch)}
									{renderAggregatedAccountCode(item.aggregate, dispatch)}
								</>
							)
						})
					}
					<Grid
						xs={3}
						item
					>
						<StyleButton
							onClick={() => {
								createPayrollTemplate({
									'journal_template': {
										'template': extraItems.aggregatedItems,
									}
								}, setEditTemplate, setStep)
							}}
						>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={14}
								color={'#FFFFFF'}
							>
								Save template
							</StyleTypography>
						</StyleButton>
					</Grid>
					<Grid
						xs={3}
						item
						marginLeft={'12px'}
					>
						<StyleButton
							onClick={() => setStep(1)}
							isWhiteButton={true}
						>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={14}
								color={'black'}
							>
								Back
							</StyleTypography>
						</StyleButton>
					</Grid>
				</Grid>
			</>
		);
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollJournal = ({
	me = () => { },
	user = {},
	user_loading = false,
	createPayrollTemplate = () => { },
	getPayrollTemplate = () => { },
	payroll_journal_template = [],
	regular_template = [],
	payroll_journal_loading = false,
	getPayScheduleRuns = () => { },
	pay_schedule_runs = [],
	pay_schedule_run_loading = false,
	downloadJournal = () => { },
}): JSX.Element => {

	const columns = [
		{
			name: "pay_schedule.name",
			label: "Schedule Name",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			},
		},
		{
			name: "tax_period",
			label: "Tax Period",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			}
		},
		{
			name: "pay_run_period",
			label: "Pay Run",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			}
		},
		{
			name: "id",
			label: " ",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleButton
						onClick={() => {
							downloadJournal({
								"journal_template": {
									'employer_pay_schedule_run_id': value,
								}
							})
						}}
					>
						Download
					</StyleButton>
				)
			}
		},
	];

	const initialState = {
		aggregatedItems: payroll_journal_template,
	}

	function reducer(state, action) {
		switch (action.type) {
			case 'toggleAggregateItem':
				return {
					...state,
					aggregatedItems: state.aggregatedItems.map(item => {
						if (item.id === action.payload.parentId) {
							return {
								...item,
								aggregate: item.aggregate.map(aggItem => {
									if (aggItem.id === action.payload.id) {
										return { ...aggItem, show: !aggItem.show };
									}
									return aggItem;
								}),
							};
						}
						return item;
					}),
				};
			case 'toggleAggregateItemNIC':
				return {
					...state,
					aggregatedItems: state.aggregatedItems.map(item => {
						if (item.id === action.payload.parentId) {
							return {
								...item,
								aggregate: item.aggregate.map(aggItem => {
									return { ...aggItem, show: !aggItem.show, accountCode: aggItem.show ? '' : aggItem.accountCode };
								}),
							};
						}
						return item;
					}),
				};
			case 'toggleParentItemNIC':
				return {
					...state,
					aggregatedItems: state.aggregatedItems.map(item =>
						item.id === action.payload.id ?
							{
								...item,
								show: !item.show,
								accountCode: item.show ? '' : item.accountCode
							}
							:
							item
					),
				};
			case 'updateAccountCode':
				return {
					...state,
					aggregatedItems: state.aggregatedItems.map(item => {
						if (item.id === action.payload.parentId) {
							return {
								...item,
								aggregate: item.aggregate.map(aggItem => {
									if (aggItem.id === action.payload.id) {
										return { ...aggItem, accountCode: action.payload.accountCode };
									}
									return aggItem;
								}),
							};
						} else if (item.id === action.payload.id) {  // For updating parent item account code
							return { ...item, accountCode: action.payload.accountCode };
						}
						return item;
					}),
				};
			case 'aggregatedItems':
				return {
					aggregatedItems: action.payload,
				};
			default:
				throw new Error();
		}
	}

	const [extraItems, dispatch] = useReducer(reducer, initialState);

	const [editTemplate, setEditTemplate] = useState(false);
	const [step, setStep] = useState(1);

	useEffect(() => {
		me();
		getPayrollTemplate();
		getPayScheduleRuns({
			"pay_schedule_runs": {
				page: 'all_payroll_history',
			}
		});
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [me, getPayrollTemplate, getPayScheduleRuns]);

	useEffect(() => {
		if (payroll_journal_template.length !== 0) {
			dispatch({
				type: 'aggregatedItems',
				payload: payroll_journal_template,
			});
		} else {
			dispatch({
				type: 'aggregatedItems',
				payload: regular_template,
			});
		}
	}, [payroll_journal_template, regular_template]);

	if (user_loading || payroll_journal_loading || pay_schedule_run_loading) {
		return <Loading />;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"reports-payroll-journal"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Reports"}
						activeSubpage={""}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					{
						editTemplate ? (

							<Grid
								container
								className={"title-container"}
								style={{ marginBottom: '20px' }}
							>
								{
									renderSteps(step, extraItems, dispatch, setStep, editTemplate, setEditTemplate, createPayrollTemplate)
								}
							</Grid>

						) : (
							<Grid
								container
								className={"title-container"}
							>
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									justifyContent={"space-between"}
									alignItems={"center"}
									marginBottom={'10px'}
								>
									<StyleTypography
										fontSize={40}
										fontSizeMedium={30}
										fontWeight={"bold"}
									>
										Payroll journals
									</StyleTypography>
									<StyleTypography
										fontSize={18}
										color={"#ABAEB7"}
									>
										Synchronise FreePayroll with your accounting software using payroll journal reports.
									</StyleTypography>
								</Grid>
								<Divider className={"divider"} />
								<Grid
									xl={6}
									lg={6}
									md={6}
									sm={6}
									xs={6}
									item
									container
									id={'edit-journal-box'}
								>
									<Grid
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										item
										paddingBottom={'12px'}
									>
										<StyleTypography
											fontSize={22}
											fontSizeMedium={18}
											color={"black"}
											fontWeight={"bold"}
										>
											Want to customise your journal?
										</StyleTypography>
									</Grid>
									<Grid
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										item
										paddingBottom={'12px'}
									>
										<StyleTypography
											fontSize={16}
											fontSizeMedium={12}
											color={"#AAAEB7"}
										>
											Customise your journal by selecting the payroll items you want to include,
											how you want to split across your organisation, and by defining your account codes.
										</StyleTypography>
									</Grid>
									<Grid>
										<StyleButton
											onClick={() => setEditTemplate(!editTemplate)}
										>
											<StyleTypography
												fontSize={14}
												fontSizeMedium={14}
												color={'#FFFFFF'}
											>
												{payroll_journal_template.length !== 0 ? "Edit template" : "Create template"}
											</StyleTypography>
										</StyleButton>
									</Grid>
								</Grid>
							</Grid>
						)
					}
					{
						payroll_journal_template.length !== 0 && (
							<Grid
								container
							>
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									className={"team-member-table-container"}
								>
									<StyleTable
										title={"Submitted Pay Runs"}
										columns={columns}
										data={pay_schedule_runs ?? []}
										options={{
											selectableRowsHeader: false,
											selectableRows: "none",
											responsive: "simple",
											enableNestedDataAccess: ".",
											selectToolbarPlacement: "none",
											searchAlwaysOpen: false,
											print: false,
											download: false,
											search: true,
											viewColumns: false,
											elevation: 0,
											filter: false,
											searchProps: {
												placeholder: "Search pay runs",
												classes: {
													input: "default-text-field"
												}
											},
										}}
										removeToolbar={true}
									/>
								</Grid>
							</Grid>
						)
					}
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		PayrollJournal,
		PayScheduleRun,
	} = state;

	return {
		...Profile,
		...PayrollJournal,
		...PayScheduleRun,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	createPayrollTemplate: (data, setEditTemplate, setStep) => dispatch(createPayrollTemplate(data, setEditTemplate, setStep)),
	getPayrollTemplate: () => dispatch(getPayrollTemplate()),
	downloadJournal: (data) => dispatch(downloadJournal(data)),
	getPayScheduleRuns: (data) => dispatch(getPayScheduleRuns(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollJournal)