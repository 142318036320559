import Grid from "@mui/material/Grid";
import StyleTable from "../../StyledComponents/StyleTable";
import React, {useState} from "react";
import StyleTypography from "../../StyledComponents/StyleTypography";
import {formatDateFromBackendWithTime} from "../../../utils/Helpers";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {connect} from "react-redux";
import {downloadPayslips, sendPayslip, viewPayslip} from "../../../actions/PayScheduleRun";
import Button from "@mui/material/Button";


const Payslips = ({
	employee,
	viewPayslip,
	sendPayslip,
	downloadPayslips,
}): JSX.Element => {

	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedPayslipId, setSelectedPayslipId] = useState(null);
	const [popOver, setPopOver] = useState(false);

	const handleMenuClose = () => {
		setAnchorEl(null);
		setPopOver(false);
		setSelectedPayslipId(null);
	};

	const columns = [
		{
			name: "employer_pay_schedule_run.period_end_date",
			label: "Pay Date",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{formatDateFromBackendWithTime(value)}
					</StyleTypography>
				),
			}
		},
		{
			name: "employer_pay_schedule_run.tax_period",
			label: "Tax Period",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			}
		},
		{
			name: "gross_income_for_tax",
			label: "Gross For Tax",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{
							parseFloat(value ?? 0).toLocaleString('en-GB', {
								style: 'currency',
								currency: 'GBP',
							})
						}
					</StyleTypography>
				),
			}
		},
		{
			name: "net_pay",
			label: "Net Pay",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{
							parseFloat(value ?? 0).toLocaleString('en-GB', {
								style: 'currency',
								currency: 'GBP',
							})
						}
					</StyleTypography>
				),
			}
		},
		{
			name: "id",
			label: "Actions",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) =>
					<Grid>
						<IconButton
							onClick={(event) => {
								setAnchorEl(event.currentTarget);
								setSelectedPayslipId(value);
								setPopOver(true);
							}}
						>
							<MoreHorizIcon/>
						</IconButton>
						<Menu
							className={"team-member-menu"}
							open={popOver}
							anchorEl={anchorEl}
							onClose={() => {
								handleMenuClose();
							}}
						>
							<MenuItem
								className={"team-member-menu-item"}
								onClick={() => {
									viewPayslip({
										"pay_schedule_runs": {
											id: selectedPayslipId,
										}
									});
									handleMenuClose();
								}}
							>
								<StyleTypography
									fontSize={15}
									fontSizeMedium={13}
									color={"#000"}
								>
									View payslip
								</StyleTypography>
							</MenuItem>
							<MenuItem
								className={"team-member-menu-item"}
								onClick={() => {
									sendPayslip({
										"pay_schedule_runs": {
											id: selectedPayslipId,
										}
									})
									handleMenuClose();
								}}
							>
								<StyleTypography
									fontSize={15}
									fontSizeMedium={13}
									color={"#000"}
								>
									Email payslip
								</StyleTypography>
							</MenuItem>
						</Menu>
					</Grid>
			}
		},
	];

	return (
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			container
			item
			justifyContent={"space-between"}
			id={'salary'}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={'first-title-grid'}
			>
				<StyleTable
					title={"Payslips"}
					columns={columns}
					data={employee.pay_runs ?? []}
					options={{
						selectableRowsHeader: false,
						selectableRows: "none",
						responsive: "simple",
						enableNestedDataAccess: ".",
						selectToolbarPlacement: "none",
						searchAlwaysOpen: false,
						print: false,
						download: false,
						search: true,
						viewColumns: false,
						elevation: 0,
						filter: false,
						searchProps: {
							placeholder: "Search payslips",
							classes: {
								input: "default-text-field"
							}
						},
					}}
					removeToolbar={false}
					removeFooter={true}
					addCustomButtons={true}
					customButtons={
						<>
							<Button
								id={'black-button'}
								sx={{
									marginLeft: '10px'
								}}
								onClick={() => {
									downloadPayslips({
										"pay_schedule_runs": {
											action: "employee_payruns",
											employee_safe_id: employee.safe_id,
										}
									});
								}}
							>
								<StyleTypography
									fontSize={16}
									fontSizeMedium={12}
									color={"#fff"}
								>
									Download all payslips
								</StyleTypography>
							</Button>
						</>
					}
				/>
			</Grid>

		</Grid>
	)
}

const mapStateToProps = (state) => {
	const { PayScheduleRun } = state;

	return {
		...PayScheduleRun,
	};
};

const mapDispatchToProps = (dispatch) => ({
	viewPayslip: (data) => dispatch(viewPayslip(data)),
	sendPayslip: (data) => dispatch(sendPayslip(data)),
	downloadPayslips: (data) => dispatch(downloadPayslips(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payslips);