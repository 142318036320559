import React from "react";
import {
	Typography,
	Divider,
	FreepayrollTextField,
	Pagination,
} from "@collegia-partners/ui-kit";
import Skeleton from "../Skeleton";

type InputHoursTableProps = {
	employeePayRuns: [];
	setEmployeePayRuns: (employeePayRuns: []) => void;
	payrollMode: "RUN_PAYROLL" | "VIEW_PAYROLL" | "PAYROLL_APPROVAL",
	isLoading: boolean;
	paginationParams: {
		page: number;
		rowsPerPage: number;
		lastPage: number;
	};
	setPaginationParams: (params: {page: number, rowsPerPage: number, lastPage: number}) => void;
}

export const InputHoursTable = ({
	employeePayRuns = [],
	isLoading,
	paginationParams,
	setPaginationParams,
	setEmployeePayRuns,
}: InputHoursTableProps): JSX.Element => {

	return (
		<div id={'TableContainer'}>
			<div className={"TableRowHeaderContainer"}>
				<div className={"TableRowHeader"}>
					<Typography
						variant="body"
						size="medium"
						color="primary"
						weight="bold"
					>
						Employee
					</Typography>
					<Typography
						variant="body"
						size="medium"
						color="primary"
						weight="bold"
					>
						Payroll ID
					</Typography>
					<Typography
						variant="body"
						size="medium"
						color="primary"
						weight="bold"
					>
						Hours Worked
					</Typography>
				</div>
				<Divider color="light-gray"/>
			</div>
			{
				isLoading &&
				<>
					{
						Array.from({ length: 5 }).map((_, index) => (
							<div className={"TableRowContainer"} key={index}>
								<div className={"TableRow"}>
									<Skeleton width={'90%'} height={'3vw'} />
									<Skeleton width={'90%'} height={'3vw'} />
									<Skeleton width={'90%'} height={'3vw'} />
									<Skeleton width={'100%'} height={'3vw'} />
								</div>
								<Divider color="light-gray" />
							</div>
						))
					}
				</>
			}
			{employeePayRuns.map((item) => (
				<div
					className={"TableRowContainer"}
					key={item.id}
				>
					<div className={"TableRow"}>
						<Typography
							variant="body"
							size="medium"
							color="black"
							weight="bold"
						>
							{item.employee.name}
						</Typography>
						<Typography
							variant="body"
							size="medium"
							color="black"
							weight="bold"
						>
							{item.employee.payroll_id}
						</Typography>
						<FreepayrollTextField
							key={item.id}
							name={'work_hours'}
							fieldType={'number'}
							value={item.work_hours || 0.00}
							onChange={(e) => {
								const newEmployeePayRuns = [...employeePayRuns];
								const employeeIndex = newEmployeePayRuns.findIndex((employee) => employee.id === item.id);
								newEmployeePayRuns[employeeIndex].work_hours = e.target.value;
								setEmployeePayRuns(newEmployeePayRuns);
							}}
							noGhostLabel
							noGhostHelperText
						/>
					</div>
					<Divider color="light-gray"/>
				</div>
			))}
			<Pagination paginationParams={paginationParams} setPaginationParams={setPaginationParams}  />
		</div>
	);
};
