import {resolveReduxState} from "../../utils/Helpers";
import {
	ADD_EMPLOYEE,
	ADD_PARENTAL_STATUTORY, CLEAR_EMPLOYEE,
	FETCH_EMPLOYEES, GET_PAYROLL_AND_EMAIL,
	RETRIEVE_CATEGORIES,
	UPDATE_EMPLOYEE
} from "../../actions/Employee/types";

const initial_state = {
	employee_loading: false,
	employees_loading: false,
	add_employee_loading: false,
	employees: [],
	paginationData: [],
	employee: {
		id: null,
	},
	ni_categories: [],
	ni_categories_loading: false,
	employees_email: [],
	employees_payroll: [],
}

const Employees = (state = initial_state, action = false) => {
	if (action) {
		switch(action.type) {

			case ADD_EMPLOYEE:
			case FETCH_EMPLOYEES:
			case UPDATE_EMPLOYEE:
			case RETRIEVE_CATEGORIES:
			case ADD_PARENTAL_STATUTORY:
			case CLEAR_EMPLOYEE:
			case GET_PAYROLL_AND_EMAIL:
				return resolveReduxState(state, action);
			default:
				return state;
		}
	}

	return state;
}

export default Employees;