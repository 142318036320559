import Grid from "@mui/material/Grid";
import {StyleAppBar} from "../../components/StyleAppBar";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Divider from "@mui/material/Divider";
import addEmployer from "../../images/svg/add-employer.svg"
import multiple from "../../images/svg/multiple.svg"
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {me} from "../../actions/Profile";
import {connect} from "react-redux";
import React, {useEffect} from "react";
import Loading from "../../components/Loading";
import {LeftMenu} from "../../components/LeftMenu";
import {getEmployees} from "../../actions/Employee";
import {completeOnboarding} from "../../actions/Employer";
import StyleButton from "../../components/StyledComponents/StyleButton";
import StyleTable from "../../components/StyledComponents/StyleTable";

const AddEmployeeMethod = ({
	user,
	me,
	user_loading,
	employees_loading,
	isOnboarding,
	getEmployees,
	employees,
	completeOnboarding,
}): JSX.Element => {

	const columns = [
		{
			name: "payroll_id",
			label: "Payroll ID",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={15}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value ?? "N/A"}
					</StyleTypography>
				)
			},
		},
		{
			name: "name",
			label: "Name",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={15}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value}
					</StyleTypography>
				)
			},
		},
		{
			name: "email",
			label: "Email",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={15}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value}
					</StyleTypography>
				)
			}
		},
		{
			name: "national_insurance_number",
			label: "NI Number",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={15}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value}
					</StyleTypography>
				)
			}
		},
	];

	const navigate = useNavigate();

	useEffect(() => {
		if (!user?.id) {
			me();
		}

		if (user?.id && isOnboarding) {
			getEmployees();
		}
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [me, user?.id, getEmployees, isOnboarding]);

	if (user_loading || employees_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"add-single-employee-page"}
		>
			<StyleAppBar
				showBackButton={true}
				backLink={isOnboarding ? "/onboarding/dashboard" : "/main/people/team-members"}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
				sx={{
					paddingLeft: '37px'
				}}
			>
				<Grid>
					<LeftMenu
						isOnboarding={isOnboarding}
						activePage={isOnboarding ? "Setup" : "People"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "35px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
						>
							<Grid>
								<StyleTypography
									fontSize={40}
									fontSizeMedium={27}
									fontWeight={"bold"}
								>
									How would you like to add your employees?
								</StyleTypography>
							</Grid>
							<Grid
								className={"sub-title-container"}
							>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={14}
									color={"#ABAEB7"}
								>
									Add one employee at a time use our form to create each employee’s
									profile one at a time. <br/> Add multiple employees at the
									same time use our CSV template to add all your employees in one go.
								</StyleTypography>
							</Grid>
							<Divider/>
						</Grid>
						<Grid
							container
							direction={"row"}
							id={"onboarding-container"}
						>
							<Grid
								className={"onboarding-box"}
								item
								container
								direction={"row"}
								alignItems={"flex-start"}
								alignContent={"space-between"}
								md={5}
								lg={4}
								xl={4}
								sm={12}
								xs={12}
							>
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
								>
									<img src={addEmployer} alt={"add one employer"} className={"img-1"}/>
								</Grid>
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									className={"title-onboarding-container"}
								>
									<StyleTypography
										fontSize={22}
										fontSizeMedium={15}
										color={"black"}
										fontWeight={"bold"}
									>
										Add one employee at a time
									</StyleTypography>
								</Grid>

								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
								>
									<StyleTypography
										fontSize={16}
										fontSizeMedium={12}
										color={"#ABAEB7"}
									>
										Use our form to create each employee’s <br/> profile one at a time.
									</StyleTypography>
								</Grid>

								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									sx={{
										alignSelf: "end",
									}}
								>
									<Button
										className={"default-blue-button"}
										onClick={() => {
											if (isOnboarding) {
												navigate("/onboarding/add-employee");
											} else {
												navigate("/main/people/add-employee");
											}
										}}
									>
										Add new
									</Button>
								</Grid>
							</Grid>

							<Grid
								className={"onboarding-box"}
								item
								container
								direction={"row"}
								md={5}
								lg={4}
								xl={4}
								sm={12}
								xs={12}
							>
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									container
									justifyContent={"space-between"}
								>
									<Grid>
										<img src={multiple} alt={"add multiple employers"} className={"img"}/>
									</Grid>
								</Grid>
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									className={"title-onboarding-container"}
								>
									<StyleTypography
										fontSize={22}
										fontSizeMedium={15}
										color={"black"}
										fontWeight={"bold"}
									>
										Add multiple employees <br/>
										at the same time
									</StyleTypography>
								</Grid>
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
								>
									<StyleTypography
										fontSize={16}
										fontSizeMedium={12}
										color={"#ABAEB7"}
									>
										Use this option to add multiple <br/> workers simultaneously.
									</StyleTypography>
								</Grid>
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									className={"button-container-2"}
								>
									<Button
										className={"default-blue-button"}
										onClick={() => {
											if (isOnboarding) {
												navigate("/onboarding/add-bulk-employees");
											} else {
												navigate("/main/people/add-bulk-employees");
											}
										}}
									>
										Get started
									</Button>
								</Grid>
							</Grid>
						</Grid>
						{
							isOnboarding && (
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									container
									item
									className={"table-container"}
								>
									{
										employees.length > 0 && (
											<Grid
												xl={12}
												lg={12}
												md={12}
												sm={12}
												xs={12}
												item
												container
											>
												<Grid
													xl={2}
													lg={4}
													md={4}
													sm={4}
													xs={4}
													item
												>
													<StyleButton
														disabled={employees.length === 0}
														onClick={() => completeOnboarding()}
													>
														Complete
													</StyleButton>
												</Grid>
											</Grid>
										)
									}
									<StyleTable
										columns={columns}
										data={employees ?? []}
										options={{
											searchProps: {
												placeholder: "Search employees",
											},
										}}
										pagination
										autoHeight
									/>
								</Grid>
							)
						}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Employer,
		Employees,
	} = state;

	return {
		...Profile,
		...Employer,
		...Employees,
	}
}

const mapDispatchToProps = dispatch => ({
	me:  () => dispatch(me()),
	getEmployees: () => dispatch(getEmployees()),
	completeOnboarding: () => dispatch(completeOnboarding()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployeeMethod)