import ReportsList from "./ReportsList";
import ReportsP60 from "./ReportsP60";
import ReportsP11 from "./ReportsP11";
import ReportsEps from "./ReportsEps";
import ReportsFps from "./ReportsFps";
import ReportsTaxesFilings from "./ReportsTaxesFilings";
import PayrollJournal from "./PayrollJournal";

const Reports = {
	ReportsList,
	ReportsP60,
	ReportsP11,
	ReportsEps,
	ReportsFps,
	ReportsTaxesFilings,
	PayrollJournal,
}
export default Reports;