import {Divider, Icon, Loading, Pagination, Typography} from "@collegia-partners/ui-kit";
import styles from "./styles.module.scss";
import React, {useEffect, useState} from "react";
import {clsx} from "clsx";

type HeaderType = {
	text: string;
	width?: string;
	color?: string;
	key?: string;
	customRender?: (row: any, rowIndex: number) => JSX.Element;
}

function TableHeader ({ headers, orderBy, setOrderBy, orderDir, setOrderDir }: { headers: HeaderType[] }) {
	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "1vw" }}>
			<div style={{display: "grid", gridTemplateColumns: headers.map(item => item.width || "1fr").join(" ") }}>
				{headers.map((item, index) => (
					<div key={index}>
						{
							item.hasOrderBy ? (
								<button
									className={styles.ColumnButton}
									onClick={() => {
										setOrderDir((prevDir) => {
											return prevDir === 'asc'
												? 'desc'
												: 'asc';
										});
										setOrderBy(item.key);
									}}
								>
									<Typography
										variant="body"
										size="medium"
										color={item.color || "black"}
										weight="bold"
									>
										{item.text}
									</Typography>

									{orderBy === item.key && (
										<Icon
											iconName="arrow-down"
											className={clsx(styles.Arrow, {
												[styles.Desc]:
												orderDir === 'asc',
											})}
										/>
									)}
								</button>
							) : (
								<Typography
									variant="body"
									size="medium"
									color={item.color || "black"}
									weight="bold"
								>
									{item.text}
								</Typography>
							)
						}
					</div>
				))}
			</div>
			<Divider color="light-gray"/>
		</div>
	)
}

function TableRows({headers, rows}: { headers: HeaderType[], rows: any[] }) {
	return (
		<div>
			{rows.map((row, rowIndex) => (
				<React.Fragment key={rowIndex}>
					{/* Row container to apply the grid layout to each row */}
					<div
						style={{
							display: "grid",
							gridTemplateColumns: headers.map((item) => item.width || "1fr").join(" "),
							alignItems: "center", // Vertical centering of each cell
							padding: "1.5vw 0", // Padding for each row
						}}
					>
						{headers.map((header, headerIndex) => (
							<div key={`${rowIndex}-${headerIndex}`} style={{overflow: "hidden"}}>
								{header.customRender ? (
									header.customRender(row, rowIndex)
								) : header.key ? (
									(() => {
										const keys = header.key.split('.');
										let value = row;
										keys.forEach(key => {
											value = value ? value[key] : undefined;
										});
										return value !== undefined ? (
											<Typography
												variant="body"
												size="medium"
												color={header.color || "black"}
												className={styles.Typography}
											>
												{value}
											</Typography>
										) : (
											<div></div>
										);
									})()
								) : (
									<div></div>
								)}
							</div>
						))}
					</div>
					<Divider color="light-gray" />
				</React.Fragment>
			))}
			{rows.length === 0 && (
				<div style={{ padding: "1.5vw 0" }}>
					<Typography
						variant="body"
						size="medium"
						color="gray"
						weight="bold"
					>
						No data available
					</Typography>
				</div>
			)}
		</div>
	);
}

export default function FPTable({
	headers,
	hookName,
	hookKey,
	hookParams = {},
	paginate = false,
}: { headers: HeaderType[], hookName: any, hookKey: string, hookParams?: any[], paginate?: boolean }) {

	const [paginationParams, setPaginationParams] = useState({
		page: 1,
		rowsPerPage: 10,
		lastPage: 1,
		from: 0,
		to: 0,
		total: 0,
	});

	const [orderBy, setOrderBy] = useState(null);
	const [orderDir, setOrderDir] = useState('desc');

	const { [hookKey]: data, isPending: fetching } = hookName({
		...hookParams,
		page: paginationParams.page,
		orderBy,
		orderDir
	});

	useEffect(() => {
		if (data) {
			setPaginationParams({
				page: data?.current_page,
				rowsPerPage: data?.per_page,
				lastPage: data?.last_page,
				from: data?.from,
				to: data?.to,
				total: data?.total,
			});
		}
	}, [data]);

	if (fetching) {
		return <Loading />;
	}

	return (
		<div>
			<TableHeader headers={headers} orderBy={orderBy} setOrderBy={setOrderBy} orderDir={orderDir} setOrderDir={setOrderDir} />
			<TableRows headers={headers} rows={data?.data}/>
			{
				paginate && data?.data.length > 0 && (
					<Pagination
						paginationParams={paginationParams}
						setPaginationParams={setPaginationParams}
					/>
				)
			}
		</div>
	);
}