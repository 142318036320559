import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import {deleteEmployee, getEmployees} from "../../actions/Employee";
import Loading from "../../components/Loading";
import IconButton from "@mui/material/IconButton";
import {EmployeeSummaryModal} from "../../components/Pages/TeamMember/EmployeeSummaryModal";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {Chip} from "@mui/material";
import StyleTable from "../../components/StyledComponents/StyleTable";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {ExportEmployeeDataModal} from "../../components/Pages/EmployeeRecord/ExportEmployeeDataModal";
import {debounceSearchRender} from "mui-datatables";

function getColor (status) {
	switch (status) {
		case "Active":
			return {
				backgroundColor: "#0360FD",
				color: "#FFFFFF"
			};
		case "Pending Information":
			return {
				backgroundColor: "#000000",
				color: "#FFFFFF"
			};
		default:
			return {
				backgroundColor: "#C4C4C4",
				color: "#FFFFFF"
			};
	}
}

function getPensionColor (status) {
	switch (status) {
		case "Active":
			return {
				backgroundColor: "#0360FD",
				color: "#FFFFFF"
			};
		case "Pending":
			return {
				backgroundColor: "#000000",
				color: "#FFFFFF"
			};
		default:
			return {
				backgroundColor: "#C4C4C4",
				color: "#FFFFFF"
			};
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const TeamMembers = ({
	me = () => {},
	getEmployees = () => {},
	employees = [],
	user = {},
	employees_loading = false,
	user_loading = false,
	paginationData = {},
	deleteEmployee = () => {}
}): JSX.Element => {

	const push = useNavigate();

	const [selectedEmployee, setSelectedEmployee] = useState({
		forename: '',
		surname: '',
		gender: '',
		birthdate: '',
		email: '',
		national_insurance_number: '',
		telephone: '',
		tax_code: '',
		is_cumulative: false,
		ni_category: '',
		payroll_id: '',
		join_date: '',
		starter_type: '',
		starter_declaration: null,
		setup_step_one: false,
		setup_step_two: false,
		setup_step_three: false,
		status: 'Active',
		address: {
			address_line_1: '',
			address_line_2: '',
			city: '',
			postal_code: '',
			country: '',
		},
		salary: {
			employment_type: '',
			annual_salary: '',
			monthly_salary: '',
			weekly_salary: '',
			hourly_salary: '',
			expected_work_hours_per_week: '',
		},
		pay_schedule: {
			id: '',
		},
		ytd_figure: {
			previous_employment_tax_deducted: '',
			previous_employment_taxable_pay: '',
			gross_for_tax_ytd: '',
			tax_deducted_ytd: '',
			student_loan_ytd: '',
			pg_loan_ytd: '',
			smp_ytd: '',
			spp_ytd: '',
			sap_ytd: '',
			shpp_ytd: '',
			spbp_ytd: '',
			ssp_ytd: '',
			employee_pension_ytd: '',
			bik_payrolled_amount_ytd: '',
		},
		active_ni_categories_ytd: [
			{
				national_insurance_category: '',
				gross_pay_for_nic_ytd: '',
				employee_nic_ytd: '',
				employer_nic_ytd: '',
				earnings_at_lel_ytd: '',
				earnings_to_pt_ytd: '',
				earnings_to_uel_ytd: '',
			}
		],

	}),
		[popOver, setPopOver] = useState(false),
	    [anchorEl, setAnchorEl] = useState(null),
		[selectedEmployeeId, setSelectedEmployeeId] = useState(null),
		[openEmployeeSummaryModal, setOpenEmployeeSummaryModal] = useState(false),
		[menuOpen, setMenuOpen] = useState(false),
		[exportEmployeeDataMenuOpen, setExportEmployeeDataMenuOpen] = useState(false);

	const [searchText, setSearchText] = useState(""),
			[perPage, setPerPage] = useState(10);

	const PageChange = (tablePagination) => {
		getEmployees(tablePagination);
	}

	const SearchChange = (tablePagination) => {
		getEmployees(tablePagination);
	}
	
	const navigate = useNavigate();
	
	const handleMenuClose = () => {
		setAnchorEl(null);
		setMenuOpen(false);
		setPopOver(false);
	}
	
	const columns = [
		{
			name: "payroll_id",
			label: "PAYROLL ID",
			options: {
				filter: false,
				sort: false,
				setCellProps: () => ({className: "hover"}),
				customBodyRender: (value, tableMeta) => (
					<StyleTypography
						fontSize={15}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value}
					</StyleTypography>
				)
			},
		},
		{
			name: "name",
			label: "Name",
			options: {
				filter: false,
				sort: false,
				setCellProps: () => ({className: "hover"}),
				customBodyRender: (value, tableMeta) => (
					<Grid
						onClick={() => {
							setSelectedEmployee(employees.find(item => item.payroll_id === tableMeta.rowData[0]));
							setOpenEmployeeSummaryModal(true);
						}}
					>
						<StyleTypography
							fontSize={15}
							fontSizeMedium={13}
							color={"#000000"}
							fontWeight={"bold"}
						>
							{value}
						</StyleTypography>
					</Grid>
				)
			},
		},
		{
			name: "status",
			label: "Payroll Status",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) =>
					<Chip label={value} className={"mui-chip"} sx={getColor(value)} />
			}
		},
		{
			name: "pension_assessment.ae_status",
			label: "Pension Status",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => {
					if (value === "Not Enrolled") {
						return <Chip label={value} className={"mui-chip"} sx={getPensionColor("Pending")}/>
					} else if (value === "Opted-out") {
						return <Chip label={value} className={"mui-chip"} sx={getPensionColor}/>
					} else if (value === "Left scheme") {
						return <Chip label={value} className={"mui-chip"} sx={getPensionColor}/>
					} else {
						return <Chip label={value} className={"mui-chip"} sx={getPensionColor('Active')}/>
					}
				}
			}
		},
		{
			name: "safe_id",
			label: "Actions",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) =>
					<Grid>
						<IconButton
							onClick={(event) => {
								setAnchorEl(event.currentTarget);
								setPopOver(true);
								setSelectedEmployeeId(value);
								setSelectedEmployee(employees.find(item => item.safe_id === value));
							}}
						>
							<MoreHorizIcon/>
						</IconButton>
						<Menu
							className={"team-member-menu"}
							open={popOver}
							anchorEl={anchorEl}
							onClose={() => {
								handleMenuClose();
							}}
						>
							<MenuItem
								className={"team-member-menu-item"}
								onClick={() => {
									handleMenuClose();
									push(`/main/people/finish-employee-setup/${selectedEmployeeId}`);
								}}
								disabled={selectedEmployee?.status !== "Pending Information"}
							>
								<StyleTypography
									fontSize={15}
									fontSizeMedium={13}
									color={"#000"}
								>
									Finish employee setup
								</StyleTypography>
							</MenuItem>
							<MenuItem
								className={"team-member-menu-item"}
								onClick={() => {
									handleMenuClose();
									push(`/main/people/record/${selectedEmployeeId}`);
								}}
								disabled={selectedEmployee?.status === "Pending Information"}
							>
								<StyleTypography
									fontSize={15}
									fontSizeMedium={13}
									color={"#000"}
								>
									Edit employee
								</StyleTypography>
							</MenuItem>
							<MenuItem
								className={"team-member-menu-item"}
								onClick={() => {
									handleMenuClose();
								}}
								disabled={selectedEmployee?.status !== "Pending Information"}
							>
								<StyleTypography
									fontSize={15}
									fontSizeMedium={13}
									color={"#000"}
									onClick={() => deleteEmployee(selectedEmployeeId)}
								>
									Delete employee
								</StyleTypography>
							</MenuItem>
						</Menu>
					</Grid>
			}
		},
	];

	useEffect(() => {
		me();
		getEmployees();
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [me, getEmployees]);

	if (user_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"team-member-page"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"People"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							className={"team-member-table-container"}
						>
							<StyleTable
								data={employees ?? []}
								columns={columns}
								title={"Team Members"}
								addCustomButtons={true}
								options={{
									textLabels: {
										body: {
											noMatch: employees_loading ?
												<Grid
													marginTop={30}
												>
													<Loading/>
												</Grid>
												:
												'Sorry, there is no matching data to display',
										},
									},
									searchText: searchText,
									onChangeRowsPerPage: (rowsPerPage) => {
										PageChange({
											page: 0,
											rowsPerPage: rowsPerPage,
											search: searchText,
										});
										setPerPage(rowsPerPage);
									},
									customSearchRender: debounceSearchRender(1000),
									onSearchChange: (_) => {
										SearchChange({
											page: 0,
											rowsPerPage: paginationData?.per_page,
											search: _,
										});
										setSearchText(_);
									},
									onChangePage: (page) => PageChange({
										page: page,
										rowsPerPage: perPage,
										search: searchText,
									}),
									count: paginationData?.total,
									page: (paginationData?.current_page ?? 1) - 1,
									rowsPerPage: perPage,
									rowsPerPageOptions: [10, 15],
								}}
								customButtons={
									<>
										<Menu
											className={"team-member-menu"}
											open={menuOpen}
											anchorEl={anchorEl}
											onClose={() => {
												handleMenuClose();
											}}
										>
											<MenuItem
												key={"add-bulk-employees"}
												className={"team-member-menu-item"}
												onClick={() => {
													handleMenuClose();
													navigate("/main/people/add-bulk-employees");
												}}
											>
												<StyleTypography
													fontSize={15}
													fontSizeMedium={13}
													color={"#000"}
												>
													Bulk add employees
												</StyleTypography>
											</MenuItem>
											<MenuItem
												key={"export-employee-data"}
												className={"team-member-menu-item"}
												onClick={() => {
													handleMenuClose();
													setExportEmployeeDataMenuOpen(!exportEmployeeDataMenuOpen);
												}}
											>
												<StyleTypography
													fontSize={15}
													fontSizeMedium={13}
													color={"#000"}
												>
													Export employee data
												</StyleTypography>
											</MenuItem>
										</Menu>
										<Button
											id={'white-button'}
											onClick={(e) => {
												!menuOpen ? setAnchorEl(e.currentTarget) : setAnchorEl(null);
												setMenuOpen(true);
											}}
										>
											<StyleTypography
												fontSize={16}
												fontSizeMedium={12}
												color={"#000"}
											>
												Import / Export
											</StyleTypography>
										</Button>
										<Button
											id={'black-button'}
											sx={{
												marginLeft: "10px",
											}}
											onClick={() => {
												push("/main/people/add-employee-method");
											}}
										>
											<StyleTypography
												fontSize={16}
												fontSizeMedium={12}
												color={"#fff"}
											>
												Add team members
											</StyleTypography>
										</Button>
									</>
								}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<ExportEmployeeDataModal
				open={exportEmployeeDataMenuOpen}
				setOpen={setExportEmployeeDataMenuOpen}
			/>
			<EmployeeSummaryModal
				open={openEmployeeSummaryModal}
				setOpen={setOpenEmployeeSummaryModal}
				setSelectedEmployee={setSelectedEmployee}
				selectedEmployee={selectedEmployee}
			/>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Employees,
	} = state;

	return {
		...Profile,
		...Employees,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	getEmployees: (tablePagination) => dispatch(getEmployees(tablePagination)),
	deleteEmployee: (id) => dispatch(deleteEmployee(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamMembers)