import Grid from "@mui/material/Grid";
import React, { useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { me } from "../../actions/Profile";
import { LeftMenu } from "../../components/LeftMenu";
import { StyleAppBar } from "../../components/StyleAppBar";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import {
	addParentalStatutory,
	bankDetails,
	findEmployee,
	getNiCategories,
	paymentAfterLeaving,
	updateEmployee,
	updateStudentLoan,
} from "../../actions/Employee";
import Loading from "../../components/Loading";
import { useParams } from "react-router-dom";
import { Chip, Tab, Tabs, Tooltip } from "@mui/material";
import { General } from "../../components/Pages/EmployeeRecord/General";
import { Pension } from "../../components/Pages/EmployeeRecord/Pension";
import { Salary } from "../../components/Pages/EmployeeRecord/Salary";
import { Taxes } from "../../components/Pages/EmployeeRecord/Taxes";
import { MaternityLeave } from "../../components/Pages/EmployeeRecord/MaternityLeave";
import { BankDetails } from "../../components/Pages/EmployeeRecord/BankDetails";
import Actions from "../../images/svg/ActionsVertical.svg";
import { TerminateEmployeeModal } from "../../components/Pages/EmployeeRecord/TerminateEmployeeModal";
import IconButton from "@mui/material/IconButton";
import SickLeave from "../../components/Pages/EmployeeRecord/SickLeave";
import { PaternityLeave } from "../../components/Pages/EmployeeRecord/PaternityLeave";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { downloadP45 } from "../../actions/Reports";
import { PaymentAfterLeavingModal } from "../../components/Pages/EmployeeRecord/PaymentAfterLeavingModal";
import Payslips from "../../components/Pages/EmployeeRecord/Payslips";
import { StudentLoan } from "../../components/Pages/EmployeeRecord/StudentLoan/StudentLoan";
import { findEmployer } from "../../actions/Employer";
import YearToDate from "../../components/Pages/EmployeeRecord/YearToDate";

function _renderTabs(
	tab,
	employeeRecord,
	setEmployeeRecord,
	employee,
	updateEmployee,
	user,
	ni_categories,
	addParentalStatutory,
	bankDetails,
	updateStudentLoan,
	employer
) {
	switch (tab) {
		case 0:
			return (
				<General
					employeeRecord={employeeRecord}
					setEmployeeRecord={setEmployeeRecord}
					employee={employee}
					updateEmployee={updateEmployee}
					ni_categories={ni_categories}
				/>
			);
		case 1:
			return (
				<Salary
					employeeRecord={employeeRecord}
					setEmployeeRecord={setEmployeeRecord}
					employee={employee}
					updateEmployee={updateEmployee}
				/>
			);
		case 2:
			return (
				<Taxes
					employeeRecord={employeeRecord}
					setEmployeeRecord={setEmployeeRecord}
					employee={employee}
					updateEmployee={updateEmployee}
					user={user}
				/>
			);
		case 3:
			return (
				<Pension
					employeeRecord={employeeRecord}
					setEmployeeRecord={setEmployeeRecord}
					employee={employee}
					updateEmployee={updateEmployee}
					employer={employer}
				/>
			);
		case 4:
			return <Payslips employee={employee} />;
		case 5:
			if (employee.gender === "F") {
				return (
					<MaternityLeave
						employeeRecord={employeeRecord}
						setEmployeeRecord={setEmployeeRecord}
						employee={employee}
						addParentalStatutory={addParentalStatutory}
					/>
				);
			} else {
				return (
					<PaternityLeave
						employeeRecord={employeeRecord}
						setEmployeeRecord={setEmployeeRecord}
						employee={employee}
						addParentalStatutory={addParentalStatutory}
					/>
				);
			}
		case 6:
			return (
				<YearToDate
					employeeRecord={employeeRecord}
					ni_categories={ni_categories}
					setEmployeeRecord={setEmployeeRecord}
					user={user}
				/>
			);
		case 7:
			return <SickLeave employee={employee} />;
		case 8:
			return (
				<BankDetails
					employeeRecord={employeeRecord}
					setEmployeeRecord={setEmployeeRecord}
					employee={employee}
					bankDetails={bankDetails}
				/>
			);

		case 9:
			return (
				<StudentLoan
					employee={employee}
					setEmployeeRecord={setEmployeeRecord}
					employeeRecord={employeeRecord}
					updateStudentLoan={updateStudentLoan}
				/>
			);
		default:
			return;
	}
}

function getColor(status) {
	switch (status) {
		case "Active":
			return {
				backgroundColor: "#0360FD",
				color: "#FFFFFF",
			};
		case "Pending Information":
			return {
				backgroundColor: "#000000",
				color: "#FFFFFF",
			};
		default:
			return {
				backgroundColor: "#C4C4C4",
				color: "#FFFFFF",
			};
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeeRecord = ({
	me = () => {},
	findEmployee = () => {},
	employee = {},
	user = {},
	employee_loading = false,
	user_loading = false,
	updateEmployee = () => {},
	ni_categories = [],
	getNiCategories = () => {},
	addParentalStatutory = () => {},
	downloadP45 = () => {},
	paymentAfterLeaving = () => {},
	bankDetails = () => {},
	updateStudentLoan = () => {},
	findEmployer = () => {},
	employer = {},
	employer_loading = false,
}): JSX.Element => {
	const employeeId = useParams().employeeId;
	const [tabIndex, setTabIndex] = useState(0);
	const [employeeRecord, setEmployeeRecord] = useReducer(
		(state, updates) => ({
			...state,
			...updates,
		}),
		{
			forename: "",
			surname: "",
			email: "",
			telephone: "",
			national_insurance_number: "",
			title: "",
			gender: "",
			birthdate: null,
			starter_type: "",
			starter_declaration: "",
			tax_code: "",
			is_cumulative: true,
			custom_id: "",
			hmrc_id: "",
			join_date: null,
			address: {
				address_line_1: "",
				address_line_2: "",
				city: "",
				postal_code: "",
				country: "",
			},
			salary: {
				employment_type: "",
				annual_salary: "",
				monthly_salary: "",
				hourly_salary: "",
				expected_work_hours_per_week: "",
			},
			active_director_detail: {
				cumulative_calculation: "",
				start_date: "",
				locked: false,
			},
		}
	);

	const [popOver, setPopOver] = useState(false),
		[anchorEl, setAnchorEl] = useState(null),
		[openTerminateEmployeeModal, setOpenTerminateEmployeeModal] =
			useState(false),
		[openPALModal, setOpenPALModal] = useState(false);

	useEffect(() => {
		if (!user.id) {
			me();
		}

		if (tabIndex === 0) {
			findEmployee(employeeId, [
				"address",
				"salary",
				"active_director_detail",
				"pay_schedule.active_pay_run",
				"working_pattern",
				"p45",
				"p60s.tax_year",
			]);
		} else if (tabIndex === 1) {
			findEmployee(employeeId, [
				"salary",
				"pay_schedule.active_pay_run",
				"working_pattern",
				"p45",
			]);
		} else if (tabIndex === 2) {
			findEmployee(employeeId, [
				"salary",
				"ytd_figure",
				"pay_schedule.active_pay_run",
				"working_pattern",
				"p45",
			]);
		} else if (tabIndex === 3) {
			findEmployee(employeeId, [
				"pension_setting",
				"pension_assessment",
				"pay_schedule.active_pay_run",
				"salary",
				"working_pattern",
				"p45",
			]);
		} else if (tabIndex === 4) {
			findEmployee(employeeId, [
				"salary",
				"pay_schedule.active_pay_run",
				"p45",
				"pay_runs.employer_pay_schedule_run",
			]);
		} else if (tabIndex === 5) {
			findEmployee(employeeId, [
				"parental_leave",
				"leaves",
				"salary",
				"pay_schedule.active_pay_run",
				"working_pattern",
				"p45",
			]);
		} else if (tabIndex === 6) {
			findEmployee(employeeId, [
				"ytd_figure",
				"active_ni_categories_ytd",
				"salary",
				"pay_schedule.active_pay_run",
				"working_pattern",
				"p45",
			]);
		} else if (tabIndex === 7) {
			findEmployee(employeeId, [
				"sick_leave.employee_leave",
				"working_pattern",
				"leaves",
				"salary",
				"pay_schedule.active_pay_run",
				"p45",
			]);
		} else if (tabIndex === 8) {
			findEmployee(employeeId, [
				"bank_detail",
				"salary",
				"pay_schedule.active_pay_run",
				"p45",
			]);
		} else if (tabIndex === 9) {
			findEmployee(employeeId, [
				"student_loans",
				"pay_schedule.active_pay_run",
				"p45",
			]);
		}

		if (ni_categories.length === 0) {
			getNiCategories();
		}

		document.documentElement.style.setProperty(
			"--scroll-height",
			`${document.body.scrollHeight}`
		);
	}, [
		me,
		user.id,
		tabIndex,
		findEmployee,
		employeeId,
		getNiCategories,
		ni_categories.length,
		findEmployer,
	]);

	useEffect(() => {
		setEmployeeRecord(employee);
	}, [employee]);

	useEffect(() => {
		findEmployer(["pension_scheme"]);
	}, [findEmployer]);

	if (employee_loading || user_loading || employer_loading) {
		return <Loading />;
	}

	return (
		<Grid container justifyContent={"center"} id={"employee-record-page"}>
			<StyleAppBar
				showBackButton={true}
				backLink={"/main/people/team-members"}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				direction={"row"}
				id={"main-content-container"}
			>
				<Grid overflow={"content"} zIndex={100}>
					<LeftMenu activePage={"People"} expanded={false} />
				</Grid>
				<Grid
					xl={12}
					lg={11}
					md={11}
					sm={11}
					xs={11}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						paddingLeft: "10.0521vw",
					}}
				>
					<Grid container>
						<Grid
							container
							alignItems={"center"}
							justifyContent={"space-between"}
							direction={"row"}
						>
							{/* Employee title, status and edit */}
							<Grid
								xs={12}
								item
								container
								alignItems={"center"}
								alignContent={"center"}
							>
								<Chip
									label={employee.status}
									className={"mui-chip"}
									sx={getColor(employee.status)}
								/>
								<Grid
									item
									sx={{
										marginLeft: "1.041vw",
										marginRight: "1.041vw",
									}}
								>
									<StyleTypography
										fontSize={35}
										fontSizeMedium={26}
										fontWeight={"bold"}
										color={"#000000"}
									>
										{`${employee.forename} ${employee.surname}`}
									</StyleTypography>
								</Grid>
								<IconButton
									onClick={(event) => {
										setAnchorEl(event.currentTarget);
										setPopOver(true);
									}}
								>
									<img
										src={Actions}
										alt={"actions"}
										id={"img-actions-button"}
									/>
								</IconButton>
							</Grid>
						</Grid>
						<Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
							<Tabs
								className={"tabs-employee-record"}
								value={tabIndex}
								variant={"scrollable"}
								scrollButtons={"auto"}
								onChange={(e, value) => setTabIndex(value)}
							>
								<Tab
									sx={{ borderBottom: "5px solid transparent" }}
									label={"General"}
									value={0}
								/>
								<Tab
									sx={{ borderBottom: "5px solid transparent" }}
									label={"Salary"}
									value={1}
								/>
								<Tab
									sx={{ borderBottom: "5px solid transparent" }}
									label={"Taxes"}
									value={2}
								/>
								<Tab
									sx={{ borderBottom: "5px solid transparent" }}
									label={"Pension"}
									value={3}
								/>
								<Tab
									sx={{ borderBottom: "5px solid transparent" }}
									label={"Payslips"}
									value={4}
								/>
								<Tab
									sx={{ borderBottom: "5px solid transparent" }}
									label={
										employee?.gender === "F"
											? "Maternity leave"
											: "Paternity leave"
									}
									value={5}
								/>
								<Tab
									sx={{ borderBottom: "5px solid transparent" }}
									label={"Year to dates"}
									value={6}
								/>
								<Tab
									sx={{ borderBottom: "5px solid transparent" }}
									label={"Sick leave"}
									value={7}
								/>
								<Tab
									sx={{ borderBottom: "5px solid transparent" }}
									label={"Bank Details"}
									value={8}
								/>
								<Tab
									sx={{ borderBottom: "5px solid transparent" }}
									label={"Student Loans"}
									value={9}
								/>
							</Tabs>
						</Grid>
						{_renderTabs(
							tabIndex,
							employeeRecord,
							setEmployeeRecord,
							employee,
							updateEmployee,
							user,
							ni_categories,
							addParentalStatutory,
							bankDetails,
							updateStudentLoan,
							employer
						)}
					</Grid>
					<Menu
						className={"team-member-menu"}
						open={popOver}
						anchorEl={anchorEl}
						onClose={() => {
							setPopOver(false);
						}}
					>
						{/*Download P45*/}
						<Tooltip
							title={
								!employee?.p45?.id &&
								"This option will be available once HMRC has validated your FPS."
							}
						>
							<span>
								<MenuItem
									className={"team-member-menu-item"}
									onClick={() => {
										setAnchorEl(null);
										setPopOver(false);
										downloadP45({
											p45: {
												id: employee?.p45?.id,
											},
										});
									}}
									disabled={!employee?.p45?.id}
								>
									<StyleTypography
										fontSize={15}
										fontSizeMedium={13}
										color={"#000"}
									>
										Download P45
									</StyleTypography>
								</MenuItem>
							</span>
						</Tooltip>

						{/*P60s*/}
						{employee?.p60s?.length > 0 &&
							employee?.p60s.map((p60, index) => (
								<MenuItem
									key={index}
									className={"team-member-menu-item"}
									onClick={() => {
										//TO DO: download P60
									}}
								>
									<StyleTypography
										fontSize={15}
										fontSizeMedium={13}
										color={"#000"}
									>
										Download P60 / {p60?.tax_year?.formated_tax_year}
									</StyleTypography>
								</MenuItem>
							))}

						<MenuItem
							className={"team-member-menu-item"}
							onClick={() => {
								setAnchorEl(null);
								setPopOver(false);
								setOpenPALModal(true);
							}}
							disabled={employee?.status !== "Terminated" || !employee?.active}
						>
							<StyleTypography fontSize={15} fontSizeMedium={13} color={"#000"}>
								Payment After Leaving
							</StyleTypography>
						</MenuItem>

						{employee?.status === "Active" && (
							<MenuItem
								className={"team-member-menu-item"}
								onClick={() => {
									setAnchorEl(null);
									setPopOver(false);
									setOpenTerminateEmployeeModal(true);
									findEmployer();
								}}
							>
								<StyleTypography
									fontSize={15}
									fontSizeMedium={13}
									color={"#000"}
								>
									Terminate employment
								</StyleTypography>
							</MenuItem>
						)}
						{employee?.status === "Pending Information" && (
							<MenuItem
								className={"team-member-menu-item"}
								onClick={() => {
									updateEmployee(
										employee.safe_id,
										{
											employees: {
												action: "delete_employee",
											},
										},
										true
									);
								}}
							>
								<StyleTypography
									fontSize={15}
									fontSizeMedium={13}
									color={"#000"}
								>
									Delete employee
								</StyleTypography>
							</MenuItem>
						)}
						{employee?.status === "Terminated" && (
							<MenuItem
								className={"team-member-menu-item"}
								onClick={() => {
									updateEmployee(
										employee.safe_id,
										{
											employees: {
												action: "reinstate_employee",
											},
										},
										true
									);
								}}
							>
								<StyleTypography
									fontSize={15}
									fontSizeMedium={13}
									color={"#000"}
								>
									Reinstate employee
								</StyleTypography>
							</MenuItem>
						)}
					</Menu>
				</Grid>
			</Grid>
			<TerminateEmployeeModal
				open={openTerminateEmployeeModal}
				setOpen={setOpenTerminateEmployeeModal}
				selectedEmployee={employee}
				employer={employer}
				updateEmployee={updateEmployee}
			/>
			<PaymentAfterLeavingModal
				open={openPALModal}
				setOpen={setOpenPALModal}
				paymentAfterLeaving={paymentAfterLeaving}
				employeeRecord={employeeRecord}
			/>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	const { Profile, Employees, PayItem, Reports, Employer } = state;

	return {
		...Profile,
		...Employees,
		...Reports,
		...PayItem,
		...Employer,
	};
};

const mapDispatchToProps = (dispatch) => ({
	me: () => dispatch(me()),
	findEmployee: (id, relations = []) => dispatch(findEmployee(id, relations)),
	updateEmployee: (id, data, redirect = false) =>
		dispatch(updateEmployee(id, data, redirect)),
	paymentAfterLeaving: (data) => dispatch(paymentAfterLeaving(data)),
	addParentalStatutory: (data) => dispatch(addParentalStatutory(data)),
	getNiCategories: () => dispatch(getNiCategories()),
	downloadP45: (data) => dispatch(downloadP45(data)),
	bankDetails: (data) => dispatch(bankDetails(data)),
	updateStudentLoan: (data) => dispatch(updateStudentLoan(data)),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeRecord);
