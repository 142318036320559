import React, {useState} from "react";
import {
	Typography,
	FreepayrollButton,
} from "@collegia-partners/ui-kit";

type RejectPayrollContentProps = {
	taxPeriod: string;
	payDate: string;
	taxYear: string;
	onRejectPayroll: () => void;
};

export default function RejectPayrollContent ({
	taxPeriod,
	payDate,
	taxYear,
	onRejectPayroll,
}: RejectPayrollContentProps) {

	const [characterCount, setCharacterCount] = useState(0);
	const [reason, setReason] = useState("");

	return (
		<div id={"RejectPayrollContainer"}>
			<Typography variant={"title"} color={"red"}> Reject Payroll </Typography>
			<ul className={"ListComponent"}>
				<li className={"ListItem"}>Tax Period: {taxPeriod}</li>
				<li className={"ListItem"}>Pay Date: {payDate}</li>
				<li className={"ListItem"}>Tax Year: {taxYear}</li>
			</ul>
			<Typography variant={"body"}>
				You have chosen to reject the payroll. Please provide a detailed reason
				for the rejection in the box below to help make the necessary adjustments.
				Ensure your feedback is clear and specific to avoid delays in processing.
			</Typography>
			<textarea
				className={"RejectPayrollTextarea"}
				placeholder={"Please provide a reason for the rejection"}
				maxLength={350}
				onChange={(e) => {
					setCharacterCount(e.target.value.length);
					setReason(e.target.value);
				}}
				value={reason}
			/>
			<Typography variant={"body"}>Character count: {characterCount}/350</Typography>
			<div className={"ApproveButtonsContainer"}>
				<FreepayrollButton variant={"outline"} size={"small"}> Cancel </FreepayrollButton>
				<FreepayrollButton variant={"primary"} size={"small"} onClick={() => onRejectPayroll('reject', reason)}> Reject </FreepayrollButton>
			</div>
		</div>
	);
};
