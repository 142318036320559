import Grid from "@mui/material/Grid";
import {StyleAppBar} from "../../components/StyleAppBar";
import React, {useEffect, useReducer, useState} from "react";
import {LeftMenu} from "../../components/LeftMenu";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import {invalidCode, me, setEditInfo, updateEmail} from "../../actions/Profile";
import {connect} from "react-redux";
import Loading from "../../components/Loading";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import closedEye from "../../images/svg/closed-eye.svg";
import openEye from "../../images/svg/open-eye.svg";
import {validateEmail} from "../../utils/Helpers";

const EditPasswordForm = ({
	user = {
	  id: null
	},
	user_loading = false,
	me = () => {},
	updateEmail = () => {},
	invalidCode = () => {},
	setEditInfo = () => {}
}): JSX.Element => {
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState("");
	const [emailInfo, setEmailInfo] = useReducer(
		(state, updates) => ({...state, ...updates}),
		{
			email: "",
			confirm_email: ""
		}
	);

	useEffect(() => {
		invalidCode();

		if (!user.id) {
			me();
			setEditInfo("email");
		}
	}, [user.id, me, invalidCode, setEditInfo]);

	if (user_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"onboarding-dashboard-page"}
		>
			<StyleAppBar
				backLink={"/main/settings/edit-info"}
				user={user}
			/>
			<Grid
				xl={11}
				lg={11}
				md={11}
				sm={11}
				xs={11}
				item
				container
				id={"main-content-container"}
			>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					<LeftMenu/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "79px",
					}}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
					>
						<Grid>
							<StyleTypography
								fontSize={40}
								fontSizeMedium={30}
								fontWeight={"bold"}
								component={"h1"}
							>
								Change email
							</StyleTypography>
						</Grid>
						<Grid
							className={"sub-title-container"}
						>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								color={"#ABAEB7"}
								component={"h2"}
							>
								Lorem Ipsum is simply dummy text of the printing and typesetting industry.
							</StyleTypography>
						</Grid>
					</Grid>

					<Grid
						container
						id={"edit-info-form-container"}
						direction={"row"}
						item
						xs={12}
						sm={12}
						md={12}
						lg={5}
						xl={5}
					>
						<Grid
							className={"edit-info-form-input"}
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
						>
							<InputLabel
								className={"default-input-label"}
							>
								New Email *
							</InputLabel>
							<OutlinedInput
								className={"default-text-field"}
								fullWidth
								required
								value={emailInfo.email}
								onChange={(e) => setEmailInfo({email: e.target.value})}
								error={
									!validateEmail(emailInfo.email) && emailInfo.email.length > 0
								}
							/>
							{
								emailInfo.email.length > 0 && !validateEmail(emailInfo.email) &&
								<small className={"red-text"}>
									Invalid e-mail
								</small>
							}
						</Grid>

						<Grid
							className={"edit-info-form-input"}
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
						>
							<InputLabel
								className={"default-input-label"}
							>
								Confirm New Email *
							</InputLabel>
							<OutlinedInput
								className={"default-text-field"}
								fullWidth
								required
								value={emailInfo.confirm_email}
								onChange={(e) => setEmailInfo({confirm_email: e.target.value})}
								error={
									emailInfo.email !== emailInfo.confirm_email &&
									emailInfo.email.length > 0 && emailInfo.confirm_email.length > 0
								}
							/>
							{
								emailInfo.email !== emailInfo.confirm_email &&
									emailInfo.email.length > 0 && emailInfo.confirm_email.length > 0 &&
								<small
									className={"red-text"}
								>
									Email do not match
								</small>
							}
						</Grid>

						<Grid
							className={"edit-info-form-input"}
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
						>
							<InputLabel
								className={"default-input-label"}
							>
								Current Password *
							</InputLabel>
							<OutlinedInput
								className={"default-text-field"}
								fullWidth
								required
								type={showPassword ? "text" : "password"}
								endAdornment={
									<img
										src={showPassword ? closedEye : openEye}
										alt={"show password"}
										onClick={() => setShowPassword(!showPassword)}
									/>
								}
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Grid>

						<Grid
							id={"edit-info-form-buttons-container"}
							justifyContent={"flex-start"}
							alignItems={"center"}
							container
							spacing={1}
						>
							<Grid
								item
								xs={4}
								sm={4}
								md={4}
								lg={4}
								xl={4}
							>
								<Button
									className={"default-black-button"}
									onClick={() => {
										updateEmail({
											"users": {
												...user,
												password,
												email_info: emailInfo
											}
										})
									}}
									disabled={
										emailInfo.email !== emailInfo.confirm_email
									}
								>
									<StyleTypography
										fontSize={14}
										fontSizeMedium={11}
										color={"#fff"}
										fontWeight={"bold"}
									>
										Change e-mail
									</StyleTypography>
								</Button>
							</Grid>

							<Grid
								item
								xs={4}
								sm={4}
								md={4}
								lg={4}
								xl={4}
							>
								<Button
									className={"default-white-button"}
									onClick={() => navigate("/main/settings/edit-info")}
								>
									<StyleTypography
										fontSize={14}
										fontSizeMedium={11}
										fontWeight={"bold"}
										color={"#000"}
									>
										Discard changes
									</StyleTypography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
	} = state;

	return {
		...Profile,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	updateEmail: (data) => dispatch(updateEmail(data)),
	invalidCode: () => dispatch(invalidCode()),
	setEditInfo: (data) => dispatch(setEditInfo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPasswordForm);