import Grid from "@mui/material/Grid";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import {StudentLoanCard} from "./StudentLoanCard";
import Button from "@mui/material/Button";

export const StudentLoan = ({
	employee,
	employeeRecord = {
		student_loans: []
	},
	setEmployeeRecord,
	updateStudentLoan,
}): JSX.Element => {

	return (
		<Grid
			xl={12}
			lg={12}
			md={11}
			sm={12}
			xs={12}
			container
			item
			justifyContent={"space-between"}
			id={"taxes"}
			paddingTop={"60px"}
		>
			<Grid
				id={"student-loans-cards-container"}
			>
				<Grid
					xl={7}
					lg={7}
					md={7}
					sm={12}
					xs={12}
					item
					className={'first-title-grid'}
				>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={30}
						fontWeight={"bold"}
						color={"#0160FD"}
						component={"h1"}
					>
						Student loans
					</StyleTypography>

					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#ABAEB7"}
					>
						Set up Student or Postgraduate Loan Repayment plan
					</StyleTypography>

					<Grid
						id={"student-loans-cards-container"}
					>
						{
							employeeRecord?.student_loans && employeeRecord?.student_loans.map((plan, index) => (
								<StudentLoanCard
									key={plan.plan_name.name}
									title={plan.plan_name.name}
									subtitle={plan.plan_name.description}
									active={plan.active ?? false}
									annual_threshold={plan?.threshold?.trigger_amount ?? 0}
									repay_percentage={plan?.threshold?.repayment_rate ?? 0}
									setEmployeeRecord={setEmployeeRecord}
									employeeRecord={employeeRecord}
									index={index}

								/>
							))
						}
					</Grid>

					<Grid
						id={"student-loans-buttons-container"}
					>
						<Button
							className={"default-black-button default-button-size"}
							onClick={() => {
								updateStudentLoan({
									'student_loan': {
										"employee_id": employee.safe_id,
										"student_loans": employeeRecord.student_loans
									}
								})
							}}
						>
							<StyleTypography
								fontSize={16}
								fontSizeMedium={11}
								fontWeight={"bold"}
								color={"#fff"}
							>
								Save
							</StyleTypography>
						</Button>

						<Button
							className={"default-white-button default-button-size"}
						>
							<StyleTypography
								fontSize={16}
								fontSizeMedium={11}
								fontWeight={"bold"}
								color={"#000"}
							>
								Cancel
							</StyleTypography>
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}