import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import StyleTable from "../../components/StyledComponents/StyleTable";
import Grid from "@mui/material/Grid";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import StyleButton from "../../components/StyledComponents/StyleButton";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const DownloadReportDialog = ({
	title = "",
	fpsDownloadArray = [],
	columns = {},
	open = false,
	setOpen = () => {},
	payRunId = "",
	downloadFPS = () => {},
	downloadEPS = () => {},
	payScheduleRun = {},
	resubmitFps = () => {},
	isEps = false,
	epsId = "",
	epsDownloadArray = [],
}): JSX.Element => {

	return (
		<>
			<Dialog
				onClose={() => {
					setOpen(false);
				}}
				open={open}
				fullWidth={true}
				maxWidth={"lg"}
			>
				<DialogContent>
					<StyleTable
						data={isEps ?  epsDownloadArray : fpsDownloadArray}
						columns={columns}
						title={title}
						options={{
							search: false,
							download: false,
							print: false,
							filter: false,
							viewColumns: false,
							toolbar: false,
						}}
						removeFooter={false}
						addCustomButtons={true}
						customButtons={
							isEps ? (
								<Grid
									container
									spacing={2}
									alignItems={"center"}
									justifyContent={"flex-end"}
								>
									<Grid
										item
									>
										<StyleButton
											fullWidth={false}
											onClick={() =>
												downloadEPS({
													eps_submissions: {
														eps_report_id: epsId,
													}
												})
											}
											disabled={epsDownloadArray.length === 0}
										>
											<StyleTypography
												fontSize={13}
												fontWeight={"bold"}
												fontSizeMedium={11}
												color={"white"}
											>
												Download all
											</StyleTypography>
										</StyleButton>
									</Grid>
								</Grid>

							) : (
								<Grid
									container
									spacing={2}
									alignItems={"center"}
									justifyContent={"flex-end"}
								>
									<Grid
										item
									>
										<StyleButton
											isRedButton={true}
											fullWidth={false}
											disabled={!payScheduleRun.resubmission_needed}
											onClick={() =>
												resubmitFps({
													pay_schedule_runs: {
														id: payRunId,
													}
												})
											}
										>
											<StyleTypography
												fontSize={13}
												fontWeight={"bold"}
												fontSizeMedium={11}
												color={"white"}
											>
												Resubmit FPS
											</StyleTypography>
										</StyleButton>
									</Grid>
									<Grid
										item
									>
										<StyleButton
											fullWidth={false}
											disabled={fpsDownloadArray.length === 0}
											onClick={() =>
												downloadFPS({
													pay_schedule_runs: {
														pay_run_id: payRunId,
													}
												})
											}
										>
											<StyleTypography
												fontSize={13}
												fontWeight={"bold"}
												fontSizeMedium={11}
												color={"white"}
											>
												Download all
											</StyleTypography>
										</StyleButton>
									</Grid>
								</Grid>
							)
						}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
}

export default DownloadReportDialog