
import {
	Typography,
	Grid,
	TextField,
	InputLabel,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions, InputAdornment, CircularProgress,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { useEffect, useRef, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import StyleButton from "../../../../StyledComponents/StyleButton";
import CheckIcon from '@mui/icons-material/Check';
import Request from "../../../../../utils/Request";
import { format, isDate } from "date-fns";
import DDLogo from "../../../../../images/svg/direct-debit-1.svg";

export const InnerStepOne = ({
	stepTwo,
	setStepTwo,
	basicSetup,
}) => {

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const disableButton = () => {
		return (
			stepTwo.pension_direct_debit_details.account_holder_name === '' ||
			stepTwo.pension_direct_debit_details.email === '' ||
			stepTwo.pension_direct_debit_details.sort_code === '' ||
			stepTwo.pension_direct_debit_details.account_number === '' ||
			stepTwo.pension_direct_debit_details.phone_info.phone === '' ||
			stepTwo.pension_direct_debit_details.postcode === '' ||
			stepTwo.pension_direct_debit_details.address_line_1 === '' ||
			stepTwo.pension_direct_debit_details.address_line_3 === '' ||
			stepTwo.pension_direct_debit_details.country === '' ||
			!checkValidAccount ||
			!onlySign ||
			!matchIsValidTel(stepTwo.pension_direct_debit_details.phone_info.phone) ||
			!emailRegex.test(stepTwo.pension_direct_debit_details.email)
		);
	};

	const [open, setOpen] = useState(false);

	const [checkValidAccount, setCheckValidAccount] = useState(false);
	const [loading, setLoading] = useState(false);
	const [iban, setIban] = useState(null);
	const [onlySign, setOnlySign] = useState(false);
	const [invalidEmailMessage, setInvalidEmailMessage] = useState("");

	let timeout = useRef(null);

	useEffect(() => {
		if (
			stepTwo.pension_direct_debit_details.account_number !== '' &&
			stepTwo.pension_direct_debit_details.sort_code !== ''
		) {
			setLoading(true);
			setIban(null);
			if (timeout) {
				clearTimeout(timeout.current);
			}
			timeout.current = setTimeout(() => {
				Request.post('/api/validate-iban', {
					"nuapay": {
						account_number: stepTwo.pension_direct_debit_details.account_number,
						sort_code: stepTwo.pension_direct_debit_details.sort_code,
					}
				}).then((response) => {
					setIban(response.data.iban);
					setLoading(false);
					setCheckValidAccount(true);
				}).catch(() => {
					setIban(null);
					setLoading(false);
					setCheckValidAccount(false);
				});
			}, 1000);
		}
	}, [stepTwo.pension_direct_debit_details.account_number, stepTwo.pension_direct_debit_details.sort_code]);

	return (
		<Grid
			direction={"column"}
			container
			id={"inner-step-one"}
		>
			<Dialog
				onClose={() => {
					setOpen(false);
				}}
				open={open}
				fullWidth={true}
				maxWidth={'md'}
				id={'direct-debit-guarantee-dialog'}
			>
				<DialogTitle>
					<Grid
						container
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<Grid>
							<Typography className={'dialog-title'}>
								We need to set up a Direct Debit to <br />
								collect pension contributions.
							</Typography>
						</Grid>
						<Grid>
							<img
								src={DDLogo}
								alt={'sign up with collegia'}
								className={'dd-logo'}
							/>
						</Grid>
					</Grid>
					<Typography className={'dialog-title-subtitle'}>
						Don’t worry, FreePayroll is free, we will only collect pension contributions.
					</Typography>
				</DialogTitle>
				<DialogContent>
					<ul>
						<li>
							We require your authorisation to collect the outstanding pension
							contributions for your workforce because of Automatic Enrolment.
							This is a UK legal requirement.
						</li>
						<li>
							When you sign the Direct Debit mandate, you understand you grant
							Collegia Partners Limited, which is regulated by the FCA FRN
							922214, the authority to collect those pension contributions from
							your corporate bank account.
						</li>
						<li>
							This Guarantee is offered by all banks and building societies that
							accept instructions to pay Direct Debits.
						</li>
						<li>
							If an error is made in the payment of your Direct Debit by Nuapay
							re Collegia Partners Trustees Limited T/A Collegia or your bank or
							building society, you are entitled to a full and immediate refund
							of the amount paid from your band or building society.
						</li>
						<li>
							If you receive a refund you are not entitled to, you must pay it
							back when Nuapay re Collegia Partners Trustees Limited T/A
							Collegia asks you to.
						</li>
						<li>
							You can cancel a Direct Debit at any time by simply contacting
							your bank or building society. Written confirmation may be
							required. Please also notify us.
						</li>
					</ul>
				</DialogContent>
				<DialogActions>
					<Grid>
						<StyleButton
							sx={{
								height: '3vw',
								minWidth: '18vw'
							}}
							isWhiteButton={true}
							fullWidth={false}
							onClick={() => {
								setOpen(false);
							}}
						>
							Cancel
						</StyleButton>
					</Grid>
					<Grid>
						<StyleButton
							sx={{
								height: '3vw',
								minWidth: '18vw'
							}}
							fullWidth={false}
							onClick={() => {
								basicSetup({
									employers: {
										'method': 'manual',
										step: 'automatic_enrolment',
										has_pension_scheme: stepTwo.has_pension_scheme,
										current_pension_provider:
											stepTwo.current_pension_provider === 'Other'
												? stepTwo.other_pension_provider
												: stepTwo.current_pension_provider,
										pension_selection: stepTwo.pension_selection,
										paid_version: stepTwo.paid_version,
										collection_day: stepTwo.pension_collection_date,
										staging_date: isDate(stepTwo.staging_date)
											? format(stepTwo.staging_date, 'yyyy-MM-dd')
											: '',
										name: stepTwo.pension_direct_debit_details.account_holder_name,
										email: stepTwo.pension_direct_debit_details.email,
										mobileNumber: stepTwo.pension_direct_debit_details.phone_info.phone,
										sort_code: stepTwo.pension_direct_debit_details.sort_code,
										account_number: stepTwo.pension_direct_debit_details.account_number,
										postCode: stepTwo.pension_direct_debit_details.postcode,
										line1: stepTwo.pension_direct_debit_details.address_line_1,
										line2: stepTwo.pension_direct_debit_details.address_line_2,
										town: stepTwo.pension_direct_debit_details.address_line_3,
										country: stepTwo.pension_direct_debit_details.country,
										iban: iban,
									},
								});
							}}
						>
							Sign Mandate
						</StyleButton>
					</Grid>
				</DialogActions>
			</Dialog>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={"main-title-container"}
			>
				<Typography
					className={"main-title"}
				>
					Automatic Enrolment
				</Typography>
				<Typography
					className={"main-subtitle"}
				>
					We need to set up a Direct Debit to collect pension contributions. Don’t worry, FreePayroll is free, we will only collect pension contributions.
				</Typography>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				columnSpacing={5}
				container
				item
				className={"body-sub-container-1 padding"}
			>
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={4}
					xs={4}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Account Holder Name*
					</InputLabel>
					<TextField
						id={"account-holder-name"}
						className={"default-text-field"}
						value={stepTwo.pension_direct_debit_details.account_holder_name}
						onChange={(e) => {
							setStepTwo({
								...stepTwo,
								pension_direct_debit_details: {
									...stepTwo.pension_direct_debit_details,
									account_holder_name: e.target.value,
								},
							});
						}}
						fullWidth
					/>
				</Grid>
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={4}
					xs={4}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Email*
					</InputLabel>
					<TextField
						id={"email"}
						className={"default-text-field"}
						value={stepTwo.pension_direct_debit_details.email}
						onChange={(e) => {
							setStepTwo({
								...stepTwo,
								pension_direct_debit_details: {
									...stepTwo.pension_direct_debit_details,
									email: e.target.value,
								},
							});
							const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
							if (!emailRegex.test(e.target.value)) {
								setInvalidEmailMessage("Please enter with a valid email");
							} else {
								setInvalidEmailMessage("");
							}

						}}
						helperText={invalidEmailMessage}
						fullWidth
					/>
				</Grid>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				columnSpacing={5}
				container
				item
				className={"body-sub-container-1"}
			>
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={4}
					xs={4}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Sort Code*
					</InputLabel>
					<TextField
						id={"sort-code"}
						className={"default-text-field"}
						value={
							stepTwo.pension_direct_debit_details.sort_code.replace(/-/g, '').slice(0, 6).replace(/(.{2})(?=.)/g, '$1-')
						}
						onChange={(e) => {
							setStepTwo({
								...stepTwo,
								pension_direct_debit_details: {
									...stepTwo.pension_direct_debit_details,
									sort_code: e.target.value,
								},
							});
						}}
						InputProps={{
							endAdornment: loading ? (
								<InputAdornment position={'end'}>
									<CircularProgress size={'18px'} />
								</InputAdornment>
							) : (
								checkValidAccount ? (
									<InputAdornment position={'end'}>
										<CheckIcon style={{ color: 'green' }} />
									</InputAdornment>
								) : (
									<InputAdornment position={'end'}>
										<CloseIcon style={{ color: 'red' }} />
									</InputAdornment>
								)
							)
						}}
						helperText={
							(
								stepTwo.pension_direct_debit_details.account_number.length === 0
								&& stepTwo.pension_direct_debit_details.sort_code.length === 9
							)
								?
								"Type the account number"
								:
								(
									!checkValidAccount &&
									stepTwo.pension_direct_debit_details.sort_code !== ''
								) &&
								"Invalid account number or sort code"
						}
						error={stepTwo.pension_direct_debit_details.sort_code !== '' && !checkValidAccount}
						fullWidth
					/>
				</Grid>
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={4}
					xs={4}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Account Number*
					</InputLabel>
					<TextField
						id={"account-number"}
						className={"default-text-field"}
						value={stepTwo.pension_direct_debit_details.account_number}
						onChange={(e) => {
							setStepTwo({
								...stepTwo,
								pension_direct_debit_details: {
									...stepTwo.pension_direct_debit_details,
									account_number: e.target.value,
								},
							});
						}}
						InputProps={{
							endAdornment: loading ? (
								<InputAdornment position={'end'}>
									<CircularProgress size={'18px'} />
								</InputAdornment>
							) : (
								checkValidAccount ? (
									<InputAdornment position={'end'}>
										<CheckIcon style={{ color: 'green' }} />
									</InputAdornment>
								) : (
									<InputAdornment position={'end'}>
										<CloseIcon style={{ color: 'red' }} />
									</InputAdornment>
								)
							)
						}}
						helperText={
							(
								!checkValidAccount &&
								stepTwo.pension_direct_debit_details.account_number !== ''
							) &&
							"Invalid account number or sort code"
						}
						error={stepTwo.pension_direct_debit_details.account_number !== '' && !checkValidAccount}
						fullWidth
					/>
				</Grid>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				columnSpacing={5}
				container
				item
				className={"body-sub-container-1"}
			>
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={4}
					xs={4}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Phone*
					</InputLabel>
					<MuiTelInput
						className={"custom-phone-input"}
						defaultCountry={"GB"}
						flagSize={"small"}
						forceCallingCode={true}
						fullWidth
						value={stepTwo.pension_direct_debit_details.phone_info.phone}
						onChange={(value, info) => {
							setStepTwo({
								...stepTwo,
								pension_direct_debit_details: {
									...stepTwo.pension_direct_debit_details,
									phone_info: {
										info: info,
										phone: value,
									},
								},
							});
						}}
						helperText={
							matchIsValidTel(stepTwo.pension_direct_debit_details.phone_info.phone) ?
								""
								:
								"Invalid phone number"
						}
					/>
				</Grid>
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={4}
					xs={4}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Postcode*
					</InputLabel>
					<TextField
						id={"email"}
						className={"default-text-field"}
						value={stepTwo.pension_direct_debit_details.postcode}
						onChange={(e) => {
							setStepTwo({
								...stepTwo,
								pension_direct_debit_details: {
									...stepTwo.pension_direct_debit_details,
									postcode: e.target.value,
								},
							});
						}}
						fullWidth
					/>
				</Grid>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				columnSpacing={5}
				container
				item
				className={"body-sub-container-1"}
			>
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={4}
					xs={4}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Address Line 1*
					</InputLabel>
					<TextField
						id={"address-line-1"}
						className={"default-text-field"}
						value={stepTwo.pension_direct_debit_details.address_line_1}
						onChange={(e) => {
							setStepTwo({
								...stepTwo,
								pension_direct_debit_details: {
									...stepTwo.pension_direct_debit_details,
									address_line_1: e.target.value,
								},
							});
						}}
						fullWidth
					/>
				</Grid>
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={4}
					xs={4}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Address Line 2 (Optional)
					</InputLabel>
					<TextField
						id={"address-line-2"}
						className={"default-text-field"}
						value={stepTwo.pension_direct_debit_details.address_line_2}
						onChange={(e) => {
							setStepTwo({
								...stepTwo,
								pension_direct_debit_details: {
									...stepTwo.pension_direct_debit_details,
									address_line_2: e.target.value,
								},
							});
						}}
						fullWidth
					/>
				</Grid>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				columnSpacing={5}
				container
				item
				className={"body-sub-container-1"}
			>
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={4}
					xs={4}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						City*
					</InputLabel>
					<TextField
						id={"address-line-3"}
						className={"default-text-field"}
						value={stepTwo.pension_direct_debit_details.address_line_3}
						onChange={(e) => {
							setStepTwo({
								...stepTwo,
								pension_direct_debit_details: {
									...stepTwo.pension_direct_debit_details,
									address_line_3: e.target.value,
								},
							});
						}}
						fullWidth
					/>
				</Grid>
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={4}
					xs={4}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Country*
					</InputLabel>
					<TextField
						id={"country"}
						className={"default-text-field"}
						value={stepTwo.pension_direct_debit_details.country}
						onChange={(e) => {
							setStepTwo({
								...stepTwo,
								pension_direct_debit_details: {
									...stepTwo.pension_direct_debit_details,
									country: e.target.value,
								},
							});
						}}
						fullWidth
					/>
				</Grid>
			</Grid>

			{/*Checkbox confirmation*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={"body-sub-container-2"}
			>
				<FormControlLabel
					control={
						<Checkbox
							icon={<CheckBoxOutlineBlankSharpIcon />}
							checkedIcon={<CheckBoxSharpIcon />}
							onChange={(e) => {
								setOnlySign(e.target.checked);
							}}
							checked={onlySign}
						/>
					}

					label={"Tick to confirm that you are the account owner and sole signatory."}
				/>
			</Grid>

			{/*Buttons*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				className={"button-grid"}
			>
				<Grid
					item
				>
					<StyleButton
						sx={{
							height: '3.5vw',
						}}
						isWhiteButton={true}
						onClick={() => {
							setStepTwo({
								...stepTwo,
								inner_step: 0,
								pension_direct_debit_details: {
									account_holder_name: '',
									account_number: '',
									sort_code: '',
									phone_info: {
										phone: '',
										info: '',
									},
									email: '',
									postcode: '',
									address_line_1: '',
									address_line_2: '',
									address_line_3: '',
									country: '',
								},

							});
							window.scrollTo(0, 0);
						}}
					>
						Cancel Mandate Signing
					</StyleButton>
				</Grid>
				<Grid
					item
					marginLeft={2}
				>
					<StyleButton
						sx={{
							height: '3.5vw',
						}}
						onClick={() => {
							setOpen(true);
						}}
						disabled={disableButton()}
					>
						Continue
					</StyleButton>
				</Grid>

			</Grid>
		</Grid>
	);
}