import Request from "../../utils/Request";
import { GET_TUTORIALS } from "./types";

export const getTutorials = () => (dispatch) => {
  Request.get("/api/tutorials")
    .then((response) => {
      dispatch({
        type: GET_TUTORIALS,
        tutorials: response.data.data,
      });
    })
    .catch((err) => console.error(err));
};
