import CircularProgress from "@mui/material/CircularProgress";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Loading = (props): JSX.Element => (
	<CircularProgress
		className={"logo-icon-loading"}
		id={props.absolute ? "absolute-loading" : "loading"}
	/>
);

export default Loading;