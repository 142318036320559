import Grid from "@mui/material/Grid";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	InputLabel,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import StyleTypography from "../../StyledComponents/StyleTypography";
import {managePayItem} from "../../../actions/PayScheduleRun";
import {connect} from "react-redux";
import {CurrencyInputCustom} from "../../StyledComponents/CurrencyInputCustom";
import StyleButton from "../../StyledComponents/StyleButton";
import {Decimal} from "decimal.js";

const SalaryPayItem = ({selectedPayItem, setSelectedPayItem}) => {
	return (
		<>
			{/*Hours or Units*/}
			<Grid
				item
				xl={6}
				lg={6}
				md={6}
				sm={6}
				xs={6}
			>
				<InputLabel
					className={"default-input-label"}
				>
					{
						selectedPayItem.salary_type === 'hourly' ? 'Hours' : 'Units'
					}
				</InputLabel>
				<TextField
					fullWidth
					className={"default-text-field"}
					onChange={(event) => {
						if (event.target.value === '') {
							setSelectedPayItem({
								...selectedPayItem,
								salary_units: event.target.value,
								item_amount: 0
							});
							return;
						}
						let amount = 0;
						if (selectedPayItem.salary_rate !== '') {
							amount = new Decimal(event.target.value).times(new Decimal(selectedPayItem.salary_rate)).toNumber();
						}
						setSelectedPayItem({
							...selectedPayItem,
							salary_units: event.target.value,
							item_amount: amount
						});
					}}
					value={selectedPayItem.salary_units}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: {prefix: ""},
						endAdornment: ""
					}}
				/>
			</Grid>

			{/*Salary rate*/}
			<Grid
				item
				xl={6}
				lg={6}
				md={6}
				sm={6}
				xs={6}
			>
				<InputLabel
					className={"default-input-label"}
				>
					Salary Rate*
				</InputLabel>
				<TextField
					disabled={selectedPayItem.pay_method === 'fixed'}
					fullWidth
					className={"default-text-field"}
					value={selectedPayItem.salary_rate}
					onChange={(event) => {
						if (event.target.value === '') {
							setSelectedPayItem({
								...selectedPayItem,
								salary_rate: event.target.value,
								item_amount: 0
							})
							return;
						}

						let amount = 0;
						if (selectedPayItem.salary_units !== '') {
							amount = new Decimal(selectedPayItem.salary_units).times(new Decimal(event.target.value)).toNumber();
						}

						setSelectedPayItem({
							...selectedPayItem,
							salary_rate: event.target.value,
							item_amount: amount
						});
					}}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: {prefix: ""},
						endAdornment: "£"
					}}
				/>
			</Grid>

			{/*Amount*/}
			<Grid
				item
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
			>
				<InputLabel
					className={"default-input-label"}
				>
					Amount*
				</InputLabel>
				<TextField
					disabled
					fullWidth
					className={"default-text-field"}
					value={selectedPayItem.item_amount}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: {prefix: ""},
						endAdornment: "£"
					}}
				/>
			</Grid>
		</>
	)
}

const OvertimePayItem = ({selectedPayItem, setSelectedPayItem}) => {

	return (
		<>
			{/*Units*/}
			<Grid
				item
				xl={6}
				lg={6}
				md={6}
				sm={6}
				xs={6}
			>
				<InputLabel
					className={"default-input-label"}
				>
					Units*
				</InputLabel>
				<TextField
					fullWidth
					className={"default-text-field"}
					onChange={(event) => {

						const amount = new Decimal(event.target.value).times(new Decimal(selectedPayItem.salary_rate)).toNumber();

						setSelectedPayItem({
							...selectedPayItem,
							salary_units: event.target.value,
							item_amount: amount
						});
					}}
					value={selectedPayItem.salary_units}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: {prefix: ""},
						endAdornment: ""
					}}
				/>
			</Grid>

			{/*Salary rate*/}
			<Grid
				item
				xl={6}
				lg={6}
				md={6}
				sm={6}
				xs={6}
			>
				<InputLabel
					className={"default-input-label"}
				>
					Overtime Rate ({selectedPayItem.salary_multiplier}x)
				</InputLabel>
				<TextField
					fullWidth
					className={"default-text-field"}
					value={selectedPayItem.salary_rate}
					onChange={(event) => {
						const amount = new Decimal(selectedPayItem.salary_units).times(new Decimal(event.target.value)).toNumber();

						setSelectedPayItem({
							...selectedPayItem,
							salary_rate: event.target.value,
							item_amount: amount
						});
					}}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: {prefix: ""},
						endAdornment: "£"
					}}
				/>
			</Grid>

			{/*Amount*/}
			<Grid
				item
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
			>
				<InputLabel
					className={"default-input-label"}
				>
					Amount*
				</InputLabel>
				<TextField
					disabled
					fullWidth
					className={"default-text-field"}
					value={selectedPayItem.item_amount}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: {prefix: ""},
						endAdornment: "£"
					}}
				/>
			</Grid>
		</>
	)
}

const OneOffPayItem = ({selectedPayItem, setSelectedPayItem}) => {
	//AMOUNT
	return (
		<Grid
			item
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
		>
			<InputLabel
				className={"default-input-label"}
			>
				Amount*
			</InputLabel>
			<TextField
				fullWidth
				className={"default-text-field"}
				onChange={(event) => {
					setSelectedPayItem({
						...selectedPayItem,
						item_amount: event.target.value
					});
				}}
				value={Math.abs(selectedPayItem.item_amount ?? 0)}
				InputProps={{
					inputComponent: CurrencyInputCustom,
					inputProps: {prefix: "", allowNegativeValue: false},
					endAdornment: "£"
				}}
			/>
		</Grid>
	)
}

const EditPayItemModal = ({
	open,
	setOpen,
	managePayItem,
	selectedPayItem,
	setSelectedPayItem,
	selectedEmployee,
}): JSX.Element => {

	return (
		<Dialog
			onClose={() => {
				setSelectedPayItem({});
				setOpen(false);
			}}
			open={open}
			fullWidth={true}
			maxWidth={'xs'}
		>
			<DialogTitle>
				<StyleTypography
					fontSize={35}
					fontSizeMedium={28}
					fontWeight={'bold'}
				>
					Edit Pay Item
				</StyleTypography>
			</DialogTitle>
			<DialogContent>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					{/*Pay Item name*/}
					<Grid
						item
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Pay Item*
						</InputLabel>
						<TextField
							fullWidth
							disabled
							className={"default-text-field"}
							value={selectedPayItem?.item_name}
						/>
					</Grid>

					{
						(selectedPayItem.is_salary && selectedPayItem.salary_type !== 'multiplier') && (
							<SalaryPayItem
								selectedPayItem={selectedPayItem}
								setSelectedPayItem={setSelectedPayItem}
							/>
						)

					}

					{
						(selectedPayItem.is_salary && selectedPayItem.salary_type === 'multiplier') && (
							<OvertimePayItem
								selectedPayItem={selectedPayItem}
								setSelectedPayItem={setSelectedPayItem}
							/>
						)
					}

					{
						(selectedPayItem.is_salary === false) && (
							<OneOffPayItem
								selectedPayItem={selectedPayItem}
								setSelectedPayItem={setSelectedPayItem}
							/>
						)
					}
				</Grid>

				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
					marginTop={2}
				>
					<Grid
						item
						container
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
						spacing={2}
					>
						<Grid
							item
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={6}
						>
							<StyleButton
								isRedButton={true}
								onClick={() => {
									managePayItem({
										"pay_schedule_runs": {
											id: selectedPayItem.id,
											employer_pay_item_id: selectedPayItem.employer_pay_item_id,
											item_type: selectedPayItem.item_type,
											item_amount: parseFloat(selectedPayItem.item_amount),
											salary_rate: selectedPayItem.salary_rate ? parseFloat(selectedPayItem.salary_rate) : null,
											salary_units: selectedPayItem.salary_units ? parseFloat(selectedPayItem.salary_units) : null,
											action: 'remove',
											employee_id: selectedEmployee.employee_id,
											employee_pay_run_id: selectedEmployee.id,
										}
									})
								}}
							>
								Remove
							</StyleButton>
						</Grid>
					</Grid>
					<Grid
						item
						container
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
						spacing={2}
					>
						<Grid
							item
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={6}
						>
							<StyleButton
								isWhiteButton={true}
								onClick={() => {
									setOpen(false);
									setSelectedPayItem({});
								}}
							>
								Cancel
							</StyleButton>
						</Grid>

						<Grid
							item
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={6}
						>
							<StyleButton
								onClick={() => {
									managePayItem({
										"pay_schedule_runs": {
											id: selectedPayItem.id,
											employer_pay_item_id: selectedPayItem.employer_pay_item_id,
											item_type: selectedPayItem.item_type,
											item_amount: parseFloat(selectedPayItem.item_amount),
											salary_rate: selectedPayItem.salary_rate ? parseFloat(selectedPayItem.salary_rate) : null,
											salary_units: selectedPayItem.salary_units ? parseFloat(selectedPayItem.salary_units) : null,
											action: 'update',
											employee_id: selectedEmployee.employee_id,
											employee_pay_run_id: selectedEmployee.id,
										}
									})
								}}
							>
								Save
							</StyleButton>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

const mapStateToProps = state => {
	const {
		PayScheduleRun,
		PayItem,
	} = state;

	return {
		...PayScheduleRun,
		...PayItem,
	}
}

const mapDispatchToProps = dispatch => ({
	managePayItem: (data) => dispatch(managePayItem(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPayItemModal)