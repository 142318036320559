import {
	DOWNLOAD_PAYMENT_BREAKDOWN,
	GET_PENSION_PAYMENTS,
} from "./types";
import Request from "../../utils/Request";
import {toast} from "react-toastify";
import * as XLSX from "xlsx";
import FileDownload from "js-file-download";

export const getPensionPayments = () => dispatch => {
	dispatch({
		type: GET_PENSION_PAYMENTS,
		pension_payments_loading: true
	});

	return new Request.get("api/employers/auto-enrolment/pension-payments")
		.then(response => {
			dispatch({
				type: GET_PENSION_PAYMENTS,
				pension_payments_loading: false,
				pension_payments: response.data.data
			});

		})
		.catch(e => {
			dispatch({
				type: GET_PENSION_PAYMENTS,
				pension_payments_loading: false,
			});
			toast.error(e.response.data.message);
			console.error(e);
		});
}

export const downloadBreakdown = (data) => dispatch => {
	dispatch({
		type: DOWNLOAD_PAYMENT_BREAKDOWN,
		pension_payments_loading: true
	});

	return new Request.post("api/employers/auto-enrolment/download-pension-payment-breakdown", data)
		.then(response => {

			const worksheet = XLSX.utils.json_to_sheet(response.data.data);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
			const excelFileContent = XLSX.write(workbook, { type: "buffer", bookType: "csv" });

			FileDownload(
				excelFileContent,
				`payment-breakdown.csv`
			);

			dispatch({
				type: DOWNLOAD_PAYMENT_BREAKDOWN,
				pension_payments_loading: false,
			});

		})
		.catch(e => {
			dispatch({
				type: DOWNLOAD_PAYMENT_BREAKDOWN,
				pension_payments_loading: false,
			});
			toast.error(e.response.data.message);
			console.error(e);
		});
}

export const downloadInvoice = (data) => dispatch => {
	dispatch({
		type: DOWNLOAD_PAYMENT_BREAKDOWN,
		pension_payments_loading: true
	});

	return new Request.post("api/employers/auto-enrolment/download-pension-payment-invoice", data,{responseType: "blob"})
		.then(response => {

			const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
			const link = document.createElement('a');
			link.href = url;
			link.target = '_blank'; // This will open the PDF in a new tab
			document.body.appendChild(link);
			link.click();

			// Clean up by removing the link and revoking the URL
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);

			dispatch({
				type: DOWNLOAD_PAYMENT_BREAKDOWN,
				pension_payments_loading: false,
			});

		})
		.catch(e => {
			dispatch({
				type: DOWNLOAD_PAYMENT_BREAKDOWN,
				pension_payments_loading: false,
			});
			toast.error(e.response.data.message);
			console.error(e);
		});
}