import {resolveReduxState} from "../../utils/Helpers";
import {
	CREATE_UPDATE_TEMPLATE,
	DOWNLOAD_JOURNAL, GET_JOURNAL_TEMPLATE
} from "../../actions/PayrollJournal/types";

const initial_state = {
	regular_template: [],
	payroll_journal_template: [],
	payroll_journal_loading: false,
}

const PayrollJournal = (state = initial_state, action = false) => {
	if (action) {
		switch(action.type) {

			case CREATE_UPDATE_TEMPLATE:
			case DOWNLOAD_JOURNAL:
			case GET_JOURNAL_TEMPLATE:
				return resolveReduxState(state, action);

			default:
				return state;
		}
	}

	return state;
}

export default PayrollJournal;