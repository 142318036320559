import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Loading from "../../../../components/Loading";
import {findEmployer, updateEmployer} from "../../../../actions/Employer";
import {Typography, FreepayrollButton, FreepayrollDropbox, Icon} from "@collegia-partners/ui-kit";
import {useNavigate} from "react-router-dom";
import PayScheduleDeleteDialog from "../../../../components/Pages/PaySchedule/PayScheduleDeleteDialog";
import {
	deletePaySchedule,
	updatePayScheduleStatus
} from "../../../../actions/PaySchedule";
import FPTable from "../../../../components/common/FPTable/FPTable";
import Layout from "../../../../components/common/Layout";
import styles from "./styles.module.scss";
import {usePaySchedules} from "../../../../hooks/pay-schedules";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PaySchedules = ({
	employer_loading = false,
	employer = {},
	findEmployer = () => {},
	deletePaySchedule = () => {},
	updatePayScheduleStatus = () => {},
}): JSX.Element => {

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false),
			[payScheduleSelected, setPayScheduleSelected] = useState({});

	const push = useNavigate();
	
	const columns = [
		{
			text: "Name",
			color: "black",
			key: "name",
		},
		{
			text: "Frequency",
			color: "black",
			key: "frequency_code.freq_name",
		},
		{
			text: "N of Employees",
			color: "black",
			key: "employees_count",
		},
		{
			text: "Status",
			color: "black",
			key: "active",
			customRender: (row, index) => (
				<Typography
					variant="body"
					size="medium"
					color={row.active ? "primary" : "red"}
					weight={"bold"}
				>
					{row.active ? "Active" : "Inactive"}
				</Typography>
			),
		},
		{
			text: "Actions",
			color: "black",
			customRender: (row, index) => (
				<FreepayrollDropbox
					target={
						<button
							onClick={() => setPayScheduleSelected(row)}
							style={{all: "unset", cursor: "pointer"}}
						>
							<Icon
								iconName="dots-horizontal"
								className={"EmployeeSummaryIcon"}
							/>
						</button>
					}
					key={index}
					className={"EmployeeSummaryActions"}
				>
					<button
						onClick={() => {
							push(`/main/company/edit-pay-schedule/${row.id}`);
						}}
						className={"EmployeeSummaryAction"}
					>
						<Icon iconName={"pencil"} width={"13.5vw"}/>
						Edit
					</button>
					<button
						onClick={() => {
							setOpenDeleteDialog(true);
						}}
						disabled={row.not_draft_pay_run !== null}
						className={"EmployeeSummaryAction"}
					>
						<Icon iconName={"delete"} width={"13.5vw"}/>
						Delete
					</button>
					<button
						onClick={() => {
							updatePayScheduleStatus(row.id, {
								'pay_schedules': {
									active: !row?.active,
								}
							}, push);
						}}
						className={"EmployeeSummaryAction"}
					>
						<div style={{
							width: '10px',
							height: '10px',
							borderRadius: '50%',
							backgroundColor: row?.active ? 'red' : 'green',
							cursor: 'pointer',
						}} />
						{row.active ? "Inactivate" : "Activate"}
					</button>
				</FreepayrollDropbox>
			),
		},
	];

	useEffect(() => {
		findEmployer([]);
	}, [findEmployer]);

	if (employer_loading) {
		return <Loading/>;
	}

	return (
		<Layout
			activePage={"Company"}
			activeSubpage={"Pay Schedules"}
			showBackButton={false}
		>
			<div style={{width: "100%", display: "flex", flexDirection: "column", gap: "2.57vw"}}>
				<div
					style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
					<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
						Pay Schedules
					</Typography>
					<div style={{ width: "fit-content" }}>
						<FreepayrollButton
							size={"medium"}
							variant={"primary"}
							className={styles.CreatePayScheduleButton}
							onClick={() => push("/main/company/add-pay-schedule")}
						>
							Create Pay Schedule
						</FreepayrollButton>
					</div>
				</div>
				<div>
					<FPTable
						headers={columns}
						hookName={usePaySchedules}
						hookKey={'paySchedules'}
						paginate={true}
					/>
				</div>
			</div>
			<PayScheduleDeleteDialog
				selectedPaySchedule={payScheduleSelected}
				setSelectedPaySchedule={setPayScheduleSelected}
				openDeleteDialog={openDeleteDialog}
				setOpenDeleteDialog={setOpenDeleteDialog}
				deletePaySchedule={deletePaySchedule}
				paySchedules={employer?.pay_schedules}
			/>
		</Layout>
	);
}




const mapStateToProps = state => {
	const {
		Employer,
		PaySchedule,
	} = state;

	return {
		...Employer,
		...PaySchedule,
	}
}

const mapDispatchToProps = dispatch => ({
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	updateEmployer: (id, data) => dispatch(updateEmployer(id, data)),
	deletePaySchedule: (id, data) => dispatch(deletePaySchedule(id, data)),
	updatePayScheduleStatus: (id, data) => dispatch(updatePayScheduleStatus(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaySchedules)