import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useReducer } from 'react';
import { createLead } from '../../../actions/SignUp';
import { connect } from 'react-redux';

const SignUpCard = ({
	step = 0,
	setStep = () => {},
	createLead = () => {},
}): JSX.Element => {
	const [state, setState] = useReducer(
		(state, updates) => ({ ...state, ...updates }),
		{
			employees: '',
			payroll: '',
		}
	);

	return (
		<Grid
			container
			alignItems={'center'}
			direction={'column'}
		>
			<Card id={'sign-up-card'}>
				<CardContent id={'sign-up-card-content'}>
					<Typography
						id={'how-many-employees'}
						className={'sign-up-header'}
					>
						How many employees do you have, including yourself?
					</Typography>
					<FormControl>
						<RadioGroup
							className={'sign-up-radio-group'}
							aria-labelledby={'how-many-employees'}
							name={'how-many-employees-group'}
							value={state.employees}
							onChange={(e) =>
								setState({
									employees: e.target.value,
								})
							}
						>
							<FormControlLabel
								value={'1-5'}
								control={<Radio />}
								label={'1-5 employees'}
							/>
							<FormControlLabel
								value={'6-24'}
								control={<Radio />}
								label={'6-24 employees'}
							/>
							<FormControlLabel
								value={'25-99'}
								control={<Radio />}
								label={'25-99 employees'}
							/>
							<FormControlLabel
								value={'100+'}
								control={<Radio />}
								label={'100+ employees'}
							/>
						</RadioGroup>
					</FormControl>

					<Typography
						id={'currently-run-payroll'}
						className={'sign-up-header'}
					>
						How do you currently run payroll?
					</Typography>
					<FormControl>
						<RadioGroup
							className={'sign-up-radio-group'}
							aria-labelledby={'currently-run-payroll'}
							name={'currently-run-group'}
							value={state.payroll}
							onChange={(e) =>
								setState({
									payroll: e.target.value,
								})
							}
						>
							<FormControlLabel
								value={'new'}
								control={<Radio />}
								label={'We’re new to running payroll'}
							/>
							<FormControlLabel
								value={'another'}
								control={<Radio />}
								label={'We use another online payroll software'}
							/>
							<FormControlLabel
								value={'manually'}
								control={<Radio />}
								label={'We do it ourselves manually'}
							/>
						</RadioGroup>
					</FormControl>

					<Button
						className={'default-black-button sign-up-button'}
						variant={'outlined'}
						fullWidth
						onClick={async () => {
							try {
								await createLead({
									leads: {
										lead_size: state.employees,
										current_payroll: state.payroll,
									},
								});
								setStep(step + 1);
							} catch (e) {
								setStep(step);
							}
						}}
						disabled={!state.payroll || !state.employees}
					>
						SUBMIT
					</Button>
				</CardContent>
			</Card>
			<Grid
				id={'already-using-freepayroll'}
				item
			>
				<Typography>
					Already using FreePayroll? <a href={'/login'}>Sign in here.</a>
				</Typography>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	const { SignUp } = state;

	return {
		...SignUp,
	};
};

const mapDispatchToProps = (dispatch) => ({
	createLead: (data = false) => dispatch(createLead(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpCard);
