import React from "react";
import {
	Typography,
	FreepayrollButton,
} from "@collegia-partners/ui-kit";

type ApprovePayrollContentProps = {
	taxPeriod: string;
	payDate: string;
	taxYear: string;
};

export default function ApprovePayrollContent ({
	taxPeriod,
	payDate,
	taxYear,
	onApprovePayroll,
}: ApprovePayrollContentProps) {
	return (
		<div id={"ApprovePayrollContainer"}>
			<Typography variant={"title"}> Approve Payroll </Typography>
			<ul className={"ListComponent"}>
				<li className={"ListItem"}>Tax Period: {taxPeriod}</li>
				<li className={"ListItem"}>Pay Date: {payDate}</li>
				<li className={"ListItem"}>Tax Year: {taxYear}</li>
			</ul>
			<Typography variant={"body"}>
				Once you approve payroll, we will send a notification to your<br/>
				payroll manager that the pay run has been approved.
			</Typography>
			<div className={"ApproveButtonsContainer"}>
				<FreepayrollButton variant={"outline"} size={"small"}> Cancel </FreepayrollButton>
				<FreepayrollButton variant={"primary"} size={"small"} onClick={onApprovePayroll}> Approve </FreepayrollButton>
			</div>
		</div>
	);
};
