import StyleTypography from "./StyledComponents/StyleTypography";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";


const RightMenuItem = ({
	icon = "",
	link = "",
	name = "",
	external = false,
}): JSX.Element => {
	const navigate = useNavigate();

	return (
		<Grid
			container
			alignItems={"center"}
			className={"right-menu-item"}
			item
			xs={12}
			sm={12}
			md={12}
			lg={12}
			xl={12}
			onClick={
				external ?
					() => window.open(link, "_bank", "noreferrer") :
					() => navigate(link)
			}
		>
			<StyleTypography
				fontSize={18}
				fontSizeMedium={16}
				color={"#BBBBC3"}
			>
				<img
					className={"right-menu-icon"}
					src={icon}
					alt={name}
				/>
				{name}
			</StyleTypography>
		</Grid>
	)
}


export default RightMenuItem;