import {InnerStepZero} from "./InnerSteps/InnerStepZero";
import InnerStepOne from "./InnerSteps/InnerStepOne";
import Grid from "@mui/material/Grid";

function _renderInnerStep (stepOne, setStepOne, basicSetup) {
	switch (stepOne.inner_step) {
		case 0:
			return (
				<InnerStepZero
					stepOne={stepOne}
					setStepOne={setStepOne}
				/>
			);
		case 1:
			return (
				<InnerStepOne
					stepOne={stepOne}
					setStepOne={setStepOne}
					basicSetup={basicSetup}
				/>
			);
		default:
			return;
	}
}

export const StepOne = ({
	stepOne,
	setStepOne,
	basicSetup = () => {},
}): JSX.Element => {

  return (
	  <Grid
		  item
		  xl={12}
		  lg={12}
		  md={12}
		  sm={12}
		  xs={12}
		  id={'step-one'}
	  >
		  {_renderInnerStep(stepOne, setStepOne, basicSetup)}
	  </Grid>

  )
}