import Grid from "@mui/material/Grid";
import StyleTypography from "../components/StyledComponents/StyleTypography";
import { isValid, parseISO } from "date-fns";

/**
 * @type {number[]}
 */
const maxValueMonth = [31, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

/**
 * @type {string[]}
 */
const formatOrder = ["yyyy", "yy", "mm", "dd", "HH", "MM", "SS"];

/**
 * @param dateFormat
 * @param minYear
 * @param maxYear
 * @returns {(function(*): (boolean|{value: *, indexesOfPipedChars: []}))|*}
 */
export const createAutoCorrectedDatePipe = (
	dateFormat = "dd/mm/yyyy",
	{ minYear = 1, maxYear = 9999 } = {}
) => {
	const dateFormatArray = dateFormat
		.split(/[^dmyHMS]+/)
		.sort((a, b) => formatOrder.indexOf(a) - formatOrder.indexOf(b));

	return function (conformedValue) {
		const indexesOfPipedChars = [],
			maxValue = {
				dd: 31,
				mm: 12,
				yy: 99,
				yyyy: maxYear,
				HH: 23,
				MM: 59,
				SS: 59,
			},
			minValue = { dd: 1, mm: 1, yy: 0, yyyy: minYear, HH: 0, MM: 0, SS: 0 },
			conformedValueArr = conformedValue.split("");

		// Check first digit
		dateFormatArray?.forEach((format) => {
			const position = dateFormat.indexOf(format);
			const maxFirstDigit = parseInt(maxValue[format].toString().charAt(0), 10);

			if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
				conformedValueArr[position + 1] = conformedValueArr[position];
				conformedValueArr[position] = 0;
				indexesOfPipedChars.push(position);
			}
		});

		// Check for invalid date
		let month = 0;
		const isInvalid = dateFormatArray.some((format) => {
			const position = dateFormat.indexOf(format);
			const length = format.length;
			const textValue = conformedValue
				.substring(position, position + length)
				.replace(/\D/g, "");
			const value = parseInt(textValue, 10);
			if (format === "mm") {
				month = value || 0;
			}
			const maxValueForFormat =
				format === "dd" ? maxValueMonth[month] : maxValue[format];
			if (format === "yyyy" && (minYear !== 1 || maxYear !== 9999)) {
				const scopedMaxValue = parseInt(
						maxValue[format].toString().substring(0, textValue.length),
						10
					),
					scopedMinValue = parseInt(
						minValue[format].toString().substring(0, textValue.length),
						10
					);
				return value < scopedMinValue || value > scopedMaxValue;
			}
			return (
				value > maxValueForFormat ||
				(textValue.length === length && value < minValue[format])
			);
		});

		if (isInvalid) {
			return false;
		}

		return {
			value: conformedValueArr.join(""),
			indexesOfPipedChars,
		};
	};
};

export const formatDateString = (dateString) => {
	const parts = dateString.split("-");
	return `${parts[2]}/${parts[1]}/${parts[0]}`;
};

/**
 *
 * @param password
 * @returns {{valid: boolean, number: boolean, character: boolean, length: boolean}}
 */
export const validatePassword = (password = "") => {
	// validate a password containing at least 8 characters
	// at least one letter and at least one number
	let valid = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
	let character = /[a-zA-Z]+/;
	let number = /\d+/;
	let length = /^.{8,}$/;
	return {
		valid: valid.test(password),
		character: character.test(password),
		number: number.test(password),
		length: length.test(password),
	};
};

/**
 *
 * @returns {{valid: boolean, number: boolean, character: boolean, length: boolean}}
 * @param payFrequency
 */
export const getPayFrequencyCode = (payFrequency) => {
	switch (payFrequency) {
		case "Weekly":
			return "W1";
		case "Fortnightly":
			return "W2";
		case "Four Weekly":
			return "W4";
		case "Monthly":
			return "M1";
		default:
			return false;
	}
};

/*
 * Usar nas datas q vem do backend yyyy-mm-dd
 */
export const formatDateFromBackend = (date) => {
	if (!date) return "";
	const parts = date.split("-");
	return `${parts[2]}/${parts[1]}/${parts[0]}`;
};

/*
 * Usar nas datas q vem do backend yyyy-mm-dd com horario
 */
export const formatDateFromBackendWithTime = (date) => {
	if (!date) return "";
	const parts = date.split("T");
	const dateParts = parts[0].split("-");
	return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
};

export const getYearFromDateBackend = (date) => {
	if (!date) {
		return "";
	}
	const parts = date.split("-");
	return `${parts[0][2]}${parts[0][3]}`;
};

/**
 * @param state
 * @param action
 * @returns {{valueOf(): boolean}|boolean}
 */
export function resolveReduxState(state = false, action = false) {
	if (action && state) {
		return {
			...state,
			...action,
		};
	}

	return state;
}

export function validateEmail(email) {
	/*eslint-disable */
	const re =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
	/*eslint-enable */
}

/**
 * @returns {string|boolean}
 */
export const retrieveAccessToken = (): string | boolean =>
	localStorage.getItem("fpEmployerToken") ?? false;


export function renderPensionStatusColor(status) {
	switch (status) {
		case "Not enrolled":
			return "#000000";
		case "Enrolled":
			return "#0360FD";
		case "Opted-out":
		case "Left scheme":
			return "#C4C4C4";
		default:
			return "#000";
	}
}

export const company_types = {
	"": "Select your company legal structure",
	"private-unlimited": "Private unlimited company",
	ltd: "Private limited company",
	plc: "Public limited company",
	"old-public-company": "Old public company",
	"private-limited-guarant-nsc-limited-exemption":
		"Private Limited Company by guarantee without share capital, use of 'Limited' exemption",
	"limited-partnership": "Limited partnership",
	"private-limited-guarant-nsc":
		"Private limited by guarantee without share capital",
	"converted-or-closed": "Converted / closed",
	"private-unlimited-nsc": "Private unlimited company without share capital",
	"private-limited-shares-section-30-exemption":
		"Private Limited Company, use of 'Limited' exemption",
	"protected-cell-company": "Protected cell company",
	"assurance-company": "Assurance company",
	"oversea-company": "Overseas company",
	"eeig-establishment":
		"European Economic Interest Grouping Establishment (EEIG)",
	"icvc-securities": "Investment company with variable capital",
	"icvc-warrant": "Investment company with variable capital",
	"icvc-umbrella": "Investment company with variable capital",
	"registered-society-non-jurisdictional": "Registered society",
	"industrial-and-provident-society": "Industrial and Provident society",
	"northern-ireland": "Northern Ireland company",
	"northern-ireland-other": "Credit union (Northern Ireland)",
	llp: "Limited liability partnership",
	"royal-charter": "Royal charter company",
	"investment-company-with-variable-capital":
		"Investment company with variable capital",
	"unregistered-company": "Unregistered company",
	other: "Other company type",
	"european-public-limited-liability-company-se":
		"European public limited liability company (SE)",
	"united-kingdom-societas": "United Kingdom Societas",
	"uk-establishment": "UK establishment company",
	"scottish-partnership": "Scottish qualifying partnership",
	"charitable-incorporated-organisation":
		"Charitable incorporated organisation",
	"scottish-charitable-incorporated-organisation":
		"Scottish charitable incorporated organisation",
	"further-education-or-sixth-form-college-corporation":
		"Further education or sixth form college corporation",
	eeig: "European Economic Interest Grouping (EEIG)",
	ukeig: "United Kingdom Economic Interest Grouping",
	"registered-overseas-entity": "Overseas entity",
};

export const renderGrids = (iconName, title, subtitle) => {
	return (
		<Grid container>
			<Grid
				item
				container
				alignItems={"flex-start"}
				justifyContent={"flex-start"}
				xs={1}
			>
				<img alt={"logo"} src={iconName} className={"icon-img"} />
			</Grid>
			<Grid item xs={true}>
				<Grid>
					<StyleTypography
						fontSize={22}
						fontSizeMedium={16}
						color={"black"}
						fontWeight={"bold"}
					>
						{title}
					</StyleTypography>
				</Grid>
				<Grid>
					<StyleTypography fontSize={18} fontSizeMedium={13} color={"#AAAEB7"}>
						{subtitle}
					</StyleTypography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export const generateRandomString = (length) => {
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result = "";
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		result += characters.charAt(randomIndex);
	}
	return result;
};

/*
 * Usar nos componentes de datepicker
 */
export const parseDate = (date) => {
	if (!date) return null;

	// Check if the date is already a Date object
	if (date instanceof Date) return date;

	// Try to parse the date string
	const parsedDate = parseISO(date);

	// If the parsed date is valid, return it
	if (isValid(parsedDate)) return parsedDate;

	// Otherwise, return null (or handle the invalid date case)
	return null;
};

export const validDataToSchema = (data, schema) => {
	const validation = schema.safeParse(data);
	const issues = validation.error?.issues;
	if (!issues || !issues.length) return { valid: true };

	const error = {};

	for (let j = 0; j < issues.length; j++) {
		const issue = issues[j];
		error[issue.path[0]] = { message: issue.message };
	}

	return { valid: false, error };
};

export const formatMoney = (amount) => {
	const formatter = new Intl.NumberFormat("en-GB", {
		style: "currency",
		currency: "GBP",
	});
	return formatter.format(amount);
};

export function getPayDayOption(payFrequency = "") {
	switch (payFrequency) {
		case "W1":
		case "W2":
		case "W4":
			return [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
			];
		case "M1":
			return [
				"1",
				"2",
				"3",
				"4",
				"5",
				"6",
				"7",
				"8",
				"9",
				"10",
				"11",
				"12",
				"13",
				"14",
				"15",
				"16",
				"17",
				"18",
				"19",
				"20",
				"21",
				"22",
				"23",
				"24",
				"25",
				"26",
				"27",
				"28",
				"29",
				"30",
				"Last Day of Month",
			];
		default:
			return [];
	}
}

export const resolveTaxPeriodAndPeriodDates = (
	frequencyCode,
	periodEndDate,
	lastDayOfMonth = false
) => {
	const moment = require("moment");
	let finalTaxYearSubmission = false;
	periodEndDate = moment(periodEndDate);
	let taxYearStartDateYear;
	if (moment(`${periodEndDate.year()}-04-06`).isSameOrBefore(periodEndDate)) {
		taxYearStartDateYear = periodEndDate.year();
	} else {
		taxYearStartDateYear = periodEndDate.year() - 1;
	}
	let taxPeriod, periodStartDate;
	const taxYearStartDate = moment(`${taxYearStartDateYear}-04-06`);

	if (frequencyCode === "M1") {
		taxPeriod = periodEndDate.diff(taxYearStartDate, "months", false) + 1;
		periodStartDate = periodEndDate.clone().subtract(1, "month").add(1, "day");
		if (lastDayOfMonth) {
			periodStartDate = periodEndDate.clone().startOf("month");
		}

		if (taxPeriod === 12) {
			finalTaxYearSubmission = true;
		}
	} else if (frequencyCode === "W1") {
		taxPeriod = periodEndDate.diff(taxYearStartDate, "weeks", false) + 1;
		if (taxPeriod === 53) {
			periodStartDate = taxYearStartDate
				.clone()
				.add(52, "weeks")
				.format("YYYY-MM-DD");
			finalTaxYearSubmission = true;
		} else {
			periodStartDate = periodEndDate
				.clone()
				.subtract(1, "week")
				.add(1, "day")
				.format("YYYY-MM-DD");
		}
	} else if (frequencyCode === "W2") {
		const weeks = periodEndDate.diff(taxYearStartDate, "weeks", false) + 1;
		taxPeriod = Math.ceil(weeks / 2) * 2;

		if (taxPeriod === 54) {
			finalTaxYearSubmission = true;
			periodStartDate = taxYearStartDate
				.clone()
				.add(52, "weeks")
				.format("YYYY-MM-DD");
		} else {
			periodStartDate = periodEndDate
				.clone()
				.subtract(2, "weeks")
				.add(1, "day")
				.format("YYYY-MM-DD");
		}
	} else {
		// W4
		const weeks = periodEndDate.diff(taxYearStartDate, "weeks", false) + 1;
		taxPeriod = Math.ceil(weeks / 4) * 4;

		if (taxPeriod === 56) {
			finalTaxYearSubmission = true;
			periodStartDate = taxYearStartDate
				.clone()
				.add(52, "weeks")
				.format("YYYY-MM-DD");
		} else {
			periodStartDate = periodEndDate
				.clone()
				.subtract(4, "weeks")
				.add(1, "day")
				.format("YYYY-MM-DD");
		}
	}

	return {
		tax_period: taxPeriod,
		period_start_date: moment(periodStartDate).format("DD/MM/YYYY"),
		period_end_date: periodEndDate.format("DD/MM/YYYY"),
		tax_year_start_date: taxYearStartDate.format("DD/MM/YYYY"),
		final_tax_year_submission: finalTaxYearSubmission,
	};
};
