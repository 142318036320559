import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import copy from '../../../images/svg/copy.svg';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { searchCompanies, verifyCompany } from '../../../actions/SignUp';
import { connect, useDispatch } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { SEARCH_COMPANIES } from '../../../actions/SignUp/types';
import StyleTypography from '../../StyledComponents/StyleTypography';

/**
 * @param step
 * @param setStep
 * @param setSignupType
 * @param companies
 * @param searchCompanies
 * @param verifyCompany
 * @param setCompany
 * @param company
 * @param signup_loading
 * @param can_proceed
 * @returns {JSX.Element}
 * @constructor
 */
const FindYourCompany = ({
	step = 0,
	setStep = () => {},
	setSignupType = () => {},
	companies = [],
	searchCompanies = () => {},
	verifyCompany = () => {},
	setCompany = () => {},
	company = {},
	signup_loading = false,
	can_proceed = false,
}): JSX.Element => {
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();

	return (
		<Grid
			id={'company-details-content'}
			container
			justifyContent={'flex-start'}
			alignItems={'flex-start'}
			direction={'column'}
		>
			<Typography className={'typography-black-header'}>
				Now, find your company
			</Typography>

			<Typography className={'typography-black-subheader'}>
				TYPE YOUR COMPANY NAME OR ENTER MANUALLY
			</Typography>

			<FormControl fullWidth>
				<Autocomplete
					className={'default-select'}
					options={companies ?? []}
					getOptionLabel={(option) =>
						option &&
						(typeof option === 'object'
							? `${option.company_number} - ${option.company_name}`
							: option)
					}
					onChange={(e, newValue = { company_number: '' }) => {
						setCompany(newValue);
						if (newValue) {
							verifyCompany({
								employers: {
									company_number: newValue.company_number,
								},
							});
						}
					}}
					loading={loading || signup_loading}
					loadingText={'Searching...'}
					noOptionsText={'Company not found.'}
					renderInput={(params) => (
						<TextField
							{...params}
							placeholder={'Search Company'}
							onChange={(e) => {
								if (e.target.value === '') {
									return;
								}
								if (searchTimeout) {
									clearTimeout(searchTimeout);
								}
								setLoading(true);
								setSearchTimeout(
									setTimeout(() => {
										searchCompanies({
											employers: {
												q: e.target.value,
												items_per_page: 0,
											},
										});
										setLoading(false);
									}, 2000)
								);
							}}
						/>
					)}
				></Autocomplete>
			</FormControl>

			{!can_proceed && company !== null && !signup_loading && (
				<StyleTypography
					fontSize={16}
					fontSizeMedium={10}
					color={'red'}
				>
					There is already an account registered for this company.
				</StyleTypography>
			)}

			<Typography
				className={'enter-details-manually'}
				onClick={() => {
					setStep(step + 2);
					setSignupType('manual');
				}}
			>
				<img
					src={copy}
					alt={'enter details manually'}
				/>
				Enter details manually
			</Typography>

			<Button
				className={'default-black-button'}
				id={'company-details-continue-button'}
				onClick={() => {
					setStep(step + 1);
					dispatch({
						type: SEARCH_COMPANIES,
						companies: [],
					});
				}}
				disabled={!company || !can_proceed || signup_loading}
			>
				Continue
			</Button>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	const { SignUp } = state;

	return {
		...SignUp,
	};
};

const mapDispatchToProps = (dispatch) => ({
	searchCompanies: (data) => dispatch(searchCompanies(data)),
	verifyCompany: (data) => dispatch(verifyCompany(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FindYourCompany);
