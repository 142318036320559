import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import Loading from "../../components/Loading";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Divider from "@mui/material/Divider";
import WarningIcon from "../../images/svg/ReportsIcon/p60-warning.svg";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import DownloadIcon from "../../images/svg/ReportsIcon/download-icon.svg";
import {getFpsReport, downloadFPS, resubmitFps} from "../../actions/Reports";
import DownloadReportDialog from "./DownloadReportDialog";
import {format, parseISO} from "date-fns";
import IconButton from "@mui/material/IconButton";
import {useLocation} from "react-router-dom";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsFps = ({
	me = () => {},
	user = {},
	user_loading = false,
	report_loading = false,
	fps_report = [],
	getFpsReport = () => {},
	downloadFPS = () => {},
	resubmitFps = () => {},
}): JSX.Element => {

	const [fpsDownloadArray, setFpsDownloadArray] = useState([]),
		[payScheduleRun, setPayScheduleRun] = useState([]),
		[title, setTitle] = useState(""),
		[open, setOpen] = useState(false),
		[payRunId, setPayRunId] = useState(0);

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const employerPayScheduleRunId = searchParams.get('employerPayScheduleId');
	const payScheduleId = searchParams.get('payScheduleId');

	const columns = [
		{
			name: "created_at",
			label: "Submitted Date",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value) => {
					return (
						<StyleTypography
							color={"#000000"}
							fontSize={18}
							fontSizeMedium={13}
							fontWeight={"bold"}
						>
							{format(parseISO(value), "dd/MM/yyyy hh:mm:ss")}
						</StyleTypography>
					)
				}

			},
		},
		{
			name: "is_error",
			label: "",
			options: {
				filter: false,
				display: false,
				sort: false,
				customBodyRender: () => ""
			}
		},
		{
			name: "is_success",
			label: "",
			options: {
				filter: false,
				display: false,
				sort: false,
				customBodyRender: () => ""
			}
		},
		{
			name: "",
			label: "Status",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowData[1] === false && tableMeta.rowData[2] === true) {
						return (
							<StyleTypography
								color={"#0360FD"}
								fontSize={18}
								fontSizeMedium={13}
								fontWeight={"bold"}
							>
								Success
							</StyleTypography>
						);
					} else if (tableMeta.rowData[1] === true && tableMeta.rowData[2] === false) {
						return (
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								fontWeight={"bold"}
								color={"red"}
							>
								Error
							</StyleTypography>
						)
					} else {
						return (
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								fontWeight={"bold"}
								color={"#707070"}
							>
								Processing
							</StyleTypography>
						)
					}
				}
			}
		},
		{
			name: "id",
			label: "Actions",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => {
					return (
						<Grid
							className={"column-style"}
						>
							<IconButton
								onClick={() =>
									downloadFPS({
										pay_schedule_runs: {
											fps_id: value,
										}
									})
								}
							>
								<img
									className={"img-style"}
									src={DownloadIcon}
									alt={"Download"}
								/>
							</IconButton>
						</Grid>
					);
				},
			},
		},
	];

	useEffect(() => {
		if (!user.id) {
			me();
		}
		getFpsReport();
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [getFpsReport, me, user?.id]);

	useEffect(() => {
		if (employerPayScheduleRunId !== null && payScheduleId !== null) {
			let employerPaySchedule = fps_report?.find(report => report?.id === parseInt(payScheduleId));
			let paySchedule = employerPaySchedule?.pay_runs?.find(payRun => payRun?.id === parseInt(employerPayScheduleRunId));
			setTitle(
				"Tax Period " + paySchedule?.tax_period +
				": " + paySchedule?.pay_run_period
			);
			setFpsDownloadArray(paySchedule?.fps_reports);
			setPayScheduleRun(paySchedule);
			setPayRunId(paySchedule?.id);
			setOpen(true);
		}
	}, [employerPayScheduleRunId, fps_report, payScheduleId]);

	if (user_loading || report_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"reports-fps"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Reports"}
						activeSubpage={""}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
						className={"reports-fps-item"}
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							justifyContent={"space-between"}
							alignItems={"center"}
						>
							<StyleTypography
								fontSize={40}
								fontSizeMedium={30}
								fontWeight={"bold"}
							>
								Full Payment Submission (FPS)
							</StyleTypography>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								color={"#ABAEB7"}
							>
								A Full Payment Submission (FPS) is a report sent to HM Revenue and Customs (HMRC)<br/>
								about payments and deductions made to employees every time they are paid.
							</StyleTypography>
							<Grid
								className={"reports-fps-divider"}
							>
								<Divider/>
							</Grid>
							<Grid
								className={"reports-fps-warning-box"}
								container
								direction={"row"}
								alignItems={"center"}
							>
								<Grid item className={"reports-fps-warning-box-margin-right"}>
									<img
										className={"reports-fps-warning-icon"}
										src={WarningIcon}
										alt={"WarningIcon"}
									/>
								</Grid>
								<Grid
									item
								>
									<StyleTypography
										fontSize={18}
										fontSizeMedium={13}
										color={"black"}
										fontWeight={"bold"}
									>
										Please find below all the FPS reports that have been sent to HMRC, you can download them for your future reference.
									</StyleTypography>
								</Grid>
							</Grid>
							{
								fps_report.length === 0 &&
								<Grid className={"reports-fps-border-box"}>
									<StyleTypography
										fontSize={18}
										fontSizeMedium={13}
										color={"black"}
										fontWeight={"bold"}
									>
										No FPS reports available to download
									</StyleTypography>
									<StyleTypography
										fontSize={16}
										fontSizeMedium={11}
										color={"#ABAEB7"}
									>
										In order to have FPS reports, you need to have extraordinary items like statutory pay.
									</StyleTypography>
								</Grid>
							}
							{
								fps_report.length > 0 &&
								<Grid className={"reports-fps-year-download"}>
									{
										fps_report?.map((item, itemIndex) => {
											return (
												<Grid key={itemIndex}>
													<Accordion
														className={"reports-fps-accordion-style"}
													>
														<AccordionSummary
															expandIcon={<ExpandMoreIcon />}
															aria-controls={"panel1bh-content"}
														>
															<Grid container direction={"row"} alignItems={"center"}>
																<Grid item>
																	<StyleTypography
																		fontSize={20}
																		fontSizeMedium={14}
																		fontWeight={"bold"}
																		color={"black"}
																	>
																		{item?.name}
																	</StyleTypography>
																</Grid>
															</Grid>
														</AccordionSummary>
														<AccordionDetails>
															{
																item?.pay_runs?.map((pay_run, pay_run_index) => (
																	<Grid
																		container
																		direction={"row"}
																		alignItems={"center"}
																		key={pay_run_index}
																		marginBottom={
																			pay_run.length-1 !== pay_run_index
																				? "0.8vw" : "0px"
																		}
																	>
																		<Grid
																			item
																			marginRight={"4.4%"}
																			className={"reports-fps-accordion-content-style"}
																			onClick={() => {
																				setTitle(
																					"Tax Period " + pay_run?.tax_period +
																					": " + pay_run?.pay_run_period
																				);
																				setFpsDownloadArray(pay_run?.fps_reports);
																				setPayScheduleRun(pay_run);
																				setPayRunId(pay_run?.id);
																				setOpen(true);
																			}}
																		>
																			<StyleTypography
																				fontSize={15}
																				fontSizeMedium={11}
																				color={"#919196"}
																			>
																				Tax period: {pay_run?.tax_period} | {pay_run?.pay_run_period}
																			</StyleTypography>
																		</Grid>
																	</Grid>
																))
															}
														</AccordionDetails>
													</Accordion>
													{
														fps_report.length-1 !== itemIndex
															? <Grid
																className={"reports-fps-year-download-divider"}
															>
																<Divider/>
															</Grid>
															: ""
													}
												</Grid>
											)
										})
									}
								</Grid>
							}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<DownloadReportDialog
				title={title}
				fpsDownloadArray={fpsDownloadArray}
				payScheduleRun={payScheduleRun}
				columns={columns}
				open={open}
				setOpen={setOpen}
				payRunId={payRunId}
				resubmitFps={resubmitFps}
			/>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Reports,
	} = state;

	return {
		...Profile,
		...Reports,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	getFpsReport: () => dispatch(getFpsReport()),
	downloadFPS: (data) => dispatch(downloadFPS(data)),
	resubmitFps: (data) => dispatch(resubmitFps(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsFps)