import Grid from "@mui/material/Grid";
import {connect} from "react-redux";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import {me} from "../../actions/Profile";
import OneOffAndRecurring from "../../components/Pages/Company/PayItems/OneOffAndRecurring";
import {useEffect, useState} from "react";
import NewPayItemType from "../../components/Pages/Company/PayItems/NewPayItemType";
import RecurringDetails from "../../components/Pages/Company/PayItems/RecurringDetails";
import PayItemCheckBox from "../../components/Pages/Company/PayItems/PayItemCheckBox";
import {createPayItem, deletePayItem, findPayItem, updatePayItem} from "../../actions/PayItem";
import Loading from "../../components/Loading";
import {findEmployer} from "../../actions/Employer";
import {useLocation} from "react-router-dom";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const renderContent = (
	user = {},
	step: number = 0,
	paymentTypeOptions: any[] = [],
	perPayrollOption: any[] = [],
	payItemBasicDetails = {},
	payItemRecurringDetails = {},
	setStep: Function = () => {
	},
	setPayItemBasicDetails: Function = () => {
	},
	setPayItemRecurringDetails: Function = () => {
	},
	createPayItem: Function = () => {
	},
	updatePayItem: Function = () => {
	},
	deletePayItem: Function = () => {
	},
) => {
	switch (step) {
		case 0:
			return (
				<OneOffAndRecurring
					setStep={setStep}
				/>
			);
		case 1:
			return (
				<NewPayItemType
					title={"New One-off Pay Item"}
					subTitle={"One-off pay items can be applied and reused in every payroll for every employee."}
					paymentTypeOptions={paymentTypeOptions}
					payItemBasicDetails={payItemBasicDetails}
					setStep={setStep}
					setPayItemBasicDetails={setPayItemBasicDetails}
				/>
			);
		case 2:
			return (
				<NewPayItemType
					title={"New Recurring Pay Item"}
					subTitle={"Recurring pay items can be applied to employees and have them automatically be added to future payrolls"}
					paymentTypeOptions={paymentTypeOptions}
					payItemBasicDetails={payItemBasicDetails}
					setStep={setStep}
					setPayItemBasicDetails={setPayItemBasicDetails}
				/>
			)
		case 3:
			return (
				<PayItemCheckBox
					user={user}
					typeOfPayItem={"New One-off Pay Item"}
					oneOff={true}
					payItemBasicDetails={payItemBasicDetails}
					payItemRecurringDetails={payItemRecurringDetails}
					setStep={setStep}
					setPayItemBasicDetails={setPayItemBasicDetails}
					setPayItemRecurringDetails={setPayItemRecurringDetails}
					createPayItem={createPayItem}
					updatePayItem={updatePayItem}
				/>
			)
		case 4:
			return (
				<RecurringDetails
					user={user}
					perPayrollOption={perPayrollOption}
					payItemBasicDetails={payItemBasicDetails}
					payItemRecurringDetails={payItemRecurringDetails}
					setStep={setStep}
					setPayItemBasicDetails={setPayItemBasicDetails}
					setPayItemRecurringDetails={setPayItemRecurringDetails}
					createPayItem={createPayItem}
					updatePayItem={updatePayItem}
				/>
			)
		default:
			return <></>
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayItems = ({
	user = {},
	pay_item_loading = false,
	user_loading = false,
	me = () => {},
	pay_item = {},
	createPayItem = () => {},
	updatePayItem = () => {},
	deletePayItem = () => {},
	findEmployer = () => {},
	employer = {},
	findPayItem = () => {
	},
}): JSX.Element => {
	const [step, setStep] = useState(0),
		paymentTypeOptions = [
			"Gross addition",
			"Gross deduction",
			"Net addition",
			"Net deduction"
		],
		perPayrollOption = [
			"Per Payroll",
			"Per Hour",
		],
		location = useLocation();

	const encodedPayItemId = (new URLSearchParams(location.search))?.get('id');

	const [payItemBasicDetails, setPayItemBasicDetails] = useState({
		id: null,
		payItemName: "",
		paymentType: " ",
		taxable: false,
		subjectNationalInsurance: false,
		is_benefit_in_kind: false,
		pensionable: false,
		payItemCode: "",
	});

	const [payItemRecurringDetails, setPayItemRecurringDetails] = useState({
		payrollAmount: 0.00,
		perPayroll: " ",
		duration: false,
		payrolls: 0,
		untilCancelled: false,
	});

	useEffect(() => {
		if (!user.id) {
			me();
		}
		findEmployer(["custom_pay_items"]);
	}, [findEmployer, me, user.id]);

	useEffect(() => {
		if (encodedPayItemId !== null) {
			findPayItem(JSON.parse(atob(encodedPayItemId)));
		}
	}, [encodedPayItemId, findPayItem]);

	useEffect(() => {
		if (Object.keys(pay_item).length > 0) {
			setPayItemBasicDetails((prevBasicDetails) => ({
				...prevBasicDetails,
				id: pay_item?.id,
				payItemName: pay_item?.name,
				paymentType: pay_item?.is_gross
					? "Gross " + pay_item?.type.toLowerCase()
					: pay_item?.is_net
						? "Net " + pay_item?.type.toLowerCase()
						: prevBasicDetails.paymentType,
				taxable: pay_item?.before_tax,
				subjectNationalInsurance: pay_item?.before_ni,
				is_benefit_in_kind: pay_item?.is_benefit_in_kind,
				pensionable: pay_item?.before_pension,
				payItemCode: pay_item?.account_code,
			}));

			setPayItemRecurringDetails((prevRecurringDetails) => ({
				...prevRecurringDetails,
				payrollAmount: pay_item?.amount,
				perPayroll: pay_item?.is_per_payroll_amount
					? "Per Payroll"
					: pay_item?.is_per_hour_amount
						? "Per Hour"
						: pay_item.perPayroll,
				duration: pay_item?.fixed_duration,
				payrolls: pay_item?.number_of_payrolls,
				untilCancelled: pay_item?.fixed_duration,
			}));
			setStep(1);
		}
	}, [pay_item]);

	if (user_loading || pay_item_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"pay-items-page"}
		>
			<StyleAppBar
				showBackButton={true}
				user={user}
				employer={employer}
				backLink={"/main/company/pay-items"}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Company"}
						activeSubpage={"Pay Items"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					{
						renderContent(
							user,
							step,
							paymentTypeOptions,
							perPayrollOption,
							payItemBasicDetails,
							payItemRecurringDetails,
							setStep,
							setPayItemBasicDetails,
							setPayItemRecurringDetails,
							createPayItem,
							updatePayItem,
							deletePayItem,
						)
					}
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		PayItem,
		Employer,
	} = state;

	return {
		...Profile,
		...PayItem,
		...Employer
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	createPayItem: (data: any[]) => dispatch(createPayItem(data)),
	updatePayItem: (id: number, data: any[]) => dispatch(updatePayItem(id, data)),
	deletePayItem: (id: number) => dispatch(deletePayItem(id)),
	findPayItem: (id: number) => dispatch(findPayItem(id)),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayItems)