import Grid from "@mui/material/Grid";
import StyleTypography from "../../StyledComponents/StyleTypography";
import React from "react";
import taxYearBanner from "../../../images/Photo-min.jpg";
import StyleButton from "../../StyledComponents/StyleButton";

export const TaxYearBanner = ({
	push = () => {},
}): JSX.Element => {
	return (
		<Grid
			container
			item
			xs={12}
			position={"relative"}
		>
			<img
				src={taxYearBanner}
				alt={"close tax year"}
				className={'image-tax-year'}
			/>
			<Grid
				position={'absolute'}
				top={'20px'}
				left={'40px'}
			>
				<Grid>
					<StyleTypography
						fontSize={16}
						fontSizeMedium={12}
						color={'#FFF'}
					>
						Reminder
					</StyleTypography>
				</Grid>
				<Grid
					paddingTop={1.25}
				>
					<StyleTypography
						fontSize={30}
						fontSizeMedium={22}
						fontWeight={'bold'}
						color={'#FFF'}
					>
						This tax year is coming to an end
					</StyleTypography>
				</Grid>
				<Grid>
					<StyleTypography
						fontSize={16}
						fontSizeMedium={12}
						color={'#FFF'}
					>
						When you are done with all your pay runs for the current tax year, click on <br/>
						the button below to close this tax year.
					</StyleTypography>
				</Grid>
				<Grid
					item
					xs={5}
					paddingTop={1.25}
				>
					<StyleButton
						isBlueButton={true}
						onClick={() => push('/main/close-tax-year')}
					>
						<StyleTypography
							fontSize={16}
							fontSizeMedium={12}
							color={'#FFF'}
						>
							Close Tax Year
						</StyleTypography>
					</StyleButton>
				</Grid>

			</Grid>
		</Grid>
	)
}