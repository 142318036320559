import {resolveReduxState} from "../../utils/Helpers";
import {
	FIND_PAY_SCHEDULE_RUN,
	GET_PAY_SCHEDULE_RUNS,
	RUN_PAYROLL
} from "../../actions/PayScheduleRun/types";

const initial_state = {
	pay_schedule_run_loading: false,
	pay_schedule_run: {},
	pay_schedule_runs: [],
	employee_pay_runs: [],
	employee_pay_run: {},
	payroll_summary: {},
	today_date: "",
	payments_breakdown: [],
	table_loading: false,
	fps_resubmissions: [],
}

const PayScheduleRun = (state = initial_state, action = false) => {
	if (action) {
		switch(action.type) {

			case GET_PAY_SCHEDULE_RUNS:
			case FIND_PAY_SCHEDULE_RUN:
			case RUN_PAYROLL:
				return resolveReduxState(state, action);

			default:
				return state;
		}
	}

	return state;
}

export default PayScheduleRun;