import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import InputLabel from "@mui/material/InputLabel";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Plus from "../../../../images/enhanced-policies/plus-black.svg";
import Trash from "../../../../images/enhanced-policies/delete.svg";
import Window from "../../../../images/enhanced-policies/window-icon.svg";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {CurrencyInputCustom} from "../../../StyledComponents/CurrencyInputCustom";

const addPolicyObject = (policyFields, setPolicyFields) => {
	const newPolicyArray = [...policyFields.policyArray, {
		weeks: 0,
		per_pay: " ",
		percent: 0,
	}];
	setPolicyFields({ ...policyFields, policyArray: newPolicyArray });
}

const editPolicyField = (policyFields, setPolicyFields, index, field, value) => {
	const newPolicyArray = [...policyFields.policyArray];
	newPolicyArray[index] = {
		...newPolicyArray[index],
		[field]: value,
	};
	setPolicyFields({ ...policyFields, policyArray: newPolicyArray });
};

const deletePolicyObject = (
	policyFields,
	setPolicyFields,
	index,
	deletedStages,
	setDeletedStages
) => {
	if (policyFields?.policyArray[index]?.id) {
		setDeletedStages(prevStages => [...prevStages, {
			...prevStages,
			id: policyFields.policyArray[index]?.id,
			weeks: policyFields.policyArray[index]?.weeks,
			per_pay: policyFields.policyArray[index]?.per_pay,
			percent: policyFields.policyArray[index]?.percent,
		}]);
	}
	const newPolicyArray = [...policyFields.policyArray];
	newPolicyArray.splice(index, 1);
	setPolicyFields({ ...policyFields, policyArray: newPolicyArray });
};

function verifyPolicyArray (policyArray:any[]) {
	for (let k = 0; k < policyArray.length; k++) {
		if (
			policyArray[k]?.weeks.length === 0 ||
			parseFloat(policyArray[k]?.weeks) <= 0 ||
			policyArray[k]?.per_pay.length === 0 ||
			policyArray[k]?.per_pay === " " ||
			policyArray[k]?.percent.length === 0 ||
			parseFloat(policyArray[k]?.percent) <= 0
		) {
			return true;
		}
	}
	return false;
}

export const EnhancedPolicyModal = ({
	user = {},
	open = false,
	policyFields = {},
	deletedStages = [],
	setOpen = () => {
	},
	setPolicyFields = () => {
	},
	setSelectedPolicy = () => {
	},
	setDeletedStages = () => {
	},
	createEnhancedPolicy = () => {
	},
	updateEnhancedPolicy = () => {
	},
}): JSX.Element => {
	const [open2, setOpen2] = useState(false);
	return (
		<Grid>
			<Dialog
				onClose={() => {
					setOpen(false);
					setPolicyFields({
						name: "",
						type: "",
						policyArray: [
							{
								weeks: 0,
								per_pay: " ",
								percent: 0,
							},
						]
					});
					setSelectedPolicy({});
					setDeletedStages([]);
				}}
				open={open}
				id={"enhanced-policy-dialog"}
			>
				<DialogActions>
					<Grid container justifyContent={"flex-end"}>
						<Grid marginBottom={"20px"} container direction={"column"}>
							<StyleTypography
								fontSize={35}
								fontSizeMedium={25}
								fontWeight={'bold'}
							>
								{
									policyFields?.id ? "Update Policy" : "Create policy"
								}
							</StyleTypography>
							<StyleTypography
								fontSize={13}
								fontSizeMedium={13}
								color={"#ABAEB7"}
							>
								Pick a policy type and adjust it to your needs
							</StyleTypography>
						</Grid>
						<Grid
							item
							container
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							spacing={2}
						>
							<Grid
								container
								item
								direction={"row"}
							>
								<Grid
									className={"dialog-container"}
									item
									container
									onClick={() => {
										setOpen(false);
										setPolicyFields({
											...policyFields,
											type: "Enhanced paternity pay",
										})
										setOpen2(true);
									}}
								>
									<Grid paddingTop={"5px"} paddingRight={"20px"}>
										<img src={Window} alt={""} className={"img"}/>
									</Grid>
									<Grid>
										<StyleTypography
											fontSize={18}
											color={"black"}
											fontWeight={"bold"}
											fontSizeMedium={13}
										>
											Enhanced paternity pay
										</StyleTypography>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								container
								item
								direction={"row"}
							>
								<Grid
									className={"dialog-container"}
									item
									container
									onClick={() => {
										setOpen(false);
										setPolicyFields({
											...policyFields,
											type: "Enhanced maternity pay",
										})
										setOpen2(true);
									}}
								>
									<Grid paddingTop={"5px"} paddingRight={"20px"}>
										<img src={Window} alt={""} className={"img"}/>
									</Grid>
									<Grid>
										<StyleTypography
											fontSize={18}
											color={"black"}
											fontWeight={"bold"}
											fontSizeMedium={13}
										>
											Enhanced maternity pay
										</StyleTypography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid marginRight={"2px"}>
							<Button
								fullWidth
								className={"company-wide-button-cancel"}
								onClick={() => {
									setPolicyFields({
										name: "",
										type: "",
										policyArray: [
											{
												weeks: 0,
												per_pay: " ",
												percent: 0,
											},
										]
									});
									setOpen(false);
									setSelectedPolicy({});
									setDeletedStages([]);
								}}
							>
								<StyleTypography
									fontSize={16}
									color={"black"}
									fontWeight={"bold"}
									fontSizeMedium={11}
								>
									Cancel
								</StyleTypography>
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
			<Dialog
				onClose={() => {
					setOpen2(false);
					setPolicyFields({
						name: "",
						policyArray: [
							{
								weeks: 0,
								per_pay: " ",
								percent: 0,
							},
						]
					});
					setSelectedPolicy({});
					setDeletedStages([]);
				}}
				open={open2}
				id={"company-wide-enhanced-pay"}
			>
				<DialogActions>
					<Grid container>
						<Grid className={"company-wide-enhanced-pay-margin-title"} container direction={"column"}>
							<StyleTypography
								fontSize={35}
								fontSizeMedium={25}
								fontWeight={'bold'}
							>
								{policyFields?.type}
							</StyleTypography>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								color={"#ABAEB7"}
							>
								Set up statutory leave scheme for the employee and let
								Collegia FreePayroll calculate the payments.
							</StyleTypography>
						</Grid>
						<Grid container>
							<InputLabel
								className={"default-input-label"}
							>
								Policy name
							</InputLabel>
							<TextField
								className={"default-text-field"}
								fullWidth
								placeholder={""}
								value={policyFields?.name}
								onChange={(e) =>
									setPolicyFields({
										...policyFields,
										name: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							className={"company-wide-enhanced-pay-divider"}
						>
							<Divider/>
						</Grid>
						{
							policyFields?.policyArray?.map((policyItem, index = {}) => (
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									container
									item
									direction={"row"}
									spacing={2}
									alignItems={"center"}
									key={index}
									marginBottom={"10px"}
								>
									<Grid item xs={3.25}>
										<TextField
											className={"default-text-field"}
											fullWidth
											value={policyItem?.weeks}
											placeholder={""}
											onChange={(e) => {
												const regex = /^[0-9\b]+$/;
												if (e.target.value === "" || regex.test(e.target.value)) {
													editPolicyField(
														policyFields,
														setPolicyFields,
														index,
														"weeks",
														e.target.value
													)
												}
											}}
											error={
												policyItem?.weeks?.length === 0
											}
											InputProps={{
												endAdornment:
													<StyleTypography
														fontSize={18}
														fontWeight={"bold"}
														color={"black"}
														fontSizeMedium={13}
													>
														Weeks
													</StyleTypography>,
											}}
										/>
									</Grid>
									<Grid item xs={5}>
										<FormControl
											fullWidth
										>
											<Select
												className={"default-select"}
												MenuProps={{
													PaperProps: {
														style: {
															maxHeight: 200,
														},
													},
												}}
												value={policyItem?.per_pay}
												onChange={(e) =>
													editPolicyField(
														policyFields,
														setPolicyFields,
														index,
														"per_pay",
														e.target.value
													)
												}
											>
												<MenuItem
													key={"select"}
													value={" "}
													disabled
												>
													Select
												</MenuItem>
												{
													["Percent of pay", "Other Option"]
														.map((item, index) => (
															<MenuItem
																key={index}
																value={item}
															>
																{item}
															</MenuItem>
														))
												}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={3.25}>
										<TextField
											className={"default-text-field"}
											fullWidth
											value={policyItem?.percent}
											placeholder={""}
											onChange={(e) =>
												editPolicyField(
													policyFields,
													setPolicyFields,
													index,
													"percent",
													e.target.value
												)
											}
											InputProps={{
												endAdornment:
													<StyleTypography
														fontSize={18}
														fontSizeMedium={13}
														fontWeight={"bold"}
														color={"black"}
													>
														%
													</StyleTypography>,
												inputComponent: CurrencyInputCustom,
												inputProps: {prefix: ""},
											}}
										/>
									</Grid>
									<Grid item xs={0.5} textAlign={"end"} paddingTop={"15px"}>
										<IconButton
											onClick={() =>
												deletePolicyObject(
													policyFields,
													setPolicyFields,
													index,
													deletedStages,
													setDeletedStages
												)
											}
										>
											<img src={Trash} alt={""} className={"img"}/>
										</IconButton>

									</Grid>
								</Grid>
							))
						}
						<Grid
							container
							direction={"row"}
							className={"policy-cursor"}
							onClick={() => addPolicyObject(policyFields, setPolicyFields)}
						>
							<Grid item paddingRight={"10px"}>
								<Typography>
									<img src={Plus} alt={""} className={"img"}/>
								</Typography>
							</Grid>
							<Grid item>
								<StyleTypography
									color={"black"}
									fontWeight={"bold"}
									fontSize={16}
									fontSizeMedium={11}
								>
									Add another stage
								</StyleTypography>
							</Grid>
						</Grid>
						<Grid container direction={"row"} spacing={2}>
							<Grid item>
								<Button
									fullWidth
									className={"create-policy-button-black"}
									disabled={
										policyFields?.name.length === 0 ||
										policyFields?.type.length === 0 ||
										verifyPolicyArray(policyFields?.policyArray) ||
										policyFields?.policyArray.length === 0
									}
									onClick={() => {
										policyFields?.id
											? updateEnhancedPolicy(
												policyFields?.id, {
												"enhanced_policy": {
													employer_id: user?.employer?.id,
													name: policyFields?.name,
													is_paternity_pay: policyFields?.type === "Enhanced paternity pay",
													is_maternity_pay: policyFields?.type === "Enhanced maternity pay",
													stages: policyFields?.policyArray,
													deletedPolicyStages: deletedStages.length > 0 ? deletedStages : null,
												}
											})
											: createEnhancedPolicy({
												"enhanced_policy": {
													employer_id: user?.employer?.id,
													name: policyFields?.name,
													is_paternity_pay: policyFields?.type === "Enhanced paternity pay",
													is_maternity_pay: policyFields?.type === "Enhanced maternity pay",
													stages: policyFields?.policyArray,
												}
											});
										setPolicyFields({
											name: "",
											type: "",
											policyArray: [
												{
													weeks: 0,
													per_pay: " ",
													percent: 0,
												},
											]
										});
										setSelectedPolicy({});
										setOpen2(false);
									}}
								>
									<StyleTypography
										fontSize={16}
										color={
											policyFields?.name.length === 0 ||
											policyFields?.type.length === 0 ||
											verifyPolicyArray(policyFields?.policyArray) ||
											policyFields?.policyArray.length === 0 ? "#C9CCD1" : "white"
										}
										fontWeight={"bold"}
										fontSizeMedium={11}
									>
										{
											policyFields?.id ? "Update Policy" : "Create policy"
										}

									</StyleTypography>
								</Button>
							</Grid>
							<Grid item>
								<Button
									fullWidth
									className={"create-policy-button-white"}
									onClick={() => {
										setOpen2(false);
										setPolicyFields({
											name: "",
											type: "",
											policyArray: [
												{
													weeks: 0,
													per_pay: " ",
													percent: 0,
												},
											]
										});
										setSelectedPolicy({});
										setDeletedStages([]);
									}}
								>
									<StyleTypography
										fontSize={16}
										color={"black"}
										fontSizeMedium={11}
										fontWeight={"bold"}
									>
										Cancel
									</StyleTypography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</Grid>
	)
}