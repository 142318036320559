import Grid from "@mui/material/Grid";
import StyleTypography from "../../StyledComponents/StyleTypography";
import {Avatar, Popover} from "@mui/material";
import React, {useState} from "react";
import AddPayItemModal from "./AddPayItemModal";
import EditPayItemModal from "./EditPayItemModal";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";

function renderSummaryLine (item, value, isDeduction = true) {

	return (
		<Grid
			className={'employee-summary-items'}
			justifyContent={"space-between"}
			container
			item
			xs={12}
		>
			<Grid>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={13}
					color={'#000000'}
					fontWeight={'normal'}
				>
					{item}
				</StyleTypography>
			</Grid>
			{
				isDeduction ? (
					<Grid>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={value > 0 ? '#FF0000' : '#000000'}
						>
							{
								parseFloat(value) > 0 && "("
							}
							{
								(value ?? 0.00).toLocaleString('en-GB', {
									style: 'currency',
									currency: 'GBP',
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								}).replace('-','')
							}
							{
								parseFloat(value) > 0 && ")"
							}
						</StyleTypography>
					</Grid>
				) : (
					<Grid>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={'#000000'}
						>
							{(value ?? 0.00).toLocaleString('en-GB', {
								style: 'currency',
								currency: 'GBP',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</StyleTypography>
					</Grid>
				)
			}
		</Grid>
	)
}

function renderSummaryPayItemLine (item, value, payItem, selectedPayItem, setSelectedPayItem, setShowEditPayItem, isDeduction) {

	return (
		<Grid
			className={'employee-summary-items'}
			justifyContent={"space-between"}
			container
			item
			xs={12}
		>
			<Grid
				onClick={() => {
					setSelectedPayItem(payItem);
					setShowEditPayItem(true);
				}}
				sx={{
					cursor: 'pointer',
				}}
			>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={13}
					color={'#0360FD'}
					fontWeight={'medium'}
					className={'pay-item-edit'}
				>
					{item} {payItem.is_benefit_in_kind && '(BiK)'}
				</StyleTypography>
			</Grid>
			{
				isDeduction ? (
					<Grid>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={value > 0 ? '#FF0000' : '#000000'}
						>
							{
								parseFloat(value) > 0 && "("
							}
							{
								(value ?? 0.00).toLocaleString('en-GB', {
									style: 'currency',
									currency: 'GBP',
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								}).replace('-','')
							}
							{
								parseFloat(value) > 0 && ")"
							}
						</StyleTypography>
					</Grid>
				) : (
					<Grid>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={'#000000'}
						>
							{(value ?? 0.00).toLocaleString('en-GB', {
								style: 'currency',
								currency: 'GBP',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</StyleTypography>
					</Grid>
				)
			}
		</Grid>
	)
}


/**
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeeSummary = ({
	payrollSubmitted = false,
	selectedEmployee,
	viewPayslip,
	sendPayslip,
}): JSX.Element => {

	const [showAddPayItem, setShowAddPayItem] = useState(false);
	const [showEditPayItem, setShowEditPayItem] = useState(false);
	const [selectedPayItem, setSelectedPayItem] = useState({});
	const [popOver, setPopOver] = useState(false),
		[anchorEl, setAnchorEl] = useState(null);
	const push = useNavigate();

	return (
		<Grid
			alignContent={"flex-start"}
			container
			id={'employee-summary'}
		>
			{/*Employee Header*/}
			<Grid
				item
				xs={12}
			>
				<Grid
					container
					alignItems={"center"}
				>
					<Grid
						sx={{
							marginRight: "10px",
						}}
					>
						<Avatar > {selectedEmployee.employee.forename[0]}{selectedEmployee.employee.surname[0]}</Avatar>
					</Grid>
					<Grid>
						<StyleTypography
							fontSize={27}
							fontSizeMedium={20}
							className={'employee-name'}
							onClick={() => push(`/main/people/record/${selectedEmployee.employee.safe_id}`)}
						>
							{selectedEmployee.employee.forename} {selectedEmployee.employee.surname}
						</StyleTypography>
						<StyleTypography
							fontSize={14}
							fontSizeMedium={12}
							color={'#000000'}
						>
							Tax Code <strong>{selectedEmployee.employee.tax_code} {!selectedEmployee.employee.is_cumulative && '(W1/M1)'}</strong>
							| NI Cat <strong>{selectedEmployee.employee.ni_category}</strong>
						</StyleTypography>
					</Grid>
					<Grid xs item textAlign={'end'}>
						<IconButton
							sx={{padding: 0}}
							onClick={(event) => {
								setAnchorEl(event.currentTarget);
								setPopOver(true);
							}}
						>
							<MoreVertIcon/>
						</IconButton>
					</Grid>
				</Grid>
				<Popover
					id={"popover"}
					open={popOver}
					anchorEl={anchorEl}
					onClose={() => {
						setAnchorEl(null);
						setPopOver(false);
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
				>
					<Grid>
						<Grid
							container
							className={"popover-list-grid"}
							alignItems={"center"}
							onClick={() => {
								sendPayslip({
									"pay_schedule_runs": {
										id: selectedEmployee.id,
									}
								})
							}}
						>
							{
								payrollSubmitted ? (
									<StyleTypography
										fontSize={15}
										fontSizeMedium={13}
										color={"#000000"}
									>
										Send payslip
									</StyleTypography>
								) : (
									<StyleTypography
										fontSize={15}
										fontSizeMedium={13}
										color={"#000000"}
									>
										Send payslip (Draft)
									</StyleTypography>
								)
							}
						</Grid>
						<Grid
							container
							className={"popover-list-grid"}
							alignItems={"center"}
							onClick={() => {
								viewPayslip({
									"pay_schedule_runs": {
										id: selectedEmployee.id,
									}
								})
							}}
						>
							{
								payrollSubmitted ? (
									<StyleTypography
										fontSize={15}
										fontSizeMedium={13}
										color={"#000000"}
									>
										View payslip
									</StyleTypography>
								) : (
									<StyleTypography
										fontSize={15}
										fontSizeMedium={13}
										color={"#000000"}
									>
										View payslip (Draft)
									</StyleTypography>
								)
							}
						</Grid>
					</Grid>
				</Popover>
			</Grid>

			{
				!payrollSubmitted && (
					<Grid
						item
						xs={12}
						paddingTop={'20px'}
					>
						<span
							className={"add-pay-item"}
							onClick={() => setShowAddPayItem(true)}
						>
							+ Add pay item
						</span>
					</Grid>
				)
			}

			{/*Summary*/}
			<Grid
				item
				xs={12}
				className={'employee-summary-items'}
			>
				<StyleTypography
					fontSize={20}
					fontSizeMedium={14}
					fontWeight={'bold'}
					color={'#000000'}
				>
					Summary
				</StyleTypography>
			</Grid>

			{/*Base Pay*/}
			{renderSummaryLine('Base Pay', selectedEmployee.base_pay_amount, false)}

			{/*PG Student Loan*/}
			{
				selectedEmployee.pg_loan > 0 &&
				renderSummaryLine('PG Student Loan', selectedEmployee.pg_loan)
			}

			{/*Student Loan*/}
			{
				selectedEmployee.student_loan > 0 &&
				renderSummaryLine('Student Loan', selectedEmployee.student_loan)
			}

			{/*Statutory Maternity Pay*/}
			{
				selectedEmployee.smp > 0 &&
				renderSummaryLine('Statutory Maternity Pay', selectedEmployee.smp, false)
			}

			{/*Statutory Paternity Pay*/}
			{
				selectedEmployee.spp > 0 &&
				renderSummaryLine('Statutory Paternity Pay', selectedEmployee.spp, false)
			}

			{/*Statutory Sick Pay*/}
			{
				selectedEmployee.ssp > 0 &&
				renderSummaryLine('Statutory Sick Pay', selectedEmployee.ssp, false)
			}

			{
				selectedEmployee.pay_items && selectedEmployee.pay_items.filter(item => item.is_gross).map((payItem) => {
					if (payItem.is_editable) {
						return renderSummaryPayItemLine(payItem.item_name, payItem.item_amount, payItem, selectedPayItem, setSelectedPayItem, setShowEditPayItem, payItem.item_type === 'Deduction')
					} else {
						return renderSummaryLine(payItem.item_name, payItem.item_amount, payItem.item_type === 'Deduction')
					}
				})
			}

			{/*Employee NIC*/}
			{renderSummaryLine('Employee NIC', selectedEmployee.employee_ni_deducted)}

			{/*PAYE Income Tax*/}
			{renderSummaryLine('PAYE Income Tax', selectedEmployee.income_tax_deducted)}

			{/*Employee Pension*/}
			{renderSummaryLine('Employee Pension', selectedEmployee.employee_pension_deducted)}

			{
				selectedEmployee.pay_items && selectedEmployee.pay_items.filter(item => item.is_net).map((payItem) => {
					if (payItem.is_editable) {
						return renderSummaryPayItemLine(payItem.item_name, payItem.item_amount, payItem, selectedPayItem, setSelectedPayItem, setShowEditPayItem, payItem.item_type === 'Deduction')
					} else {
						return renderSummaryLine(payItem.item_name, payItem.item_amount, payItem.item_type === 'Deduction')
					}
				})
			}

			{/*Net Pay*/}
			{renderSummaryLine('Net Pay', selectedEmployee.net_pay, false)}

			{/*Employer*/}
			<Grid
				item
				xs={12}
				className={'employee-summary-title'}
			>
				<StyleTypography
					fontSize={20}
					fontSizeMedium={14}
					fontWeight={'bold'}
					color={'#000000'}
				>
					Employer
				</StyleTypography>
			</Grid>

			{/*Employer NIC*/}
			{renderSummaryLine('Employer NIC', selectedEmployee.employer_ni_deducted, false)}

			{/*Employer Pension*/}
			{renderSummaryLine('Employer Pension', selectedEmployee.employer_pension_deducted, false)}

			<AddPayItemModal
				open={showAddPayItem}
				setOpen={setShowAddPayItem}
				selectedEmployee={selectedEmployee}
			/>

			<EditPayItemModal
				open={showEditPayItem}
				setOpen={setShowEditPayItem}
				selectedEmployee={selectedEmployee}
				selectedPayItem={selectedPayItem}
				setSelectedPayItem={setSelectedPayItem}
			/>
		</Grid>
	);
}

export default EmployeeSummary;