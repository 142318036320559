import Grid from "@mui/material/Grid";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FormControlLabel from "@mui/material/FormControlLabel";
import {CurrencyInputCustom} from "../../../StyledComponents/CurrencyInputCustom";
import Radio from "@mui/material/Radio";
import PayItemCheckBox from "./PayItemCheckBox";
import InputLabel from "@mui/material/InputLabel";
import React from "react";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const RecurringDetails = ({
	user = {},
	perPayrollOption= [],
	payItemBasicDetails = {},
	payItemRecurringDetails = {},
	setStep = () => {
	},
	setPayItemBasicDetails = () => {
	},
	setPayItemRecurringDetails = () => {
	},
	createPayItem = () => {
	},
	updatePayItem = () => {
	},
}): JSX.Element => {
	return (
		<Grid
			container
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				justifyContent={"space-between"}
				alignItems={"center"}
			>
				<Grid>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={30}
						fontWeight={"bold"}
					>
						Pay item: {payItemBasicDetails?.payItemName}
					</StyleTypography>
				</Grid>
				<Grid
					container
				>
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
						container
						alignItems={"center"}
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							paddingTop={"10px"}
						>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								color={"#ABAEB7"}
							>
								Recurring pay items can be applied to employees and have them automatically be added to future payrolls.
							</StyleTypography>
						</Grid>
						<Grid
							className={"pay-items-divider"}
						>
							<Divider/>
						</Grid>
						<Grid
							container
							direction={"row"}
							className={"employee-month-award"}
							onClick={() => setStep(2)}
						>
							<Grid
								item
								xs={9}
							>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									fontWeight={"bold"}
									color={"black"}
								>
									{payItemBasicDetails?.payItemName}
								</StyleTypography>
								<StyleTypography
									fontSize={16}
									fontSizeMedium={12}
									color={"#ABAEB7"}
								>
									{payItemBasicDetails?.paymentType}
								</StyleTypography>
							</Grid>
							<Grid
								item
								xs={3}
							>
								<Grid
									container
									direction={"row"}
									alignItems={"center"}
									justifyContent={"center"}
								>
									<Grid item xs={8}>
										<StyleTypography
											fontSize={16}
											fontSizeMedium={12}
											color={"black"}
										>
											Change
										</StyleTypography>
									</Grid>
									<Grid item xs={4} paddingTop={"3px"}>
										<ArrowForwardIosIcon className={"arrow-icon"}/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							container
						>
							<InputLabel
								className={"default-input-label"}
							>
								Set an amount per payroll or per hour worked
							</InputLabel>
							<Grid container direction={"row"} spacing={4} className={"pay-items-margin-pay-item"}>
								<Grid item>
									<TextField
										className={"default-text-field"}
										fullWidth
										InputProps={{
											endAdornment: <InputAdornment position={"end"}>
												<StyleTypography fontSize={16} color={"black"} fontWeight={"bold"}>
													£
												</StyleTypography>
											</InputAdornment>,
											inputComponent: CurrencyInputCustom,
											inputProps: {prefix: ""},
										}}
										value={payItemRecurringDetails?.payrollAmount}
										placeholder={"0.00"}
										onChange={(e) =>
											setPayItemRecurringDetails({
												...payItemRecurringDetails,
												payrollAmount: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid item minWidth={"278px"}>
									<FormControl
										fullWidth
									>
										<Select
											className={"default-select"}
											MenuProps={{
												PaperProps: {
													style: {
														maxHeight: 200,
													},
												},
											}}
											value={payItemRecurringDetails?.perPayroll}
											onChange={(e) =>
												setPayItemRecurringDetails({
													...payItemRecurringDetails,
													perPayroll: e.target.value,
												})
											}
										>
											<MenuItem
												key={"select"}
												value={" "}
												disabled
											>
												Select
											</MenuItem>
											{
												perPayrollOption.map((item, index) => (
													<MenuItem
														key={index}
														value={item}
													>
														{item}
													</MenuItem>
												))
											}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid>
								<InputLabel
									className={"default-input-label"}
								>
									Set duration
								</InputLabel>
								<Grid container marginBottom={"10px"}>
									<FormControlLabel
										value={payItemRecurringDetails?.duration}
										control={
											<Radio
												checked={payItemRecurringDetails?.duration}
												onClick={() => {
													setPayItemRecurringDetails({
														...payItemRecurringDetails,
														duration: !payItemRecurringDetails?.duration,
														untilCancelled: false,
													});
												}}
											/>
										}
										label={
											<StyleTypography
												fontSize={16}
												color={"black"}
												fontWeight={"bold"}
												fontSizeMedium={12}
											>
												Fixed duration
											</StyleTypography>
										}
									/>
								</Grid>
								{
									payItemRecurringDetails?.duration &&
										<Grid container className={"pay-items-payrolls-margin"}>
											<TextField
												className={"default-text-field"}
												fullWidth
												InputProps={{
													endAdornment: <InputAdornment position={"end"}>
														<StyleTypography fontSize={20} color={"#AAAEB7"}>
															Payrolls
														</StyleTypography>
													</InputAdornment>,
												}}
												placeholder={"0"}
												value={payItemRecurringDetails?.payrolls}
												onChange={(e) => {
													const re = /^[0-9\b]+$/;
													if (e.target.value === '' || re.test(e.target.value)) {
														setPayItemRecurringDetails({
															...payItemRecurringDetails,
															payrolls: e.target.value,
														});
													}
												}}
											/>
										</Grid>
								}
								<Grid container className={"pay-items-margin-pay-item"}>
									<FormControlLabel
										value={payItemRecurringDetails?.untilCancelled}
										control={
											<Radio
												checked={payItemRecurringDetails?.untilCancelled}
												onClick={() => {
													setPayItemRecurringDetails({
														...payItemRecurringDetails,
														untilCancelled: !payItemRecurringDetails?.untilCancelled,
														duration: false,
													});
												}}
											/>
										}
										label={
											<StyleTypography
												fontSize={16}
												color={"black"}
												fontSizeMedium={12}
												fontWeight={"bold"}
											>
												Until cancelled
											</StyleTypography>
										}
									/>
								</Grid>
							</Grid>
						</Grid>
						<PayItemCheckBox
							user={user}
							typeOfPayItem={"New Recurring Pay Item"}
							oneOff={false}
							payItemBasicDetails={payItemBasicDetails}
							payItemRecurringDetails={payItemRecurringDetails}
							setStep={setStep}
							setPayItemBasicDetails={setPayItemBasicDetails}
							setPayItemRecurringDetails={setPayItemRecurringDetails}
							createPayItem={createPayItem}
							updatePayItem={updatePayItem}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default RecurringDetails