import Grid from "@mui/material/Grid";
import React, {useEffect, useReducer, useState} from "react";
import {SickLeaveMain} from "./SickLeaveComponents/SickLeaveMain";
import {SickLeaveDay} from "./SickLeaveComponents/SickLeaveDay";
import {SickLeaveWeeklyEarnings} from "./SickLeaveComponents/SickLeaveWeeklyEarnings";
import {SickLeaveSummary} from "./SickLeaveComponents/SickLeaveSummary";
import {createSickLeaveEmployee, deleteSickLeaveEmployee, reviewSickLeave} from "../../../actions/SickLeave";
import {connect} from "react-redux";
import WarningWhiteIcon from "../../../images/svg/SickLeave/warning-white.svg";
import StyleTypography from "../../StyledComponents/StyleTypography";

function _renderSteps (
	step = 0,
	employee = {},
	leaversEmployees = [],
	sickLeaveDetails = {},
	setStep = () => {},
	setSickLeaveDetails = () => {},
	createSickLeaveEmployee = () => {},
	deleteSickLeaveEmployee = () => {},
	reviewSickLeaveEmployee = () => {},
	waiting_days = 0,
	payable_days = 0,
	days_unavailable = 0,
) {
	switch (step) {
		case 0:
			return (
				<SickLeaveMain
					leaversEmployees={leaversEmployees}
					setStep={setStep}
					deleteSickLeaveEmployee={deleteSickLeaveEmployee}
					employee={employee}
				/>
			);
		case 1:
			return (
				<SickLeaveDay
					employee={employee}
					sickLeaveDetails={sickLeaveDetails}
					setStep={setStep}
					setSickLeaveDetails={setSickLeaveDetails}
				/>
			);
		case 2:
			return (
				<SickLeaveWeeklyEarnings
					employee={employee}
					sickLeaveDetails={sickLeaveDetails}
					setStep={setStep}
					setSickLeaveDetails={setSickLeaveDetails}
					reviewSickLeaveEmployee={reviewSickLeaveEmployee}
				/>
			);
		case 3:
			return (
				<SickLeaveSummary
					employee={employee}
					sickLeaveDetails={sickLeaveDetails}
					setStep={setStep}
					setSickLeaveDetails={setSickLeaveDetails}
					createSickLeaveEmployee={createSickLeaveEmployee}
					waiting_days={waiting_days}
					payable_days={payable_days}
					days_unavailable={days_unavailable}
				/>
			);
		default:
			return;
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const SickLeave = ({
	employee = {},
	createSickLeaveEmployee = () => {},
	deleteSickLeaveEmployee = () => {},
	reviewSickLeaveEmployee = () => {},
	days_unavailable,
	waiting_days,
	payable_days,
	step_end = 0,
}): JSX.Element => {

	const [sickLeaveDetails, setSickLeaveDetails] = useReducer(
		(state, updates) => ({...state, ...updates}),
		{
			first_sick_day: null,
			last_sick_day: null,
			last_sick_day_radio: false,
			average_weekly_earnings: 0.00,
		}
	);
	const [step, setStep] = useState(0);

	useEffect(() => {
		setStep(step_end);
	}, [step_end, payable_days]);

	return (
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			container
			item
			justifyContent={"space-between"}
			id={"sick-leave"}
		>
			{
				employee.on_leave && (
					<Grid
						className={"sick-leave-weekly-earnings-statutory-sick-pay-box"}
						container
						direction={"row"}
						alignItems={"center"}
					>
						<Grid item className={"sick-leave-weekly-earnings-statutory-sick-pay-margin-left"}>
							<img
								className={"sick-leave-weekly-earnings-statutory-sick-pay-img"}
								src={WarningWhiteIcon}
								alt={"WarningWhiteIcon"}
							/>
						</Grid>
						<Grid
							item
						>
							<Grid>
								<StyleTypography
									fontSize={18}
									fontWeight={"bold"}
									fontSizeMedium={13}
									color={"white"}
								>
									{employee?.name} is on leave
								</StyleTypography>
							</Grid>
						</Grid>
					</Grid>
				)
			}
			{
				employee.status === 'Pending Information' ? (
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
						container
						className={"maternity-paternity-container"}
						justifyContent={"space-between"}
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
						>
							<StyleTypography
								fontSize={40}
								fontSizeMedium={30}
								fontWeight={'bold'}
								component={"h1"}
							>
								Please complete the employee's profile, <br/>before setting up sick leave.
							</StyleTypography>
						</Grid>

					</Grid>
				) : (
					_renderSteps(
						step,
						employee,
						employee?.sick_leave,
						sickLeaveDetails,
						setStep,
						setSickLeaveDetails ,
						createSickLeaveEmployee,
						deleteSickLeaveEmployee,
						reviewSickLeaveEmployee,
						waiting_days,
						payable_days,
						days_unavailable,
					)
				)
			}
		</Grid>
	)
}

const mapStateToProps = state => {
	const {
		SickLeave,
	} = state;

	return {
		...SickLeave,
	}
}

const mapDispatchToProps = dispatch => ({
	createSickLeaveEmployee: (data) => dispatch(createSickLeaveEmployee(data)),
	deleteSickLeaveEmployee: (data) => dispatch(deleteSickLeaveEmployee(data)),
	reviewSickLeaveEmployee: (data) => dispatch(reviewSickLeave(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SickLeave)