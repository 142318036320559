import {
	CREATE_LEAD,
	CREATE_LEAD_SUCCESS,
	CREATE_USER,
	CREATE_USER_FAILURE,
	CREATE_USER_SUCCESS,
	SEARCH_COMPANIES,
	SEARCH_DIRECTORS,
	VERIFY_COMPANY,
	VERIFY_EMAIL,
} from "../../actions/SignUp/types";
import {resolveReduxState} from "../../utils/Helpers";

const initial_state = {
	user: {
		full_name: '',
		email: '',
		phone_country_code: '',
		phone_number: '',
		password: '',
	},
	lead: {
		lead_size: '',
		current_payroll: '',
		lead_id: null,
	},
	signup_loading: false,
	signup_error: false,
	signup_error_message: '',
	signup_error_code: '',
	can_proceed: false,
	companies: [],
	directors: [],
}

const SignUp = (state = initial_state, action = false) => {
	if (action) {
		switch(action.type) {

			case CREATE_LEAD:
			case CREATE_LEAD_SUCCESS:
			case CREATE_USER:
			case CREATE_USER_SUCCESS:
			case CREATE_USER_FAILURE:
			case VERIFY_EMAIL:
			case SEARCH_COMPANIES:
			case SEARCH_DIRECTORS:
			case VERIFY_COMPANY:
				return resolveReduxState(state, action);

			default:
				return state;
		}
	}

	return state;
}

export default SignUp;