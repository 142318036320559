import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { StyleAppBar } from "../../components/StyleAppBar";
import { me } from "../../actions/Profile";
import { connect } from "react-redux";
import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import gray from "../../images/svg/aro 1.svg";
import blue from "../../images/svg/aro 2.svg";
import check from "../../images/svg/aro 3.svg";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { LeftMenu } from "../../components/LeftMenu";
import { findEmployer } from "../../actions/Employer";
import { FreepayrollButton } from "@collegia-partners/ui-kit";

function CustomStepIcon(props) {
	const { active, completed } = props;

	if (active) {
		return <img src={blue} alt={"blue"} />;
	} else if (completed) {
		return <img src={check} alt={"check"} />;
	} else {
		return <img src={gray} alt={"gray"} />;
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const OnboardingDashboard = ({
	user = {},
	user_loading = false,
	me = () => {},
	employer = {},
	findEmployer = () => {},
}): JSX.Element => {
	const steps = [
		{
			label: "Step 1",
			optional: (
				<StyleTypography fontSize={20} fontSizeMedium={15} fontWeight={"bold"}>
					Create company details
				</StyleTypography>
			),
			description: "Create your company details",
			link: "/company-details",
		},
		{
			label: "Step 2",
			optional: (
				<StyleTypography fontSize={20} fontSizeMedium={15} fontWeight={"bold"}>
					Basic Setup
				</StyleTypography>
			),
			description: "Payroll dates, HMRC settings and Automatic Enrolment",
			link: "/onboarding/basic-setup",
		},
		{
			label: "Step 3",
			optional: (
				<StyleTypography fontSize={20} fontSizeMedium={15} fontWeight={"bold"}>
					Pay Schedule
				</StyleTypography>
			),
			description: `Let's add your default pay schedule. You will be able to add more later.`,
			link: "/onboarding/pay-schedule",
		},
		{
			label: "Step 4",
			optional: (
				<StyleTypography fontSize={20} fontSizeMedium={15} fontWeight={"bold"}>
					Adding employees
				</StyleTypography>
			),
			description: `Easily set up employees in FreePayroll it will only take a couple of minutes`,
			link: "/onboarding/add-employee-method",
		},
	];

	const push = useNavigate();

	useEffect(() => {
		me();
		findEmployer(["pay_schedules"]);
	}, [findEmployer, me]);

	useEffect(() => {
		if (employer?.onboarding_complete) {
			push("/main/dashboard");
		}

		if (user?.id && !user?.email_verified_at) {
			push("/confirm-email");
		}

		if (!employer?.id) {
			setActiveStep(0);
		} else if (!employer?.basic_setup_complete) {
			setActiveStep(1);
		} else if (employer?.pay_schedules?.length <= 0) {
			setActiveStep(2);
		} else {
			setActiveStep(3);
		}

		document.documentElement.style.setProperty(
			"--scroll-height",
			`${document.body.scrollHeight}`
		);
	}, [employer, push, user?.email_verified_at, user?.id]);

	const [activeStep, setActiveStep] = useState(0);

	if (user_loading) {
		return <Loading />;
	}

	return (
		<Grid container justifyContent={"center"} id={"onboarding-dashboard-page"}>
			<StyleAppBar
				showBackButton={false}
				backLink={"/onboarding/dashboard"}
				user={user}
			/>
			<Grid
				xl={11}
				lg={11}
				md={11}
				sm={11}
				xs={11}
				item
				container
				id={"main-content-container"}
			>
				<Grid xl={3} lg={3} md={3} sm={3} xs={3} item>
					<LeftMenu activePage={"Setup"} isOnboarding={true} />
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "79px",
					}}
				>
					<Grid container>
						<Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
							<Grid>
								<StyleTypography
									fontSize={40}
									fontSizeMedium={31}
									fontWeight={"bold"}
								>
									Your payroll setup roadmap
								</StyleTypography>
							</Grid>
							<Grid className={"sub-title-container"}>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={14}
									color={"#ABAEB7"}
								>
									Let’s finish setting up your payroll. Just a few more steps
									and you’re ready <br />
									to run your first payroll with FreePayroll!
								</StyleTypography>
							</Grid>
						</Grid>
						<Grid id={"stepper-container"}>
							<Stepper activeStep={activeStep} orientation="vertical">
								{steps.map((step, index) => (
									<Step key={step.label}>
										<StepLabel StepIconComponent={CustomStepIcon}>
											{step.optional}
										</StepLabel>
										<StepContent>
											<Grid
												id={"step-content-container"}
												container
												flexWrap={"nowrap"}
												gap={"2.604vw"}
												alignItems={"center"}
											>
												<Grid>
													<StyleTypography
														fontSize={20}
														fontSizeMedium={15}
														color={"#FFFFFF"}
														fontWeight={"bold"}
													>
														{step.description}
													</StyleTypography>
													<StyleTypography
														fontSize={16}
														fontSizeMedium={12}
														color={"#A6B1C9"}
													>
														7 minutes
													</StyleTypography>
												</Grid>
												<Grid className="step-white-button">
													<FreepayrollButton
														variant="white-button"
														onClick={() => push(step.link)}
														size="xsmall"
													>
														Continue
													</FreepayrollButton>
												</Grid>
											</Grid>
										</StepContent>
									</Step>
								))}
							</Stepper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	const { Profile, Employer } = state;

	return {
		...Profile,
		...Employer,
	};
};

const mapDispatchToProps = (dispatch) => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OnboardingDashboard);
