import {StyleAppBar} from "../../components/StyleAppBar";
import Grid from "@mui/material/Grid";
import {LeftMenu} from "../../components/LeftMenu";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import React, {useEffect, useState} from "react";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import {bulkEmployeeImport, bulkEmployeeSave, bulkImportCsv, getPayrollAndEmail} from "../../actions/Employee";
import Loading from "../../components/Loading";
import {MenuItem, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import BulkEmployeesFps from "../../components/People/BulkEmployeesFps";
import BulkEmployeesCsv from "../../components/People/BulkEmployeesCsv";
import {findEmployer} from "../../actions/Employer";
import {Download} from "@mui/icons-material";

const handleDownload = () => {
	const link = document.createElement('a');
	link.href = '/FreePayroll-Template-Final.csv'; // Path to your CSV template in the public folder
	link.download = 'FreePayroll-Template-Final.csv';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

const BulkEmployees = ({
	user = {
		id: 0,
	},
	me = () => {},
	bulkEmployeeImport = () => {},
	add_employee_loading = false,
	bulkEmployeeSave = () => {},
	isOnboarding = false,
	employees_email = [],
	employees_payroll = [],
	getPayrollAndEmail = () => {},
	bulkImportCsv = () => {},
	findEmployer = () => {},
	employer = {},
}): JSX.Element => {
	const [uploadMethod, setUploadMethod] = useState(" ");
	const [uploadedData, setUploadedData] = useState([]);
	const [openFps, setOpenFps] = useState(false);
	const [openCsv, setOpenCsv] = useState(false);
	useEffect(() => {
		me();
		if (uploadedData.length > 0) {
			setOpenFps(true);
		}
		getPayrollAndEmail();
		findEmployer(['pay_schedules']);
	}, [getPayrollAndEmail, me, uploadedData.length, findEmployer]);

	if (add_employee_loading) {
		return <Loading/>
	}
	return (
		<Grid
			container
			justifyContent={"center"}
			id={"main-page"}
		>
			<StyleAppBar
				showBackButton={true}
				backLink={isOnboarding ? "/onboarding/add-employee-method" : "/main/people/add-employee-method"}
				user={user}
				employer={employer}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						isOnboarding={isOnboarding}
						activePage={isOnboarding ? "Setup" : "People"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
						>
							<Grid>
								<StyleTypography
									fontSize={40}
									fontSizeMedium={31}
									fontWeight={"bold"}
									component={"h1"}
								>
									Bulk Add Employee
								</StyleTypography>
							</Grid>
							<Grid
								className={"sub-title-container"}
							>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={14}
									color={"#000000"}
									component={"h2"}
								>
									Here you can add multiple employees in one single go. <br/>
									Please select the method of import first.
								</StyleTypography>
							</Grid>
						</Grid>

						<Grid
							id={"bulk-employees-download-template"}
							container
							alignItems={"center"}
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
						>
							<StyleTypography
								color={"#000"}
								fontSize={16}
								fontSizeMedium={13}
								fontWeight={500}
								className={"download-template"}
								onClick={handleDownload}
							>
								<Download/>
								Click here to download the csv template
							</StyleTypography>
						</Grid>

						<Grid
							item
							xs={5}
							sm={5}
							md={5}
							lg={5}
							xl={5}
							marginTop={2}
						>
							<FormControl
								fullWidth
							>
								<Select
									className={"default-select"}
									id={"select-current-provider"}
									value={uploadMethod}
									onChange={(event) => {
										setUploadMethod(event.target.value);
										if (event.target.value === "csv") {
											setOpenCsv(true);
											setOpenFps(false);
											setUploadMethod(" ");
										} else {
											setOpenFps(true);
											setOpenCsv(false);
										}
									}}
								>
									<MenuItem
										value={" "}
										disabled
									>
										Select
									</MenuItem>
									<MenuItem
										value={"fps_ytd"}
									>
										Import employees and YTD figures from FPS
									</MenuItem>
									<MenuItem
										value={"fps"}
									>
										Import employees from FPS and disregard YTD figures
									</MenuItem>
									<MenuItem
										value={"csv"}
									>
										Import employees from CSV
									</MenuItem>
								</Select>
							</FormControl>
							<BulkEmployeesFps
								isOnboarding={isOnboarding}
								openFps={openFps}
								setOpenFps={setOpenFps}
								uploadedData={uploadedData}
								uploadMethod={uploadMethod}
								bulkEmployeeSave={bulkEmployeeSave}
								bulkEmployeeImport={bulkEmployeeImport}
								setUploadedData={setUploadedData}
								setUploadMethod={setUploadMethod}
							/>
							<BulkEmployeesCsv
								openCsv={openCsv}
								setOpenCsv={setOpenCsv}
								employeesEmail={employees_email}
								employeePayroll={employees_payroll}
								bulkImportCsv={bulkImportCsv}
								isOnboarding={isOnboarding}
								employer={employer}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Employees,
		Employer,
	} = state;

	return {
		...Profile,
		...Employees,
		...Employer,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	findEmployer: (relations) => dispatch(findEmployer(relations)),
	bulkEmployeeImport: (file, setUploadedData) => dispatch(bulkEmployeeImport(file, setUploadedData)),
	bulkEmployeeSave: (data, isMainPage, setSubmissionErrors, push) => dispatch(bulkEmployeeSave(data, isMainPage, setSubmissionErrors, push)),
	bulkImportCsv: (data, isMainPage, push) => dispatch(bulkImportCsv(data, isMainPage, push)),
	getPayrollAndEmail: () => dispatch(getPayrollAndEmail()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkEmployees);