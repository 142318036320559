import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {Divider, InputLabel, MenuItem, Select} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import {CurrencyInputCustom} from "../../../StyledComponents/CurrencyInputCustom";
import {useState} from "react";


function renderCategoryInput({ label, value, onChange, show = true }) {
	return (
		<Grid xl={4} lg={4} md={4} sm={12} xs={12} item hidden={!show}>
			<InputLabel className={"default-input-label"}>
				{label}
			</InputLabel>
			<TextField
				fullWidth
				className={"default-text-field"}
				InputProps={{
					endAdornment: "£",
					inputComponent: CurrencyInputCustom,
					inputProps: {prefix: ""},
				}}
				value={value}
				onChange={onChange}
			/>
		</Grid>
	);
}


export const ExistingEmployeeWizard = ({
	ni_categories,
	employeeInformation,
	setEmployeeInformation,
	nicCategoryValues,
	setNicCategoryValues,
}): JSX.Element => {

	const [show, setShow] = useState(false);

	const regexTaxCode = /^(?:[CS])?(([1-9][0-9]{0,5}[LMNPTY])|(BR)|(0T)|(NT)|(D[0-8])|([K][1-9][0-9]{0,5}))$/;
	return (
		<>
			{/*Tax details year to date*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				spacing={4}
			>
				{/*Section title*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={15}
						color={"#000000"}
						fontWeight={600}
					>
						Tax details for the year to date
					</StyleTypography>
					<Divider/>
				</Grid>

				{/*Tax Information*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					container
					spacing={4}
				>
					{/*Tax Code*/}
					<Grid
						xl={4}
						lg={4}
						md={4}
						sm={12}
						xs={12}
						item
					>
						<InputLabel
							className={"default-input-label"}
						>
							Tax Code*
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							onChange={(event) => {
								setEmployeeInformation({
									...employeeInformation,
									tax_code: event.target.value,
								});
							}}
							error={!regexTaxCode.test(employeeInformation.tax_code)}
							value={employeeInformation.tax_code}
						/>
					</Grid>

					{/*Tax Basis*/}
					<Grid
						xl={true}
						lg={true}
						md={true}
						sm={12}
						xs={12}
						item
						container
					>
						<InputLabel
							className={"default-input-label"}
						>
							Tax Basis*
						</InputLabel>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
						>
							<RadioGroup
								row
								onChange={(event) => {
									setEmployeeInformation({
										...employeeInformation,
										is_cumulative: event.target.value,
									});
								}}
								value={employeeInformation.is_cumulative}
							>
								<FormControlLabel
									value={'true'}
									control={<Radio />}
									label={
										<StyleTypography
											fontSize={18}
											fontSizeMedium={13}
											color={'#000000'}
										>
											Cumulative
										</StyleTypography>
									}
								/>
								<FormControlLabel
									value={'false'}
									control={<Radio />}
									label={
										<StyleTypography
											fontSize={18}
											fontSizeMedium={13}
											color={'#000000'}
										>
											Non-Cumulative (W1/M1)
										</StyleTypography>
									}
								/>
							</RadioGroup>
						</Grid>
					</Grid>
				</Grid>

				{/*Current employment taxable pay and tax*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					container
					spacing={4}
				>
					{/*Current Employment Taxable pay*/}
					<Grid
						xl={4}
						lg={4}
						md={4}
						sm={12}
						xs={12}
						item
					>
						<InputLabel
							className={"default-input-label"}
						>
							Current Employment Taxable Pay YTD*
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							onChange={(event) => {
								setEmployeeInformation({
									...employeeInformation,
									ytd_figure: {
										...employeeInformation.ytd_figure,
										gross_for_tax_ytd: event.target.value,
									},
								});
							}}
							InputProps={{
								endAdornment: "£",
								inputComponent: CurrencyInputCustom,
								inputProps: {prefix: ""},
							}}
							value={employeeInformation.ytd_figure?.gross_for_tax_ytd}
						/>
					</Grid>

					{/*Current Employment Tax paid YTD*/}
					<Grid
						xl={4}
						lg={4}
						md={4}
						sm={12}
						xs={12}
						item
					>
						<InputLabel
							className={"default-input-label"}
						>
							Current Employment Tax Paid YTD*
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							onChange={(event) => {
								setEmployeeInformation({
									...employeeInformation,
									ytd_figure: {
										...employeeInformation.ytd_figure,
										tax_deducted_ytd: event.target.value,
									},
								});
							}}
							InputProps={{
								endAdornment: "£",
								inputComponent: CurrencyInputCustom,
								inputProps: {prefix: ""},
							}}
							value={employeeInformation.ytd_figure?.tax_deducted_ytd}
						/>
					</Grid>
				</Grid>

			</Grid>

			{/*National Insurance details for the year to date*/}

			{
				nicCategoryValues.map((category, index) => {
					return (
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
							spacing={4}
						>

							{/*Section title*/}
							<Grid
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								item
								container
								justifyContent={"space-between"}
							>
								<Grid>
									<StyleTypography
										fontSize={18}
										fontSizeMedium={15}
										fontWeight={600}
										color={"#000000"}
									>
										National insurance details for the year to date
									</StyleTypography>
								</Grid>
								{
									index !== 0 && (
										<Grid
											onClick={() => {
												setNicCategoryValues(nicCategoryValues.filter((item, i) => i !== index));
											}}
										>
											Remove Category
										</Grid>
									)
								}
								{
									index === 0 && (
										<Grid
											onClick={() => {
												setNicCategoryValues([
													...nicCategoryValues,
													{
														national_insurance_category: " ",
														gross_pay_for_nic_ytd: "",
														earnings_at_lel_ytd: "",
														earnings_to_pt_ytd: "",
														earnings_to_uel_ytd: "",
														employee_nic_ytd: "",
														employer_nic_ytd: "",
														directorDeclaration: " ",
														director_earnings_ytd: "",
														director_at_lel_ytd: "",
														director_to_pt_ytd: "",
														director_to_uel_ytd: "",
														director_nic_ytd: "",
														director_employer_nic_ytd: "",
													}
												]);
											}}
										>
											Add Category
										</Grid>
									)
								}
								<Grid xs={12} item>
									<Divider/>
								</Grid>
							</Grid>

							{/*Director declaration*/}
							<Grid
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								item
								container
								display={employeeInformation.is_director ? "flex" : "none"}
							>
								<InputLabel
									className={"default-input-label"}
								>
									Director declaration*
								</InputLabel>
								<FormControl
									fullWidth
								>
									<Select
										className={"default-select"}
										id={"select-title"}
										MenuProps={{
											PaperProps: {
												style: {
													maxHeight: 200, // Adjust the maximum height of the list
													maxWidth: 300,
													overflowX: 'scroll',
												},
											},
										}}
										value={category.directorDeclaration}
										onChange={(event) => {
											setNicCategoryValues(nicCategoryValues.map((item, i) => {
												if (i === index) {
													return {
														...item,
														directorDeclaration: event.target.value,
													}
												}
												return item;
											}));
										}}
									>
										<MenuItem
											key={'select-title'}
											value={" "}
											disabled
											sx={{
												fontSize: '0.9028vw'
											}}
										>
											Select
										</MenuItem>
										<MenuItem
											key={'directorSinceBeginning'}
											value={"directorSinceBeginning"}
											sx={{
												fontSize: '0.9028vw'
											}}
										>
											This employee has been a director since the
											beginning of the current tax year
										</MenuItem>
										<MenuItem
											key={'directorSinceMidYear'}
											value={"directorSinceMidYear"}
											sx={{
												fontSize: '0.9028vw'
											}}
										>
											This employee became a director during the current tax year,
											after being paid as an employee
										</MenuItem>
										<MenuItem
											key={'directorCeasedMidYear'}
											value={"directorCeasedMidYear"}
											sx={{
												fontSize: '0.9028vw'
											}}
										>
											This employee has served as a director since the start of the current
											tax year and concluded their directorship within the same tax year,
											yet remains employed.
										</MenuItem>
										<MenuItem
											key={'directorSinceMidYearCeasedMidYear'}
											value={"directorSinceMidYearCeasedMidYear"}
											sx={{
												fontSize: '0.9028vw'
											}}
										>
											This employee was initially paid as an employee, then became a
											director within the same tax year, concluded their
											directorship in the same tax year, but continues to be employed.
										</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							{/*NI Category*/}
							<Grid
								xl={4}
								lg={4}
								md={4}
								sm={12}
								xs={12}
								item
							>
								<InputLabel
									className={"default-input-label"}
								>
									NI Category*
								</InputLabel>
								<FormControl
									fullWidth
								>
									<Select
										className={"default-select"}
										id={"select-title"}
										MenuProps={{
											PaperProps: {
												style: {
													maxHeight: 200, // Adjust the maximum height of the list
													maxWidth: 300,
													overflowX: 'scroll',
												},
											},
										}}
										value={category.national_insurance_category}
										onChange={(event) => {
											setNicCategoryValues(nicCategoryValues.map((item, i) => {
												if (i === index) {
													return {
														...item,
														national_insurance_category: event.target.value,
													}
												}
												return item;
											}));
										}}
									>
										<MenuItem
											key={'select-title'}
											value={" "}
											disabled
											sx={{
												fontSize: '0.9028vw'
											}}
										>
											Select
										</MenuItem>
										{
											ni_categories?.map((ni_category) => {
												return (
													<MenuItem
														key={ni_category.id}
														value={ni_category.category}
														sx={{
															fontSize: '0.9028vw'
														}}
														disabled={
															nicCategoryValues.some((item, i) => {
																if (i !== index) {
																	return item.national_insurance_category === ni_category.category;
																}
																return false;
															})
														}
													>
														{ni_category.category} - {ni_category.description}
													</MenuItem>
												)
											})
										}
									</Select>
								</FormControl>
							</Grid>

							{/*Gross Earnings for NI YTD*/}
							{
								renderCategoryInput({
									label: "Gross earnings for NIC YTD*",
									value: category.gross_pay_for_nic_ytd,
									onChange: (event) => {
										setNicCategoryValues(nicCategoryValues.map((item, i) => {
											if (i === index) {
												return {
													...item,
													gross_pay_for_nic_ytd: event.target.value,
												}
											}
											return item;
										}));
									}
								})
							}

							{/*Earnings at LEL YTD*/}
							{
								renderCategoryInput({
									label: "Earnings at LEL YTD*",
									value: category.earnings_at_lel_ytd,
									onChange: (event) => {
										setNicCategoryValues(nicCategoryValues.map((item, i) => {
											if (i === index) {
												return {
													...item,
													earnings_at_lel_ytd: event.target.value,
												}
											}
											return item;
										}));
									}
								})
							}

							{/*Earnings at PT YTD*/}
							{
								renderCategoryInput({
									label: "Earnings at PT YTD*",
									value: category.earnings_to_pt_ytd,
									onChange: (event) => {
										setNicCategoryValues(nicCategoryValues.map((item, i) => {
											if (i === index) {
												return {
													...item,
													earnings_to_pt_ytd: event.target.value,
												}
											}
											return item;
										}));
									}
								})
							}

							{/*Earnings at UEL YTD*/}
							{
								renderCategoryInput({
									label: "Earnings at UEL YTD*",
									value: category.earnings_to_uel_ytd,
									onChange: (event) => {
										setNicCategoryValues(nicCategoryValues.map((item, i) => {
											if (i === index) {
												return {
													...item,
													earnings_to_uel_ytd: event.target.value,
												}
											}
											return item;
										}));
									}
								})
							}

							{/*Employee NIC YTD*/}
							{
								renderCategoryInput({
									label: "Employee NIC YTD*",
									value: category.employee_nic_ytd,
									onChange: (event) => {
										setNicCategoryValues(nicCategoryValues.map((item, i) => {
											if (i === index) {
												return {
													...item,
													employee_nic_ytd: event.target.value,
												}
											}
											return item;
										}));
									}
								})
							}

							{/*Employer NIC YTD*/}
							{
								renderCategoryInput({
									label: "Employer NIC YTD*",
									value: category.employer_nic_ytd,
									onChange: (event) => {
										setNicCategoryValues(nicCategoryValues.map((item, i) => {
											if (i === index) {
												return {
													...item,
													employer_nic_ytd: event.target.value,
												}
											}
											return item;
										}));
									}
								})
							}

							<Grid xs={12} item>
								<Divider/>
							</Grid>

							{/*Director Earnings YTD*/}
							{
								renderCategoryInput({
									label: "Director Earnings YTD*",
									value: category.director_earnings_ytd,
									onChange: (event) => {
										setNicCategoryValues(nicCategoryValues.map((item, i) => {
											if (i === index) {
												return {
													...item,
													director_earnings_ytd: event.target.value,
												}
											}
											return item;
										}));
									},
									show: (
										category.directorDeclaration !== "directorSinceBeginning" &&
										category.directorDeclaration !== "directorCeasedMidYear"
									) && category.directorDeclaration !== " "
								})
							}

							{/*Director Earnings at LEL YTD*/}
							{
								renderCategoryInput({
									label: "Director Earnings at LEL YTD*",
									value: category.director_at_lel_ytd,
									onChange: (event) => {
										setNicCategoryValues(nicCategoryValues.map((item, i) => {
											if (i === index) {
												return {
													...item,
													director_at_lel_ytd: event.target.value,
												}
											}
											return item;
										}));
									},
									show: (
										category.directorDeclaration !== "directorSinceBeginning" &&
										category.directorDeclaration !== "directorCeasedMidYear"
									) && category.directorDeclaration !== " "
								})
							}

							{/*Director Earnings at PT YTD*/}
							{
								renderCategoryInput({
									label: "Director Earnings at PT YTD*",
									value: category.director_to_pt_ytd,
									onChange: (event) => {
										setNicCategoryValues(nicCategoryValues.map((item, i) => {
											if (i === index) {
												return {
													...item,
													director_to_pt_ytd: event.target.value,
												}
											}
											return item;
										}));
									},
									show: (
										category.directorDeclaration !== "directorSinceBeginning" &&
										category.directorDeclaration !== "directorCeasedMidYear"
									) && category.directorDeclaration !== " "
								})
							}

							{/*Director Earnings at UEL YTD*/}
							{
								renderCategoryInput({
									label: "Director Earnings at UEL YTD*",
									value: category.director_to_uel_ytd,
									onChange: (event) => {
										setNicCategoryValues(nicCategoryValues.map((item, i) => {
											if (i === index) {
												return {
													...item,
													director_to_uel_ytd: event.target.value,
												}
											}
											return item;
										}));
									},
									show: (
										category.directorDeclaration !== "directorSinceBeginning" &&
										category.directorDeclaration !== "directorCeasedMidYear"
									) && category.directorDeclaration !== " "
								})
							}

							{/*Director NIC YTD*/}
							{
								renderCategoryInput({
									label: "Director NIC YTD*",
									value: category.director_nic_ytd,
									onChange: (event) => {
										setNicCategoryValues(nicCategoryValues.map((item, i) => {
											if (i === index) {
												return {
													...item,
													director_nic_ytd: event.target.value,
												}
											}
											return item;
										}));
									},
									show: (
										category.directorDeclaration !== "directorSinceBeginning" &&
										category.directorDeclaration !== "directorCeasedMidYear"
									) && category.directorDeclaration !== " "
								})
							}

							{/*Director Employer NIC YTD*/}
							{
								renderCategoryInput({
									label: "Director Employer NIC YTD*",
									value: category.director_employer_nic_ytd,
									onChange: (event) => {
										setNicCategoryValues(nicCategoryValues.map((item, i) => {
											if (i === index) {
												return {
													...item,
													director_employer_nic_ytd: event.target.value,
												}
											}
											return item;
										}));
									},
									show: (
										category.directorDeclaration !== "directorSinceBeginning" &&
										category.directorDeclaration !== "directorCeasedMidYear"
									) && category.directorDeclaration !== " "
								})
							}

						</Grid>
					);
				})
			}

			{/*Pension contributions and benefits for the year to date*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				spacing={4}
			>
				{/*Section title*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={15}
						color={"#000000"}
						fontWeight={600}
					>
						Pension contributions and payrolled benefits for the year to date
					</StyleTypography>
					<Divider/>
				</Grid>

				{/*Employee pension contributions YTD*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Employee pension contributions YTD*
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								ytd_figure: {
									...employeeInformation.ytd_figure,
									employee_pension_ytd: event.target.value,
								},
							});
						}}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: {prefix: ""},
						}}
						value={employeeInformation.ytd_figure?.employee_pension_ytd}
					/>
				</Grid>

				{/*Payrolled benefits*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Payrolled benefits YTD*
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								ytd_figure: {
									...employeeInformation.ytd_figure,
									bik_payrolled_amount_ytd: event.target.value,
								},
							});
						}}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: {prefix: ""},
						}}
						value={employeeInformation.ytd_figure?.bik_payrolled_amount_ytd}
					/>
				</Grid>
			</Grid>

			{/*Current Employment Statutory and Loans*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				spacing={4}
			>
				{/*Section title*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={15}
						color={"#000000"}
						fontWeight={600}
					>
						Current employment statutory payments and loans for the year to date
					</StyleTypography>
					<Divider/>
				</Grid>

				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={15}
						fontWeight={400}
						color={"#000000"}
					>
						Has the employee been paid statutory or student loans ?
					</StyleTypography>
					<RadioGroup
						row
						onChange={(e) => {
							setShow(e.target.value === "true")
						}}
					>
						<FormControlLabel value={true} control={<Radio />} label={"Yes"} />
						<FormControlLabel value={false} control={<Radio />} label={"No"} />
					</RadioGroup>
				</Grid>

				{
					show && (
						<>
							{/* Total Statutory Sick Pay*/}
							<Grid
								xl={4}
								lg={4}
								md={4}
								sm={12}
								xs={12}
								item
							>
								<InputLabel
									className={"default-input-label"}
								>
									Total Statutory Sick Pay
								</InputLabel>
								<TextField
									fullWidth
									className={"default-text-field"}
									onChange={(event) => {
										setEmployeeInformation({
											...employeeInformation,
											ytd_figure: {
												...employeeInformation.ytd_figure,
												ssp_ytd: event.target.value,
											},
										});
									}}
									InputProps={{
										endAdornment: "£",
										inputComponent: CurrencyInputCustom,
										inputProps: {prefix: ""},
									}}
									value={employeeInformation.ytd_figure?.ssp_ytd}
								/>
							</Grid>

							{/*Total Statutory Maternity Pay*/}
							<Grid
								xl={4}
								lg={4}
								md={4}
								sm={12}
								xs={12}
								item
							>
								<InputLabel
									className={"default-input-label"}
								>
									Total Statutory Maternity Pay
								</InputLabel>
								<TextField
									fullWidth
									className={"default-text-field"}
									onChange={(event) => {
										setEmployeeInformation({
											...employeeInformation,
											ytd_figure: {
												...employeeInformation.ytd_figure,
												smp_ytd: event.target.value,
											},
										});
									}}
									InputProps={{
										endAdornment: "£",
										inputComponent: CurrencyInputCustom,
										inputProps: {prefix: ""},
									}}
									value={employeeInformation.ytd_figure?.smp_ytd}
								/>
							</Grid>

							{/*Total Statutory Paternity Pay*/}
							<Grid
								xl={4}
								lg={4}
								md={4}
								sm={12}
								xs={12}
								item
							>
								<InputLabel
									className={"default-input-label"}
								>
									Total Statutory Paternity Pay
								</InputLabel>
								<TextField
									fullWidth
									className={"default-text-field"}
									onChange={(event) => {
										setEmployeeInformation({
											...employeeInformation,
											ytd_figure: {
												...employeeInformation.ytd_figure,
												spp_ytd: event.target.value,
											},
										});
									}}
									InputProps={{
										endAdornment: "£",
										inputComponent: CurrencyInputCustom,
										inputProps: {prefix: ""},
									}}
									value={employeeInformation.ytd_figure?.spp_ytd}
								/>
							</Grid>

							{/*Total Shared Parental Pay*/}
							<Grid
								xl={4}
								lg={4}
								md={4}
								sm={12}
								xs={12}
								item
							>
								<InputLabel
									className={"default-input-label"}
								>
									Total Shared Parental Pay
								</InputLabel>
								<TextField
									fullWidth
									className={"default-text-field"}
									onChange={(event) => {
										setEmployeeInformation({
											...employeeInformation,
											ytd_figure: {
												...employeeInformation.ytd_figure,
												shpp_ytd: event.target.value,
											},
										});
									}}
									InputProps={{
										endAdornment: "£",
										inputComponent: CurrencyInputCustom,
										inputProps: {prefix: ""},
									}}
									value={employeeInformation.ytd_figure?.shpp_ytd}
								/>
							</Grid>

							{/*Total Statutory Adoption Pay*/}
							<Grid
								xl={4}
								lg={4}
								md={4}
								sm={12}
								xs={12}
								item
							>
								<InputLabel
									className={"default-input-label"}
								>
									Total Statutory Adoption Pay
								</InputLabel>
								<TextField
									fullWidth
									className={"default-text-field"}
									onChange={(event) => {
										setEmployeeInformation({
											...employeeInformation,
											ytd_figure: {
												...employeeInformation.ytd_figure,
												sap_ytd: event.target.value,
											},
										});
									}}
									InputProps={{
										endAdornment: "£",
										inputComponent: CurrencyInputCustom,
										inputProps: {prefix: ""},
									}}
									value={employeeInformation.ytd_figure?.sap_ytd}
								/>
							</Grid>

							{/*Total Statutory Parental Bereavement Pay*/}
							<Grid
								xl={4}
								lg={4}
								md={4}
								sm={12}
								xs={12}
								item
							>
								<InputLabel
									className={"default-input-label"}
								>
									Total Statutory Parental Bereavement Pay
								</InputLabel>
								<TextField
									fullWidth
									className={"default-text-field"}
									onChange={(event) => {
										setEmployeeInformation({
											...employeeInformation,
											ytd_figure: {
												...employeeInformation.ytd_figure,
												spbp_ytd: event.target.value,
											},
										});
									}}
									InputProps={{
										endAdornment: "£",
										inputComponent: CurrencyInputCustom,
										inputProps: {prefix: ""},
									}}
									value={employeeInformation.ytd_figure?.spbp_ytd}
								/>
							</Grid>

							{/*Total student loan deductions*/}
							<Grid
								xl={4}
								lg={4}
								md={4}
								sm={12}
								xs={12}
								item
							>
								<InputLabel
									className={"default-input-label"}
								>
									Total student loan deductions
								</InputLabel>
								<TextField
									fullWidth
									className={"default-text-field"}
									onChange={(event) => {
										setEmployeeInformation({
											...employeeInformation,
											ytd_figure: {
												...employeeInformation.ytd_figure,
												student_loan_ytd: event.target.value,
											},
										});
									}}
									InputProps={{
										endAdornment: "£",
										inputComponent: CurrencyInputCustom,
										inputProps: {prefix: ""},
									}}
									value={employeeInformation.ytd_figure?.student_loan_ytd}
								/>
							</Grid>

							{/*Total post grad loan deductions*/}
							<Grid
								xl={4}
								lg={4}
								md={4}
								sm={12}
								xs={12}
								item
							>
								<InputLabel
									className={"default-input-label"}
								>
									Total postgraduate loan deductions
								</InputLabel>
								<TextField
									fullWidth
									className={"default-text-field"}
									onChange={(event) => {
										setEmployeeInformation({
											...employeeInformation,
											ytd_figure: {
												...employeeInformation.ytd_figure,
												pg_loan_ytd: event.target.value,
											},
										});
									}}
									InputProps={{
										endAdornment: "£",
										inputComponent: CurrencyInputCustom,
										inputProps: {prefix: ""},
									}}
									value={employeeInformation.ytd_figure?.pg_loan_ytd}
								/>
							</Grid>
						</>
					)
				}
			</Grid>

		</>
	)
}