import Grid from "@mui/material/Grid";
import arrow from "../images/svg/arrow-diagonal.svg";
import MuiTypography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { login, selectAccount } from "../actions/Profile";
import Loading from "../components/Loading";
import {
	FreepayrollSegmentedControl,
	Typography,
} from "@collegia-partners/ui-kit";
import NewTextField from "../components/common/NewTextField";
import DialogContent from "@mui/material/DialogContent";
import StyleTable from "../components/StyledComponents/StyleTable";
import Dialog from "@mui/material/Dialog";
import LogoImage from "../images/svg/Logos/fp-white-symbol.svg";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const Login = ({
	login = () => {},
	login_loading = false,
	login_error = false,
	selectAccount = () => {},
}): JSX.Element => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [open, setOpen] = useState(false);
	const [accounts, setAccounts] = useState([]);
	const [userNotLogged, setUserNotLogged] = useState({});

	const columns = [
		{
			name: "employer.company_name",
			label: "Account",
			width: 200,
		},

		{
			name: "employer.id",
			label: "Action",
			options: {
				customBodyRender: (value) => (
					<Button
						className={"default-black-button"}
						variant={"contained"}
						fullWidth
						onClick={() => {
							selectAccount({
								users: {
									acc_id: value,
									user_id: userNotLogged?.id,
								},
							});
						}}
					>
						Login
					</Button>
				),
			},
		},
	];

	useEffect(() => {
		document.documentElement.style.setProperty(
			"--scroll-height",
			`${document.body.scrollHeight}`
		);
		if (searchParams.get("token") !== null) {
			localStorage.removeItem("fpEmployerToken");
			setTimeout(() => {
				localStorage.setItem("fpEmployerToken", searchParams.get("token"));
				if (searchParams.get("payrollId") !== null) {
					window.location.href = "/main/run-payroll/" + searchParams.get("payrollId");
				} else {
					window.location.href = "/main";
				}
			}, 500);
		}
		setOpen(accounts.length > 0);
	}, [accounts, navigate, searchParams]);

	if (login_loading || searchParams.get("token") !== null) {
		return <Loading />;
	}

	return (
		<Grid xs={12} md={12} lg={12} xl={12} sm={12} container item>
			<Grid
				id={"login-image"}
				alignItems={"center"}
				justifyContent={"center"}
				direction={"column"}
				xs={12}
				sm={12}
				md={6}
				lg={6}
				xl={5.9}
				item
				container
			>
				<img src={LogoImage} alt={"Logo"} className={"logo"} />
			</Grid>

			<Grid
				xs={12}
				sm={12}
				md={6}
				lg={6}
				xl={6.1}
				className={"login-container"}
				alignContent={"flex-start"}
				container
				item
			>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className={"inner-login-container"}
					item
					container
					justifyContent={"center"}
				>
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
						container
						justifyContent={"center"}
						direction={"column"}
						style={{ textAlign: "center" }}
						gap={1}
					>
						<Typography variant="title" size="xlarge">
							Welcome to FreePayroll
						</Typography>
						<Typography variant="body" size="medium" color="black">
							Please use you FreePayroll or Collegia Pension credentials to
							<br />
							log in to your account.
						</Typography>
					</Grid>

					<Grid
						id={"login-form-container"}
						container
						item
						xl={9}
						lg={9}
						md={9}
						sm={9}
						xs={12}
					>
						<div
							style={{
								width: "100%",
								marginLeft: "auto",
								marginRight: "auto",
								marginBottom: "1.041vw",
							}}
						>
							<FreepayrollSegmentedControl
								size="small"
								options={[
									{ label: "Employer", value: "employer" },
									{ label: "Bureau", value: "bureau" },
								]}
								isDisabled
							/>
						</div>

						<NewTextField
							autoComplete={"new-password"}
							placeholder={"Email"}
							error={login_error}
							onChange={(event) => {
								setEmail(event.target.value.toLowerCase());
							}}
							value={email}
						/>

						<NewTextField
							type={"password"}
							placeholder={"Password"}
							error={login_error}
							onChange={(event) => setPassword(event.target.value)}
							InputProps={{
								endAdornment: <a href={"/forgot-password"}>Forgot?</a>,
							}}
							helperText={login_error && "Invalid email or password"}
						/>

						<FormControlLabel
							className={"remember-me"}
							control={<Switch />}
							label={"Remember this account"}
						/>

						<Button
							className={"default-black-button"}
							variant={"contained"}
							fullWidth
							disabled={!email || !password}
							onClick={() => {
								login(
									{
										users: {
											email,
											password,
										},
									},
									setAccounts,
									setUserNotLogged
								);
							}}
						>
							Login
						</Button>

						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							textAlign={"center"}
						>
							<MuiTypography
								className={"not-a-customer-yet"}
								onClick={() => navigate("/sign-up")}
							>
								I'm not a customer yet
								<img src={arrow} alt={"diagonal arrow"} />
							</MuiTypography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Dialog
				onClose={() => {
					setOpen(false);
				}}
				open={open}
				fullWidth={true}
			>
				<DialogContent>
					<StyleTable
						data={accounts || []}
						columns={columns}
						title={"Accounts"}
					/>
				</DialogContent>
			</Dialog>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	const { Profile } = state;

	return {
		...Profile,
	};
};

const mapDispatchToProps = (dispatch) => ({
	login: (data, setAccounts, setUserNotLogged) =>
		dispatch(login(data, setAccounts, setUserNotLogged)),
	selectAccount: (data) => dispatch(selectAccount(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
