import { GET_TUTORIALS } from "../../actions/Tutorials/types";

const initial_state = {
	tutorials: [],
};

const Tutorial = (state = initial_state, action) => {
	switch (action.type) {
		case GET_TUTORIALS:
			return {
				...state,
				tutorials: action.tutorials,
			};

		default:
			return state;
	}
};

export default Tutorial;
