import React from 'react';
import TextField from "@mui/material/TextField";

const StyleTextField = ({
	color = '#fff',
	backgroundColor = '#F4F6FB',
	onChange,
	error = false,
	fontSize = '0.94vw',
	helperText = '',
	height = '2.5vw',
	value = '',
}) => {

	const styles = {
		backgroundColor,
		color,
		borderRadius: 0,
		border: error ? '0.5px solid red' : 'none',
	};


	return (
		<TextField
			style={styles}
			onChange={onChange}
			value={value}
			InputProps={{
				style: {
					borderRadius: 0,
					fontSize,
					height,
				},
			}}
			sx={{
				'& .MuiOutlinedInput-notchedOutline': {
					border: error ? '0.5px solid red' : 'none',  // Conditionally set border based on error state
				},
				'&:hover .MuiOutlinedInput-notchedOutline': {
					border: error ? '0.5px solid red' : 'none',  // Keep border on hover if error state is true
				},
				'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
					border: error ? '0.5px solid red' : 'none',  // Keep border when focused if error state is true
				},
			}}
			error={error}
			helperText={helperText}
		/>
	);
};

export default StyleTextField;