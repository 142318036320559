import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {me} from "../../actions/Profile";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Loading from "../../components/Loading";
import Divider from "@mui/material/Divider";
import House from "../../images/svg/hmrc-page/black-house.svg";
import {Chip} from "@mui/material";
import StyleButton from "../../components/StyledComponents/StyleButton";
import {createOrRetrieveToken, getMandateInfo} from "../../actions/BanksMandates";
import {CreateNewMandateModal} from "../../components/Pages/AutomaticEnrolment/CreateMandateModal";
import {GET_MANDATE_INFO} from "../../actions/BanksMandates/types";
import closedEye from '../../images/svg/closed-eye.svg';
import openEye from '../../images/svg/open-eye.svg';

const renderGrids = (component1, component2) => {
	return (
		<Grid
			container
			alignItems={"center"}
			justifyContent={"space-between"}
			className={"information-item-grid"}
		>
			<Grid>
				{component1}
			</Grid>
			<Grid>
				{component2}
			</Grid>
		</Grid>
	);
}

function retrieveMandateStatus(status) {
	switch (status) {
		case "CANCELLED":
			return "Cancelled";
		case "READY_FOR_EXPORT":
		case "EXPORTED":
		case "ACTIVE":
			return "Active";
		default:
			return "N/A";
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const AutomaticEnrolmentDdi = ({
	me = () => {},
	user = {},
	user_loading = false,
	mandate_info = {},
	mandate_loading = false,
	getMandateInfo = () => {},
	createOrRetrieveToken = () => {},
	token_loading,
}): JSX.Element => {

	const [showAccountNumber, setShowAccountNumber] = useState(false);
	const [showSortCode, setShowSortCode] = useState(false);
	const [showCreateMandateModal, setShowCreateMandateModal] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!user.id) {
			me();
		}

		getMandateInfo();

		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [me, user, getMandateInfo]);

	useEffect(() => {
		let timeout;
		if (token_loading) {
			timeout = setInterval(() => {
				getMandateInfo();
			}, 2000);
		}
		return () => clearTimeout(timeout);
	}, [getMandateInfo, token_loading]);

	if (token_loading) {
		return (
			<Grid
				container
				justifyContent={"center"}
				alignItems={"center"}
				direction={"column"}
			>
				<Grid
					item
					justifyContent={"center"}
					marginTop={"10%"}
				>
					<Grid marginBottom={4}>
						<StyleTypography
							fontSize={32}
							fontSizeMedium={24}
							fontWeight={"bold"}
						>
							If you already signed your mandate, please wait 10 seconds. If you are not
							<br/>redirected automatically, please <a href={"/main/auto-enrolment-ddi"}>click here.</a>
						</StyleTypography>
					</Grid>
					<StyleTypography
						fontSize={32}
						fontSizeMedium={24}
						fontWeight={"bold"}
						color={'red'}
					>
						If you don't want to complete this process now, please
						<span
							style={{cursor: "pointer", marginLeft: "10px", textDecoration: "underline"}}
							onClick={() => dispatch({
								type: GET_MANDATE_INFO,
								token_loading: false,
							})}
						>
							click here.
						</span>
					</StyleTypography>

				</Grid>
				<Loading/>
			</Grid>
		)
	}

	if (user_loading || mandate_loading) {
		return <Loading/>
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"auto-enrolment-page"}
		>
			<CreateNewMandateModal
				open={showCreateMandateModal}
				setOpen={setShowCreateMandateModal}
				createOrRetrieveToken={createOrRetrieveToken}
			/>
			<StyleAppBar
				showBackButton={true}
				backLink={"/main/auto-enrolment-menu"}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Auto Enrolment"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							justifyContent={"space-between"}
							alignItems={"center"}
						>
							<StyleTypography
								fontSize={40}
								fontSizeMedium={30}
								fontWeight={"bold"}
							>
								Direct debit instruction
							</StyleTypography>
							<Grid
								sx={{
									paddingTop: "10px",
									paddingBottom: "30px"
								}}
							>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={"#ABAEB7"}
								>
									Create or view your direct debit instruction
								</StyleTypography>
							</Grid>
						</Grid>
						<Divider className={"divider"}/>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
							className={"information-container"}
							direction={"row"}
						>
							{/*Show button when mandate is cancelled*/}
							{
								mandate_info?.mandateInfo?.status === "CANCELLED" && (
									<Grid
										xs={12}
										item
										container
										marginBottom={2}
									>
										<Grid>
											<StyleButton
												onClick={() =>
													setShowCreateMandateModal(true)
												}
											>
												Create mandate
											</StyleButton>
										</Grid>
									</Grid>
								)
							}

							{/*Show mandate info, when have mandate*/}
							{
								mandate_info?.id && (
									<Grid
										xl={5.18}
										lg={5.18}
										md={5.18}
										sm={5.18}
										xs={5.18}
										item
										container
										className={"left-container"}
									>
										<Grid
											container
											justifyContent={"space-between"}
											paddingBottom={'1.0417vw'}
										>
											<Grid>
												<Chip
													label={retrieveMandateStatus(mandate_info?.mandateInfo?.status)}
													className={"mui-chip"}
													sx={{backgroundColor: "#000000", color: "#FFFFFF", marginLeft: "10px"}} />
											</Grid>
										</Grid>
										<Divider className={"divider"}/>
										{
											renderGrids(
												<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
													Mandate reference
												</StyleTypography>,
												<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
													{mandate_info?.mandateInfo?.mandateId ?? "N/A"}
												</StyleTypography>
											)
										}
										<Divider className={"divider"}/>
										{
											renderGrids(
												<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
													Payment day
												</StyleTypography>,
												<Grid>
													<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
														{mandate_info?.payment_day ?? "N/A"}
													</StyleTypography>
												</Grid>
											)
										}
										<Divider className={"divider"}/>
										{
											renderGrids(
												<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
													Account Number
												</StyleTypography>,
												<Grid
													item
													container
													alignItems={"center"}
												>
													<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
														<img
															alt={"eye"}
															src={showAccountNumber ? openEye : closedEye}
															style={{cursor: "pointer", marginRight: "10px"}}
															onClick={() => setShowAccountNumber(!showAccountNumber)}
														/>
														{
															showAccountNumber
																? mandate_info?.debtorAccount?.domesticAccountNumber
																: "XXXXXXX"
														}
													</StyleTypography>
												</Grid>
											)
										}
										<Divider className={"divider"}/>
										{
											renderGrids(
												<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13}
												                 color={"black"}>
													Sort Code
												</StyleTypography>,
												<Grid
													item
													container
													alignItems={"center"}
												>
													<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
														<img
															alt={"eye"}
															src={showSortCode ? openEye : closedEye}
															style={{cursor: "pointer", marginRight: "10px"}}
															onClick={() => setShowSortCode(!showSortCode)}
														/>
														{
															showSortCode
																? mandate_info?.debtorAccount?.domesticBranchCode
																: "XXXXXX"
														}
													</StyleTypography>
												</Grid>
											)
										}
									</Grid>

								)
							}
							<Grid
								xl={5.18}
								lg={5.18}
								md={5.18}
								sm={5.18}
								xs={5.18}
								item
								container
								sx={{
									marginLeft: "36px"
								}}
								className={"right-container"}
								alignItems={"flex-start"}
								alignSelf={"flex-start"}
							>
								<Grid container className={"information-item-grid"}>
									<Grid sx={{lineHeight: 2.3, marginRight: '20px'}}>
										<img
											alt={"logo"}
											src={House}
										/>
									</Grid>
									<Grid item xs={true}>
										<Grid>
											<StyleTypography fontSize={22} fontSizeMedium={16} color={"black"} fontWeight={"bold"}>
												Mandate
											</StyleTypography>
										</Grid>
										<Grid>
											<StyleTypography fontSize={18} fontSizeMedium={13} color={"#AAAEB7"}>
												Collegia uses electronic mandates to collect your pension contributions.
											</StyleTypography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>

	);
}

const mapStateToProps = state => {
	const {
		Profile,
		BanksMandates,
	} = state;

	return {
		...Profile,
		...BanksMandates,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	getMandateInfo: () => dispatch(getMandateInfo()),
	createOrRetrieveToken: () => dispatch(createOrRetrieveToken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutomaticEnrolmentDdi)