import Grid from "@mui/material/Grid";
import {StyleAppBar} from "../../components/StyleAppBar";
import React, {useEffect, useState} from "react";
import {LeftMenu} from "../../components/LeftMenu";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import {editInfo, me, sendVerifyAccountCode, validateVerifyAccountCode} from "../../actions/Profile";
import {connect} from "react-redux";
import Loading from "../../components/Loading";
import {Divider} from "@mui/material";
import PinInput from "react-pin-input";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

const VerifyAccount = ({
	user = {
	  id: null
	},
	user_loading = false,
	me = () => {},
	editInfo = () => {},
	edit_info = "email",
	valid_code = false,
	sendVerifyAccountCode = () => {},
	validateVerifyAccountCode = () => {}
}): JSX.Element => {
	const [pinCode, setPinCode] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		if (!user.id) {
			me();
		}
	});

	useEffect(() => {
		if (valid_code) {
			navigate(
				edit_info === "email" ?
					"/main/settings/edit-email" :
					"/main/settings/edit-password"
			);
		}
	});

	if (user_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"onboarding-dashboard-page"}
		>
			<StyleAppBar
				backLink={"/main/settings/edit-info"}
				user={user}
			/>
			<Grid
				xl={11}
				lg={11}
				md={11}
				sm={11}
				xs={11}
				item
				container
				id={"main-content-container"}
			>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					<LeftMenu/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "79px",
					}}
				>
					<Grid
						container
					>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
						>
							<Grid>
								<StyleTypography
									fontSize={40}
									fontSizeMedium={30}
									fontWeight={"bold"}
									component={"h1"}
								>
									Verify account
								</StyleTypography>
							</Grid>
							<Grid
								className={"sub-title-container"}
							>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={"#ABAEB7"}
									component={"h2"}
								>
									For security reasons, we sent you an e-mail with a code.
									Please type it below to continue.
								</StyleTypography>
							</Grid>
						</Grid>

						<Divider className={"divider"}/>

						<Grid
							container
							id={"verify-account-container"}
						>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
							>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={"#98989A"}
								>
									Code sent by email
								</StyleTypography>
							</Grid>

							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
							>
								<PinInput
									length={6}
									initialValue={pinCode}
									type={"numeric"}
									onChange={(value) => setPinCode(value)}
									onComplete={(value) => setPinCode(value)}
								/>
							</Grid>

							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
							>
								<StyleTypography
									fontSize={16}
									fontSizeMedium={12}
									color={"#98989A"}
								>
									The security code will expire in 15 minutes.
								</StyleTypography>
							</Grid>

							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								id={"send-code-again"}
							>
								<StyleTypography
									fontSize={20}
									fontSizeMedium={15}
									color={"#98989A"}
								>
									Did not receive the code?
									<span
										onClick={() => sendVerifyAccountCode({
											"users": {
												...user,
												edit_info,
											}
										})}
									>
										{" Send again"}
									</span>
								</StyleTypography>
							</Grid>

							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
							>
								<Button
									className={"default-black-button"}
									onClick={async() => {
										await validateVerifyAccountCode({
											"users": {
												...user,
												pin_code: pinCode
											}
										});
									}}
								>
									<StyleTypography
										fontSize={14}
										fontSizeMedium={12}
										color={"#fff"}
									>
										Continue
									</StyleTypography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
	} = state;

	return {
		...Profile,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	editInfo: (data) => dispatch(editInfo(data)),
	sendVerifyAccountCode: (data) => dispatch(sendVerifyAccountCode(data)),
	validateVerifyAccountCode: (data) => dispatch(validateVerifyAccountCode(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccount);