import Grid from "@mui/material/Grid";
import StyleTypography from "../../StyledComponents/StyleTypography";
import {InputAdornment, InputLabel, MenuItem, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Money from "../../../images/svg/money-icon.svg";
import AddMoney from "../../../images/svg/money-add-icon.svg";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {CurrencyInputCustom} from "../../StyledComponents/CurrencyInputCustom";
import {renderGrids} from "../../../utils/Helpers";

export const Taxes = ({
	employeeRecord,
	setEmployeeRecord,
	employee,
	updateEmployee,
}): JSX.Element => {

	const regexTaxCode = /^(?:[CS])?(([1-9][0-9]{0,5}[LMNPTY])|(BR)|(0T)|(NT)|(D[0-8])|([K][1-9][0-9]{0,5}))$/;

	return (
		<Grid
			xl={12}
			lg={12}
			md={11}
			sm={12}
			xs={12}
			container
			item
			justifyContent={"space-between"}
			id={"taxes"}
			paddingTop={"60px"}
		>

			<Grid
				xl={5.95}
				lg={5.95}
				md={5.95}
				sm={5.95}
				xs={5.95}
				item
				container
				rowSpacing={3.75}
				columnSpacing={2.5}
				alignContent={"flex-start"}
			>
				{/* Starter Type */}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Starter type
					</InputLabel>
					<FormControl fullWidth>
						<Select
							className={"default-select font-bold"}
							MenuProps={{
								PaperProps: {
									style: {
										maxWidth: "685px",
									},
								},
							}}
							value={employeeRecord.starter_type ?? " "}
							onChange={(e) => {
								setEmployeeRecord({
									...employeeRecord,
									starter_type: e.target.value,
								});
							}}
							disabled={employeeRecord.fps_submitted}
						>
							<MenuItem
								key={'select-title'}
								value={" "}
								disabled
							>
								Select
							</MenuItem>
							<MenuItem
								key={'new_with_p45'}
								value={"new_with_p45"}
							>
								New employee with P45
							</MenuItem>
							<MenuItem
								key={'new_without_p45'}
								value={"new_without_p45"}
							>
								New employee without P45
							</MenuItem>
							<MenuItem
								key={'existing_employee'}
								value={"existing_employee"}
							>
								Existing employee
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>

				{/* Starter declaration */}
				{
					employeeRecord.starter_type === "new_without_p45" && (
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							paddingTop={"30px"}
						>
							<InputLabel
								className={"default-input-label"}
							>
								Starter declaration
							</InputLabel>
							<FormControl fullWidth>
								<Select
									className={"default-select font-bold"}
									MenuProps={{
										PaperProps: {
											style: {
												maxWidth: "685px",
											},
										},
									}}
									value={employeeRecord?.starter_declaration ?? " "}
									onChange={(e) => {
										setEmployeeRecord({
											...employeeRecord,
											starter_declaration: e.target.value,
										});
									}}
								>
									<MenuItem
										key={'select-title'}
										value={' '}
										disabled
									>
										Select
									</MenuItem>
									<MenuItem
										key={'first_job'}
										value={"first_job"}
									>
										First job since start of tax year
									</MenuItem>
									<MenuItem
										key={'only_job'}
										value={"only_job"}
									>
										Currently only job
									</MenuItem>
									<MenuItem
										key={'other_job'}
										value={"other_job"}
									>
										Got another job or pension
									</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)
				}

				{/* Tax code and Tax Basis */}
				{
					employeeRecord.starter_type !== null && (
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							paddingTop={"30px"}
						>
							<Grid container direction={"row"} alignItems={"center"}>
								<Grid xs={6} item>
									<InputLabel
										className={"default-input-label"}
									>
										Tax code
									</InputLabel>
									<TextField
										className={"default-text-field"}
										placeholder={"Type your tax code"}
										value={employeeRecord.tax_code ?? ""}
										onChange={(e) => {
											setEmployeeRecord({
												...employeeRecord,
												tax_code: e.target.value,
											});
										}}
										fullWidth
										error={!regexTaxCode.test(employeeRecord.tax_code)}
										InputProps={{
											classes: {
												input: "font-bold",
											},
										}}
									/>
								</Grid>
								<Grid xs={5} item paddingLeft={"30px"}>
									<InputLabel
										className={"default-input-label"}
									>
										Tax Basis
									</InputLabel>
									<FormControl fullWidth>
										<Select
											className={"default-select font-bold"}
											MenuProps={{
												PaperProps: {
													style: {
														maxWidth: "685px",
													},
												},
											}}
											value={employeeRecord?.is_cumulative.toString()}
											onChange={(e) => {
												console.log(e.target.value);
												setEmployeeRecord({
													...employeeRecord,
													is_cumulative: e.target.value,
												});
											}}
										>
											<MenuItem
												key={'cumulative'}
												value={'true'}
											>
												Cumulative
											</MenuItem>
											<MenuItem
												key={'non-cumulative'}
												value={'false'}
											>
												Non-cumulative (week 1/month 1)
											</MenuItem>
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
					)
				}

				{/* Previous taxable pay and tax deducted*/}
				{
					employeeRecord.starter_type === "new_with_p45" && (
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							paddingTop={"30px"}
						>
							<Grid container direction={"row"} alignItems={"center"}>
								<Grid xs={6} item paddingRight={"10px"}>
									<InputLabel
										className={"default-input-label"}
									>
										Previous taxable pay
									</InputLabel>
									<TextField
										className={"default-text-field"}
										placeholder={"Type your tax previous taxable pay"}
										value={employeeRecord.ytd_figure?.previous_employment_taxable_pay ?? ""}
										onChange={(e) => {
											setEmployeeRecord({
												...employeeRecord,
												ytd_figure: {
													...employeeRecord.ytd_figure,
													previous_employment_taxable_pay: e.target.value,
												},
											});
										}}
										fullWidth
										InputProps={{
											endAdornment: <InputAdornment position={"end"}>
												<StyleTypography fontSize={16} color={"black"} fontWeight={"bold"}>
													£
												</StyleTypography>
											</InputAdornment>,
											inputComponent: CurrencyInputCustom,
											inputProps: {prefix: ""},
										}}
									/>
								</Grid>
								<Grid xs={6} item paddingLeft={"10px"}>
									<InputLabel
										className={"default-input-label"}
									>
										Previous tax paid
									</InputLabel>
									<TextField
										className={"default-text-field"}
										placeholder={"Type your previous tax paid"}
										value={employeeRecord.ytd_figure?.previous_employment_tax_deducted ?? ""}
										onChange={(e) => {
											setEmployeeRecord({
												...employeeRecord,
												ytd_figure: {
													...employeeRecord.ytd_figure,
													previous_employment_tax_deducted: e.target.value,
												},
											});
										}}
										fullWidth
										InputProps={{
											endAdornment: <InputAdornment position={"end"}>
												<StyleTypography fontSize={16} color={"black"} fontWeight={"bold"}>
													£
												</StyleTypography>
											</InputAdornment>,
											inputComponent: CurrencyInputCustom,
											inputProps: {prefix: ""},
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					)
				}

				{/* Buttons */}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					container
					className={"employee-record-buttons"}
				>
					<Grid item>
						<Button
							className={"default-black-button employee-record-buttons-save"}
							disabled={
								employeeRecord.starter_type === null ||
								employeeRecord.tax_code === null ||
								employeeRecord.ytd_figure?.previous_employment_taxable_pay === null ||
								employeeRecord.ytd_figure?.previous_employment_tax_deducted === null ||
								!regexTaxCode.test(employeeRecord.tax_code)
							}
							onClick={() => {
								updateEmployee(employee.safe_id, {
									employees: {
										'action': 'taxes',
										tax_code: employeeRecord.tax_code,
										pay_schedule_run_id: employee.pay_schedule.active_pay_run.id,
										is_cumulative: employeeRecord.is_cumulative,
										starter_declaration: employeeRecord.starter_declaration,
										starter_type: employeeRecord.starter_type,
										ytd_figure: {
											tax_code: employeeRecord.tax_code,
											is_cumulative: employeeRecord.is_cumulative ? 'true' : 'false',
											previous_employment_taxable_pay: employeeRecord.ytd_figure?.previous_employment_taxable_pay,
											previous_employment_tax_deducted: employeeRecord.ytd_figure?.previous_employment_tax_deducted,
											gross_for_tax_ytd: employeeRecord.ytd_figure?.gross_for_tax_ytd ?? 0,
											tax_deducted_ytd: employeeRecord.ytd_figure?.tax_deducted_ytd,
										},
									}
								})
							}}
						>
							Save
						</Button>
					</Grid>
					<Grid
						item
						sx={{
							marginLeft: '1vw',
						}}
					>
						<Button
							className={"default-white-button employee-record-buttons-cancel"}
							onClick={() => {
								setEmployeeRecord(employee);
							}}
						>
							Discard
						</Button>
					</Grid>
				</Grid>
			</Grid>

			{/* Texts informational */}
			<Grid
				xl={5.7}
				lg={5.7}
				md={5.7}
				sm={5.7}
				xs={5.7}
				item
				container
				className={"styled-grid"}
			>
				{renderGrids(
					Money,
					"Tax code",
					"Enter the employee’s tax code. If you receive a letter from HMRC, please update the tax code accordingly.",
				)}
				<Grid
					paddingTop={"30px"}
					paddingBottom={"30px"}
					width={"100%"}
				>
					<Divider/>
				</Grid>
				{renderGrids(
					AddMoney,
					"Previous taxable pay",
					"If a new employee with a P45 was chosen as the starter type, please state the employee’s previous taxable pay (before current employment) from their P45 statement.",
				)}
				<Grid
					paddingTop={"30px"}
					paddingBottom={"30px"}
					width={"100%"}
				>
					<Divider/>
				</Grid>
				{renderGrids(
					Money,
					"Previous Tax Paid",
					"If a new employee with a P45 was chosen as the starter type, please state the employee’s previous tax paid (before current employment) from their P45 statement.",
				)}
			</Grid>
		</Grid>
	);
};