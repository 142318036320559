import Grid from "@mui/material/Grid";
import {Dialog, DialogActions, DialogContent, DialogTitle, InputLabel,} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import StyleTypography from "../../StyledComponents/StyleTypography";
import {CurrencyInputCustom} from "../../StyledComponents/CurrencyInputCustom";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import enGb from "date-fns/locale/en-GB";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {
	addYears,
	differenceInCalendarDays,
	eachDayOfInterval, endOfMonth, format,
	isAfter,
	isSameMonth,
	parseISO, startOfMonth,
} from "date-fns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {useEffect, useState} from "react";
import {parseDate} from "../../../utils/Helpers";

function getMonthId(monthName) {
	switch (monthName) {
		case "January":
			return 0;
		case "February":
			return 1;
		case "March":
			return 2;
		case "April":
			return 3;
		case "May":
			return 4;
		case "June":
			return 5;
		case "July":
			return 6;
		case "August":
			return 7;
		case "September":
			return 8;
		case "October":
			return 9;
		case "November":
			return 10;
		case "December":
			return 11;
		default:
			return "error";
	}
}

function getWorkingDaysNumbers(working_pattern) {
	let array = [];

	if (working_pattern?.monday) {
		array.push(1);
	}

	if (working_pattern?.tuesday) {
		array.push(2);
	}

	if (working_pattern?.wednesday) {
		array.push(3);
	}

	if (working_pattern?.thursday) {
		array.push(4);
	}

	if (working_pattern?.friday) {
		array.push(5);
	}

	if (working_pattern?.saturday) {
		array.push(6);
	}

	if (working_pattern?.sunday) {
		array.push(0);
	}

	return array;
}

const getWorkingDaysInMonth = (startDate, endDate, workingDayNumbers) => {

	if (
		startDate === null ||
		startDate === undefined ||
		endDate === undefined ||
		endDate === null
	) {
		return 0;
	}
	const days = eachDayOfInterval({ start: startDate, end: endDate });
	return days.filter(day => workingDayNumbers.includes(day.getDay())).length;
};

const calculateProratedAmount = (periodStart, terminationDate, monthlyEquivalent, workingDayNumbers) => {
	let proratedAmount;

	if (periodStart === null || terminationDate === null) {
		return 0;
	}

	periodStart = parseISO(periodStart);

	if (isSameMonth(periodStart, terminationDate)) {
		const workingDaysInMonth = getWorkingDaysInMonth(startOfMonth(periodStart), endOfMonth(periodStart), workingDayNumbers);
		const daysWorked = getWorkingDaysInMonth(periodStart, terminationDate, workingDayNumbers);

		proratedAmount = monthlyEquivalent / workingDaysInMonth * daysWorked;
	} else {
		const workingDaysInMonth1 = getWorkingDaysInMonth(startOfMonth(periodStart), endOfMonth(periodStart), workingDayNumbers);
		const daysWorkedInMonth1 = getWorkingDaysInMonth(periodStart, endOfMonth(periodStart), workingDayNumbers);

		const workingDaysInMonth2 = getWorkingDaysInMonth(startOfMonth(terminationDate), endOfMonth(terminationDate), workingDayNumbers);
		const daysWorkedInMonth2 = getWorkingDaysInMonth(startOfMonth(terminationDate), terminationDate, workingDayNumbers);

		proratedAmount = (monthlyEquivalent / workingDaysInMonth1 * daysWorkedInMonth1) + (monthlyEquivalent / workingDaysInMonth2 * daysWorkedInMonth2);
	}

	return Math.ceil(proratedAmount * 100) / 100;
};

export const TerminateEmployeeModal = ({
   open,
   setOpen,
   selectedEmployee,
   employer,
   updateEmployee,
}): JSX.Element => {

	const [terminationDate, setTerminationDate] = useState(null);
	const [yearlyHolidays, setYearlyHolidays] = useState(null);
	const [leaveYearStartDate, setLeaveYearStartDate] = useState(null);
	const [holidaysTaken, setHolidaysTaken] = useState(null);
	const [accruedHolidays, setAccruedHolidays] = useState(null);
	const [holidaysLeft, setHolidaysLeft] = useState(null);
	const [holidayPay, setHolidayPay] = useState(null);
	const [proRataTerminationPay, setProRataTerminationPay] = useState(null);

	const workingDaysNumbers = getWorkingDaysNumbers(selectedEmployee?.working_pattern);

	useEffect(() => {
		if (terminationDate === null) {
			return;
		}

		if (isAfter(parseISO(selectedEmployee?.join_date), leaveYearStartDate)) {
			let accruedHolidays = differenceInCalendarDays(terminationDate, parseISO(selectedEmployee?.join_date)) + 1;
			setAccruedHolidays(accruedHolidays);
		} else {
			let accruedHolidays = differenceInCalendarDays(terminationDate, leaveYearStartDate) + 1;
			setAccruedHolidays(accruedHolidays);
		}

		let daysInLeaveYear = differenceInCalendarDays(addYears(leaveYearStartDate, 1), leaveYearStartDate);
		setHolidaysLeft((Math.ceil((accruedHolidays / daysInLeaveYear) * (yearlyHolidays) * 100) / 100) - holidaysTaken);

		if (holidaysLeft > 0 && selectedEmployee?.salary?.employment_type === "salaried") {
			setHolidayPay(Math.ceil((selectedEmployee?.salary?.annual_salary / 260) * (holidaysLeft) * 100) / 100);
		}

		if (selectedEmployee.on_leave === false) {
			if (selectedEmployee?.pay_schedule?.day_rate_method === "yearly_working_days") {
				let daysWorked = getWorkingDaysInMonth(parseISO(selectedEmployee?.pay_schedule?.active_pay_run?.period_start_date), terminationDate, workingDaysNumbers);
				let yearlyWorkingDays = selectedEmployee?.working_pattern?.number_of_working_days * 52;
				setProRataTerminationPay(Math.ceil((selectedEmployee?.salary?.annual_salary / yearlyWorkingDays) * (daysWorked) * 100) / 100);
			} else {
				setProRataTerminationPay(calculateProratedAmount(selectedEmployee?.pay_schedule?.active_pay_run?.period_start_date, terminationDate, selectedEmployee?.salary?.monthly_salary, workingDaysNumbers));
			}

		} else {
			setProRataTerminationPay(0);
		}

	}, [
		accruedHolidays,
		holidaysLeft,
		holidaysTaken,
		leaveYearStartDate,
		selectedEmployee?.join_date,
		selectedEmployee.on_leave,
		selectedEmployee?.pay_schedule?.active_pay_run?.period_start_date,
		selectedEmployee?.pay_schedule?.day_rate_method,
		selectedEmployee?.salary?.annual_salary,
		selectedEmployee?.salary?.employment_type,
		selectedEmployee?.salary?.monthly_salary,
		selectedEmployee?.working_pattern?.number_of_working_days,
		terminationDate,
		workingDaysNumbers,
		yearlyHolidays
	]);

	return (
		<Dialog
			onClose={() => {
				setOpen(false);
				setTerminationDate(null);
				setYearlyHolidays(0);
				setLeaveYearStartDate(null);
				setHolidaysTaken(0);
				setAccruedHolidays(0);
				setHolidaysLeft(0);
				setHolidayPay(0);
				setProRataTerminationPay(0);
			}}
			open={open}
			fullWidth={true}
			maxWidth={'sm'}
			id={"terminate-employee-dialog"}
		>
			<DialogTitle>
				<StyleTypography
					fontSize={35}
					fontSizeMedium={28}
					fontWeight={'bold'}
				>
					Terminate employment
				</StyleTypography>
			</DialogTitle>
			<DialogContent>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					{/*Termination Date*/}
					<Grid
						item
						xl={7}
						lg={7}
						md={7}
						sm={7}
						xs={7}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Termination date
						</InputLabel>
						<LocalizationProvider
							dateAdapter={AdapterDateFns}
							adapterLocale={enGb}
						>
							<DesktopDatePicker
								slotProps={{ field: { readOnly: true } }}
								enableAccessibleFieldDOMStructure
								className={"date-picker"}
								defaultValue={terminationDate}
								onChange={(newValue) => {
									if (newValue instanceof Date && !isNaN(newValue.getTime())) {
										const minDate = parseISO(selectedEmployee?.pay_schedule?.active_pay_run?.period_start_date);
										const maxDate = parseISO(selectedEmployee?.pay_schedule?.active_pay_run?.period_end_date);
										if (newValue >= minDate && newValue <= maxDate) {
											if (getMonthId(employer.holiday_year_start_month) > newValue.getUTCMonth()) {
												let startLeaveYearDate = new Date(newValue.getUTCFullYear() - 1, getMonthId(employer.holiday_year_start_month), 1);
												setLeaveYearStartDate(startLeaveYearDate);
											} else {
												let startLeaveYearDate = new Date(newValue.getUTCFullYear(), getMonthId(employer.holiday_year_start_month), 1);
												setLeaveYearStartDate(startLeaveYearDate);
											}
											setTerminationDate(newValue);
										} else {
											setLeaveYearStartDate(null);
											setTerminationDate(null);
										}
									}
								}}
								minDate={parseISO(selectedEmployee?.pay_schedule?.active_pay_run?.period_start_date)}
								maxDate={parseISO(selectedEmployee?.pay_schedule?.active_pay_run?.period_end_date)}

							/>
						</LocalizationProvider>
					</Grid>

					{
						leaveYearStartDate &&
						<>
							{/*Yearly holidays*/}
							<Grid
								item
								xl={6}
								lg={6}
								md={6}
								sm={6}
								xs={6}
							>
								<InputLabel
									className={"default-input-label"}
								>
									Entitled yearly holidays
								</InputLabel>
								<TextField
									fullWidth
									className={"default-text-field"}
									onChange={(event) => {
										setYearlyHolidays(event.target.value);
									}}
									value={yearlyHolidays ?? 0}
									InputProps={{
										inputComponent: CurrencyInputCustom,
										inputProps: {prefix: ""},
										endAdornment: "days"
									}}
								/>
							</Grid>

							{/*Holidays taken*/}
							<Grid
								item
								xl={6}
								lg={6}
								md={6}
								sm={6}
								xs={6}
							>
								<InputLabel
									className={"default-input-label"}
								>
									Holidays taken
								</InputLabel>
								<TextField
									fullWidth
									className={"default-text-field"}
									onChange={(event) => {
										setHolidaysTaken(event.target.value);
									}}
									value={holidaysTaken ?? 0}
									InputProps={{
										inputComponent: CurrencyInputCustom,
										inputProps: {prefix: ""},
										endAdornment: "days"
									}}
								/>
							</Grid>

							{/*Holidays Left*/}
							<Grid
								item
								xl={6}
								lg={6}
								md={6}
								sm={6}
								xs={6}
							>
								<InputLabel
									className={"default-input-label"}
								>
									Holidays left
								</InputLabel>
								<TextField
									fullWidth
									className={"default-text-field"}
									disabled
									value={holidaysLeft ?? 0}
									InputProps={{
										inputComponent: CurrencyInputCustom,
										inputProps: {prefix: ""},
										endAdornment: "days"
									}}
								/>
							</Grid>

							{/*Holiday pay*/}
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
							>
								<InputLabel
									className={"default-input-label"}
								>
									Entitled holiday pay
								</InputLabel>
								<TextField
									fullWidth
									className={"default-text-field"}
									disabled={selectedEmployee.salary.employment_type === "salaried"}
									value={holidayPay ?? 0}
									InputProps={{
										inputComponent: CurrencyInputCustom,
										inputProps: {prefix: ""},
										endAdornment: "£"
									}}
								/>
							</Grid>

							{/*Prorata salary*/}
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								sx={{display: selectedEmployee.salary.employment_type === "salaried" ? "block" : "none"}}
							>
								<InputLabel
									className={"default-input-label"}
								>
									Prorated salary
								</InputLabel>
								<TextField
									fullWidth
									className={"default-text-field"}
									disabled
									value={proRataTerminationPay ?? 0}
									InputProps={{
										inputComponent: CurrencyInputCustom,
										inputProps: {prefix: ""},
										endAdornment: "£"
									}}
								/>
							</Grid>
						</>
					}

				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<Button
							fullWidth
							className={"default-black-button"}
							disabled={terminationDate === null}
							onClick={() => {
								updateEmployee(selectedEmployee.safe_id, {
									employees: {
										'action': 'terminate_employee',
										yearly_holidays: yearlyHolidays,
										holidays_taken: holidaysTaken,
										holidays_left: holidaysLeft,
										termination_date: format(parseDate(terminationDate),"yyyy-MM-dd"),
										leave_year_start_date: leaveYearStartDate.toISOString().split('T')[0],
										leave_year_end_date: addYears(leaveYearStartDate, 1).toISOString().split('T')[0],
										prorated_termination_pay: proRataTerminationPay,
										entitled_holiday_pay: holidayPay,
										employee_id: selectedEmployee.id
									}
								});
								setOpen(false);
							}}
						>
							Save
						</Button>
					</Grid>

					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<Button
							fullWidth
							className={"default-white-button"}
							onClick={() => {
								setOpen(false);
								setTerminationDate(null);
								setYearlyHolidays(0);
								setLeaveYearStartDate(null);
								setHolidaysTaken(0);
								setAccruedHolidays(0);
								setHolidaysLeft(0);
								setHolidayPay(0);
								setProRataTerminationPay(0);
							}}
						>
							Cancel
						</Button>
					</Grid>

				</Grid>
			</DialogActions>
		</Dialog>
	)
}