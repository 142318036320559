import {resolveReduxState} from "../../utils/Helpers";
import {
	ADD_PAY_SCHEDULE,
	DELETE_PAY_SCHEDULE,
	FIND_PAY_SCHEDULE,
	GET_PAY_SCHEDULES,
	UPDATE_PAY_SCHEDULE
} from "../../actions/PaySchedule/types";

const initial_state = {
	pay_schedule_loading: false,
	pay_schedule: {
		id: "",
		name: "",
		frequency_code: "",
		week_payday: "",
		month_payday: "",
		custom_payday: "",
		starting_period_end_date: "",
		day_rate_method: "",
	},
	pay_schedules: [],
}

const PaySchedule = (state = initial_state, action = false) => {
	if (action) {
		switch(action.type) {

			case ADD_PAY_SCHEDULE:
			case GET_PAY_SCHEDULES:
			case FIND_PAY_SCHEDULE:
			case DELETE_PAY_SCHEDULE:
			case UPDATE_PAY_SCHEDULE:
				return resolveReduxState(state, action);

			default:
				return state;
		}
	}

	return state;
}

export default PaySchedule;