import Request from '../../utils/Request';
import {CLOSE_ACCOUNT, EDIT_INFO, FETCH_USER, LOGIN} from "./types";
import {toast} from "react-toastify";

export const login = (data, setAccounts, setUserNotLogged) => dispatch => {
	dispatch({
		type: LOGIN,
		login_loading: true,
	});
	if (data) {
		Request.post('/api/employers/users/login', data)
			.then(response => {
				if (response.status === 200) {

					if (response.data.has_multiple_employers) {
						setUserNotLogged(response.data.user);
						setAccounts(response.data.accounts);
						dispatch({
							type: LOGIN,
							login_loading: false,
						});
					} else {
						localStorage.clear();
						localStorage.setItem('fpEmployerToken', response.data.token);
						window.location.href = "/main";
					}
				}
			}).catch(err => {
			if (err?.response?.status !== 500) {
				dispatch({
					type: LOGIN,
					login_error: true,
					login_loading: false,
				});
			}
			console.error(err);
		});
	}
};

export const selectAccount = (data) => dispatch => {
	dispatch({
		type: LOGIN,
		login_loading: true,
	});
	if (data) {
		Request.post('/api/employers/users/select-account', data)
			.then(response => {
				if (response.status === 200) {
					localStorage.clear();
					localStorage.setItem('fpEmployerToken', response.data.token);
				}
				window.location.href = "/main"
			})
			.catch(err => {
				dispatch({
					type: LOGIN,
					login_error: true,
					login_loading: false,
				});
				console.error(err);
			});
	}
};

export const me = () => dispatch => {
	dispatch({
		type: FETCH_USER,
		user_loading: true,
	});
	Request.get('/api/employers/users/me')
		.then(response => {
			if (response.status === 200) {

				dispatch({
					type: FETCH_USER,
					user_loading: false,
					user: {
						...response.data
					},
				});
			}
		})
		.catch(err => {
			dispatch({
				type: FETCH_USER,
				user_loading: false,
			});
			console.error(err);
		});
};

export const confirmEmail = (data, push) => dispatch => {
	dispatch({
		type: FETCH_USER,
		user_loading: true,
	});
	Request.post('/api/employers/users/confirm-email', data)
		.then(response => {
			if (response.status === 200) {

				if (response.data.temporary_password) {
					push('/main/settings/edit-password');
				} else {
					dispatch({
						type: FETCH_USER,
						user_loading: false,
						user: {
							...response.data.user
						},
					});
					push('/onboarding/dashboard');
				}

				toast.success("Email confirmed successfully.", {
					position: toast.POSITION.TOP_CENTER
				});
			}
		})
		.catch((error) => {
			console.error(error);
			dispatch({
				type: FETCH_USER,
				user_loading: false,
			});
			if (error.response.status !== 500) {

				error.response.data.attempts_left > 0 ?

					toast.error("Invalid pin. You have " + error.response.data.attempts_left + " attempt(s) left.", {
						position: toast.POSITION.TOP_CENTER
					})
					:
					toast.error("You have exceeded the maximum number of attempts. Please contact support.", {
						position: toast.POSITION.TOP_CENTER
					});
			}
			console.error(error);
		});
};

export const sendEmail = () => dispatch => {
	dispatch({
		type: FETCH_USER,
		user_loading: true,
	});
	Request.get('/api/employers/users/send-email-confirmation')
		.then(response => {
			if (response.status === 200) {
				dispatch({
					type: FETCH_USER,
					user_loading: false,
				});
			}
		})
		.catch(err => {
			dispatch({
				type: FETCH_USER,
				user_loading: false,
			});
			console.error(err);
		});
};

export const editInfo = (data) => dispatch => {
	dispatch({
		type: EDIT_INFO,
		edit_info: data
	});
};

export const invalidCode = () => dispatch => {
	dispatch({
		type: EDIT_INFO,
		valid_code: false
	});
};

export const setEditInfo = (data) => dispatch => {
	dispatch({
		type: EDIT_INFO,
		valid_code: data
	});
};

export const sendVerifyAccountCode = (data) => dispatch => {
	if (data) {
		dispatch({
			type: EDIT_INFO,
			user_loading: true,
		});
		Request.post("api/employers/users/send-verify-account-code", data)
			.then(response => {
				dispatch({
					type: EDIT_INFO,
					user_loading: false,
				});
			});
	}
};

export const validateVerifyAccountCode = (data) => dispatch => {
	if (data) {
		dispatch({
			type: EDIT_INFO,
			user_loading: true,
		});
		return new Request.post("api/employers/users/validate-verify-account-code", data)
			.then(response => {
				dispatch({
					type: EDIT_INFO,
					user_loading: false,
					valid_code: true
				});
			})
			.catch(e => {
				dispatch({
					type: EDIT_INFO,
					user_loading: false,
					valid_code: false
				});
				toast.error(e.response.data.message);
				console.error(e);
			});
	}
};

export const updateEmail = (data) => dispatch => {
	if (data) {
		dispatch({
			type: EDIT_INFO,
			user_loading: true
		});

		Request.post("api/employers/users/update-email", data)
			.then(response => {
				dispatch({
					type: EDIT_INFO,
					user_loading: false
				});
				if (response.data.success) {
					window.location.href = "/main";
					toast.success("Email successfully changed.")
				}
			})
			.catch(e => {
				dispatch({
					type: EDIT_INFO,
					user_loading: false
				});
				toast.error(e.response.data.message);
				console.error(e);
			});
	}
};

export const updatePassword = (data) => dispatch => {
	if (data) {
		dispatch({
			type: EDIT_INFO,
			user_loading: true
		});

		Request.post("api/employers/users/change-password", data)
			.then(response => {
				dispatch({
					type: EDIT_INFO,
					user_loading: false
				});
				if (response.data.success) {
					window.location.href = "/main";
					toast.success("Password changed successfully.")
				}
			})
			.catch(e => {
				dispatch({
					type: EDIT_INFO,
					user_loading: false
				});
				toast.error(e.response.data.message);
				console.error(e);
			});
	}
};

export const closeAccount = (data) => dispatch => {
	if (data) {
		dispatch({
			type: CLOSE_ACCOUNT,
			user_loading: true
		});
		
		Request.post("/api/employers/users/close-account", data)
			.then(response => {
				dispatch({
					type: CLOSE_ACCOUNT,
					user_loading: false
				});
				localStorage.clear();
				window.location.href = "/login";
			})
			.catch(e => {
				dispatch({
					type: CLOSE_ACCOUNT,
					user_loading: false
				});
			})
	}
};
