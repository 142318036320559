export const BASIC_SETUP = "BASIC_SETUP";
export const COMPLETE_ONBOARDING = "COMPLETE_ONBOARDING";
export const CREATE_EMPLOYER = "CREATE_EMPLOYER";
export const UPDATE_EMPLOYER_PENSION_SETTINGS = "UPDATE_EMPLOYER_PENSION_SETTINGS";
export const CREATE_PAY_ITEM = "CREATE_PAY_ITEM";
export const UPDATE_PAY_ITEM = "UPDATE_PAY_ITEM";
export const DELETE_PAY_ITEM = "DELETE_PAY_ITEM";
export const GET_EMPLOYER = "GET_EMPLOYER";
export const CREATE_SALARY_ITEM = "CREATE_SALARY_ITEM";
export const CREATE_ADMIN = "CREATE_ADMIN";
export const UPDATE_AUTOMATIC_EPS = "UPDATE_AUTOMATIC_EPS";