import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {findEmployer} from "../../actions/Employer";
import {formatDateFromBackendWithTime} from "../../utils/Helpers";
import {disableRunPayrollButton} from "../MainPage";
import {Typography, Chip, FreepayrollButton} from "@collegia-partners/ui-kit";
import FPTable from "../../components/common/FPTable/FPTable";
import Layout from "../../components/common/Layout";
import {useListPayrolls} from "../../hooks/payrolls";

export function renderPayRunStatusColor(status) {
	switch (status) {
		case "Submitted":
			return '#0360FD';
		case "Reopened":
			return '#FFA500';
		default:
			return '#C4C4C4';
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollActivePage = ({
	employer = {},
	findEmployer = () => {},
}): JSX.Element => {

	const push = useNavigate();

	const columns = [
		{
			text: "Schedule Name",
			key: "name",
			width: '1.5fr',
			hasOrderBy: true,
		},
		{
			text: "Tax Period",
			key: "tax_period",
			hasOrderBy: true,
		},
		{
			text: "Pay Run",
			key: "pay_run_period",
			width: '1.5fr'
		},
		{
			text: "Pay Date",
			key: "period_end_date",
			customRender: (row, index) => (
				<Typography
					variant="body"
					size="medium"
					key={index}
					color={"black"}
				>
					{formatDateFromBackendWithTime(row.period_end_date)}
				</Typography>
			),

		},
		{
			text: "Status",
			key: "status",
			customRender: (row, index) => (
				<Chip key={index} color={renderPayRunStatusColor(row.status)} >
					{row.status}
				</Chip>
			),
		},
		{
			text: "Actions",
			width: '0.75fr',
			customRender: (row, index) => (
				<FreepayrollButton
					variant={"primary"}
					onClick={() => {
						push(`/main/run-payroll/${btoa(row.id.toString())}`);
					}}
					size={"small"}
					disabled={disableRunPayrollButton(employer)}
					key={index}
				>
					Run Payroll
				</FreepayrollButton>
			),
		},
	]

	useEffect(() => {
		findEmployer([]);
	}, [findEmployer]);

	return (
		<Layout
			activePage={"Payrolls"}
			activeSubpage={"Active"}
			showBackButton={false}
		>
			<div style={{width: "100%", display: "flex", flexDirection: "column", gap: "2.57vw"}}>
				<div
					style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
					<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
						Current Payrolls
					</Typography>
				</div>
				<div>
					<FPTable
						headers={columns}
						hookName={useListPayrolls}
						hookKey={'payrolls'}
						hookParams={{
							mode: 'active',
						}}
						paginate={true}
					/>
				</div>
			</div>
		</Layout>
	);
}

const mapStateToProps = state => {
	const {
		Employer,
	} = state;

	return {
		...Employer,
	}
}

const mapDispatchToProps = dispatch => ({
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollActivePage)