import {
	BASIC_SETUP,
	COMPLETE_ONBOARDING, CREATE_ADMIN,
	CREATE_EMPLOYER,
	CREATE_SALARY_ITEM,
	GET_EMPLOYER, UPDATE_AUTOMATIC_EPS,
	UPDATE_EMPLOYER_PENSION_SETTINGS,
} from "./types";
import Request from "../../utils/Request";
import {FETCH_USER} from "../Profile/types";
import {MANDATE_FLOW_INITIATED} from "../BanksMandates/types";
import {toast} from "react-toastify";

export const createEmployer = (data) => dispatch => {
	if (data) {
		dispatch({
			type: CREATE_EMPLOYER,
			employer_loading: true
		});

		return new Request.post("api/employers", data)
			.then(response => {
				if (response.data.success) {
					localStorage.clear();
					localStorage.setItem('fpEmployerToken', response.data.token);

					window.location.href = '/onboarding/dashboard';
				}
				dispatch({
					type: CREATE_EMPLOYER,
					employer_loading: false,
				});

			})
			.catch(e => {
				dispatch({
					type: CREATE_EMPLOYER,
					employer_loading: false,
				});
				toast.error(e.response.data.message);
				console.error(e);
			});
	}
}

export const basicSetup = (data) => dispatch => {
	dispatch({
		type: BASIC_SETUP,
		basic_setup_loading: true
	});
	if (data) {
		Request.post('/api/employers/basic-setup',data)
			.then(response => {
				if (response.data.step === 'automatic_enrolment') {
					dispatch({
						type: MANDATE_FLOW_INITIATED,
						token: response.data.token,
						mandate_flow_initiated: true
					});
				}

				// if (response.data.step === 'employer_liability') {
				// 	window.location.href = '/onboarding/dashboard'
				// }

				if (response.data.step === 'compliance') {
					window.location.href = '/onboarding/dashboard'
				}

				dispatch({
					type: BASIC_SETUP,
					basic_setup_loading: false,
					employer: {
						...response.data.employer
					}
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: BASIC_SETUP,
					basic_setup_loading: false
				});
				if (error.response.status === 400) {
					toast.error("An unexpected error occurred. Please contact support and inform code SUP-09NC.");
				}
				if (error.response.status === 422) {
					dispatch({
						type: BASIC_SETUP,
						already_have_collegia_account: true
					});
					toast.error(error.response.data.message);
				}
			})
	}
};

export const importCollegiaData = (data) => dispatch => {
	dispatch({
		type: BASIC_SETUP,
		basic_setup_loading: true
	});
	if (data) {
		Request.post('/api/employers/import-collegia',data)
			.then(response => {

				if (response.data.has_mandate) {
					dispatch({
						type: FETCH_USER,
						user: {
							...response.data.user
						}
					});
				} else {
					dispatch({
						type: MANDATE_FLOW_INITIATED,
						token: response.data.token,
						mandate_flow_initiated: true
					});
				}

				dispatch({
					type: BASIC_SETUP,
					basic_setup_loading: false,
					already_have_collegia_account: false
				});

			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: BASIC_SETUP,
					basic_setup_loading: false
				});
				if (error.response.status === 422) {
					toast.error(error.response.data.message);
				}

			})
	}
};

export const completeOnboarding = () => dispatch => {
	dispatch({
		type: COMPLETE_ONBOARDING,
		employer_loading: true
	});
	return new Request.put('/api/employers/complete-onboarding',[])
		.then(response => {
			if (response.status === 200) {
				window.location.href = '/main';
				dispatch({
					type: COMPLETE_ONBOARDING,
					employer_loading: false,
				});
			}

		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: COMPLETE_ONBOARDING,
				employer_loading: false,
			});

		})
};

export const findEmployer = (relations = []) => dispatch => {

	dispatch({
		type: GET_EMPLOYER,
		employer_loading: true,
	});

	let queryString = '';
	if (relations.length) {
		// Create a string like 'relations[]=relation1&relations[]=relation2'
		queryString = relations.map(relation => `relations[]=${relation}`).join('&');
	}

	Request.get(`/api/employers/find?${queryString}`)
		.then(response => {
			if (response.status === 200) {

				if (response.data?.employer?.collegia_emandate && response.data?.employer?.collegia_emandate?.token_active) {
					dispatch({
						type: MANDATE_FLOW_INITIATED,
						token: response.data?.employer?.collegia_emandate?.token,
						mandate_flow_initiated: true
					});
				}

				dispatch({
					type: GET_EMPLOYER,
					employer: {
						...response.data.employer,
					},
				});
			}
			dispatch({
				type: GET_EMPLOYER,
				employer_loading: false,
			});
		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: GET_EMPLOYER,
				employer_loading: false,
			});
		})

}

export const updateDefaultPensionSettings = (data) => dispatch => {
	dispatch({
		type: UPDATE_EMPLOYER_PENSION_SETTINGS,
		employer_loading: true
	});
	if (data) {
		return new Request.post(`/api/employers/update-pension-settings/`, data)
			.then(response => {
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false,
					employer: {
						pension_scheme: response.data.data
					}
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false
				});
			})
	}
};

export const updateHMRCSettings = (data) => dispatch => {
	dispatch({
		type: UPDATE_EMPLOYER_PENSION_SETTINGS,
		employer_loading: true
	});
	if (data) {
		return new Request.post(`/api/employers/update-hmrc-settings/`, data)
			.then(response => {
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false,
					employer: {
						rti_key: response.data.data
					}
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false
				});
			})
	}
};

export const updateEmploymentAllowanceSettings = (data) => dispatch => {
	dispatch({
		type: UPDATE_EMPLOYER_PENSION_SETTINGS,
		employer_loading: true
	});
	if (data) {
		return new Request.post(`/api/employers/update-employment-allowance-settings/`, data)
			.then(response => {
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false,
					employer: {
						...response.data.data
					}
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false
				});
			})
	}
};

export const updateApprenticeLevy = (data) => dispatch => {
	dispatch({
		type: UPDATE_EMPLOYER_PENSION_SETTINGS,
		employer_loading: true
	});
	if (data) {
		return new Request.post(`/api/employers/update-apprentice-settings/`, data)
			.then(response => {
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false,
					employer: {
						...response.data.data
					}
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false
				});
			})
	}
};

export const updateSer = (data) => dispatch => {
	dispatch({
		type: UPDATE_EMPLOYER_PENSION_SETTINGS,
		employer_loading: true
	});
	if (data) {
		return new Request.post(`/api/employers/update-ser-settings/`, data)
			.then(response => {
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false,
					employer: {
						...response.data.data
					}
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false
				});
			})
	}
};

export const updateStatutoryAmounts = (data) => dispatch => {
	dispatch({
		type: UPDATE_EMPLOYER_PENSION_SETTINGS,
		employer_loading: true
	});
	if (data) {
		return new Request.post(`/api/employers/update-statutory-ytd-amounts`, data)
			.then(response => {
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false,
					employer: {
						...response.data.data
					}
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false
				});
			})
	}
};

export const connectHmrc = (data) => dispatch => {
	dispatch({
		type: UPDATE_EMPLOYER_PENSION_SETTINGS,
		employer_loading: true
	});
	if (data) {
		return new Request.post(`/api/employers/test-hmrc-connection/`, data)
			.then(response => {
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false,
				});

				if (response.data.hmrc_connected) {
					toast.success('HMRC connected successfully');
				} else {
					toast.error('HMRC connection failed. Please check your credentials and try again.');
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false
				});
			})
	}
};

export const updateEmployer = (id, data, relations = []) => dispatch => {
	dispatch({
		type: UPDATE_EMPLOYER_PENSION_SETTINGS,
		employer_loading: true
	});
	let queryString = '';
	if (relations.length) {
		// Create a string like 'relations[]=relation1&relations[]=relation2'
		queryString = relations.map(relation => `relations[]=${relation}`).join('&');
	}

	if (data && id) {
		const formData = new FormData();
		formData.append("picture", data?.employers?.company_logo);
		delete data?.employer?.company_logo;
		formData.append("data", JSON.stringify(data));
		return new Request.post(`/api/employers/update-employer/${id}?${queryString}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		})
			.then(response => {
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false,
					employer: {
						...response.data.data
					}
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: UPDATE_EMPLOYER_PENSION_SETTINGS,
					employer_loading: false
				});
			})
	}
};

export const createSalaryItem = (data) => dispatch => {
	if (data) {
		dispatch({
			type: CREATE_SALARY_ITEM,
			employer_loading: true
		});
		Request.post("/api/employers/create-salary-item", data)
			.then(response => {
				window.location.href = "/main/company/salaries";
			})
			.catch(e => {
				dispatch({
					type: CREATE_SALARY_ITEM,
					employer_loading: false
				});
				console.error(e);
			})
	}
}

export const removeSalaryItem = (id) => dispatch => {
	if (id) {
		dispatch({
			type: CREATE_SALARY_ITEM,
			employer_loading: true
		});
		Request.post("/api/employers/remove-salary-item/" + id,)
			.then(response => {
				dispatch({
					type: CREATE_SALARY_ITEM,
					employer_loading: false,
					employer: {
						...response.data.data
					}
				});
			})
			.catch(e => {
				dispatch({
					type: CREATE_SALARY_ITEM,
					employer_loading: false
				});
				console.error(e);
			})
	}
}

export const closeTaxYear = (data) => dispatch => {
	dispatch({
		type: CREATE_SALARY_ITEM,
		employer_loading: true
	});
	Request.post("/api/employers/close-tax-year",data)
		.then( () =>  {
			dispatch({
				type: CREATE_SALARY_ITEM,
				employer_loading: false
			});
			toast.success("Tax year closed successfully.");
			window.location.href = "/main";
		})
		.catch(error => {
			dispatch({
				type: CREATE_SALARY_ITEM,
				employer_loading: false
			});
			if (error.response.status === 400) {
				toast.error(error.response.data.message);
			}
			console.error(error);
		})
}

export const getAdmins = (data) => dispatch => {
	if (data) {
		dispatch({
			type: GET_EMPLOYER,
			employer_loading: true
		});

		Request.post("/api/employers/get-admins", data)
			.then(response => {
				dispatch({
					type: GET_EMPLOYER,
					employer_loading: false,
					admins: response.data.admins
				});
			})
			.catch(e => {
				dispatch({
					type: GET_EMPLOYER,
					employer_loading: false
				});
				toast.error(e.response.data.message);
				console.error(e);
			});
	}
}

export const createAdmin = (data) => dispatch => {
	if (data) {
		dispatch({
			type: CREATE_ADMIN,
			employer_loading: true,
		});

		Request.post("/api/employers/create-admin", data)
			.then(response => {
				dispatch({
					type: CREATE_ADMIN,
					employer_loading: false,
					error: response.data.error,
					error_message: "",
				});
				window.location.href = "/main/company/profile";
				toast.success("Admin created successfully.");
			})
			.catch(e => {
				dispatch({
					type: CREATE_ADMIN,
					employer_loading: false,
					error: e.response.data.error,
					error_message: e.response.data.message,
				});
				toast.error(e.response.data.message);
				console.error(e);
			});
	}
};

export const updateAutomaticEps = (data) => dispatch => {
	if (data) {
		dispatch({
			type: UPDATE_AUTOMATIC_EPS,
			employer_loading: true,
		});
		Request.put("/api/employers/update-automatic-eps", data)
			.then((response) => {
				if (response?.data?.success) {
					dispatch(findEmployer());
				} else {
					dispatch({
						type: UPDATE_AUTOMATIC_EPS,
						employer_loading: false,
					});
				}
			})
			.catch(e => {
				dispatch({
					type: UPDATE_AUTOMATIC_EPS,
					employer_loading: false,
				});
				toast.error(e.response.data.message);
				console.error(e);
			});
	}
};