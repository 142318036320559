import {InnerStepZero} from "./InnerSteps/InnerStepZero";
import {InnerStepOne} from "./InnerSteps/InnerStepOne";
import Grid from "@mui/material/Grid";
import {InnerStepTwo} from "./InnerSteps/InnerStepTwo";

function _renderInnerStep (stepTwo, setStepTwo, setTabIndex, basicSetup) {
	switch (stepTwo.inner_step) {
		case 0:
			return (
				<InnerStepZero
					stepTwo={stepTwo}
					setStepTwo={setStepTwo}
					basicSetup={basicSetup}
				/>
			)
		case 1:
			return (
				<InnerStepOne
					stepTwo={stepTwo}
					setStepTwo={setStepTwo}
					basicSetup={basicSetup}
				/>
			)
		case 2:
			return (
				<InnerStepTwo
					stepTwo={stepTwo}
					setStepTwo={setStepTwo}
					basicSetup={basicSetup}
				/>
			)

		default:
			return;
	}
}

export const StepTwo = ({
	stepTwo,
	setStepTwo,
    setTabIndex,
	basicSetup,
}): JSX.Element => {

  return (
	  <Grid
		  item
		  xl={12}
		  lg={12}
		  md={12}
		  sm={12}
		  xs={12}
		  id={'step-two'}
	  >
		  {_renderInnerStep(stepTwo, setStepTwo, setTabIndex, basicSetup)}
	  </Grid>

  )
}