import { Button, Typography, Grid} from '@mui/material';
import { connect } from 'react-redux';
import closedEye from '../../../../../images/svg/closed-eye.svg';
import openEye from '../../../../../images/svg/open-eye.svg';
import { useState } from 'react';
import NewTextField from '../../../../common/NewTextField';

const InnerStepOne = ({
	stepOne,
	setStepOne,
	basicSetup = () => {},
	user = null,
}) => {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<Grid
			direction={'column'}
			container
			id={'inner-step-one'}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={'main-title-container'}
			>
				<Typography className={'main-title'}>HMRC Settings</Typography>
				<Typography className={'main-subtitle'}>
					We need the following information to properly calculate taxes due and
					report them to HMRC.
				</Typography>
			</Grid>

			<Grid
				item
				className={'body-sub-container'}
			>
				<Typography className={'body-subtitle-1'}>
					Real Time Information (RTI)
				</Typography>
				<Typography className={'body-subtitle-2'}>
					RTI is the system used in the to report payroll information in
					real-time to HMRC, the UK’s tax authority. FreePayroll automates HMRC
					reporting, allowing you to focus on running your business.
				</Typography>
			</Grid>

			<Grid
				item
				className={'body-sub-container'}
				paddingBottom={'50px'}
			>
				<Typography className={'body-subtitle-1'}>
					Some of the reports that we send to HMRC on your behalf
				</Typography>
				<Typography className={'body-subtitle-2'}>
					Full Payment Submission (FPS): This report must be submitted each time
					employees are paid and includes details such as employee earnings, tax
					and National Insurance contributions, and other deductions.
					<br />
					<br />
					Employer Payment Summary (EPS): This report is used to claim
					Employment Allowance, recover statutory payments, and report any other
					adjustments to the amounts previously reported in FPS submissions.
				</Typography>
			</Grid>

			<Grid
				columnSpacing={4}
				container
				item
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
			>
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={4}
					xs={4}
					item
					className={'body-sub-container-1'}
				>
					<NewTextField
						label={'HMRC Gateway ID*'}
						className={'default-text-field custom-height'}
						placeholder={'Type your user ID'}
						value={stepOne.rti_user_id}
						onChange={(e) => {
							setStepOne({ ...stepOne, rti_user_id: e.target.value });
						}}
						InputProps={{
							endAdornment: (
								<a
									href={
										'https://www.gov.uk/government/publications/access-our-services-using-government-gateway/use-a-government-gateway-account'
									}
									target={'_blank'}
									rel={'noreferrer'}
								>
									Help?
								</a>
							),
						}}
						autocomplete={'off'}
						fullWidth
					/>
				</Grid>

				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={4}
					xs={4}
					item
					className={'body-sub-container-2'}
				>
					<NewTextField
						className={'default-text-field custom-height'}
						value={stepOne.rti_password}
						label={'HMRC Password*'}
						onChange={(e) => {
							setStepOne({ ...stepOne, rti_password: e.target.value });
						}}
						type={showPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<img
									src={showPassword ? closedEye : openEye}
									alt={'show password'}
									onClick={() => setShowPassword(!showPassword)}
								/>
							),
						}}
						autocomplete={'off'}
						fullWidth
					/>
				</Grid>
			</Grid>

			<Grid
				container
				item
				className={'button-container'}
			>
				<Button
					className={'default-black-button'}
					disabled={!stepOne.rti_user_id || !stepOne.rti_password}
					onClick={() => {
						basicSetup({
							employers: {
								step: 'hmrc_settings',
								rti_keys: {
									user: stepOne.rti_user_id,
									password: stepOne.rti_password,
									paye_reference: stepOne.employer_paye_reference,
									unique_taxpayer_reference: stepOne.unique_taxpayer_reference,
									account_office_reference: stepOne.accounts_office_reference,
								},
								staging_date: stepOne.staging_date,
								agriculture_sector: stepOne.agriculture_sector,
								aquaculture_sector: stepOne.aquaculture_sector,
								road_transport_sector: stepOne.road_transport_sector,
								other_sector: stepOne.other_sector,
								small_employer_relief: stepOne.small_employer_relief,
								employment_allowance: stepOne.employment_allowance,
							},
						});
					}}
				>
					Continue
				</Button>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	const { Profile } = state;

	return {
		...Profile,
	};
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InnerStepOne);
