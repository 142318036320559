import Grid from "@mui/material/Grid";
import {connect} from "react-redux";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import {me} from "../../actions/Profile";
import React, {useEffect, useState} from "react";
import Loading from "../../components/Loading";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {EnhancedPolicyModal} from "../../components/Pages/Company/EnhancedPolicy/EnhancedPolicyModal";
import Window from "../../images/enhanced-policies/window-icon.svg";
import PlusWhite from "../../images/enhanced-policies/plus-white.svg";
import {createEnhancedPolicy, updateEnhancedPolicy} from "../../actions/EnhancedPolicy";
import StyleButton from "../../components/StyledComponents/StyleButton";
import {findEmployer, updateSer} from "../../actions/Employer";

function policyStageArray(policyStages = []) {
	let stages = [];
	for (let k = 0; k < policyStages.length; k ++) {
		let stageObjects = {
			id: policyStages[k]?.id,
			weeks: policyStages[k]?.weeks,
			per_pay: policyStages[k]?.calculation_type,
			percent: policyStages[k]?.amount,
		}
		stages.push(stageObjects);
	}
	return stages;
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const EnhancedPolicy = ({
	user = {},
	user_loading = false,
	enhanced_policy_loading = false,
	me = () => {
	},
	createEnhancedPolicy = () => {
	},
	updateEnhancedPolicy = () => {
	},
    employer_loading = false,
    employer = {},
    findEmployer = () => {},
	updateSer = () => {},
}): JSX.Element => {
	const [policyFields, setPolicyFields] = useState({
		name: "",
		type: "",
		policyArray: [
			{
				weeks: 0,
				per_pay: " ",
				percent: 0,
			},
		]
	}),
		[dialog, setDialog] = useState(false),
		[selectedPolicy, setSelectedPolicy] = useState({}),
		[deletedStages,setDeletedStages] = useState([]);

	useEffect(() => {
		if (!user.id) {
			me();
		}
		if (selectedPolicy?.id) {
			setPolicyFields((prevBasicDetails) => ({
				...prevBasicDetails,
				id: selectedPolicy?.id,
				name: selectedPolicy?.name,
				type: selectedPolicy?.is_paternity_pay && !selectedPolicy?.is_maternity_pay
					? "Enhanced paternity pay"
					: !selectedPolicy?.is_paternity_pay && selectedPolicy?.is_maternity_pay
						? "Enhanced maternity pay"
						: "",
				policyArray: policyStageArray(selectedPolicy?.enhanced_policies_stages)
			}));
		}
	}, [
		me,
		selectedPolicy?.enhanced_policies_stages,
		selectedPolicy?.id,
		selectedPolicy?.is_maternity_pay,
		selectedPolicy?.is_paternity_pay,
		selectedPolicy?.name,
		user
	]);

	useEffect(() => {
		if (user?.employer?.id) {
			findEmployer(["enhanced_policy"]);
		}
	}, [findEmployer, user?.employer?.id]);

	if (user_loading || enhanced_policy_loading || employer_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			id={"enhanced-policy"}
			container
			justifyContent={"center"}
		>
			<StyleAppBar
				backLink={"/main/company/enhanced-policy"}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Company"}
						activeSubpage={"Policies"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
						className={"enhanced-policy-container"}
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
							justifyContent={"space-between"}
							alignItems={"center"}
						>
							<Grid>
								<StyleTypography
									fontSize={40}
									fontSizeMedium={30}
									fontWeight={"bold"}
								>
									Company Wide Policies
								</StyleTypography>
							</Grid>
							<Grid
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								item
								container
								justifyContent={"space-between"}
								alignItems={"center"}
							>
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									paddingTop={"10px"}
								>
									<StyleTypography
										fontSize={16}
										fontSizeMedium={13}
										color={"#ABAEB7"}
									>
										Lorem Ipsum is simply dummy text of the printing and typesetting industry.
									</StyleTypography>
								</Grid>
								<Grid
									className={"enhanced-policy-divider"}
								>
									<Divider/>
								</Grid>
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									container
									item
									direction={"row"}
									alignItems={"flex-start"}
								>
									<Grid
										xs={6}
										item
									>
										<Grid>
											{
												user?.employer?.enhanced_policy?.map((item, index) => (
													<Grid
														paddingRight={"10px"}
														key={index}
														onClick={() =>
															setSelectedPolicy(item)
														}
													>
														<Grid
															className={"awesome-policy"}
															container
															direction={"row"}
															onClick={() => setDialog(true)}
														>
															<Grid paddingTop={"5px"} paddingRight={"20px"}>
																<img src={Window} alt={""} className={"img"}/>
															</Grid>
															<Grid>
																<StyleTypography
																	fontSize={18}
																	fontSizeMedium={13}
																	color={"black"}
																	fontWeight={"bold"}
																>
																	{item?.name}
																</StyleTypography>
																<StyleTypography
																	fontSize={16}
																	fontSizeMedium={11}
																	color={"#ABAEB7"}
																>
																	{
																		item?.is_paternity_pay && !item?.is_maternity_pay
																			? "Enhanced paternity pay"
																			: !item?.is_paternity_pay && item?.is_maternity_pay
																				? "Enhanced maternity pay"
																				: ""
																	}
																</StyleTypography>
															</Grid>
														</Grid>
													</Grid>
												))
											}
										</Grid>
										<Grid>
											<Button
												className={"create-button"}
												fullWidth
												onClick={() => setDialog(true)}
											>
												<Grid
													container
													direction={"row"}
													alignItems={"center"}
													alignContent={"center"}
													justifyContent={"center"}
												>
													<Grid xs={2} item>
														<img
															src={PlusWhite}
															alt={"logo"}
															className={"img"}
														/>
													</Grid>
													<Grid>
														<StyleTypography
															fontSize={16}
															color={"white"}
															fontWeight={"bold"}
															fontSizeMedium={11}
														>
															Create new policy
														</StyleTypography>
													</Grid>
												</Grid>
											</Button>
										</Grid>
									</Grid>
									<Grid/>
									<Grid
										xs={6}
										item
										className={"statutory-style"}
										paddingLeft={"20px"}
									>
										<StyleTypography
											fontSize={22}
											fontSizeMedium={16}
											fontWeight={"bold"}
											color={"black"}
										>
											Statutory pay claims
										</StyleTypography>
										<Grid marginTop={"10px"} marginBottom={"10px"}>
											<StyleTypography
												fontSize={18}
												fontSizeMedium={13}
												color={"#AAAEB7"}
											>
												If you have paid any Statutory Maternity Pay or
												Statutory Paternity Pay, we will claim 92% of it from HMRC.
												If you qualify for small Employer Relief, it will be 103%.
											</StyleTypography>
										</Grid>
										<Divider className={"divider"}/>
										<Grid
											container
											marginTop={"30px"}
											justifyContent={"space-between"}
											alignItems={"center"}
										>
											<Grid
												item
											>
												<StyleTypography
													fontSize={19}
													fontSizeMedium={15}
													color={"black"}
													fontWeight={"bold"}
												>
													Small Employer's Relief
												</StyleTypography>
											</Grid>
											<Grid
												item
												xs={3}
											>
												<StyleButton
													onClick={() => {
														updateSer({
															"employers": {
																"small_employer_relief": employer?.small_employer_relief ? 'false' : 'true'
															}
														})
													}}
												>
													<StyleTypography fontWeight={"bold"} fontSize={16} fontSizeMedium={12} color={"#FFF"}>
														{employer?.small_employer_relief ? "Stop" : "Enable"}
													</StyleTypography>
												</StyleButton>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<EnhancedPolicyModal
				user={user}
				open={dialog}
				policyFields={policyFields}
				deletedStages={deletedStages}
				setOpen={setDialog}
				setPolicyFields={setPolicyFields}
				setSelectedPolicy={setSelectedPolicy}
				setDeletedStages={setDeletedStages}
				createEnhancedPolicy={createEnhancedPolicy}
				updateEnhancedPolicy={updateEnhancedPolicy}
			/>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		EnhancedPolicy,
		Employer,
	} = state;

	return {
		...Profile,
		...EnhancedPolicy,
		...Employer,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	createEnhancedPolicy: (data) => dispatch(createEnhancedPolicy(data)),
	updateEnhancedPolicy: (id, data) => dispatch(updateEnhancedPolicy(id, data)),
	updateSer: (data) => dispatch(updateSer(data)),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedPolicy)