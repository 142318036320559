import AutomaticEnrolmentMenu from "./AutomaticEnrolmentMenu";
import AutomaticEnrolmentSettings from "./AutomaticEnrolmentSettings";
import AutomaticEnrolmentPayments from "./AutomaticEnrolmentPayments";
import AutomaticEnrolmentDdi from "./AutomaticEnrolmentDdi";

const AEPages = {
	AutomaticEnrolmentMenu,
	AutomaticEnrolmentSettings,
	AutomaticEnrolmentPayments,
	AutomaticEnrolmentDdi,
}

export default AEPages;