import {
	CREATE_ENHANCED_POLICY,
	UPDATE_ENHANCED_POLICY
} from "./types";
import Request from "../../utils/Request";

export const createEnhancedPolicy = (data) => dispatch => {
	dispatch({
		type: CREATE_ENHANCED_POLICY,
		enhanced_policy_loading: true
	});
	if (data) {
		return new Request.post('/api/employers/enhanced-policy', data)
			.then(response => {
				dispatch({
					type: CREATE_ENHANCED_POLICY,
					enhanced_policy_loading: false
				});
				window.location.href = '/main/company/enhanced-policy';
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: CREATE_ENHANCED_POLICY,
					enhanced_policy_loading: false
				});
			})
	}
};

export const updateEnhancedPolicy = (id, data) => dispatch => {
	dispatch({
		type: UPDATE_ENHANCED_POLICY,
		enhanced_policy_loading: true
	});
	if (data && id) {
		return new Request.put(`/api/employers/enhanced-policy/${id}`, data)
			.then(response => {
				dispatch({
					type: UPDATE_ENHANCED_POLICY,
					enhanced_policy_loading: false
				});
				window.location.href = '/main/company/enhanced-policy';
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: UPDATE_ENHANCED_POLICY,
					enhanced_policy_loading: false
				});
			})
	}
};