import React, { useState, useEffect } from 'react';
import StyleTypography from "./StyledComponents/StyleTypography";
import ErrorSvg from "../images/svg/Errors/NotFound.svg"

const ScreenWidthChecker = ({ children }) => {
  const [isTooSmall, setIsTooSmall] = useState(window.innerWidth < 1000); // Change 1000 to your desired minimum width

  const handleResize = () => {
    setIsTooSmall(window.innerWidth < 1000); // Change 1000 to your desired minimum width
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isTooSmall) {
    return (
      <div style={{ padding: '10%', textAlign: 'center' , verticalAlign: 'middle'}}>
        <img
            src={ErrorSvg}
            alt={'Device width too small'}
            style={{ width: '100%', maxWidth: '500px', height: 'auto', marginBottom: '40px'}}
        />
        <StyleTypography
            color={'black'}
            fontSizeMedium={70}
        >
          Your screen is too small to <br/>
            use this application.
        </StyleTypography>
      </div>
    );
  }

  return children;
};

export default ScreenWidthChecker;
