import {resolveReduxState} from "../../utils/Helpers";
import {
	CREATE_TOKEN,
	GET_MANDATE_INFO,
	MANDATE_FLOW_INITIATED,
	RETRIEVE_TOKEN
} from "../../actions/BanksMandates/types";

const initial_state = {
	token_loading: false,
	token: "",
	mandate_flow_initiated: false,
	mandate_info: {},
	mandate_loading: false,
}

const BanksMandates = (state = initial_state, action = false) => {
	if (action) {
		switch(action.type) {

			case GET_MANDATE_INFO:
			case MANDATE_FLOW_INITIATED:
			case CREATE_TOKEN:
			case RETRIEVE_TOKEN:
				return resolveReduxState(state, action);

			default:
				return state;
		}
	}

	return state;
}

export default BanksMandates;