import Grid from "@mui/material/Grid";
import {StyleAppBar} from "../../components/StyleAppBar";
import React, {useEffect, useReducer, useState} from "react";
import {LeftMenu} from "../../components/LeftMenu";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import {invalidCode, me, setEditInfo, updatePassword} from "../../actions/Profile";
import {connect} from "react-redux";
import Loading from "../../components/Loading";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import closedEye from "../../images/svg/closed-eye.svg";
import openEye from "../../images/svg/open-eye.svg";
import {validatePassword} from "../../utils/Helpers";

const EditPasswordForm = ({
	user = {
		id: null,
		temporary_password: false,
	},
	user_loading = false,
	me = () => {},
	invalidCode = () => {},
	updatePassword = () => {},
	setEditInfo = () => {}
}): JSX.Element => {
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [passwordInfo, setPasswordInfo] = useReducer(
		(state, updates) => ({...state, ...updates}),
		{
			new_password: "",
			confirm_new_password: "",
			current_password: ""
		}
	);

	useEffect(() => {
		invalidCode();

		if (!user.id) {
			me();
			setEditInfo("password");
		}
	}, [user.id, me, invalidCode, setEditInfo]);

	if (user_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"onboarding-dashboard-page"}
		>
			<StyleAppBar
				showBackButton={!user.temporary_password}
				backLink={user.temporary_password ? null : "/main/settings/edit-info"}
				user={user}
			/>
			<Grid
				xl={11}
				lg={11}
				md={11}
				sm={11}
				xs={11}
				item
				container
				id={"main-content-container"}
			>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{
						!user.temporary_password &&
						<LeftMenu/>
					}
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "79px",
					}}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
					>
						<Grid>
							<StyleTypography
								fontSize={40}
								fontSizeMedium={30}
								fontWeight={"bold"}
								component={"h1"}
							>
								Change password
							</StyleTypography>
						</Grid>
						<Grid
							className={"sub-title-container"}
						>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								color={"#ABAEB7"}
								component={"h2"}
							>
								Lorem Ipsum is simply dummy text of the printing and typesetting industry.
							</StyleTypography>
						</Grid>
					</Grid>

					<Grid
						container
						id={"edit-info-form-container"}
						direction={"row"}
						item
						xs={12}
						sm={12}
						md={12}
						lg={5}
						xl={5}
					>
						<Grid
							className={"edit-info-form-input"}
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
						>
							<InputLabel
								className={"default-input-label"}
							>
								New Password *
							</InputLabel>
							<OutlinedInput
								className={"default-text-field"}
								fullWidth
								required
								type={showPassword ? "text" : "password"}
								value={passwordInfo.new_password}
								onChange={(e) => setPasswordInfo({
									new_password: e.target.value
								})}
								endAdornment={
									<img
										src={showPassword ? closedEye : openEye}
										alt={"show password"}
										onClick={() => setShowPassword(!showPassword)}
									/>
								}
								error={
									!validatePassword(passwordInfo.new_password).valid &&
									passwordInfo.new_password !== ""
								}
							/>
							{
								!validatePassword(passwordInfo.new_password).valid &&
								passwordInfo.new_password !== "" &&
								<small
									className={"red-text"}
								>
									The password must have at least 8 characters, with at least one letter and one number
								</small>
							}
						</Grid>

						<Grid
							className={"edit-info-form-input"}
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
						>
							<InputLabel
								className={"default-input-label"}
							>
								Confirm New Password *
							</InputLabel>
							<OutlinedInput
								className={"default-text-field"}
								fullWidth
								required
								type={showPassword ? "text" : "password"}
								value={passwordInfo.confirm_new_password}
								onChange={(e) => setPasswordInfo({
									confirm_new_password: e.target.value
								})}
								endAdornment={
									<img
										src={showPassword ? closedEye : openEye}
										alt={"show password"}
										onClick={() => setShowPassword(!showPassword)}
									/>
								}
								error={
									passwordInfo.new_password !== passwordInfo.confirm_new_password
								}
							/>
							{
								passwordInfo.new_password !== passwordInfo.confirm_new_password &&
								<small className={"red-text"}>
									Passwords do not match
								</small>
							}
						</Grid>

						<Grid
							className={"edit-info-form-input"}
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
						>
							<InputLabel
								className={"default-input-label"}
							>
								Current Password *
							</InputLabel>
							<OutlinedInput
								className={"default-text-field"}
								fullWidth
								required
								type={showPassword ? "text" : "password"}
								value={passwordInfo.current_password}
								onChange={(e) => setPasswordInfo({
									current_password: e.target.value
								})}
								endAdornment={
									<img
										src={showPassword ? closedEye : openEye}
										alt={"show password"}
										onClick={() => setShowPassword(!showPassword)}
									/>
								}
							/>
						</Grid>

						<Grid
							id={"edit-info-form-buttons-container"}
							justifyContent={"flex-start"}
							alignItems={"center"}
							container
							spacing={1}
						>
							<Grid
								item
								xs={4}
								sm={4}
								md={4}
								lg={4}
								xl={4}
							>
								<Button
									className={"default-black-button"}
									onClick={() => updatePassword({
										"users": {
											...user,
											password_info: passwordInfo
										}
									})}
									disabled={
										passwordInfo.new_password !== passwordInfo.confirm_new_password ||
										!validatePassword(passwordInfo.new_password).valid
									}
								>
									<StyleTypography
										fontSize={14}
										fontSizeMedium={11}
										color={"#fff"}
										fontWeight={"bold"}
									>
										Change password
									</StyleTypography>
								</Button>
							</Grid>

							<Grid
								item
								xs={4}
								sm={4}
								md={4}
								lg={4}
								xl={4}
							>
								<Button
									className={"default-white-button"}
									onClick={() => {
										setPasswordInfo({});
										navigate("/main/settings/edit-info");
									}}
									disabled={user.temporary_password}
								>
									<StyleTypography
										fontSize={14}
										fontSizeMedium={11}
										color={"#000"}
										fontWeight={"bold"}
									>
										Discard changes
									</StyleTypography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
	} = state;

	return {
		...Profile,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	invalidCode: () => dispatch(invalidCode()),
	updatePassword: (data) => dispatch(updatePassword(data)),
	setEditInfo: (data) => dispatch(setEditInfo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPasswordForm);