import Grid from "@mui/material/Grid";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const NewPayItemType = ({
	title = "",
	subTitle = "",
	payItemBasicDetails = {},
	paymentTypeOptions = [],
	setStep = () => {
	},
	setPayItemBasicDetails = () => {
	},
}): JSX.Element => {
	return (
		<Grid
			container
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				justifyContent={"space-between"}
				alignItems={"center"}
			>
				<Grid>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={30}
						fontWeight={"bold"}
					>
						{title}
					</StyleTypography>
				</Grid>
				<Grid
					container
				>
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
						container
						alignItems={"center"}
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							paddingTop={"10px"}
						>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								color={"#ABAEB7"}
							>
								{subTitle}
							</StyleTypography>
						</Grid>
						<Grid
							className={"pay-items-divider"}
						>
							<Divider/>
						</Grid>
						<Grid
							container
							className={"pay-items-new-pay-item"}
						>
							<InputLabel
								className={"default-input-label"}
							>
								Pay item name
							</InputLabel>
							<FormControl
								fullWidth
							>
								<TextField
									className={"default-text-field"}
									fullWidth
									required
									value={payItemBasicDetails?.payItemName}
									onChange={(e) =>
										e.target.value.length <= 60
											? setPayItemBasicDetails({
												...payItemBasicDetails,
												payItemName: e.target.value,
											})
											: {}
									}
								/>
							</FormControl>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={10}
								color={"#AAAEB7"}
							>
								{60 - payItemBasicDetails?.payItemName?.length} characters remaining
							</StyleTypography>
						</Grid>
						<Grid
							container
							className={"pay-items-new-pay-item"}
							marginTop={1}
						>
							<InputLabel
								className={"default-input-label"}
							>
								Pay item code (This will be the code displayed on your payroll journal)
							</InputLabel>
							<FormControl
								fullWidth
							>
								<TextField
									className={"default-text-field"}
									fullWidth
									required
									value={payItemBasicDetails?.payItemCode}
									onChange={(e) =>
										e.target.value.length <= 60
											? setPayItemBasicDetails({
												...payItemBasicDetails,
												payItemCode: e.target.value,
											})
											: {}
									}
								/>
							</FormControl>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={10}
								color={"#AAAEB7"}
							>
								{60 - payItemBasicDetails?.payItemName?.length} characters remaining
							</StyleTypography>
						</Grid>
						<Grid container className={"pay-items-margin-check-box"}>
							<FormControlLabel
								control={
									<Checkbox
										value={payItemBasicDetails?.is_benefit_in_kind}
										checked={payItemBasicDetails?.is_benefit_in_kind}
										icon={<CheckBoxOutlineBlankSharpIcon/>}
										checkedIcon={<CheckBoxSharpIcon/>}
										onChange={() =>
											setPayItemBasicDetails({
												...payItemBasicDetails,
												is_benefit_in_kind: !payItemBasicDetails?.is_benefit_in_kind,
												paymentType: " ",
											})
										}
									/>
								}
								label={
									<StyleTypography
										color={"black"}
										fontSize={16}
										fontSizeMedium={12}
										className={"checkbox-label"}
									>
										This is a benefit in kind
									</StyleTypography>
								}
							/>
						</Grid>
						<Grid
							container
							className={"pay-items-new-pay-item-margin"}
						>
							<InputLabel
								className={"default-input-label"}
							>
								Payment Type
							</InputLabel>
							<FormControl
								fullWidth
							>
								<Select
									className={"default-select"}
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: 200,
											},
										},
									}}
									value={payItemBasicDetails?.paymentType}
									onChange={(e) => {
										setPayItemBasicDetails({
											...payItemBasicDetails,
											paymentType: e.target.value,
										});
									}}
								>
									<MenuItem
										key={"select"}
										value={" "}
										disabled
									>
										Select
									</MenuItem>
									{
										paymentTypeOptions?.map((item, index) => {
											if (payItemBasicDetails?.is_benefit_in_kind) {
												if (item.length > 0) {
													if (item === "Gross addition") {
														return (
															<MenuItem key={index} value={item}>
																{item}
															</MenuItem>
														);
													} else {
														return null;
													}
												} else {
													return null;
												}
											} else {
												return (
													<MenuItem key={index} value={item}>
														{item}
													</MenuItem>
												);
											}
										})
									}
								</Select>
							</FormControl>
							<Grid paddingTop={"8%"} paddingBottom={"20%"}>
								<Button
									className={"save-button"}
									fullWidth
									onClick={() => {
										switch (title) {
											case "New One-off Pay Item":
												setStep(3);
												break;
											case "New Recurring Pay Item":
												setStep(4);
												break;
											default:
												break;
										}
									}}
									disabled={
										payItemBasicDetails?.payItemName?.length === 0 ||
										payItemBasicDetails?.paymentType === " " ||
										payItemBasicDetails?.paymentType?.length === 0
									}
								>
									<StyleTypography
										fontSize={14}
										fontWeight={"bold"}
										fontSizeMedium={12}
										color={"white"}
									>
										Next
									</StyleTypography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default NewPayItemType