import Grid from "@mui/material/Grid";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import StyleTypography from "../../StyledComponents/StyleTypography";
import FormControl from "@mui/material/FormControl";
import {CurrencyInputCustom} from "../../StyledComponents/CurrencyInputCustom";

export const SalaryModal = ({
	open,
	setOpen,
	employeeRecord,
	setEmployeeRecord,
	employee,
	updateEmployee,
}): JSX.Element => {

	return (
		<Dialog
			onClose={() => setOpen(false)}
			open={open}
			fullWidth={true}
			maxWidth={'sm'}
			id={"salary-modal"}
		>
			<DialogTitle>
				<StyleTypography
					fontSize={35}
					fontSizeMedium={28}
					fontWeight={'bold'}
				>
					Edit salary for {employeeRecord.forename} {employeeRecord.surname}
				</StyleTypography>
			</DialogTitle>
			<DialogContent>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					{/*Employment Type*/}
					<Grid
						item
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Salary type*
						</InputLabel>
						<FormControl
							fullWidth
							sx={{
								marginTop: '10px',
							}}
						>
							<Select
								className={"default-select"}
								id={"select-gender"}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 200, // Adjust the maximum height of the list
										},
									},
								}}
								value={employeeRecord.salary?.employment_type ?? " "}
								onChange={(e) => {
									setEmployeeRecord({
										...employeeRecord,
										salary: {
											...employeeRecord.salary,
											employment_type: e.target.value,
											annual_salary: "",
											monthly_salary: "",
											hourly_salary: "",
											weekly_salary: "",
											expected_work_hours_per_week: '',
										}
									})
								}}
							>
								<MenuItem
									key={'select-title'}
									value={" "}
									disabled
								>
									Select
								</MenuItem>
								<MenuItem
									key={'salaried'}
									value={"salaried"}
								>
									Salaried
								</MenuItem>
								<MenuItem
									key={'hourly'}
									value={"hourly"}
								>
									Hourly
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					{/*Annual, Monthly and Weekly salaries*/}
					{
						(employeeRecord.salary?.employment_type === 'salaried') && (
							<>
								{/*Annual salary*/}
								<Grid
									item
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
								>
									<InputLabel
										className={"default-input-label"}
									>
										Annual salary*
									</InputLabel>
									<TextField
										fullWidth
										className={"default-text-field"}
										InputProps={{
											endAdornment: <InputAdornment position={"end"}>
												<StyleTypography fontSize={16} color={"black"} fontWeight={"bold"}>
													£
												</StyleTypography>
											</InputAdornment>,
											inputComponent: CurrencyInputCustom,
											inputProps: {prefix: ""},
										}}
										value={employeeRecord.salary?.annual_salary ?? ""}
										onChange={(e) => {
											setEmployeeRecord({
												...employeeRecord,
												salary: {
													...employeeRecord.salary,
													annual_salary: e.target.value,
													monthly_salary: (e.target.value / 12 * 100).toFixed(2) % 1 === 0 ? (e.target.value / 12).toFixed(2) : Math.ceil(e.target.value / 12 * 100) / 100,
													weekly_salary: (e.target.value / 52 * 100).toFixed(2) % 1 === 0 ? (e.target.value / 52).toFixed(2) : Math.ceil(e.target.value / 52 * 100) / 100,
													hourly_salary: Math.floor(
														(e.target.value / 52 % 1 === 0 ? e.target.value / 52 : Math.ceil(e.target.value / 52 * 100) / 100) /
														(employeeRecord.salary?.expected_work_hours_per_week ?? 0) * 100
													) / 100,
												}
											})
										}}
									/>
								</Grid>

								{/*Monthly salary*/}
								<Grid
									item
									xl={6}
									lg={6}
									md={6}
									sm={6}
									xs={6}
								>
									<InputLabel
										className={"default-input-label"}
									>
										Monthly salary*
									</InputLabel>
									<TextField
										fullWidth
										className={"default-text-field"}
										InputProps={{
											endAdornment: <InputAdornment position={"end"}>
												<StyleTypography fontSize={16} color={"black"} fontWeight={"bold"}>
													£
												</StyleTypography>
											</InputAdornment>,
											inputComponent: CurrencyInputCustom,
											inputProps: {prefix: ""},
										}}
										onChange={(e) => {
											setEmployeeRecord({
												...employeeRecord,
												salary: {
													...employeeRecord.salary,
													annual_salary: Math.floor(e.target.value * 12 * 10)/10,
													monthly_salary: e.target.value,
													weekly_salary: Math.ceil((Math.floor(e.target.value * 12 * 10)/10 / 52) * 100)/100,
													hourly_salary: Math.floor((Math.ceil((Math.floor(e.target.value * 12 * 10)/10 / 52) * 100)/100) / (employeeRecord.salary?.expected_work_hours_per_week ?? 0) * 100)/100,
												}
											})
										}}
										value={employeeRecord.salary?.monthly_salary ?? ""}
									/>
								</Grid>

								{/*Weekly salary*/}
								<Grid
									item
									xl={6}
									lg={6}
									md={6}
									sm={6}
									xs={6}
								>
									<InputLabel
										className={"default-input-label"}
									>
										Weekly salary
									</InputLabel>
									<TextField
										fullWidth
										className={"default-text-field"}
										InputProps={{
											endAdornment: <InputAdornment position={"end"}>
												<StyleTypography fontSize={16} color={"black"} fontWeight={"bold"}>
													£
												</StyleTypography>
											</InputAdornment>,
											inputComponent: CurrencyInputCustom,
											inputProps: {prefix: ""},
										}}
										onChange={(e) => {
											setEmployeeRecord({
												...employeeRecord,
												salary: {
													...employeeRecord.salary,
													annual_salary: Math.floor(e.target.value * 52 * 10) / 10,
													monthly_salary: Math.ceil((Math.floor(e.target.value * 52 * 10) / 10) / 12 * 100) / 100,
													weekly_salary: e.target.value,
													hourly_salary: Math.floor((e.target.value / (employeeRecord.salary?.expected_work_hours_per_week ?? 0)) * 100)/100,
												}
											})
										}}
										value={employeeRecord.salary?.weekly_salary ?? ""}
									/>
								</Grid>
							</>
						)
					}

					{/*Hourly salary*/}
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Hourly salary*
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							InputProps={{
								endAdornment: <InputAdornment position={"end"}>
									<StyleTypography fontSize={16} color={"black"} fontWeight={"bold"}>
										£
									</StyleTypography>
								</InputAdornment>,
								inputComponent: CurrencyInputCustom,
								inputProps: {prefix: ""},
							}}
							value={employeeRecord.salary?.hourly_salary ?? ""}
							onChange={(e) => {
								setEmployeeRecord({
									...employeeRecord,
									salary: {
										...employeeRecord.salary,
										hourly_salary: e.target.value,
									}
								})
							}}
							disabled={employeeRecord.salary?.employment_type !== 'hourly'}
						/>
					</Grid>

					{/*Hours per week*/}
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Expected hours of work per week*
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							value={employeeRecord.salary?.expected_work_hours_per_week ?? ""}
							onChange={(e) => {
								if (employeeRecord.salary?.employment_type === 'salaried') {
									setEmployeeRecord({
										...employeeRecord,
										salary: {
											...employeeRecord.salary,
											expected_work_hours_per_week: e.target.value,
											hourly_salary: employeeRecord.salary?.annual_salary / (52 * e.target.value),
										}
									})
								} else {
									setEmployeeRecord({
										...employeeRecord,
										salary: {
											...employeeRecord.salary,
											expected_work_hours_per_week: e.target.value,
											hourly_salary: e.target.value === "" ? "" : employeeRecord.salary?.hourly_salary,
										}
									})
								}
							}}
							InputProps={{
								inputComponent: CurrencyInputCustom,
								inputProps: { prefix: "" },
								endAdornment: "Hours"
							}}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<Button
							fullWidth
							className={"default-black-button"}
							disabled={
								employeeRecord.salary === null ||
								employeeRecord.salary.employment_type === "" ||
								(
									employeeRecord.salary.expected_work_hours_per_week === "" ||
									employeeRecord.salary.expected_work_hours_per_week === undefined
								) ||
								((
									employeeRecord.salary.employment_type === 'salaried'
								) && (
									(
										employeeRecord.salary.annual_salary === "" ||
										employeeRecord.salary.annual_salary === undefined
									) ||
									(
										employeeRecord.salary.monthly_salary === "" ||
										employeeRecord.salary.monthly_salary === undefined
									) || (
										employeeRecord.salary.weekly_salary === "" ||
										employeeRecord.salary.weekly_salary === undefined
									)
								)) ||
								(
									employeeRecord.salary.hourly_salary === "" ||
									employeeRecord.salary.hourly_salary === undefined
								)
							}
							onClick={() => {
								updateEmployee(employee.safe_id, {
									employees: {
										'action': 'salary',
										salary_id: employeeRecord.salary?.id,
										monthly_salary: employeeRecord.salary?.monthly_salary,
										annual_salary: employeeRecord.salary?.annual_salary,
										hourly_salary: employeeRecord.salary?.hourly_salary,
										weekly_salary: employeeRecord.salary?.weekly_salary,
										expected_work_hours_per_week: employeeRecord.salary.expected_work_hours_per_week,
										employment_type: employeeRecord.salary.employment_type,
										pay_schedule_run_id: employee.pay_schedule.active_pay_run.id,
									}
								})
							}}
						>
							Save
						</Button>
					</Grid>

					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<Button
							fullWidth
							className={"default-white-button"}
							onClick={() => {
								setEmployeeRecord({
									...employeeRecord,
									salary: employee.salary,
								});
								setOpen(false);
							}}
						>
							Cancel
						</Button>
					</Grid>

				</Grid>
			</DialogActions>
		</Dialog>
	)
}