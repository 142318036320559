import {
	keepPreviousData,
	useQuery,
} from '@tanstack/react-query';
import Request from "../utils/Request";

export function useListPayrolls({mode, page, orderBy, orderDir}) {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['pay-schedules', mode, page, orderBy, orderDir],
		queryFn: async () => {
			return await Request.get('/api/employers/payrolls/get',{params: {page, mode, orderBy, orderDir}})
				.then(response => response.data)
		},
		placeholderData: keepPreviousData,
	});

	return {
		payrolls: data?.data,
		isPending,
		isError,
	};
}