export const ADD_EMPLOYEE = "ADD_EMPLOYEE";

export const FETCH_EMPLOYEES = "FETCH_EMPLOYEES";

export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";

export const CLEAR_EMPLOYEE = "CLEAR_EMPLOYEE";

export const RETRIEVE_CATEGORIES = "RETRIEVE_CATEGORIES";

export const ADD_PARENTAL_STATUTORY = "ADD_PARENTAL_STATUTORY";

export const GET_PAYROLL_AND_EMAIL = "GET_PAYROLL_AND_EMAIL";