import Grid from "@mui/material/Grid";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel, MenuItem, Select,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import StyleTypography from "../../StyledComponents/StyleTypography";
import {managePayItem} from "../../../actions/PayScheduleRun";
import {connect} from "react-redux";
import {getPayItems} from "../../../actions/PayItem";
import FormControl from "@mui/material/FormControl";
import {useState} from "react";
import {CurrencyInputCustom} from "../../StyledComponents/CurrencyInputCustom";
import {Decimal} from 'decimal.js';

const SalaryPayItem = ({selectedPayItem, amount, setAmount, units, setUnits, salaryRate, setSalaryRate}) => {

	return (
		<>
			{/*Hours or Units*/}
			<Grid
				item
				xl={6}
				lg={6}
				md={6}
				sm={6}
				xs={6}
			>
				<InputLabel
					className={"default-input-label"}
				>
					{
						selectedPayItem.salary_type === 'hourly' ? 'Hours' : 'Units'
					}
				</InputLabel>
				<TextField
					fullWidth
					className={"default-text-field"}
					onChange={(event) => {
						setUnits(event.target.value);
						if (event.target.value === '') {
							setAmount('');
							return;
						}
						if (!salaryRate) return;
						const a = new Decimal(event.target.value);
						const b = new Decimal(salaryRate ?? 0);
						setAmount(a.times(b).toNumber());
					}}
					value={units}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: {prefix: ""},
						endAdornment: ""
					}}
				/>
			</Grid>

			{/*Salary rate*/}
			<Grid
				item
				xl={6}
				lg={6}
				md={6}
				sm={6}
				xs={6}
			>
				<InputLabel
					className={"default-input-label"}
				>
					Salary Rate*
				</InputLabel>
				<TextField
					disabled={selectedPayItem.pay_method === 'fixed'}
					fullWidth
					className={"default-text-field"}
					value={salaryRate}
					onChange={(event) => {
						setSalaryRate(event.target.value);
						if (event.target.value === '') {
							setAmount('');
							return;
						}
						if (!units) return;
						const a = new Decimal(units);
						const b = new Decimal(event.target.value);
						setAmount(a.times(b).toNumber());
					}}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: {prefix: ""},
						endAdornment: "£"
					}}
				/>
			</Grid>

			{/*Amount*/}
			<Grid
				item
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
			>
				<InputLabel
					className={"default-input-label"}
				>
					Amount*
				</InputLabel>
				<TextField
					disabled
					fullWidth
					className={"default-text-field"}
					value={amount}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: {prefix: ""},
						endAdornment: "£"
					}}
				/>
			</Grid>
		</>
	)
}

const OvertimePayItem = ({selectedPayItem, amount, setAmount, units, setUnits, salaryRate, setSalaryRate}) => {

	return (
		<>
			{/*Units*/}
			<Grid
				item
				xl={6}
				lg={6}
				md={6}
				sm={6}
				xs={6}
			>
				<InputLabel
					className={"default-input-label"}
				>
					Units*
				</InputLabel>
				<TextField
					fullWidth
					className={"default-text-field"}
					onChange={(event) => {
						setUnits(event.target.value);
						if (event.target.value === '') {
							setAmount('');
							return;
						}
						if (!salaryRate) return;
						const a = new Decimal(event.target.value);
						const b = new Decimal(salaryRate);
						setAmount(a.times(b).toNumber());
					}}
					value={units}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: {prefix: ""},
						endAdornment: ""
					}}
				/>
			</Grid>

			{/*Salary rate*/}
			<Grid
				item
				xl={6}
				lg={6}
				md={6}
				sm={6}
				xs={6}
			>
				<InputLabel
					className={"default-input-label"}
				>
					Overtime Rate ({selectedPayItem.salary_multiplier}x)
				</InputLabel>
				<TextField
					disabled={selectedPayItem.pay_method === 'fixed'}
					fullWidth
					className={"default-text-field"}
					value={salaryRate}
					onChange={(event) => {
						setSalaryRate(event.target.value);
						if (event.target.value === '') {
							setAmount('');
							return;
						}
						if (!units) return;
						const a = new Decimal(units);
						const b = new Decimal(event.target.value);
						setAmount(a.times(b).toNumber());
					}}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: {prefix: ""},
						endAdornment: "£"
					}}
				/>
			</Grid>

			{/*Amount*/}
			<Grid
				item
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
			>
				<InputLabel
					className={"default-input-label"}
				>
					Amount*
				</InputLabel>
				<TextField
					disabled
					fullWidth
					className={"default-text-field"}
					value={amount}
					InputProps={{
						inputComponent: CurrencyInputCustom,
						inputProps: {prefix: ""},
						endAdornment: "£"
					}}
				/>
			</Grid>
		</>
	)
}

const OneOffPayItem = ({amount, setAmount}) => {
	//AMOUNT
	return (
		<Grid
			item
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
		>
			<InputLabel
				className={"default-input-label"}
			>
				Amount*
			</InputLabel>
			<TextField
				fullWidth
				className={"default-text-field"}
				onChange={(event) => {
					setAmount(event.target.value);
				}}
				value={amount}
				InputProps={{
					inputComponent: CurrencyInputCustom,
					inputProps: {prefix: ""},
					endAdornment: "£"
				}}
			/>
		</Grid>
	)
}


const AddPayItemModal = ({
	open,
	setOpen,
	pay_items = [],
	getPayItems,
	managePayItem,
	selectedEmployee,
}): JSX.Element => {

	const [selectedPayItem, setSelectedPayItem] = useState(' ');
	const [amount, setAmount] = useState('');
	const [units, setUnits] = useState('');
	const [salaryRate, setSalaryRate] = useState(selectedPayItem?.salary_rate);

	return (
		<Dialog
			onClose={() => {
				setSelectedPayItem(' ');
				setAmount('');
				setOpen(false);
			}}
			open={open}
			fullWidth={true}
			maxWidth={'sm'}
			id={"salary-modal"}
		>
			<DialogTitle>
				<StyleTypography
					fontSize={35}
					fontSizeMedium={28}
					fontWeight={'bold'}
				>
					Add Pay Item
				</StyleTypography>
			</DialogTitle>
			<DialogContent>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					{/*Pay Item dropdown*/}
					<Grid
						item
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Pay Items*
						</InputLabel>
						<FormControl
							fullWidth
						>
							<Select
								className={"default-select"}
								id={"select-gender"}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 200, // Adjust the maximum height of the list
										},
									},
								}}
								onChange={(event) => {
									if (event.target.value?.salary_type === 'multiplier' && event.target.value?.pay_method === 'fixed') {
										const a = new Decimal(selectedEmployee.employee.salary.hourly_salary);
										const b = new Decimal(event.target.value?.salary_multiplier);
										setSalaryRate(a.times(b).toNumber());
									} else {
										setSalaryRate(event.target.value?.salary_rate);
									}
									setSelectedPayItem(event.target.value);
									setAmount('');
									setUnits('');
								}}
								value={selectedPayItem}
							>
								<MenuItem
									key={'select-title'}
									value={" "}
									disabled
								>
									Select
								</MenuItem>
								{
									pay_items.map((pay_item) => {
										return (
											<MenuItem
												key={pay_item.id}
												value={pay_item}
											>
												{pay_item.name} {pay_item.is_salary ? ' (Salary)' : ''}
											</MenuItem>
										)
									})
								}
							</Select>
						</FormControl>
					</Grid>

					{
						(selectedPayItem.is_salary && selectedPayItem.salary_type !== 'multiplier') && (
							<SalaryPayItem
								selectedPayItem={selectedPayItem}
								setAmount={setAmount}
								setUnits={setUnits}
								amount={amount}
								units={units}
								salaryRate={salaryRate}
								setSalaryRate={setSalaryRate}
							/>
						)
					}

					{
						(selectedPayItem.is_salary && selectedPayItem.salary_type === 'multiplier') && (
							<OvertimePayItem
								selectedPayItem={selectedPayItem}
								setAmount={setAmount}
								setUnits={setUnits}
								amount={amount}
								units={units}
								salaryRate={salaryRate}
								setSalaryRate={setSalaryRate}
							/>
						)
					}

					{
						(selectedPayItem.is_salary === false && selectedPayItem.is_recurring === false) && (
							<OneOffPayItem
								amount={amount}
								setAmount={setAmount}
							/>
						)
					}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<Button
							fullWidth
							className={"default-black-button"}
							disabled={selectedPayItem === ' ' || amount === ''}
							onClick={() => {
								managePayItem({
									"pay_schedule_runs": {
										item_amount: parseFloat(amount),
										salary_units: units ? parseFloat(units) : null,
										pay_method: selectedPayItem.pay_method,
										salary_rate: salaryRate ? parseFloat(salaryRate) : null,
										employee_pay_run_id: selectedEmployee.id,
										employee_id: selectedEmployee.employee_id,
										employer_pay_item_id: selectedPayItem.id,
										action: 'add'
									}
								})
							}}
						>
							Add
						</Button>
					</Grid>

					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<Button
							fullWidth
							className={"default-white-button"}
							onClick={() => {
								setSelectedPayItem(' ');
								setAmount('');
								setOpen(false);
							}}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

const mapStateToProps = state => {
	const {
		PayScheduleRun,
		PayItem,
	} = state;

	return {
		...PayScheduleRun,
		...PayItem,
	}
}

const mapDispatchToProps = dispatch => ({
	getPayItems: dispatch(getPayItems()),
	managePayItem: (data) => dispatch(managePayItem(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPayItemModal)