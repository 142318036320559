import Grid from '@mui/material/Grid';
import { useState } from 'react';
import SignUpCard from '../components/Pages/SignUp/SignUpCard';
import CreateAccountCard from '../components/Pages/SignUp/CreateAccountCard';
import Loading from '../components/Loading';
import { connect } from 'react-redux';

const renderContent = (step = 0, setStep) => {
	switch (step) {
		case 0:
			return (
				<SignUpCard
					step={step}
					setStep={setStep}
				/>
			);
		case 1:
			return <CreateAccountCard />;
		default:
			return <></>;
	}
};

const SignUp = ({ signup_loading = false }): JSX.Element => {
	const [step, setStep] = useState(0);

	if (signup_loading) {
		return <Loading />;
	}

	return (
		<Grid
			id={'sign-up-image'}
			container
		>
			<Grid
				item
				md={6}
				sm={12}
				xs={12}
				lg={6}
				xl={6}
			/>
			<Grid
				id={'sign-up-container'}
				container
				justifyContent={'flex-end'}
				alignItems={'center'}
				item
				md={6}
				sm={12}
				xs={12}
				lg={6}
				xl={6}
			>
				{renderContent(step, setStep)}
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	const { SignUp } = state;

	return {
		...SignUp,
	};
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
