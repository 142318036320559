import Grid from "@mui/material/Grid";
import {connect} from "react-redux";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import {me} from "../../actions/Profile";
import React, {useEffect, useState} from "react";
import Loading from "../../components/Loading";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Divider from "@mui/material/Divider";
import {findEmployer, updateSer, updateStatutoryAmounts} from "../../actions/Employer";
import {InputLabel} from "@mui/material";
import TextField from "@mui/material/TextField";
import {CurrencyInputCustom} from "../../components/StyledComponents/CurrencyInputCustom";
import StyleButton from "../../components/StyledComponents/StyleButton";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const Statutories = ({
	user = {},
	user_loading = false,
	me = () => {},
	updateSer = () => {},
	updateStatutoryAmounts = () => {},
	employer = {},
	findEmployer = () => {},
	employer_loading = false,
}): JSX.Element => {

	const [employerRecord, setEmployerRecord] = useState({});

	useEffect(() => {
		me();
		findEmployer(["active_employer_ytd_figure"]);
	}, [findEmployer, me]);

	useEffect(() => {
		setEmployerRecord(employer);
	}, [employer]);

	if (employer_loading || user_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			id={"enhanced-policy"}
			container
			justifyContent={"center"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
				employer={employer}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Company"}
						activeSubpage={"Statutory"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
						className={"enhanced-policy-container"}
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
							justifyContent={"space-between"}
							alignItems={"center"}
						>
							<Grid>
								<StyleTypography
									fontSize={40}
									fontSizeMedium={30}
									fontWeight={"bold"}
								>
									Statutory pay
								</StyleTypography>
							</Grid>
							<Grid
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								item
								container
								justifyContent={"space-between"}
								alignItems={"center"}
							>
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									paddingTop={"10px"}
								>
									<StyleTypography
										fontSize={16}
										fontSizeMedium={13}
										color={"#ABAEB7"}
									>
										If you have paid any Statutory Maternity Pay or
										Statutory Paternity Pay, we will claim 92% of it from HMRC.<br/>
										If you qualify for small Employer Relief, it will be 103%.
									</StyleTypography>
								</Grid>
								<Grid
									className={"enhanced-policy-divider"}
								>
									<Divider/>
								</Grid>
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									container
									item
									direction={"row"}
									alignItems={"flex-start"}
								>
									<Grid
										xs={8}
										item
									>
										<Grid
											container
											justifyContent={"space-between"}
											alignItems={"center"}
											spacing={2}
										>
											{/*SMP YTD*/}
											<Grid
												xs={12}
												item
												container
												spacing={2}
											>
												<Grid
													xs={6}
													item
												>
													<InputLabel
														className={"default-input-label"}
													>
														Recovered SMP YTD
													</InputLabel>
													<TextField
														className={"default-text-field"}
														fullWidth
														value={employerRecord?.active_employer_ytd_figure?.smp_recovered_ytd}
														onChange={(e) => {
															setEmployerRecord({
																...employerRecord,
																active_employer_ytd_figure: {
																	...employerRecord?.active_employer_ytd_figure,
																	smp_recovered_ytd: e.target.value,
																}
															});
														}}
														InputProps={{
															endAdornment: "£",
															inputComponent: CurrencyInputCustom,
															inputProps: {prefix: ""},
														}}
													/>
												</Grid>

												<Grid
													xs={6}
													item
												>
													<InputLabel
														className={"default-input-label"}
													>
														SMP NI Compensation YTD
													</InputLabel>
													<TextField
														className={"default-text-field"}
														fullWidth
														value={employerRecord?.active_employer_ytd_figure?.smp_nic_ytd}
														onChange={(e) => {
															setEmployerRecord({
																...employerRecord,
																active_employer_ytd_figure: {
																	...employerRecord?.active_employer_ytd_figure,
																	smp_nic_ytd: e.target.value,
																}
															});
														}}
														InputProps={{
															endAdornment: "£",
															inputComponent: CurrencyInputCustom,
															inputProps: {prefix: ""},
														}}
													/>
												</Grid>
											</Grid>

											{/*SPP YTD*/}
											<Grid
												xs={12}
												item
												container
												spacing={2}
											>
												<Grid
													xs={6}
													item
												>
													<InputLabel
														className={"default-input-label"}
													>
														Recovered SPP YTD
													</InputLabel>
													<TextField
														className={"default-text-field"}
														fullWidth
														value={employerRecord?.active_employer_ytd_figure?.spp_recovered_ytd}
														onChange={(e) => {
															setEmployerRecord({
																...employerRecord,
																active_employer_ytd_figure: {
																	...employerRecord?.active_employer_ytd_figure,
																	spp_recovered_ytd: e.target.value,
																}
															});
														}}
														InputProps={{
															endAdornment: "£",
															inputComponent: CurrencyInputCustom,
															inputProps: {prefix: ""},
														}}
													/>
												</Grid>

												<Grid
													xs={6}
													item
												>
													<InputLabel
														className={"default-input-label"}
													>
														SPP NI Compensation YTD
													</InputLabel>
													<TextField
														className={"default-text-field"}
														fullWidth
														value={employerRecord?.active_employer_ytd_figure?.spp_nic_ytd}
														onChange={(e) => {
															setEmployerRecord({
																...employerRecord,
																active_employer_ytd_figure: {
																	...employerRecord?.active_employer_ytd_figure,
																	spp_nic_ytd: e.target.value,
																}
															});
														}}
														InputProps={{
															endAdornment: "£",
															inputComponent: CurrencyInputCustom,
															inputProps: {prefix: ""},
														}}
													/>
												</Grid>
											</Grid>

											{/*SAP YTD*/}
											<Grid
												xs={12}
												item
												container
												spacing={2}
											>
												<Grid
													xs={6}
													item
												>
													<InputLabel
														className={"default-input-label"}
													>
														Recovered SAP YTD
													</InputLabel>
													<TextField
														className={"default-text-field"}
														fullWidth
														value={employerRecord?.active_employer_ytd_figure?.sap_recovered_ytd}
														onChange={(e) => {
															setEmployerRecord({
																...employerRecord,
																active_employer_ytd_figure: {
																	...employerRecord?.active_employer_ytd_figure,
																	sap_recovered_ytd: e.target.value,
																}
															});
														}}
														InputProps={{
															endAdornment: "£",
															inputComponent: CurrencyInputCustom,
															inputProps: {prefix: ""},
														}}
													/>
												</Grid>

												<Grid
													xs={6}
													item
												>
													<InputLabel
														className={"default-input-label"}
													>
														SAP NI Compensation YTD
													</InputLabel>
													<TextField
														className={"default-text-field"}
														fullWidth
														value={employerRecord?.active_employer_ytd_figure?.sap_nic_ytd}
														onChange={(e) => {
															setEmployerRecord({
																...employerRecord,
																active_employer_ytd_figure: {
																	...employerRecord?.active_employer_ytd_figure,
																	sap_nic_ytd: e.target.value,
																}
															});
														}}
														InputProps={{
															endAdornment: "£",
															inputComponent: CurrencyInputCustom,
															inputProps: {prefix: ""},
														}}
													/>
												</Grid>
											</Grid>

											{/*SPBP YTD*/}
											<Grid
												xs={12}
												item
												container
												spacing={2}
											>
												<Grid
													xs={6}
													item
												>
													<InputLabel
														className={"default-input-label"}
													>
														Recovered SPBP YTD
													</InputLabel>
													<TextField
														className={"default-text-field"}
														fullWidth
														value={employerRecord?.active_employer_ytd_figure?.spbp_recovered_ytd}
														onChange={(e) => {
															setEmployerRecord({
																...employerRecord,
																active_employer_ytd_figure: {
																	...employerRecord?.active_employer_ytd_figure,
																	spbp_recovered_ytd: e.target.value,
																}
															});
														}}
														InputProps={{
															endAdornment: "£",
															inputComponent: CurrencyInputCustom,
															inputProps: {prefix: ""},
														}}
													/>
												</Grid>

												<Grid
													xs={6}
													item
												>
													<InputLabel
														className={"default-input-label"}
													>
														SPBP NI Compensation YTD
													</InputLabel>
													<TextField
														className={"default-text-field"}
														fullWidth
														value={employerRecord?.active_employer_ytd_figure?.spbp_nic_ytd}
														onChange={(e) => {
															setEmployerRecord({
																...employerRecord,
																active_employer_ytd_figure: {
																	...employerRecord?.active_employer_ytd_figure,
																	spbp_nic_ytd: e.target.value,
																}
															});
														}}
														InputProps={{
															endAdornment: "£",
															inputComponent: CurrencyInputCustom,
															inputProps: {prefix: ""},
														}}
													/>
												</Grid>
											</Grid>

											{/*ShPP YTD*/}
											<Grid
												xs={12}
												item
												container
												spacing={2}
											>
												<Grid
													xs={6}
													item
												>
													<InputLabel
														className={"default-input-label"}
													>
														Recovered ShPP YTD
													</InputLabel>
													<TextField
														className={"default-text-field"}
														fullWidth
														value={employerRecord?.active_employer_ytd_figure?.shpp_recovered_ytd}
														onChange={(e) => {
															setEmployerRecord({
																...employerRecord,
																active_employer_ytd_figure: {
																	...employerRecord?.active_employer_ytd_figure,
																	shpp_recovered_ytd: e.target.value,
																}
															});
														}}
														InputProps={{
															endAdornment: "£",
															inputComponent: CurrencyInputCustom,
															inputProps: {prefix: ""},
														}}
													/>
												</Grid>

												<Grid
													xs={6}
													item
												>
													<InputLabel
														className={"default-input-label"}
													>
														ShPP NI Compensation YTD
													</InputLabel>
													<TextField
														className={"default-text-field"}
														fullWidth
														value={employerRecord?.active_employer_ytd_figure?.shpp_nic_ytd}
														onChange={(e) => {
															setEmployerRecord({
																...employerRecord,
																active_employer_ytd_figure: {
																	...employerRecord?.active_employer_ytd_figure,
																	shpp_nic_ytd: e.target.value,
																}
															});
														}}
														InputProps={{
															endAdornment: "£",
															inputComponent: CurrencyInputCustom,
															inputProps: {prefix: ""},
														}}
													/>
												</Grid>
											</Grid>

										</Grid>
										<Grid
											container
											marginTop={2}
										>
											<Grid>
												<StyleButton
													onClick={() => {
														updateStatutoryAmounts({
															"employers": {
																ytd_figure: employerRecord?.active_employer_ytd_figure,
															}
														})
													}}
												>
													Save
												</StyleButton>
											</Grid>
											<Grid marginLeft={2}>
												<StyleButton
													onClick={() => {
														setEmployerRecord(employer);
													}}
													isWhiteButton={true}
												>
													Discard
												</StyleButton>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Employer,
	} = state;

	return {
		...Profile,
		...Employer
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	updateSer: (data) => dispatch(updateSer(data)),
	updateStatutoryAmounts: (data) => dispatch(updateStatutoryAmounts(data)),
	findEmployer: (relations = []) => dispatch(findEmployer(relations))
});

export default connect(mapStateToProps, mapDispatchToProps)(Statutories)