import {resolveReduxState} from "../../utils/Helpers";
import {
	CLOSE_ACCOUNT,
	EDIT_INFO,
	FETCH_USER,
	FETCH_USER_ERROR,
	FETCH_USER_SUCCESS, LOGIN
} from "../../actions/Profile/types";

const initial_state = {
	user: {
		id: null,
		user_profile_type_id: null,
		name: '',
		email: '',
		phone_number: '',
		email_verified_at: '',
		employer: {
			id: null,
			basic_setup_step: 0,
			onboarding_complete: false,
		},
	},
	user_loading: false,
	login_loading: false,
	login_error: false,
	edit_info: "email",
	valid_code: false,
}

const Profile = (state = initial_state, action = false) => {
	if (action) {
		switch(action.type) {

			case LOGIN:
			case FETCH_USER:
			case FETCH_USER_ERROR:
			case FETCH_USER_SUCCESS:
			case EDIT_INFO:
			case CLOSE_ACCOUNT:
				return resolveReduxState(state, action);

			default:
				return state;
		}
	}

	return state;
}

export default Profile;