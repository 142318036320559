import {
	GET_EMPLOYER_PAYMENT_SUMMARY
} from "./types";
import Request from "../../utils/Request";

export const getEmployerPaymentSummary = (data) => dispatch => {

	dispatch({
		type: GET_EMPLOYER_PAYMENT_SUMMARY,
		employer_payment_summary_loading: true,
	});

	return new Request.post('/api/employers/employer-payment-summary', data)
		.then(response => {
			dispatch({
				type: GET_EMPLOYER_PAYMENT_SUMMARY,
				employer_payment_summary_loading: false,
				employer_payment_summaries: response.data.data,
			});

		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: GET_EMPLOYER_PAYMENT_SUMMARY,
				employer_payment_summary_loading: false,
			});
		})
};