import Grid from '@mui/material/Grid';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {StyleAppBar} from '../../components/StyleAppBar';
import {LeftMenu} from '../../components/LeftMenu';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import StyleTypography from '../../components/StyledComponents/StyleTypography';
import Button from '@mui/material/Button';
import {me} from '../../actions/Profile';
import {connect} from 'react-redux';
import {Divider} from '@mui/material';
import Select from '@mui/material/Select';
import {company_types} from '../../utils/Helpers';
import MenuItem from '@mui/material/MenuItem';
import Loading from '../../components/Loading';
import {findEmployer, updateEmployer} from '../../actions/Employer';
import Box from '@mui/material/Box';
import countryList from 'react-select-country-list';
import Autocomplete from '@mui/material/Autocomplete';
import NewTextField from '../../components/common/NewTextField';
import CompanyLogo from "../../images/company-deatils/profile.svg";

const Details = (
	{
		me = () => {
		},
		user = {},
		user_loading = false,
		employer_loading = false,
		employer,
		findEmployer = () => {
		},
		updateEmployer = () => {
		},
	}
): JSX.Element => {
	const countries = useMemo(() => countryList().getData(), []);

	const [employerRecord, setEmployerRecord] = useState({});

	const Months = [
		{id: 1, month: 'January'},
		{id: 2, month: 'February'},
		{id: 3, month: 'March'},
		{id: 4, month: 'April'},
		{id: 5, month: 'May'},
		{id: 6, month: 'June'},
		{id: 7, month: 'July'},
		{id: 8, month: 'August'},
		{id: 9, month: 'September'},
		{id: 10, month: 'October'},
		{id: 11, month: 'November'},
		{id: 12, month: 'December'},
	];

	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const fileTypes = ['image/jpeg', 'image/png'];
	const inputRef = useRef(null);

	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file && fileTypes.includes(file.type)) {
			const img = new Image();
			img.src = URL.createObjectURL(file);

			img.onload = () => {
				if (img.width >= 200 && img.height >= 200) {
					setSelectedFile(file);
					setPreview(URL.createObjectURL(file));
				} else {
					setSelectedFile(null);
					setPreview(null);
					alert('Image must be 200x200 pixels.');
				}
			};
		}
	};
	
	const triggerFileInput = () => {
		inputRef.current.click();
	};

	useEffect(() => {
		me();
		findEmployer(['address']);
	}, [findEmployer, me]);

	useEffect(() => {
		setEmployerRecord(employer);
	}, [employer]);

	if (user_loading || employer_loading) {
		return (
			<Loading/>
		);
	}

	return (
		<Grid
			container
			justifyContent={'center'}
			id={'company-details-page'}
		>
			<StyleAppBar
				showBackButton={false}
				backLink={'/main'}
				user={user}
				employer={employer}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={'main-content-container'}
			>
				<Grid>
					<LeftMenu
						activePage={'Company'}
						activeSubpage={'Details'}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={'row'}
					alignContent={'flex-start'}
					sx={{
						flex: 1,
						marginLeft: '36px',
					}}
				>
					<Grid container>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
						>
							<Grid>
								<StyleTypography
									fontSize={40}
									fontSizeMedium={30}
									fontWeight={'bold'}
									component={'h1'}
								>
									{employer?.company_name}
								</StyleTypography>
							</Grid>
							<Grid className={'sub-title-container'}>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={14}
									color={'#ABAEB7'}
								>
									{employer?.legal_structure}
								</StyleTypography>
							</Grid>
						</Grid>

						<Divider className={'divider details-divider'}/>

						<Grid
							container
							marginBottom={"20px"}
							alignItems={"center"}
							spacing={2}
						>
							<Grid
								item
							>
								<FormControl>
									<Grid sx={{display: "none"}}>
										<input
											type={"file"}
											accept={".jpg, .jpeg, .png"}
											onChange={handleFileChange}
											ref={inputRef}
										/>
									</Grid>
									<img
										id={"details-image-container"}
										src={
											preview
												? preview
												: employer?.company_logo
													? employer?.company_logo
													: CompanyLogo
										}
										alt={"Company Logo"}
										onClick={triggerFileInput}
									/>
								</FormControl>

							</Grid>
							<Grid
								item
							>
								<Grid>
									<StyleTypography
										fontSize={18}
										fontSizeMedium={13}
										fontWeight={'bold'}
										color={'#000000'}
									>
										Company logo
									</StyleTypography>
								</Grid>
								<Grid>
									<StyleTypography
										fontSize={14}
										fontSizeMedium={10}
										color={"#AAAEB7"}
									>
										Will be shown on employee payslips. We support JPEG or PNG
									</StyleTypography>
									<StyleTypography
										fontSize={14}
										fontSizeMedium={10}
										color={"#AAAEB7"}
									>
										with a minimum resolution of 200x200.
									</StyleTypography>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							id={'details-container'}
							container
							spacing={4}
						>
							<Grid
								item
								xl={6}
								lg={6}
								md={6}
								sm={12}
								xs={12}
							>
								<FormControl fullWidth>
									<NewTextField
										className={'details-company-text-field'}
										label={'Company name'}
										defaultValue={employerRecord?.company_name ?? ''}
										value={employerRecord?.company_name ?? ''}
										disabled={!employer?.is_manual_creation}
										onChange={e => {
											setEmployerRecord({
												...employerRecord,
												company_name: e.target.value,
											});
										}}
									/>
								</FormControl>
							</Grid>

							{/*Address Line 1*/}
							<Grid
								item
								xl={6}
								lg={6}
								md={6}
								sm={12}
								xs={12}
							>
								<FormControl fullWidth>
									<NewTextField
										className={'details-company-text-field'}
										label={'Address line 1'}
										value={employerRecord?.address?.address_line_1}
										onChange={(e) => {
											setEmployerRecord({
												...employerRecord,
												address: {
													...employerRecord?.address,
													address_line_1: e.target.value,
												},
											});
										}}
									/>
								</FormControl>
							</Grid>

							{/*Company Type*/}
							<Grid
								item
								xl={6}
								lg={6}
								md={6}
								sm={12}
								xs={12}
							>
								<InputLabel className={'default-input-label'}>
									Legal Structure *
								</InputLabel>
								<Select
									className={'default-select details-company-select'}
									fullWidth
									placeholder={'Legal Structure'}
									required
									defaultValue={employerRecord?.legal_structure ?? ''}
									value={employerRecord?.legal_structure ?? ''}
									onChange={(e) => {
										setEmployerRecord({
											...employerRecord,
											legal_structure: e.target.value,
										});
									}}
								>
									{Object.keys(company_types)
										.sort()
										.map((key) => (
											<MenuItem
												value={company_types[key]}
												key={key}
											>
												{`${key.toUpperCase()} - ${company_types[key]}`}
											</MenuItem>
										))}
								</Select>
							</Grid>

							{/*Address Line 2*/}
							<Grid
								item
								xl={6}
								lg={6}
								md={6}
								sm={12}
								xs={12}
							>
								<FormControl fullWidth>
									<NewTextField
										className={'details-company-text-field'}
										label={'Address line 2 (Optional)'}
										value={employerRecord?.address?.address_line_2}
										onChange={(event) => {
											setEmployerRecord({
												...employerRecord,
												address: {
													...employerRecord?.address,
													address_line_2: event.target.value,
												},
											});
										}}
									/>
								</FormControl>
							</Grid>

							{/*Registration Number*/}
							<Grid
								item
								xl={3}
								lg={3}
								md={3}
								sm={6}
								xs={6}
							>
								<FormControl fullWidth>
									<NewTextField
										className={'details-company-text-field'}
										label={'Registration number'}
										value={employer?.company_number}
										disabled
									/>
								</FormControl>
							</Grid>

							{/*Holiday Year*/}
							<Grid
								item
								xl={3}
								lg={3}
								md={3}
								sm={6}
								xs={6}
							>
								<InputLabel className={'default-input-label'}>
									Holiday Year Start Month *
								</InputLabel>
								<FormControl
									fullWidth
								>
									<Select
										className={"default-select"}
										id={"select-gender"}
										MenuProps={{
											PaperProps: {
												style: {
													maxHeight: 200, // Adjust the maximum height of the list
													maxWidth: 300,
													overflowX: 'scroll',
												},
											},
										}}
										value={employerRecord?.holiday_year_start_month ?? ''}
										onChange={(event) => {
											setEmployerRecord({
												...employerRecord,
												holiday_year_start_month: event.target.value,
											});
										}}
									>
										{Months.map((month) => (
											<MenuItem
												key={month.id}
												value={month.month}
												sx={{
													fontSize: '0.9028vw'
												}}
											>
												{month.month}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>

							{/*Postal Code*/}
							<Grid
								item
								xl={3}
								lg={3}
								md={3}
								sm={6}
								xs={6}
							>
								<FormControl fullWidth>
									<NewTextField
										className={'details-company-text-field'}
										label={'Postal Code'}
										value={employerRecord?.address?.postal_code}
										onChange={(e) =>
											setEmployerRecord({
												...employerRecord,
												address: {
													...employerRecord?.address,
													postal_code: e.target.value,
												},
											})
										}
									/>
								</FormControl>
							</Grid>

							{/*City*/}
							<Grid
								item
								xl={3}
								lg={3}
								md={3}
								sm={6}
								xs={6}
							>
								<FormControl fullWidth>
									<NewTextField
										className={'details-company-text-field'}
										label={'City'}
										value={employerRecord?.address?.city}
										onChange={(e) =>
											setEmployerRecord({
												...employerRecord,
												address: {
													...employerRecord?.address,
													city: e.target.value,
												},
											})
										}
									/>
								</FormControl>
							</Grid>

							{/*Country*/}
							<Grid
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								item
								container
								justifyContent={'flex-end'}
								spacing={4}
							>
								{/*Country*/}
								<Grid
									item
									xl={6}
									lg={6}
									md={6}
									sm={12}
									xs={12}
								>
									<InputLabel className={'default-input-label'}>
										Country
									</InputLabel>
									<Autocomplete
										id={'country-select-demo'}
										className={'default-select details-company-select'}
										options={countries}
										getOptionLabel={(option) =>
											option.label !== null ? option.label : 'Select a country'
										}
										renderOption={(props, option) => (
											<Box
												component="li"
												sx={{'& > img': {mr: 2, flexShrink: 0}}}
												{...props}
											>
												<img
													loading="lazy"
													width="20"
													src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
													srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
													alt=""
												/>
												{option.label}
											</Box>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												inputProps={{
													...params.inputProps,
													autoComplete: 'new-password', // disable autocomplete and autofill
												}}
											/>
										)}
										value={
											countries.filter(
												(country) =>
													country.label === employerRecord.address?.country
											)[0] ?? null
										}
										onChange={(event, newValue) => {
											setEmployerRecord({
												...employerRecord,
												address: {
													...employerRecord.address,
													country: newValue.label,
												},
											});
										}}
									/>
								</Grid>
							</Grid>
						</Grid>

						<Grid
							container
							id={'details-button-container'}
							direction={'row'}
						>
							<Grid>
								<Button
									className={'default-black-button'}
									onClick={() =>
										updateEmployer(
											employer.id,
											{
												employers: {
													...employerRecord,
													company_logo: selectedFile,
												},
											},
											['address']
										)
									}
									disabled={
										!employerRecord.legal_structure ||
										!employerRecord.address?.address_line_1 ||
										!employerRecord.address.postal_code ||
										!employerRecord.address.city ||
										!employerRecord.address.country
									}
								>
									Save
								</Button>
							</Grid>

							<Grid sx={{marginLeft: '20px'}}>
								<Button
									className={'default-white-button'}
									onClick={() => setEmployerRecord(employer)}
								>
									Discard
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	const {Profile, Employer} = state;

	return {
		...Profile,
		...Employer,
	};
};

const mapDispatchToProps = (dispatch) => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	updateEmployer: (id, data, relations = []) => dispatch(updateEmployer(id, data, relations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);
