import Grid from "@mui/material/Grid";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import React, {useState} from "react";
import {InputLabel} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import enGb from "date-fns/locale/en-GB";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import {isWithinInterval, parseISO} from "date-fns";

function _verifyDates(startedSickDate , lastSickDate) {
	const momentDate1 = new Date(startedSickDate);
	const momentDate2 = new Date (lastSickDate);
	return momentDate1 >= momentDate2;
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
export const SickLeaveDay = (
	{
		employee = {},
		sickLeaveDetails = {},
		setStep = () => {},
		setSickLeaveDetails = () => {},
	}
): JSX.Element => {
	const [invalidFirstSickDate, setInvalidFirstSickDate] = useState(true),
		[invalidLastSickDate, setInvalidLastSickDate] = useState(true);
	return (
		<Grid
			className={"sick-leave-day"}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<StyleTypography
					fontSize={40}
					fontSizeMedium={29}
					fontWeight={'bold'}
					component={"h1"}
				>
					When was {employee.name} first sick day?
				</StyleTypography>
			</Grid>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={"sick-leave-day-subtitle-margin"}
			>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={13}
					color={"#ABAEB7"}
				>
					This includes weekends, bank holidays, and non-working days.
				</StyleTypography>
			</Grid>
			<Grid>
				<InputLabel
					className={"default-input-label"}
				>
					First Sick day
				</InputLabel>
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					adapterLocale={enGb}
				>
					<DesktopDatePicker
						fullWidth
						slotProps={{ field: { readOnly: true } }}
						enableAccessibleFieldDOMStructure
						className={"default-text-field"}
						onChange={(newValue) => {
							if (newValue instanceof Date && !isNaN(newValue.getTime())) {
								setSickLeaveDetails({
									first_sick_day: newValue.toISOString().split('T')[0]
								});
							}
						}}
						value={parseISO(sickLeaveDetails.first_sick_day)}
						onError={(error, value) => {
							if (error) {
								setInvalidFirstSickDate(true);
							} else {
								setInvalidFirstSickDate(false);
							}
						}}
						shouldDisableDate={(day) => {
							return employee.leaves.map((leave) => {
								return {
									startDate: parseISO(leave.leave_start_date),
									endDate: parseISO(leave.leave_end_date)
								}
							}).some((leave) => {
								return isWithinInterval(day, {start: leave.startDate, end: leave.endDate})
							})
						}}
					/>
				</LocalizationProvider>
			</Grid>
			<Grid
				container
				direction={"column"}
				className={"sick-leave-day-subtitle-last-sick-day-margin"}
			>
				<StyleTypography
					fontWeight={"bold"}
					fontSize={18}
					fontSizeMedium={13}
					color={"black"}
				>
					Do you know {employee?.name} last sick day?
				</StyleTypography>
				<StyleTypography
					fontSize={16}
					fontSizeMedium={12}
					color={"#AAAEB7"}
				>
					If no, we will pay statutory payments until you tell us {employee?.name} has returned to work.
				</StyleTypography>
				<Grid
					className={"sick-leave-day-subtitle-last-sick-radio-margin"}
					container
					direction={"column"}
				>
					<FormControl>
						<RadioGroup
							aria-labelledby={"sick-leave-day-group"}
							name={"sick-leave-day-group"}
							value={sickLeaveDetails.last_sick_day_radio}
							row={true}
							onChange={(e) => {
								setSickLeaveDetails({
									last_sick_day_radio: e.target.value === "true"
								});
								if (sickLeaveDetails.last_sick_day_radio === false) {
									setSickLeaveDetails({
										last_sick_day: null
									});
									setInvalidLastSickDate(true);
								}
							}}
						>
							<FormControlLabel
								value={true}
								control={<Radio />}
								label={"Yes"}
							/>
							<FormControlLabel
								value={false}
								control={<Radio/>}
								label={"No"}
							/>
						</RadioGroup>
					</FormControl>
					{
						sickLeaveDetails?.last_sick_day_radio === true &&
						<Grid className={"sick-leave-day-subtitle-last-sick-day-input"}>
							<InputLabel
								className={"default-input-label"}
							>
								Last Sick day
							</InputLabel>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								adapterLocale={enGb}
							>
								<DesktopDatePicker
									fullWidth
									slotProps={{ field: { readOnly: true } }}
									enableAccessibleFieldDOMStructure
									className={"default-text-field"}
									onChange={(newValue) => {
										if (newValue instanceof Date && !isNaN(newValue.getTime())) {
											setSickLeaveDetails({
												last_sick_day: newValue.toISOString().split('T')[0]
											});
										}
									}}
									value={parseISO(sickLeaveDetails.last_sick_day)}
									onError={(error, value) => {
										if (error) {
											setInvalidLastSickDate(true);
										} else {
											setInvalidLastSickDate(false);
										}
									}}
									shouldDisableDate={(day) => {
										return employee.leaves.map((leave) => {
											return {
												startDate: parseISO(leave.leave_start_date),
												endDate: parseISO(leave.leave_end_date)
											}
										}).some((leave) => {
											return isWithinInterval(day, {start: leave.startDate, end: leave.endDate})
										})
									}}
								/>
							</LocalizationProvider>
						</Grid>
					}
				</Grid>
			</Grid>
			<Grid
				container
				direction={"row"}
				className={"sick-leave-day-buttons"}
			>
				<Button
					className={"sick-leave-day-black-button"}
					onClick={() => setStep(2)}
					disabled={
						invalidFirstSickDate ||
						(sickLeaveDetails.last_sick_day_radio && invalidLastSickDate &&
						_verifyDates(sickLeaveDetails.first_sick_day, sickLeaveDetails.last_sick_day))
					}
				>
					<StyleTypography
						color={"#fff"}
						fontWeight={"bold"}
						fontSize={16}
						fontSizeMedium={13}
					>
						Continue
					</StyleTypography>
				</Button>

				<Button
					className={"sick-leave-day-white-button"}
					onClick={() => {
						setSickLeaveDetails({
							first_sick_day: null,
							last_sick_day: null,
							last_sick_day_radio: false,
							average_weekly_earnings: 0.00,
							work_week: {
								monday: false,
								tuesday: false,
								wednesday: false,
								thursday: false,
								friday: false,
								saturday: false,
								sunday: false,
							},
						});
						setStep(0);
					}}
				>
					<StyleTypography
						color={"#000"}
						fontWeight={"bold"}
						fontSize={16}
						fontSizeMedium={13}
					>
						Discard
					</StyleTypography>
				</Button>
			</Grid>
		</Grid>
	)
}