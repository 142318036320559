import { z } from "zod";
import StyleTypography from "../../../components/StyledComponents/StyleTypography";
import { CurrencyInputCustom } from "../../StyledComponents/CurrencyInputCustom";
import { Divider, TextField } from "@mui/material";
import {
	FreepayrollButton,
} from "@collegia-partners/ui-kit";
import { useCallback, useEffect, useState } from "react";
import { validDataToSchema } from "../../../utils/Helpers";

const yearToDateSchema = z.object({
	grossForTax: z.string({ message: "Required" }).min(0, "Required"),
	taxDeducted: z.string({ message: "Required" }).min(1, "Required"),
	studentLoan: z.string({ message: "Required" }).min(1, "Required"),
	postgraduateLoan: z.string({ message: "Required" }).min(1, "Required"),
	employeePension: z.string({ message: "Required" }).min(1, "Required"),
	employerPension: z.string({ message: "Required" }).min(1, "Required"),

	benefitInKindPayrolledAmount: z
		.string({ message: "Required" })
		.min(1, "Required"),
	employeNetPayPension: z.string({ message: "Required" }).min(1, "Required"),

	statutoryMaternityPay: z.string({ message: "Required" }).min(1, "Required"),
	statutoryPaternityPay: z.string({ message: "Required" }).min(1, "Required"),
	statutoryAdoptionPay: z.string({ message: "Required" }).min(1, "Required"),
	statutorySickPay: z.string({ message: "Required" }).min(1, "Required"),
	parentalBereavement: z.string({ message: "Required" }).min(1, "Required"),
	sharedParentalPay: z.string({ message: "Required" }).min(1, "Required"),
});

const categorySchema = z.object({
	nationalInsuranceCategory: z
		.string({ message: "Required" })
		.min(1, "Required"),
	earningsAtLeL: z.string({ message: "Required" }).min(1, "Required"),
	earningsToPT: z.string({ message: "Required" }).min(1, "Required"),
	earningsToUEL: z.string({ message: "Required" }).min(1, "Required"),
	employeeNationalInsurance: z
		.string({ message: "Required" })
		.min(1, "Required"),
	employerNationalInsurance: z
		.string({ message: "Required" })
		.min(1, "Required"),
	grossPayForNationalInsurance: z
		.string({ message: "Required" })
		.min(1, "Required"),

	directorEarningsAtLeL: z.string({ message: "Required" }).min(1, "Required"),
	directorEarningsToPT: z.string({ message: "Required" }).min(1, "Required"),
	directorEarningsToUEL: z.string({ message: "Required" }).min(1, "Required"),
	directorNationalInsurance: z
		.string({ message: "Required" })
		.min(1, "Required"),
	directorEmployerNationalInsurance: z
		.string({ message: "Required" })
		.min(1, "Required"),
	directorGrossPayForNationalInsurance: z
		.string({ message: "Required" })
		.min(1, "Required"),
});

export default function YearToDate({ employeeRecord, ni_categories, user }) {
	const isEditable = !employeeRecord.fps_submitted || user.is_support_user;
	const [categoriesError, setCategoriesError] = useState([]);
	const [categories, setCategories] = useState([]);
	const [data, setData] = useState({});
	const [errors, setErrors] = useState({});

	const handleCategoryChange = useCallback(({ e, option, index, key }) => {
		setCategoriesError((prev) => {
			if (!prev[index]) prev[index] = {};
			prev[index][key] = "";
			return prev;
		});

		setCategories((prev) => {
			const newCategories = [...prev];
			newCategories[index][key] = e ? e.target.value : option.value;
			return newCategories;
		});
	}, []);

	const handleDataChange = useCallback(({ e, key }) => {
		setErrors((prev) => {
			prev[key] = "";
			return prev;
		});
		setData((prev) => ({ ...prev, [key]: e ? e.target.value : e }));
	}, []);

	const onSubmit = useCallback(
		(e) => {
			e?.preventDefault();

			const validDataResult = validDataToSchema(data, yearToDateSchema);

			if (!validDataResult.valid) {
				setErrors(validDataResult.error);
				return;
			}

			for (let i = 0; i < categories.length; i++) {
				const category = categories[i];
				const validResult = validDataToSchema(category, categorySchema);
				console.log("validResult", validResult);
				if (validResult.valid) continue;

				setCategoriesError((prev) => {
					if (!prev[i]) prev[i] = {};
					prev[i] = validResult.error;
					return prev;
				});

				return;
			}

			const body = { ...data, categories };
			console.log("body", body);
		},
		[categories, data]
	);

	useEffect(() => {
		const dynamicCategories = employeeRecord?.active_ni_categories_ytd;
		if (!employeeRecord || !dynamicCategories || !dynamicCategories.length)
			return;

		const newCategories = dynamicCategories.map((c) => ({
			nationalInsuranceCategory: c.national_insurance_category,
			earningsAtLeL: c.earnings_at_lel_ytd,
			earningsToPT: c.earnings_to_pt_ytd,
			earningsToUEL: c.earnings_to_uel_ytd,
			employeeNationalInsurance: c.employee_nic_ytd,
			employerNationalInsurance: c.employer_nic_ytd,
			grossPayForNationalInsurance: c.gross_pay_for_nic_ytd,

			directorEarningsAtLeL: c.director_at_lel_ytd,
			directorEarningsToPT: c.director_to_pt_ytd,
			directorEarningsToUEL: c.director_to_uel_ytd,
			directorNationalInsurance: c.director_nic_ytd,
			directorEmployerNationalInsurance: c.director_employer_nic_ytd,
			directorGrossPayForNationalInsurance: c.director_earnings_ytd,
		}));

		setCategories(newCategories);
	}, [employeeRecord]);

	useEffect(() => {
		if (!employeeRecord || !employeeRecord.ytd_figure) return;

		setData({
			grossForTax: employeeRecord.ytd_figure.gross_for_tax_ytd,
			taxDeducted: employeeRecord.ytd_figure.tax_deducted_ytd,
			studentLoan: employeeRecord.ytd_figure.student_loan_ytd,
			postgraduateLoan: employeeRecord.ytd_figure.pg_loan_ytd,
			employeePension: employeeRecord.ytd_figure.employee_pension_ytd,
			employerPension: employeeRecord.ytd_figure.employer_pension_ytd,

			benefitInKindPayrolledAmount:
				employeeRecord.ytd_figure.bik_payrolled_amount_ytd,
			employeNetPayPension:
				employeeRecord.ytd_figure.employee_net_pay_pension_ytd,

			statutoryMaternityPay: employeeRecord.ytd_figure.smp_ytd,
			statutoryPaternityPay: employeeRecord.ytd_figure.spp_ytd,
			statutoryAdoptionPay: employeeRecord.ytd_figure.sap_ytd,
			statutorySickPay: employeeRecord.ytd_figure.ssp_ytd,
			parentalBereavement: employeeRecord.ytd_figure.spbp_ytd,
			sharedParentalPay: employeeRecord.ytd_figure.shpp_ytd,
		});
	}, [employeeRecord]);

	return (
		<form id={"year-to-date-form"} onSubmit={(e) => onSubmit(e)}>
			<StyleTypography fontSize={20} fontSizeMedium={16} color={"black"}>
				Tax
			</StyleTypography>

			<Divider />

			<div className={"fields-group"}>
				<div>
					<StyleTypography fontSize={14} fontSizeMedium={12} color={"black"}>
						Gross for tax
					</StyleTypography>

					<TextField
						fullWidth
						className={"default-text-field"}
						disabled={!isEditable}
						error={!!errors.grossForTax}
						helperText={errors.grossForTax?.message}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						value={data.grossForTax}
						onChange={(e) => handleDataChange({ e, key: "grossForTax" })}
					/>
				</div>

				<div>
					<StyleTypography fontSize={14} fontSizeMedium={12} color={"black"}>
						Tax deducted
					</StyleTypography>

					<TextField
						fullWidth
						className={"default-text-field"}
						disabled={!isEditable}
						error={!!errors.taxDeducted}
						helperText={errors.taxDeducted?.message}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						value={data.taxDeducted}
						onChange={(e) => handleDataChange({ e, key: "taxDeducted" })}
					/>
				</div>

				<div>
					<StyleTypography fontSize={14} fontSizeMedium={12} color={"black"}>
						Student loan
					</StyleTypography>

					<TextField
						fullWidth
						className={"default-text-field"}
						disabled={!isEditable}
						error={!!errors.studentLoan}
						helperText={errors.studentLoan?.message}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						value={data.studentLoan}
						onChange={(e) => handleDataChange({ e, key: "studentLoan" })}
					/>
				</div>

				<div>
					<StyleTypography fontSize={14} fontSizeMedium={12} color={"black"}>
						Postgraduate loan
					</StyleTypography>

					<TextField
						fullWidth
						className={"default-text-field"}
						disabled={!isEditable}
						error={!!errors.postgraduateLoan}
						helperText={errors.postgraduateLoan?.message}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						value={data.postgraduateLoan}
						onChange={(e) => handleDataChange({ e, key: "postgraduateLoan" })}
					/>
				</div>

				<div>
					<StyleTypography fontSize={14} fontSizeMedium={12} color={"black"}>
						Employee pension
					</StyleTypography>

					<TextField
						fullWidth
						className={"default-text-field"}
						disabled={!isEditable}
						error={!!errors.employeePension}
						helperText={errors.employeePension?.message}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						value={data.employeePension}
						onChange={(e) => handleDataChange({ e, key: "employeePension" })}
					/>
				</div>

				<div>
					<StyleTypography fontSize={14} fontSizeMedium={12} color={"black"}>
						Employer pension
					</StyleTypography>

					<TextField
						fullWidth
						className={"default-text-field"}
						disabled={!isEditable}
						error={!!errors.employerPension}
						helperText={errors.employerPension?.message}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						value={data.employerPension}
						onChange={(e) => handleDataChange({ e, key: "employerPension" })}
					/>
				</div>
			</div>

			<StyleTypography fontSize={20} fontSizeMedium={16} color={"black"}>
				Benefits
			</StyleTypography>

			<Divider />

			<div className={"fields-group"}>
				<div>
					<StyleTypography fontSize={14} fontSizeMedium={12} color={"black"}>
						Benefit in kind payrolled amount
					</StyleTypography>

					<TextField
						fullWidth
						className={"default-text-field"}
						disabled={!isEditable}
						error={!!errors.benefitInKindPayrolledAmount}
						helperText={errors.benefitInKindPayrolledAmount?.message}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						value={data.benefitInKindPayrolledAmount}
						onChange={(e) =>
							handleDataChange({ e, key: "benefitInKindPayrolledAmount" })
						}
					/>
				</div>

				<div>
					<StyleTypography fontSize={14} fontSizeMedium={12} color={"black"}>
						Employe net pay pension
					</StyleTypography>

					<TextField
						fullWidth
						className={"default-text-field"}
						disabled={!isEditable}
						error={!!errors.employeNetPayPension}
						helperText={errors.employeNetPayPension?.message}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						value={data.employeNetPayPension}
						onChange={(e) =>
							handleDataChange({ e, key: "employeNetPayPension" })
						}
					/>
				</div>
			</div>

			<StyleTypography fontSize={20} fontSizeMedium={16} color={"black"}>
				Statutory Payments
			</StyleTypography>

			<Divider />

			<div className={"fields-group"}>
				<div>
					<StyleTypography fontSize={14} fontSizeMedium={12} color={"black"}>
						Statutory maternity pay
					</StyleTypography>

					<TextField
						fullWidth
						className={"default-text-field"}
						disabled={!isEditable}
						error={!!errors.statutoryMaternityPay}
						helperText={errors.statutoryMaternityPay?.message}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						value={data.statutoryMaternityPay}
						onChange={(e) =>
							handleDataChange({ e, key: "statutoryMaternityPay" })
						}
					/>
				</div>

				<div>
					<StyleTypography fontSize={14} fontSizeMedium={12} color={"black"}>
						Statutory paternity pay
					</StyleTypography>

					<TextField
						fullWidth
						className={"default-text-field"}
						disabled={!isEditable}
						error={!!errors.statutoryPaternityPay}
						helperText={errors.statutoryPaternityPay?.message}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						value={data.statutoryPaternityPay}
						onChange={(e) =>
							handleDataChange({ e, key: "statutoryPaternityPay" })
						}
					/>
				</div>

				<div>
					<StyleTypography fontSize={14} fontSizeMedium={12} color={"black"}>
						Statutory adoption pay
					</StyleTypography>

					<TextField
						fullWidth
						className={"default-text-field"}
						disabled={!isEditable}
						error={!!errors.statutoryAdoptionPay}
						helperText={errors.statutoryAdoptionPay?.message}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						value={data.statutoryAdoptionPay}
						onChange={(e) =>
							handleDataChange({ e, key: "statutoryAdoptionPay" })
						}
					/>
				</div>

				<div>
					<StyleTypography fontSize={14} fontSizeMedium={12} color={"black"}>
						Statutory sick pay
					</StyleTypography>

					<TextField
						fullWidth
						className={"default-text-field"}
						disabled={!isEditable}
						error={!!errors.statutorySickPay}
						helperText={errors.statutorySickPay?.message}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						value={data.statutorySickPay}
						onChange={(e) => handleDataChange({ e, key: "statutorySickPay" })}
					/>
				</div>

				<div>
					<StyleTypography fontSize={14} fontSizeMedium={12} color={"black"}>
						Parental bereavement
					</StyleTypography>

					<TextField
						fullWidth
						className={"default-text-field"}
						disabled={!isEditable}
						error={!!errors.parentalBereavement}
						helperText={errors.parentalBereavement?.message}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						value={data.parentalBereavement}
						onChange={(e) =>
							handleDataChange({ e, key: "parentalBereavement" })
						}
					/>
				</div>

				<div>
					<StyleTypography fontSize={14} fontSizeMedium={12} color={"black"}>
						Shared parental pay
					</StyleTypography>

					<TextField
						fullWidth
						className={"default-text-field"}
						disabled={!isEditable}
						error={!!errors.sharedParentalPay}
						helperText={errors.sharedParentalPay?.message}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						value={data.sharedParentalPay}
						onChange={(e) => handleDataChange({ e, key: "sharedParentalPay" })}
					/>
				</div>
			</div>

			{categories.map((category, index) => (
				<>
					<StyleTypography fontSize={20} fontSizeMedium={16} color={"black"}>
						National Insurance
					</StyleTypography>

					<Divider />

					<div className={"fields-group"}>
						<div>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={12}
								color={"black"}
							>
								NI Category
							</StyleTypography>
							<TextField
								fullWidth
								className={"default-text-field"}
								disabled={true}
								value={category.nationalInsuranceCategory}
							/>
						</div>

						<div>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={12}
								color={"black"}
							>
								Earnings at LEL
							</StyleTypography>

							<TextField
								fullWidth
								className={"default-text-field"}
								disabled={!isEditable}
								error={!!categoriesError[index]?.earningsAtLeL}
								helperText={categoriesError[index]?.earningsAtLeL?.message}
								InputProps={{
									endAdornment: "£",
									inputComponent: CurrencyInputCustom,
									inputProps: { prefix: "" },
								}}
								value={category.earningsAtLeL}
								onChange={(e) =>
									handleCategoryChange({ e, index, key: "earningsAtLeL" })
								}
							/>
						</div>

						<div>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={12}
								color={"black"}
							>
								Earnings at PT
							</StyleTypography>

							<TextField
								fullWidth
								className={"default-text-field"}
								disabled={!isEditable}
								error={!!categoriesError[index]?.earningsToPT}
								helperText={categoriesError[index]?.earningsToPT?.message}
								InputProps={{
									endAdornment: "£",
									inputComponent: CurrencyInputCustom,
									inputProps: { prefix: "" },
								}}
								value={category.earningsToPT}
								onChange={(e) =>
									handleCategoryChange({ e, index, key: "earningsToPT" })
								}
							/>
						</div>

						<div>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={12}
								color={"black"}
							>
								Earnings to UEL
							</StyleTypography>

							<TextField
								fullWidth
								className={"default-text-field"}
								disabled={!isEditable}
								error={!!categoriesError[index]?.earningsToUEL}
								helperText={categoriesError[index]?.earningsToUEL?.message}
								InputProps={{
									endAdornment: "£",
									inputComponent: CurrencyInputCustom,
									inputProps: { prefix: "" },
								}}
								value={category.earningsToUEL}
								onChange={(e) =>
									handleCategoryChange({ e, index, key: "earningsToUEL" })
								}
							/>
						</div>

						<div>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={12}
								color={"black"}
							>
								Employee national insurance
							</StyleTypography>

							<TextField
								fullWidth
								className={"default-text-field"}
								disabled={!isEditable}
								error={!!categoriesError[index]?.employeeNationalInsurance}
								helperText={
									categoriesError[index]?.employeeNationalInsurance?.message
								}
								InputProps={{
									endAdornment: "£",
									inputComponent: CurrencyInputCustom,
									inputProps: { prefix: "" },
								}}
								value={category.employeeNationalInsurance}
								onChange={(e) =>
									handleCategoryChange({
										e,
										index,
										key: "employeeNationalInsurance",
									})
								}
							/>
						</div>

						<div>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={12}
								color={"black"}
							>
								Employer national insurance
							</StyleTypography>

							<TextField
								fullWidth
								className={"default-text-field"}
								disabled={!isEditable}
								error={!!categoriesError[index]?.employerNationalInsurance}
								helperText={
									categoriesError[index]?.employerNationalInsurance?.message
								}
								InputProps={{
									endAdornment: "£",
									inputComponent: CurrencyInputCustom,
									inputProps: { prefix: "" },
								}}
								value={category.employerNationalInsurance}
								onChange={(e) =>
									handleCategoryChange({
										e,
										index,
										key: "employerNationalInsurance",
									})
								}
							/>
						</div>

						<div>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={12}
								color={"black"}
							>
								Gross pay for national insurance
							</StyleTypography>

							<TextField
								fullWidth
								className={"default-text-field"}
								disabled={!isEditable}
								error={!!categoriesError[index]?.grossPayForNationalInsurance}
								helperText={
									categoriesError[index]?.grossPayForNationalInsurance?.message
								}
								InputProps={{
									endAdornment: "£",
									inputComponent: CurrencyInputCustom,
									inputProps: { prefix: "" },
								}}
								value={category.grossPayForNationalInsurance}
								onChange={(e) =>
									handleCategoryChange({
										e,
										index,
										key: "grossPayForNationalInsurance",
									})
								}
							/>
						</div>
					</div>

					<StyleTypography fontSize={20} fontSizeMedium={16} color={"black"}>
						National Insurance Director
					</StyleTypography>

					<Divider />

					<div className={"fields-group"}>
						<div>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={12}
								color={"black"}
							>
								Director Earnings at LEL
							</StyleTypography>

							<TextField
								fullWidth
								className={"default-text-field"}
								disabled={!isEditable}
								error={!!categoriesError[index]?.directorEarningsAtLeL}
								helperText={
									categoriesError[index]?.directorEarningsAtLeL?.message
								}
								InputProps={{
									endAdornment: "£",
									inputComponent: CurrencyInputCustom,
									inputProps: { prefix: "" },
								}}
								value={category.directorEarningsAtLeL}
								onChange={(e) =>
									handleCategoryChange({
										e,
										index,
										key: "directorEarningsAtLeL",
									})
								}
							/>
						</div>

						<div>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={12}
								color={"black"}
							>
								Director Earnings to PT
							</StyleTypography>

							<TextField
								fullWidth
								className={"default-text-field"}
								disabled={!isEditable}
								error={!!categoriesError[index]?.directorEarningsToPT}
								helperText={
									categoriesError[index]?.directorEarningsToPT?.message
								}
								InputProps={{
									endAdornment: "£",
									inputComponent: CurrencyInputCustom,
									inputProps: { prefix: "" },
								}}
								value={category.directorEarningsToPT}
								onChange={(e) =>
									handleCategoryChange({
										e,
										index,
										key: "directorEarningsToPT",
									})
								}
							/>
						</div>

						<div>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={12}
								color={"black"}
							>
								Director Earnings to UEL
							</StyleTypography>

							<TextField
								fullWidth
								className={"default-text-field"}
								disabled={!isEditable}
								error={!!categoriesError[index]?.directorEarningsToUEL}
								helperText={
									categoriesError[index]?.directorEarningsToUEL?.message
								}
								InputProps={{
									endAdornment: "£",
									inputComponent: CurrencyInputCustom,
									inputProps: { prefix: "" },
								}}
								value={category.directorEarningsToUEL}
								onChange={(e) =>
									handleCategoryChange({
										e,
										index,
										key: "directorEarningsToUEL",
									})
								}
							/>
						</div>

						<div>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={12}
								color={"black"}
							>
								Director national insurance
							</StyleTypography>

							<TextField
								fullWidth
								className={"default-text-field"}
								disabled={!isEditable}
								error={!!categoriesError[index]?.directorNationalInsurance}
								helperText={
									categoriesError[index]?.directorNationalInsurance?.message
								}
								InputProps={{
									endAdornment: "£",
									inputComponent: CurrencyInputCustom,
									inputProps: { prefix: "" },
								}}
								value={category.directorNationalInsurance}
								onChange={(e) =>
									handleCategoryChange({
										e,
										index,
										key: "directorNationalInsurance",
									})
								}
							/>
						</div>

						<div>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={12}
								color={"black"}
							>
								Director Employer National Insurance
							</StyleTypography>

							<TextField
								fullWidth
								className={"default-text-field"}
								disabled={!isEditable}
								error={
									!!categoriesError[index]?.directorEmployerNationalInsurance
								}
								helperText={
									categoriesError[index]?.directorEmployerNationalInsurance
										?.message
								}
								InputProps={{
									endAdornment: "£",
									inputComponent: CurrencyInputCustom,
									inputProps: { prefix: "" },
								}}
								value={category.directorEmployerNationalInsurance}
								onChange={(e) =>
									handleCategoryChange({
										e,
										index,
										key: "directorEmployerNationalInsurance",
									})
								}
							/>
						</div>

						<div>
							<StyleTypography
								fontSize={14}
								fontSizeMedium={12}
								color={"black"}
							>
								Director Gross Pay for National Insurance
							</StyleTypography>

							<TextField
								fullWidth
								className={"default-text-field"}
								disabled={!isEditable}
								error={
									!!categoriesError[index]?.directorGrossPayForNationalInsurance
								}
								helperText={
									categoriesError[index]?.directorGrossPayForNationalInsurance
										?.message
								}
								InputProps={{
									endAdornment: "£",
									inputComponent: CurrencyInputCustom,
									inputProps: { prefix: "" },
								}}
								value={category.directorGrossPayForNationalInsurance}
								onChange={(e) =>
									handleCategoryChange({
										e,
										index,
										key: "directorGrossPayForNationalInsurance",
									})
								}
							/>
						</div>
					</div>
				</>
			))}

			{isEditable && (
				<div className={"save-button-container"}>
					<FreepayrollButton type="submit" variant="primary">
						Save
					</FreepayrollButton>
				</div>
			)}
		</form>
	);
}
