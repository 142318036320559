export const CREATE_LEAD = 'CREATE_LEAD';

export const CREATE_LEAD_SUCCESS = 'CREATE_LEAD_SUCCESS';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';

export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_COMPANY = "VERIFY_COMPANY";
export const SEARCH_COMPANIES = "SEARCH_COMPANIES";
export const SEARCH_DIRECTORS = "SEARCH_DIRECTORS";