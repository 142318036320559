import Grid from "@mui/material/Grid";
import {
	Alert,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import Button from "@mui/material/Button";
import StyleTypography from "../../StyledComponents/StyleTypography";
import FormControl from "@mui/material/FormControl";
import {useState} from "react";
import Request from "../../../utils/Request";

export const PayScheduleModal = (
	{
		open,
		setOpen,
		employeeRecord,
		setEmployeeRecord,
		employee,
		updateEmployee,
	}
): JSX.Element => {
	const [isLoading, setIsLoading] = useState(false);
	const [paySchedules, setPaySchedules] = useState([]);

	return (
		<Dialog
			onClose={() => {
				setEmployeeRecord({
					...employeeRecord,
					pay_schedule: employee?.pay_schedule,
				});
				setPaySchedules([]);
				setOpen(false);
			}}
			open={open}
			fullWidth={true}
			maxWidth={'sm'}
			id={"salary-modal"}
		>
			<DialogTitle>
				<StyleTypography
					fontSize={35}
					fontSizeMedium={28}
					fontWeight={'bold'}
				>
					Change pay schedule for {employee.forename} {employee.surname}
				</StyleTypography>
			</DialogTitle>
			<DialogContent>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					{/*Employment Type*/}
					<Grid
						item
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Pay schedule*
						</InputLabel>
						<FormControl
							fullWidth
							sx={{
								marginTop: '10px',
							}}
						>
							<Select
								className={"default-select"}
								id={"select-gender"}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 200, // Adjust the maximum height of the list
										},
									},
								}}
								isLoading={isLoading}
								onOpen={() => {
									if (paySchedules.length === 0) {
										setIsLoading(true);
										Request.get(`/api/employers/find?relations[]=pay_schedules`)
											.then(response => {
												if (response.status === 200) {
													setPaySchedules(response.data.employer.pay_schedules)
												}
												setIsLoading(false);
											})
											.catch(error => {
												console.error(error);
												setIsLoading(false);
											})
									}
								}}
								disabled={isLoading}
								value={employeeRecord?.pay_schedule?.id}
								onChange={(e) => {
									setEmployeeRecord({
										...employeeRecord,
										pay_schedule: {
											id: e.target.value,
										}
									});
								}}
							>
								<MenuItem
									key={'select-title'}
									value={employee?.pay_schedule?.id}
									disabled
								>
									{employee?.pay_schedule?.name}
								</MenuItem>
								{
									isLoading ? (
										<MenuItem key={'loading'} disabled>
											<CircularProgress size={24} />
											<span style={{ marginLeft: '10px' }}>Loading options...</span>
										</MenuItem>
									) : (
										paySchedules?.map((pay_schedule) => pay_schedule?.id !== employee?.pay_schedule?.id && (
											<MenuItem key={pay_schedule.id} value={pay_schedule.id}>
												{pay_schedule.name}
											</MenuItem>
										))
									)
								}
							</Select>
						</FormControl>
						{
							employeeRecord?.pay_schedule && (

								<Grid
									padding={1}
								>
									<Alert severity="error">
										Are you sure you want to change this employee's pay schedule?
										If you proceed, the employee will be moved to the new pay schedule, and you will be
										responsible for ensuring that there are no overpayments or underpayments.
									</Alert>
								</Grid>
							)
						}
					</Grid>

				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<Button
							fullWidth
							className={"default-black-button"}
							disabled={employeeRecord?.pay_schedule?.id === employee?.pay_schedule?.id}
							onClick={() => {
								updateEmployee(employee.safe_id, {
									employees: {
										'action': 'pay_schedule',
										new_pay_schedule_id: employeeRecord?.pay_schedule?.id,
									}
								})
							}}
						>
							Save
						</Button>
					</Grid>

					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<Button
							fullWidth
							className={"default-white-button"}
							onClick={() => {
								setEmployeeRecord({
									...employeeRecord,
									pay_schedule: employee?.pay_schedule,
								});
								setPaySchedules([]);
								setOpen(false);
							}}
						>
							Cancel
						</Button>
					</Grid>

				</Grid>
			</DialogActions>
		</Dialog>
	)
}