import Grid from "@mui/material/Grid";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import StyleTypography from "../../StyledComponents/StyleTypography";
import React, {useState} from "react";
import closedEye from "../../../images/svg/closed-eye.svg";
import openEye from "../../../images/svg/open-eye.svg";

export const HmrcEditModal = ({
	open,
	setOpen,
	employerRecord,
	setEmployerRecord,
	employer,
	updateHMRCSettings,
	connectHmrc,
}): JSX.Element => {

	const regex_office_reference = /^[0-9]{3}P[A-Z][0-9]{7}[0-9X]$/;
	const regex_paye_reference = /^\d{3}\/.*$/i;
	const [showPassword, setShowPassword] = useState(false);

	return (
		<Dialog
			onClose={() => setOpen(false)}
			open={open}
			fullWidth={true}
			maxWidth={'sm'}
			id={"salary-modal"}
		>
			<DialogTitle>
				<Grid
					justifyContent={"space-between"}
					container
					alignItems={"center"}
				>
					<Grid>
						<StyleTypography
							fontSize={35}
							fontSizeMedium={28}
							fontWeight={'bold'}
						>
							Edit HMRC settings
						</StyleTypography>
					</Grid>
					<Grid>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={16}
							color={'#000'}
							className={'text-underlined'}
							onClick={() => window.open("https://help.freepayroll.uk/en/article/issues-with-hrmc-credentials-1w9meu7/", "_blank")}
						>
							Can't connect?
						</StyleTypography>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>

					{/*PAYE Reference*/}
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<InputLabel
							className={"default-input-label"}
						>
							PAYE reference
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							value={employerRecord.rti_key?.paye_reference}
							onChange={(e) => {
								setEmployerRecord({
									...employerRecord,
									rti_key: {
										...employerRecord.rti_key,
										paye_reference: e.target.value,
									}
								})
							}}
							error={
								!regex_paye_reference.test(employerRecord.rti_key?.paye_reference)
							}
							helperText={
								!regex_paye_reference.test(employerRecord.rti_key?.paye_reference) && "PAYE reference invalid"
							}
						/>
					</Grid>

					{/*Accounts office ref*/}
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Accounts office reference
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							value={employerRecord.rti_key?.account_office_reference}
							onChange={(e) => {
								setEmployerRecord({
									...employerRecord,
									rti_key: {
										...employerRecord.rti_key,
										account_office_reference: e.target.value,
									}
								})
							}}
							error={!regex_office_reference.test(employerRecord.rti_key?.account_office_reference)}
							helperText={
								!regex_office_reference.test(employerRecord.rti_key?.account_office_reference) && "Accounts office reference invalid"
							}
						/>
					</Grid>

					{/*HMRC Gateway User ID*/}
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<InputLabel
							className={"default-input-label"}
						>
							HMRC Gateway User ID
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							value={employerRecord.rti_key?.user}
							onChange={(e) => {
								setEmployerRecord({
									...employerRecord,
									rti_key: {
										...employerRecord.rti_key,
										user: e.target.value,
									}
								})
							}}
							error={employerRecord.rti_key?.user === "" || !employerRecord.rti_key?.user}
						/>
					</Grid>

					{/*HMRC Gateway User password*/}
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<InputLabel
							className={"default-input-label"}
						>
							HMRC Gateway User password
						</InputLabel>
						<TextField
							type={showPassword ? "text" : "password"}
							fullWidth
							className={"default-text-field"}
							value={employerRecord.rti_key?.password}
							onChange={(e) => {
								setEmployerRecord({
									...employerRecord,
									rti_key: {
										...employerRecord.rti_key,
										password: e.target.value,
									}
								})
							}}
							InputProps={{
								endAdornment: (
									<img
										src={showPassword ? closedEye : openEye}
										alt={'show password'}
										onClick={() => setShowPassword(!showPassword)}
									/>
								),
							}}
							error={employerRecord.rti_key?.password === "" || !employerRecord.rti_key?.password}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					<Grid
						container
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
						item
						spacing={2}
					>
						<Grid
							item
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={6}
						>
							<Button
								fullWidth
								className={"default-black-button"}
								onClick={() => {
									connectHmrc({
										'rti_keys': {
											'paye_reference': employerRecord.rti_key?.paye_reference,
											'account_office_reference': employerRecord.rti_key?.accounts_office_reference,
											'user': employerRecord.rti_key?.user,
											'password': employerRecord.rti_key?.password,
										}
									});
								}}
								disabled={
									!regex_office_reference.test(employerRecord.rti_key?.account_office_reference) ||
									!regex_paye_reference.test(employerRecord.rti_key?.paye_reference) ||
									employerRecord.rti_key?.user === "" ||
									employerRecord.rti_key?.password === "" ||
									!employerRecord.rti_key?.user ||
									!employerRecord.rti_key?.password
								}
							>
								<StyleTypography
									fontSize={16}
									fontSizeMedium={14}
									fontWeight={'bold'}
									color={'#fff'}
								>
									Test Connection
								</StyleTypography>
							</Button>
						</Grid>
					</Grid>

					<Grid
						container
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
						item
						spacing={2}
					>
						<Grid
							item
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={6}
						>
							<Button
								fullWidth
								className={"default-white-button"}
								onClick={() => {
									setEmployerRecord({
										...employerRecord,
										rti_key: employer.rti_key,
									});
									setOpen(false);
								}}
							>
								Cancel
							</Button>
						</Grid>

						<Grid
							item
							xl={6}
							lg={6}
							md={6}
							sm={6}
							xs={6}
						>
							<Button
								fullWidth
								className={"default-black-button"}
								onClick={() => {
									updateHMRCSettings({
										'rti_keys': {
											'paye_reference': employerRecord.rti_key?.paye_reference,
											'account_office_reference': employerRecord.rti_key?.account_office_reference,
											'user': employerRecord.rti_key?.user,
											'password': employerRecord.rti_key?.password,
										}
									});
									setOpen(false);
								}}
								disabled={
									!regex_office_reference.test(employerRecord.rti_key?.account_office_reference) ||
									!regex_paye_reference.test(employerRecord.rti_key?.paye_reference) ||
									employerRecord.rti_key?.user === "" ||
									employerRecord.rti_key?.password === "" ||
									!employerRecord.rti_key?.user ||
									!employerRecord.rti_key?.password
								}
							>
								Save
							</Button>
						</Grid>
					</Grid>

				</Grid>
			</DialogActions>
		</Dialog>
	)
}