import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import React, {useEffect, useState} from "react";
import Loading from "../../components/Loading";
import {debounceSearchRender} from "mui-datatables";
import StyleTable from "../../components/StyledComponents/StyleTable";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {me} from "../../actions/Profile";
import {deletePayItem} from "../../actions/PayItem";
import {findEmployer} from "../../actions/Employer";
import {connect} from "react-redux";
import {StyleAppBar} from "../../components/StyleAppBar";
import {LeftMenu} from "../../components/LeftMenu";
import {useNavigate} from "react-router-dom";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ListPayItems = ({
	user = {},
	pay_item_loading = false,
	user_loading = false,
	me = () => {},
	deletePayItem = () => {},
	findEmployer = () => {},
	employer = {},
}): JSX.Element => {
	const [popOver, setPopOver] = useState(false),
		[anchorEl, setAnchorEl] = useState(null),
		[payItem, setPayItem] = useState([]),
		push = useNavigate(),
		[payItemId, setPayItemId] = useState(null);

	const handleMenuClose = () => {
		setAnchorEl(null);
		setPopOver(false);
	}

	const columns = [
		{
			name: "name",
			label: "NAME",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={15}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value}
					</StyleTypography>
				)
			},
		},
		{
			name: "type",
			label: "TYPE",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => (
					<StyleTypography
						fontSize={15}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{
							tableMeta.rowData[6] && !tableMeta.rowData[7]
								? "Gross " + value
								: !tableMeta.rowData[6] && tableMeta.rowData[7]
									? "Net " + value : ""
						}
					</StyleTypography>
				)
			},
		},
		{
			name: "before_tax",
			label: "TAXABLE",
			options: {
				hint: "We will include this amount in the total sum used to calculate income tax",
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={15}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value ? "Yes" : "No"}
					</StyleTypography>
				)
			},
		},
		{
			name: "before_ni",
			label: "NIABLE",
			options: {
				hint: "We will include this amount in the total sum used to calculate national insurance",
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={15}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value ? "Yes" : "No"}
					</StyleTypography>
				)
			},
		},
		{
			name: "before_pension",
			label: "PENSIONABLE",
			options: {
				hint: "We will include this amount in the total sum used to calculate pension contributions",
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={15}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value ? "Yes" : "No"}
					</StyleTypography>
				)
			},
		},
		{
			name: "id",
			label: "Actions",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) =>
					<Grid>
						<IconButton
							onClick={(event) => {
								setPayItemId(value);
								setAnchorEl(event.currentTarget);
								setPopOver(true);
							}}
						>
							<MoreHorizIcon/>
						</IconButton>
						<Menu
							className={"team-member-menu"}
							open={popOver}
							anchorEl={anchorEl}
							onClose={() => {
								handleMenuClose();
							}}
						>
							<MenuItem
								className={"team-member-menu-item"}
								onClick={() => {
									push(`/main/company/create-pay-item?id=${btoa(JSON.stringify(payItemId))}`);
									handleMenuClose();
								}}
							>
								<StyleTypography
									fontSize={15}
									fontSizeMedium={13}
									color={"#000"}
								>
									Edit pay item
								</StyleTypography>
							</MenuItem>
							<MenuItem
								className={"team-member-menu-item"}
								disabled={true}
								onClick={() => {
									handleMenuClose();
								}}
							>
								<StyleTypography
									fontSize={15}
									fontSizeMedium={13}
									color={"#000"}
									onClick={() => {}}
								>
									Delete pay item
								</StyleTypography>
							</MenuItem>
						</Menu>
					</Grid>
			}
		},
		{
			name: "is_gross",
			label: "",
			options: {
				filter: false,
				sort: false,
				display: false
			},
		},
		{
			name: "is_net",
			label: "",
			options: {
				filter: false,
				sort: false,
				display: false
			},
		},
	];

	useEffect(() => {
		if (!user.id) {
			me();
		}
		findEmployer(["custom_pay_items"]);
	}, [findEmployer, me, user.id]);

	useEffect(() => {
		setPayItem(employer?.custom_pay_items);
	},[employer?.custom_pay_items])


	if (user_loading || pay_item_loading) {
		return <Loading/>;
	}
	return (

		<Grid
			container
			justifyContent={"center"}
			id={"pay-items-page"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
				employer={employer}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Company"}
						activeSubpage={"Pay Items"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
							justifyContent={"space-between"}
							alignItems={"center"}
						>
							<Grid>
								<StyleTypography
									fontSize={40}
									fontSizeMedium={30}
									fontWeight={"bold"}
								>
									Pay items
								</StyleTypography>
							</Grid>
							<Grid
								container
							>
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									container
									justifyContent={"space-between"}
									alignItems={"center"}
								>
									<Grid
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										item
										paddingTop={"10px"}
									>
										<StyleTypography
											fontSize={18}
											fontSizeMedium={13}
											color={"#ABAEB7"}
										>
											Pay items allow you to set up custom payments and track each payment in the payroll journal
										</StyleTypography>
									</Grid>
									<Grid className={"pay-items-divider"}><Divider/></Grid>
									<Grid
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										item
										container
										className={"pay-items-type-container"}
										justifyContent={"space-between"}
									>
										<StyleTable
											data={payItem ?? []}
											columns={columns}
											title={"Pay Items"}
											addCustomButtons={true}
											options={{
												serverSide: false,
												textLabels: {
													body: {
														noMatch: payItem?.length === 0 ?
															<Grid
																marginTop={30}
															>
																<Loading/>
															</Grid>
															:
															'Sorry, there is no matching data to display',
													},
												},
												customSearchRender: debounceSearchRender(1000),
											}}
											customButtons={
												<>
													<Button
														id={'black-button'}
														sx={{
															marginLeft: "10px",
														}}
														onClick={() => {
															push("/main/company/create-pay-item")
														}}
													>
														<StyleTypography
															fontSize={16}
															fontSizeMedium={12}
															color={"#fff"}
														>
															Add Pay Item
														</StyleTypography>
													</Button>
												</>
											}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>

	);
}

const mapStateToProps = state => {
	const {
		Profile,
		PayItem,
		Employer,
	} = state;

	return {
		...Profile,
		...PayItem,
		...Employer
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	deletePayItem: (id: number) => dispatch(deletePayItem(id)),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListPayItems)