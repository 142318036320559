import Grid from "@mui/material/Grid";
import {
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle, FormHelperText,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import StyleTypography from "../../StyledComponents/StyleTypography";
import FormControl from "@mui/material/FormControl";
import {CurrencyInputCustom} from "../../StyledComponents/CurrencyInputCustom";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useState} from "react";

export const EditModal = ({
	open,
	setOpen,
	employerRecord,
	setEmployerRecord,
	employer,
    updateDefaultPensionSettings,
}): JSX.Element => {

	const [applyToAll, setApplyToAll] = useState(false);

	return (
		<Dialog
			onClose={() => setOpen(false)}
			open={open}
			fullWidth={true}
			maxWidth={'sm'}
			id={"salary-modal"}
		>
			<DialogTitle>
				<StyleTypography
					fontSize={35}
					fontSizeMedium={28}
					fontWeight={'bold'}
				>
					Edit pension settings
				</StyleTypography>
			</DialogTitle>
			<DialogContent>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>

					{/*Pension Type*/}
					<Grid
						item
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Pension type
						</InputLabel>
						<FormControl
							fullWidth
						>
							<Select
								className={"default-select"}
								id={"select-gender"}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 200, // Adjust the maximum height of the list
										},
									},
								}}
								value={employerRecord.pension_scheme?.relief_at_source}
								onChange={(e) => {
									setEmployerRecord({
										...employerRecord,
										pension_scheme: {
											...employerRecord.pension_scheme,
											relief_at_source: e.target.value,
											salary_sacrifice: e.target.value === "true" ? "false" : "true"
										}
									})
								}}
							>
								<MenuItem
									key={'select-title'}
									value={"true"}
								>
									Relief at source
								</MenuItem>
								<MenuItem
									key={'select-title'}
									value={"false"}
								>
									Salary sacrifice
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					{/*Pension Calculation*/}
					<Grid
						item
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Pension calculation
						</InputLabel>
						<FormControl
							fullWidth
						>
							<Select
								className={"default-select"}
								id={"select-gender"}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 200, // Adjust the maximum height of the list
										},
									},
								}}
								onChange={(e) => {
									setEmployerRecord({
										...employerRecord,
										pension_scheme: {
											...employerRecord.pension_scheme,
											pensionable_earnings: e.target.value,
											qualifying_earnings: e.target.value === "true" ? "false" : "true"
										}
									})
								}}
								value={employerRecord.pension_scheme?.pensionable_earnings}
							>
								<MenuItem
									key={'select-title'}
									value={"true"}
								>
									Pensionable Earnings
								</MenuItem>
								<MenuItem
									key={'select-title'}
									value={"false"}
								>
									Qualifying Earnings
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					{/*Employee contribution*/}
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Employee contribution*
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							InputProps={{
								endAdornment: <InputAdornment position={"end"}>
									<StyleTypography fontSize={16} color={"black"} fontWeight={"bold"}>
										%
									</StyleTypography>
								</InputAdornment>,
								inputComponent: CurrencyInputCustom,
								inputProps: {prefix: ""},
							}}
							value={employerRecord.pension_scheme?.employee_rate_percentage ?? "N/A"}
							onChange={(e) => {
								setEmployerRecord({
									...employerRecord,
									pension_scheme: {
										...employerRecord.pension_scheme,
										employee_rate_percentage: e.target.value,
										employee_rate: Number(e.target.value) / 100
									}
								})
							}}
						/>
					</Grid>

					{/*Employer contribution*/}
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Employer contribution*
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							InputProps={{
								endAdornment: <InputAdornment position={"end"}>
									<StyleTypography fontSize={16} color={"black"} fontWeight={"bold"}>
										%
									</StyleTypography>
								</InputAdornment>,
								inputComponent: CurrencyInputCustom,
								inputProps: {prefix: ""},
							}}
							value={employerRecord.pension_scheme?.employer_rate_percentage ?? "N/A"}
							onChange={(e) => {
								setEmployerRecord({
									...employerRecord,
									pension_scheme: {
										...employerRecord.pension_scheme,
										employer_rate_percentage: e.target.value,
										employer_rate: Number(e.target.value) / 100
									}
								})
							}}
							error={employerRecord.pension_scheme?.employer_rate_percentage < 3}
							helperText={employerRecord.pension_scheme?.employer_rate_percentage < 3 ? "Minimum 3%" : ""}
						/>
					</Grid>

					<Grid
						item
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						{
							Number(employerRecord.pension_scheme?.employer_rate_percentage) + Number(employerRecord.pension_scheme?.employee_rate_percentage) < 8 && (
								<FormHelperText error>*Minimum combined contribution amount is 8% (employee + employer).</FormHelperText>
							)
						}

						{
							Number(employerRecord.pension_scheme?.employer_rate_percentage) + Number(employerRecord.pension_scheme?.employee_rate_percentage) > 100 && (
								<FormHelperText error>*Maximum combined contribution amount is 100% (employee + employer).</FormHelperText>
							)
						}

					</Grid>

					{/*Apply to all employees*/}
					<Grid
						item
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<FormControlLabel
							control={
								<Checkbox
									defaultValue={false}
									icon={<CheckBoxOutlineBlankSharpIcon/>}
									checkedIcon={<CheckBoxSharpIcon/>}
									onChange={(e) => {
										setApplyToAll(e.target.checked)
									}}
								/>
							}
							label={"Apply this changes to all existing employees"}
						/>
					</Grid>

				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<Button
							fullWidth
							className={"default-black-button"}
							disabled={
								(employerRecord.pension_scheme?.employee_rate_percentage === employer.pension_scheme?.employee_rate_percentage &&
								employerRecord.pension_scheme?.employer_rate_percentage === employer.pension_scheme?.employer_rate_percentage &&
								employerRecord.pension_scheme?.pensionable_earnings === employer.pension_scheme?.pensionable_earnings &&
								employerRecord.pension_scheme?.relief_at_source === employer.pension_scheme?.relief_at_source) ||
								Number(employerRecord.pension_scheme?.employer_rate_percentage) < 3 ||
								(
									Number(employerRecord.pension_scheme?.employer_rate_percentage) + Number(employerRecord.pension_scheme?.employee_rate_percentage) > 100 ||
									Number(employerRecord.pension_scheme?.employer_rate_percentage) + Number(employerRecord.pension_scheme?.employee_rate_percentage) < 8
								)
							}
							onClick={() => {
								updateDefaultPensionSettings({
									"pension_schemes": {
										"update_data": {
											employee_rate: employerRecord.pension_scheme?.employee_rate,
											employer_rate: employerRecord.pension_scheme?.employer_rate,
											salary_sacrifice: employerRecord.pension_scheme?.salary_sacrifice,
											pensionable_earnings: employerRecord.pension_scheme?.pensionable_earnings,
											relief_at_source: employerRecord.pension_scheme?.relief_at_source,
											qualifying_earnings: employerRecord.pension_scheme?.qualifying_earnings,
										},
										apply_to_all: applyToAll,
									}
								})
							}}
						>
							Save
						</Button>
					</Grid>

					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<Button
							fullWidth
							className={"default-white-button"}
							onClick={() => {
								setEmployerRecord({
									...employerRecord,
									pension_scheme: employer.pension_scheme,
								});
								setOpen(false);
							}}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}