import { connect } from "react-redux";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {viewPaymentBreakdown} from "../../actions/PayScheduleRun";
import {CompletedContent} from "../../components/CompletedContent/CompletedContent";
import Layout from "../../components/common/Layout";

const CompletedPayroll = ({
    viewPaymentBreakdown,
}): JSX.Element => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    return (
        <Layout
            activeSubpage={"Active"}
            activePage={"Payroll"}
            showBackButton={true}
            backLink={'/main'}
            isLoggedLayout={false}
        >
            <CompletedContent
                mode={"completed"}
                approvalAction={"Payroll completed"}
                scheduleName={searchParams.get("pay_schedule")}
                taxPeriod={searchParams.get("tax_period")}
                payDate={searchParams.get("pay_date")}
                onButtonClick={() => navigate("/main")}
                onDownloadClick={() => viewPaymentBreakdown({}, searchParams.get("id"), true)}
            />
        </Layout>
    );
}


const mapStateToProps = state => {
    const {
        PayScheduleRun,
    } = state;

    return {
        ...PayScheduleRun,
    }
}


const mapDispatchToProps = dispatch => ({
    viewPaymentBreakdown: (id, openModal, isDownload) => dispatch(viewPaymentBreakdown(id,openModal, isDownload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompletedPayroll)