import Grid from "@mui/material/Grid";
import React, {useState} from "react";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Divider from "@mui/material/Divider";
import StyleButton from "../../components/StyledComponents/StyleButton";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const CloseTaxYearCarryOver = ({
	setStep = {},
	closeTaxYear = () => {},
}): JSX.Element => {
	const [choice, setChoice] = useState('');

	return (
		<Grid
			container
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<Grid>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={30}
						fontWeight={'bold'}
						component={'h1'}
					>
						Carry Over Pay Schedules
					</StyleTypography>
				</Grid>
				<Grid className={'sub-title-container'} maxWidth={"85%"}>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={14}
						color={'#ABAEB7'}
					>
						As you prepare to start the new tax year, you have the option to either carry over your existing pay schedules or inactivate them and create new ones.
					</StyleTypography>
				</Grid>
			</Grid>

			<Divider className={'divider details-divider'}/>

			<Grid marginBottom={"30px"}>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={14}
					color={'black'}
				>
					Please select your preference before proceeding:
				</StyleTypography>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				marginBottom={"50px"}
			>
				<RadioGroup
					row
					onChange={(event) => {
						setChoice(event.target.value)
					}}
					value={choice}
				>
					<FormControlLabel
						sx={{marginBottom: "50px", alignItems: "flex-start"}}
						value={'carry_over'}
						control={<Radio className={"close-tax-carry-over-radio-button"}/>}
						label={
							<Grid width={"70%"}>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={14}
									color={'black'}
									fontWeight={"bold"}
								>
									Carry Over:
								</StyleTypography>

								<StyleTypography
									fontSize={18}
									fontSizeMedium={14}
									color={'#ABAEB7'}
								>
									If you choose to carry over your current pay schedules, all existing schedules will be automatically moved to the new tax year, ensuring continuity without the need for additional setup.
								</StyleTypography>
							</Grid>
						}
					/>
					<FormControlLabel
						value={'delete_create_new'}
						sx={{alignItems: "flex-start"}}
						control={<Radio className={"close-tax-carry-over-radio-button"}/>}
						label={
							<Grid width={"70%"}>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={14}
									color={'black'}
									fontWeight={"bold"}
								>
									Delete and Create New:
								</StyleTypography>

								<StyleTypography
									fontSize={18}
									fontSizeMedium={14}
									color={'#ABAEB7'}
								>
									If you prefer to start fresh, you can inactivate the existing pay schedules and set up new ones tailored to your specific needs for the new tax year.
								</StyleTypography>
							</Grid>
						}
					/>
				</RadioGroup>
			</Grid>

			<Grid
				container
			>
				<Grid item marginRight={"20px"} sm={3}>
					<StyleButton
						onClick={() => closeTaxYear({
							"employers": {
								'choice': choice
							}
						})}
						customStyle={{height: "3.5vw"}}
					>
						<StyleTypography
							fontSize={16}
							fontSizeMedium={12}
							color={'white'}
							fontWeight={"bold"}
						>
							Finish
						</StyleTypography>
					</StyleButton>
				</Grid>
			</Grid>
		</Grid>
	);
}
export default CloseTaxYearCarryOver
