import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Loading from "../../components/Loading";
import Divider from "@mui/material/Divider";
import House from "../../images/svg/hmrc-page/black-house.svg";
import Plane from "../../images/svg/hmrc-page/black-paper-plane.svg";
import {connectHmrc, findEmployer, updateAutomaticEps, updateHMRCSettings} from "../../actions/Employer";
import {HmrcEditModal} from "../../components/Pages/Hmrc/HmrcEditModal";
import EditIcon from "@mui/icons-material/Edit";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import StyleButton from "../../components/StyledComponents/StyleButton";

const renderGrids = (component1, component2) => {
	return (
		<Grid
			container
			alignItems={"center"}
			justifyContent={"space-between"}
			className={"information-item-grid"}
		>
			<Grid>
				{component1}
			</Grid>
			<Grid>
				{component2}
			</Grid>
		</Grid>
	);
}
/**
 * @returns {JSX.Element}
 * @constructor
 */
const HMRC = ({
	me = () => {},
	user = {},
	user_loading = false,
	employer_loading = false,
	employer = {},
	findEmployer = () => {},
	connectHmrc = () => {},
	updateHMRCSettings = () => {},
	updateAutomaticEps = () => {}
}): JSX.Element => {

	const [showEditModal, setShowEditModal] = useState(false);
	const [employerRecord, setEmployerRecord] = useState({});
	const [openEpsModal, setOpenEpsModal] = useState(false);

	useEffect(() => {
		me();
		findEmployer(["rti_key"]);
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [me, findEmployer]);

	useEffect(() => {
		setEmployerRecord(employer);
	},[employer])

	if (user_loading || employer_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"hmrc-page"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
				employer={employer}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"HMRC"}
						lockMenuItems={[
							employer?.rti_key?.hmrc_connected === false ? 'Payroll' : null,
							employer?.rti_key?.hmrc_connected === false ? 'People' : null,
							employer?.rti_key?.hmrc_connected === false ? 'Reports' : null,
							employer?.rti_key?.hmrc_connected === false ? 'Company' : null,
							employer?.rti_key?.hmrc_connected === false ? 'Auto Enrolment' : null,
						]}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							justifyContent={"space-between"}
							alignItems={"center"}
						>
							<StyleTypography
								fontSize={40}
								fontSizeMedium={30}
								fontWeight={"bold"}
							>
								Company HMRC settings
							</StyleTypography>
							<Grid
								sx={{
									paddingTop: "10px",
									paddingBottom: "30px"
								}}
							>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={"#ABAEB7"}
								>
									Credentials for HMRC reporting and RTI submissions
								</StyleTypography>
							</Grid>
						</Grid>
						<Divider className={"divider"}/>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
							className={"information-container"}
							direction={"row"}
						>
							<Grid
								xl={5.18}
								lg={5.18}
								md={5.18}
								sm={5.18}
								xs={5.18}
								item
								container
								className={"left-container"}
							>
								<Grid
									container
									justifyContent={"space-between"}
									className={'edit'}
								>
									<Grid>
										<Chip
											label={
												employer?.rti_key?.hmrc_connected ?
													"Connected"
													:
													"Not connected"
											}
											className={"mui-chip"}
											sx={{backgroundColor: "#000000", color: "#FFFFFF", marginLeft: "10px"}} />
									</Grid>
									<Grid sx={{cursor: "pointer"}} onClick={() => setShowEditModal(true)}>
										<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={16} fontSizeMedium={13} color={"black"}>
											Edit
											<EditIcon className={"icon"}/>
										</StyleTypography>
									</Grid>
								</Grid>
								<Divider className={"divider"}/>
								{
									renderGrids(
										<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
											PAYE reference
										</StyleTypography>,
										<Grid>
											<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
												{employer?.rti_key?.paye_reference ?? "N/A"}
											</StyleTypography>
										</Grid>
									)
								}
								<Divider className={"divider"}/>
								{
									renderGrids(
										<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
											Accounts office reference
										</StyleTypography>,
										<Grid>
											<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
												{employer?.rti_key?.account_office_reference ?? "N/A"}
											</StyleTypography>
										</Grid>
									)
								}
								<Divider className={"divider"}/>
								{
									renderGrids(
										<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
											HMRC Gateway User ID
										</StyleTypography>,
										<Grid>
											<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
												{employer?.rti_key?.user ?? "N/A"}
											</StyleTypography>
										</Grid>
									)
								}
								<Divider className={"divider"}/>
								{
									renderGrids(
										<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
											HMRC Gateway Password
										</StyleTypography>,
										<Grid>
											<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
												xxxxxx
											</StyleTypography>
										</Grid>
									)
								}
							</Grid>

							<Grid
								xl={5.18}
								lg={5.18}
								md={5.18}
								sm={5.18}
								xs={5.18}
								item
								container
								sx={{
									marginLeft: "36px"
								}}
								className={"right-container"}
								alignItems={"flex-start"}
								alignSelf={"flex-start"}
							>
								<Grid container className={"information-item-grid"}>
									<Grid item xs={true}>
										<Grid container alignItems={"center"}>
											<Grid sx={{marginRight: '20px'}}>
												<img
													alt={"logo"}
													src={House}
													className={"right-icon"}
												/>
											</Grid>
											<StyleTypography fontSize={22} color={"black"} fontSizeMedium={16} fontWeight={"bold"}>
												HMRC
											</StyleTypography>
										</Grid>
										<Grid container alignItems={"center"}>
											<StyleTypography fontSize={18} fontSizeMedium={13} color={"#AAAEB7"}>
												PAYE is the HMRC system for collecting income tax and National Insurance from employment.
											</StyleTypography>
										</Grid>
									</Grid>
								</Grid >
								<Divider className={"divider"}/>
								<Grid container className={"information-item-grid"}>
									<Grid item xs={true}>
										<Grid container alignItems={"center"}>
											<Grid sx={{marginRight: '20px'}}>
												<img
													alt={"logo"}
													src={Plane}
													className={"right-icon"}
												/>
											</Grid>
											<StyleTypography fontSize={22} color={"black"} fontSizeMedium={16} fontWeight={"bold"}>
												RTI Submission
											</StyleTypography>
										</Grid>
										<Grid>
											<StyleTypography fontSize={18} fontSizeMedium={13} color={"#AAAEB7"}>
												Each time you run payroll for your employees, we perform a RTI Submission to HMRC.
											</StyleTypography>
										</Grid>
									</Grid>
								</Grid>
								<Divider className={"divider"}/>
								<Grid container className={"information-item-grid"}>
									<FormControlLabel
										control={
											<Switch
												sx={{ marginLeft: 'auto' }}
												checked={employerRecord?.eps_automatic}
												onClick={() => setOpenEpsModal(true)}
											/>
										}
										label={
											<StyleTypography
												fontSize={18}
												fontSizeMedium={13}
												color={"black"}
											>
												Automatic EPS submission
											</StyleTypography>
										}
										labelPlacement={"start"}
										sx={{width: '100%', marginLeft: 0}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<HmrcEditModal
					open={showEditModal}
					setOpen={setShowEditModal}
					employer={employer}
					employerRecord={employerRecord}
					setEmployerRecord={setEmployerRecord}
					connectHmrc={connectHmrc}
					updateHMRCSettings={updateHMRCSettings}
				/>
				<Dialog
					open={openEpsModal}
					onClose={() => setOpenEpsModal(false)}
					fullWidth={true}
					maxWidth={'sm'}
				>
					<DialogTitle>
						<StyleTypography className={'dialog-title'} fontSize={30} fontSizeMedium={26}>
							{
								employerRecord?.eps_automatic
									? "Are you sure you want to disable automatic EPS submission?"
									: "Are you sure you want to enable automatic EPS submission?"
							}
						</StyleTypography>
					</DialogTitle>
					<DialogContent>
						<StyleTypography
							fontSize={22}
							fontSizeMedium={18}
							color={"#000"}
						>
							{
								employerRecord?.eps_automatic
									? "If you disable automatic EPS submission, you will need to handle it manually"
									: "Enabling automatic EPS submission will automatically submit EPS to HMRC, every " +
									"18th of the month. You will still be able to send any custom submission, " +
									"by accessing the reports section."
							}
						</StyleTypography>
					</DialogContent>
					<DialogActions>
						<Grid container xs={6} spacing={1}>
							<Grid item xs={5.8}>
								<StyleButton
									onClick={() => {
										updateAutomaticEps({
											employers: {
												eps_automatic: !employerRecord?.eps_automatic
											}
										});
										setOpenEpsModal(false);
									}}
								>
									<StyleTypography fontWeight={"bold"} fontSize={18} fontSizeMedium={14} color={"#FFF"}>
										Yes
									</StyleTypography>
								</StyleButton>
							</Grid>
							<Grid item xs={5.8}>
								<StyleButton
									isWhiteButton={true}
									onClick={() => setOpenEpsModal(false)}
								>
									<StyleTypography fontWeight={"bold"} fontSize={16} fontSizeMedium={12} color={"black"}>
										No
									</StyleTypography>
								</StyleButton>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Employer,
	} = state;

	return {
		...Profile,
		...Employer,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	updateHMRCSettings: (data) => dispatch(updateHMRCSettings(data)),
	connectHmrc: (data) => dispatch(connectHmrc(data)),
	updateAutomaticEps: (data) => dispatch(updateAutomaticEps(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HMRC)