import Grid from "@mui/material/Grid";
import StyleTypography from "../../StyledComponents/StyleTypography";
import React, {useState} from "react";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import {CurrencyInputCustom} from "../../StyledComponents/CurrencyInputCustom";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import enGb from "date-fns/locale/en-GB";
import {addDays, addWeeks, isWithinInterval, parseISO, subDays} from "date-fns";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import {MenuItem, Select} from "@mui/material";

const DateSelection = ({
	employeeRecord,
	setEmployeeRecord,
	employee,
	setIsDateError,
	weeksOnLeave,
}) => {

	if (weeksOnLeave !== "") {
		return (
			<Grid
				container
				direction={"row"}
				spacing={4}
				paddingTop={"30px"}
				paddingBottom={"30px"}
			>
				<Grid
					item
					xs={12}
					container
				>
					{
						weeksOnLeave === 'two_weeks_separate_blocks' && (
							<Grid item xs={12}>
								<StyleTypography
									color={"black"}
									fontSize={18}
									fontSizeMedium={13}
									fontWeight={"bold"}
								>
									First block of leave
								</StyleTypography>
							</Grid>
						)
					}
					<Grid item xs={5} marginRight={2}>
						<InputLabel
							className={"default-input-label"}
						>
							Leave start date
						</InputLabel>
						<LocalizationProvider
							dateAdapter={AdapterDateFns}
							adapterLocale={enGb}
						>
							<DesktopDatePicker
								className={"date-picker"}
								value={parseISO(employeeRecord.parental_leave?.first_start_date)}
								onChange={(newValue) => {
									if (newValue instanceof Date && !isNaN(newValue.getTime())) {
										employeeRecord.parental_leave.first_start_date = newValue.toISOString().split('T')[0];
										if (weeksOnLeave === 'one_week' || weeksOnLeave === 'two_weeks_separate_blocks') {
											employeeRecord.parental_leave.first_end_date = subDays(addWeeks(newValue, 1),1).toISOString().split('T')[0];
										} else if (weeksOnLeave === 'two_weeks') {
											employeeRecord.parental_leave.first_end_date = subDays(addWeeks(newValue, 2),1).toISOString().split('T')[0];
										}
										setEmployeeRecord(employeeRecord);
									}
								}}
								shouldDisableDate={(day) => {
									return employee.leaves?.map((leave) => {
										return {
											startDate: parseISO(leave.leave_start_date),
											endDate: parseISO(leave.leave_end_date)
										}
									}).some((leave) => {
										return isWithinInterval(day, {start: leave.startDate, end: leave.endDate})
									})
								}}
								onError={(error, value) => {
									if (error) {
										setIsDateError(true);
									} else {
										setIsDateError(false);
									}
								}}
								minDate={parseISO(employeeRecord.parental_leave?.expected_due_date)}
								maxDate={addDays(parseISO(employeeRecord.parental_leave?.expected_due_date), 49)}
							/>
						</LocalizationProvider>
					</Grid>
					<Grid item xs={5}>
						<InputLabel
							className={"default-input-label"}
						>
							Leave end date
						</InputLabel>
						<LocalizationProvider
							dateAdapter={AdapterDateFns}
							adapterLocale={enGb}
						>
							<DesktopDatePicker
								slotProps={{ field: { readOnly: true } }}
								enableAccessibleFieldDOMStructure
								className={"date-picker"}
								value={parseISO(employeeRecord?.parental_leave?.first_end_date)}
								disabled
								shouldDisableDate={(day) => {
									return employee.leaves?.map((leave) => {
										return {
											startDate: parseISO(leave.leave_start_date),
											endDate: parseISO(leave.leave_end_date)
										}
									}).some((leave) => {
										return isWithinInterval(day, {start: leave.startDate, end: leave.endDate})
									})
								}}
								onError={(error, value) => {
									if (error) {
										setIsDateError(true);
									} else {
										setIsDateError(false);
									}
								}}
							/>
						</LocalizationProvider>
					</Grid>
				</Grid>
				{
					weeksOnLeave === 'two_weeks_separate_blocks' && (
						<Grid
							item
							xs={12}
							container
						>
							<Grid item xs={12}>
								<StyleTypography
									color={"black"}
									fontSize={18}
									fontSizeMedium={13}
									fontWeight={"bold"}
								>
									Second block of leave
								</StyleTypography>
							</Grid>
							<Grid item xs={5} marginRight={2}>
								<InputLabel
									className={"default-input-label"}
								>
									Leave start date
								</InputLabel>
								<LocalizationProvider
									dateAdapter={AdapterDateFns}
									adapterLocale={enGb}
								>
									<DesktopDatePicker
										className={"date-picker"}
										value={parseISO(employeeRecord.parental_leave?.second_start_date)}
										onChange={(newValue) => {
											if (newValue instanceof Date && !isNaN(newValue.getTime())) {
												employeeRecord.parental_leave.second_start_date = newValue.toISOString().split('T')[0];
												employeeRecord.parental_leave.second_end_date = subDays(addWeeks(newValue, 1),1).toISOString().split('T')[0];
												setEmployeeRecord(employeeRecord);
											}
										}}
										shouldDisableDate={(day) => {
											return employee.leaves?.map((leave) => {
												return {
													startDate: parseISO(leave.leave_start_date),
													endDate: parseISO(leave.leave_end_date)
												}
											}).some((leave) => {
												return isWithinInterval(day, {start: leave.startDate, end: leave.endDate})
											})
										}}
										onError={(error, value) => {
											if (error) {
												setIsDateError(true);
											} else {
												setIsDateError(false);
											}
										}}
										minDate={parseISO(employeeRecord.parental_leave?.expected_due_date)}
										maxDate={addDays(parseISO(employeeRecord.parental_leave?.expected_due_date), 49)}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={5}>
								<InputLabel
									className={"default-input-label"}
								>
									Leave end date
								</InputLabel>
								<LocalizationProvider
									dateAdapter={AdapterDateFns}
									adapterLocale={enGb}
								>
									<DesktopDatePicker
										className={"date-picker"}
										value={parseISO(employeeRecord?.parental_leave?.second_end_date)}
										disabled
										shouldDisableDate={(day) => {
											return employee.leaves?.map((leave) => {
												return {
													startDate: parseISO(leave.leave_start_date),
													endDate: parseISO(leave.leave_end_date)
												}
											}).some((leave) => {
												return isWithinInterval(day, {start: leave.startDate, end: leave.endDate})
											})
										}}
										onError={(error, value) => {
											if (error) {
												setIsDateError(true);
											} else {
												setIsDateError(false);
											}
										}}
									/>
								</LocalizationProvider>
							</Grid>
						</Grid>
					)
				}
			</Grid>
		);
	}

}

/**
 * @param user
 * @param employee
 * @param employeeRecord
 * @param setEmployeeRecord
 * @returns {JSX.Element}
 * @constructor
 */
export const PaternityLeave = (
	{
		employee = {},
		employeeRecord = {
			parental_leave: {
				expected_due_date: "",
				average_weekly_earnings: "",
				is_pensionable: false,
				include_enhanced_policy: false,
				is_maternity: false,
				is_paternity: false,
				employee_leave: {
					leave_start_date: "",
					leave_end_date: "",
				},
			},
		},
		setEmployeeRecord = () => {
		},
		addParentalStatutory = () => {
		},
	}
): JSX.Element => {


	const [isDateError, setIsDateError] = useState(false);

	return (
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			container
			item
			justifyContent={"space-between"}
			id={'maternity-paternity'}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				className={"maternity-paternity-container"}
				justifyContent={"space-between"}
			>
				<Grid
					xs={5.16}
					item
				>
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
					>
						<StyleTypography
							fontSize={40}
							fontSizeMedium={30}
							fontWeight={'bold'}
							component={"h1"}
						>
							Paternity pay
						</StyleTypography>
					</Grid>

					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
						paddingBottom={"30px"}
					>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={"#ABAEB7"}
						>
							Setup a statutory leave for the employee and let FreePayroll calculate the payments.
						</StyleTypography>
					</Grid>

					{/*Expected due date*/}
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
					>
						<InputLabel
							className={"default-input-label"}
						>
							Expected due date
						</InputLabel>
						<LocalizationProvider
							dateAdapter={AdapterDateFns}
							adapterLocale={enGb}
						>
							<DesktopDatePicker
								slotProps={{ field: { readOnly: true } }}
								enableAccessibleFieldDOMStructure
								className={"date-picker"}
								value={parseISO(employeeRecord.parental_leave?.expected_due_date)}
								onChange={(newValue) => {
									if (newValue instanceof Date && !isNaN(newValue.getTime())) {
										if (employeeRecord && !employeeRecord.parental_leave) {
											employeeRecord.parental_leave = {}; // Initialize it as an empty object
											employeeRecord.parental_leave.employee_leave = {};
										}
										let offset = newValue.getTimezoneOffset();
										let adjustedDate = new Date(newValue.getTime() - (offset*60*1000));
										const updatedEmployeeRecord = {
											...employeeRecord,
											parental_leave: {
												...employeeRecord.parental_leave,
												expected_due_date: adjustedDate.toISOString().split('T')[0]
											}
										};
										setEmployeeRecord(updatedEmployeeRecord);
									}
								}}
							/>
						</LocalizationProvider>
					</Grid>

					{/*Weeks on leave*/}
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
					>
						<InputLabel
							className={"default-input-label"}
						>
							How will the employee take the leave?
						</InputLabel>
						<FormControl
							fullWidth
						>
							<Select
								className={"default-select"}
								id={"select-title"}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 200, // Adjust the maximum height of the list
										},
									},
								}}
								onChange={(event) => {

									if (!employeeRecord.parental_leave) {
										employeeRecord.parental_leave = {};
									} else {
										employeeRecord.parental_leave.first_start_date = null;
										employeeRecord.parental_leave.first_end_date = null;
										employeeRecord.parental_leave.second_start_date = null;
										employeeRecord.parental_leave.second_end_date = null;
									}

									employeeRecord.parental_leave.paternity_leave_type = event.target.value;
									setEmployeeRecord(employeeRecord);
								}}
								value={employeeRecord.parental_leave?.paternity_leave_type|| " "}
							>
								<MenuItem
									key={'select-title'}
									value={" "}
									disabled
								>
									Select
								</MenuItem>
								<MenuItem
									key={'one-week'}
									value={"one_week"}
								>
									My employee will take 1 week of leave
								</MenuItem>
								<MenuItem
									key={'two-weeks'}
									value={"two_weeks"}
								>
									My employee will take 2 weeks of leave in a row
								</MenuItem>
								<MenuItem
									key={'two-weeks-separate-blocks'}
									value={"two_weeks_separate_blocks"}
								>
									My employee will take 2 weeks of leave in separate blocks
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					<DateSelection
						employeeRecord={employeeRecord}
						setEmployeeRecord={setEmployeeRecord}
						employee={employee}
						setIsDateError={setIsDateError}
						weeksOnLeave={employeeRecord.parental_leave?.paternity_leave_type}
					/>

					{/*Average weekly earnings*/}
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
						paddingBottom={"20px"}
					>
						<StyleTypography
							color={"black"}
							fontSize={18}
							fontSizeMedium={13}
							fontWeight={"bold"}
						>
							Average weekly earnings
						</StyleTypography>
						<Grid
							paddingBottom={"10px"}
						>
							<StyleTypography
								color={"#AAAEB7"}
								fontSize={16}
								fontSizeMedium={12}
							>
								FreePayroll can only calculate the average weekly earnings, if we have at least 52 weeks of data.
								Otherwise, you will need to input manually.
							</StyleTypography>
						</Grid>
						<TextField
							className={"default-text-field"}
							value={employeeRecord.parental_leave?.average_weekly_earnings}
							onChange={(e) => {
								if (!employeeRecord.parental_leave) {
									employeeRecord.parental_leave = {}; // Initialize it as an empty object
								}
								employeeRecord.parental_leave.average_weekly_earnings = e.target.value;
								setEmployeeRecord(employeeRecord);
							}}
							fullWidth
							InputProps={{
								inputComponent: CurrencyInputCustom,
								inputProps: {prefix: "£"},
							}}
						/>
					</Grid>

					<Button
						className={"maternity-paternity-button"}
						fullWidth
						onClick={() => addParentalStatutory({
							employees: {
								'statutory_type': 'paternity',
								'employee_safe_id': employeeRecord.safe_id,
								is_paternity: true,
								is_maternity: false,
								expected_due_date: employeeRecord.parental_leave?.expected_due_date,
								first_start_date: employeeRecord.parental_leave?.first_start_date,
								first_end_date: employeeRecord.parental_leave?.first_end_date,
								second_start_date: employeeRecord.parental_leave?.second_start_date,
								second_end_date: employeeRecord.parental_leave?.second_end_date,
								average_weekly_earnings: employeeRecord.parental_leave.average_weekly_earnings,
								paternity_leave_type: employeeRecord.parental_leave.paternity_leave_type,
							}
						})}
						disabled={isDateError || employeeRecord.parental_leave?.average_weekly_earnings === undefined}
					>
						Add leave
					</Button>
				</Grid>
				<Grid
					xs={5.16}
					item
					className={"statutory-style"}
				>
					<StyleTypography
						fontSize={22}
						fontSizeMedium={16}
						fontWeight={"bold"}
						color={"black"}
					>
						Statutory pay claims
					</StyleTypography>
					<Grid marginTop={"10px"}>
						<StyleTypography
							fontSize={16}
							fontSizeMedium={12}
							color={"#AAAEB7"}
						>
							If you have paid any Statutory Maternity Pay or
							Statutory Paternity Pay, we will claim 92% of it from HMRC.
							If you qualify for Small Employer's Relief, it will be 103%.
						</StyleTypography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}