import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import StyleTypography from "../../StyledComponents/StyleTypography";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {useState} from "react";
import {DialogActions} from "@mui/material";
import StyleButton from "../../StyledComponents/StyleButton";


export const PaymentAfterLeavingModal = ({
    open = false,
	employeeRecord = {},
    setOpen = () => {},
	paymentAfterLeaving = () => {},
}) => {

	const [isRegularPayment, setIsRegularPayment] = useState('true');

	return (
		<Dialog
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			fullWidth={true}
			maxWidth={'sm'}
		>
			<DialogTitle>
				<StyleTypography
					fontSize={35}
					fontSizeMedium={28}
					color={"#0160FD"}
					fontWeight={"bold"}
				>
					Payment after leaving
				</StyleTypography>
				<StyleTypography
					fontSize={22}
					fontSizeMedium={16}
					color={"#ABAEB7"}
				>
					When enabled, we will create an empty payslip in the upcoming pay run for this employee.
				</StyleTypography>
			</DialogTitle>
			<DialogContent>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					marginTop={2}
				>
					<Grid
						item
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						marginBottom={2}
					>
						<StyleTypography
							fontSize={22}
							fontSizeMedium={20}
							color={"#000000"}
							fontWeight={"bold"}
						>
							Payment Type
						</StyleTypography>
					</Grid>

					<Grid
						item
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<RadioGroup
							column={'true'}
							sx={{
								'& .MuiFormControlLabel-root': {
									marginBottom: 2,
								},
							}}
							onChange={(e) => {
								setIsRegularPayment(e.target.value);
							}}
							value={isRegularPayment}
						>
							<FormControlLabel
								value={'true'}
								control={<Radio />}
								label={
									<StyleTypography
										fontSize={20}
										fontSizeMedium={16}
										color={"#000000"}
									>
										Regular payment
										<br/>
										<small>For example, contractual pay such as salary or notice pay.</small>
									</StyleTypography>
								}
							/>
							<FormControlLabel
								value={'false'}
								control={<Radio />}
								disabled
								label={
									<StyleTypography
										fontSize={20}
										fontSizeMedium={16}
										color={"#000000"}
									>
										Irregular payment
										<br/>
										<small>For example, accrued holiday pay, or an unexpected bonus.</small>
									</StyleTypography>
								}
							/>
						</RadioGroup>
					</Grid>

					{
						isRegularPayment === 'true' && (
							<>
								{
									employeeRecord?.pay_schedule?.active_pay_run?.rollback_in_progress && (
										<Grid
											item
											xl={12}
											lg={12}
											md={12}
											sm={12}
											xs={12}
										>
											<StyleTypography
												fontSize={22}
												fontSizeMedium={20}
												color={"red"}
												fontWeight={"bold"}
											>
												Please wait for the rollback to complete before adding a payment after leaving for this employee.
											</StyleTypography>
										</Grid>
									)
								}
							</>
						)
					}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
				>
					<Grid
						item
						xl={3}
						lg={3}
						md={3}
						sm={3}
						xs={3}
					>
						<StyleButton
							fullWidth
							disabled={employeeRecord?.pay_schedule?.active_pay_run?.rollback_in_progress}
							onClick={() => {
								paymentAfterLeaving({
									'employees': {
										employee_safe_id: employeeRecord?.safe_id,
										payment_type: isRegularPayment ? 'regular' : 'irregular',
										action: employeeRecord.payment_after_leaving ? 'disable' : 'enable'
									}
								});
								setOpen(false);
							}}
						>
							{employeeRecord.payment_after_leaving ? 'Disable' : 'Enable'}
						</StyleButton>
					</Grid>

					<Grid
						item
						xl={3}
						lg={3}
						md={3}
						sm={3}
						xs={3}
						marginLeft={3}
					>
						<StyleButton
							fullWidth
							isWhiteButton={true}
							onClick={() => {
								setOpen(false);
							}}
						>
							Cancel
						</StyleButton>
					</Grid>

				</Grid>
			</DialogActions>
		</Dialog>
	);
}