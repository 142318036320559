import React, {useState} from "react";
import 'react-calendar/dist/Calendar.css';
import {
	Typography,
	Switch,
	FreepayrollTextField,
	FreepayrollSelect,
	FreepayrollDateField
} from "@collegia-partners/ui-kit";
import styles from "./styles.module.scss";
import RunPayScheduleAutomaticDialog from "./RunPayScheduleAutomaticDialog";
import {getPayDayOption} from "../../../utils/Helpers";
import moment from "moment";
import DayRateMethodDialog from "./DayRateMethodDialog";

function disableDayOption(payFrequency = "", payDay, date) {
	switch (payFrequency) {
		case "W1":
		case "W2":
		case "W4":
			if (payDay === "Friday") {
				return date.isoWeekday() !== 5;
			} else if (payDay === "Thursday") {
				return date.isoWeekday() !== 4;
			} else if (payDay === "Wednesday") {
				return date.isoWeekday() !== 3;
			} else if (payDay === "Tuesday") {
				return date.isoWeekday() !== 2;
			} else if (payDay === "Monday") {
				return date.isoWeekday() !== 1;
			}
			break;
		case "M1":
			if (payDay === "Last Day of Month") {
				const lastDay = date.clone().endOf("month").date();
				return date.date() !== lastDay;
			}
			if (date.month() === 1) {
				if (Number(payDay) > date.clone().endOf("month").date()) {
					return date.clone().endOf("month").date() !== date.date();
				}
			}
			return date.date() !== Number(payDay);
		default:
			return [];
	}
}

export const WhatIsPaySchedule = (): JSX.Element => {
	return (
		<div className={styles.WhatIsPayScheduleContainer}>
			<Typography variant={"body"} size={"xmedium"} color={"black"} weight={"bold"}>
				Why do we need to ask for this?
			</Typography>
			<Typography variant={"body"} size={"medium"} color={"gray"} weight={"normal"}>
				Pay schedules define the regular intervals at which your employees receive their wages <br/>
				or salaries, effectively setting how frequently they are paid. You can create multiple pay<br/>
				schedules, each representing a different payment frequency, and assign the same pay schedule<br/>
				to multiple employees as needed.
			</Typography>
		</div>
	);
};


export const GeneralFields = ({
	scheduleName,
	setScheduleName,
	payFrequency,
	setPayFrequency,
	minDate,
	maxDate,
	payDay,
	setPayDay,
	payDate,
	setPayDate,
	dayRateMethod,
	setDayRateMethod,
	isAutomaticPaySchedule,
	setIsAutomaticPaySchedule,
	isBureau,
	submissionDateOffset,
	setSubmissionDateOffset,
	shouldDisableField = false
}) => {
	
	const payFrequencies = [
		{ label: "Weekly", value: "W1" },
		{ label: "Fortnightly", value: "W2" },
		{ label: "Four Weekly", value: "W4" },
		{ label: "Monthly", value: "M1" }
	];
	
	const dayRateMethodOptions = [
		{
			label: "Calendar Month",
			value: "calendar_month"
		},
		{
			label: "Yearly Working Days",
			value: "yearly_working_days"
		}
	];
	
	const automaticSubmissionDayOptions = [
		{
			label: "At pay date",
			value: 0
		},
		{
			label: "One day before pay date",
			value: 1
		},
		{
			label: "Two days before pay date",
			value: 2
		},
		{
			label: "Three days before pay date",
			value: 3
		},
		{
			label: "Four days before pay date",
			value: 4,
		},
		{
			label: "Five days before pay date",
			value: 5
		},
	];
	
	const [warningDialog, setWarningDialog] = useState(false);
	const [dayRateModal, setDayRateModal] = useState(false);
	
	return (
		<div className={styles.GeneralInformationContainer}>
			<div className={styles.GeneralInformationRow}>
				<FreepayrollTextField
					placeholder={"Type Pay Schedule Name"}
					name={"payScheduleName"}
					fieldType={"text"}
					value={scheduleName}
					onChange={setScheduleName}
					label={"Pay Schedule Name*"}
					noGhostHelperText
				/>
				<FreepayrollSelect
					options={payFrequencies}
					value={payFrequency}
					disabled={shouldDisableField}
					onSelect={setPayFrequency}
					label={"Pay Frequency*"}
				/>
			</div>
			{
				scheduleName?.length > 0 && payFrequency?.length > 0 && (
					<>
						<div className={styles.GeneralInformationRow}>
							<FreepayrollSelect
								options={getPayDayOption(payFrequency).map(value => ({
									label: value,
									value: value
								}))}
								value={payDay}
								disabled={shouldDisableField}
								onSelect={setPayDay}
								label={"Pay Days*"}
							/>
							<FreepayrollDateField
								label={"Pay Date*"}
								value={moment(payDate)}
								onChange={setPayDate}
								minDate={moment(minDate)}
								maxDate={moment(maxDate)}
								disabled={shouldDisableField}
								shouldDisableDate={(day) => {
									return disableDayOption(payFrequency, payDay, day);
								}}
							/>
						</div>
						<div className={styles.GeneralInformationRow}>
							<FreepayrollSelect
								options={dayRateMethodOptions}
								value={dayRateMethod}
								onSelect={setDayRateMethod}
								label={"Day Rate method*"}
								labelEndAdornment={
									<button style={{
										background: "none",
										border: "none",
										color: "blue",
										cursor: "pointer",
										padding: "0",
										font: "inherit",
									}} onClick={() => {
										setDayRateModal(true);
									}}>
										<Typography variant={"body"} color={"primary"}>
											What is the day rate method ?
										</Typography>
									</button>
								}
							/>
							{
								isBureau && (
									<>
										<Switch
											isChecked={isAutomaticPaySchedule}
											onCheckChange={(checked) => {
												if (checked) {
													setWarningDialog(true);
												} else {
													setIsAutomaticPaySchedule(checked);
												}
											}}
											textPosition={"right"}
											label={
												<Typography variant={"body"} size={"medium"} color={"black"} weight={"bold"}>
													Run pay schedule on automatic: {isAutomaticPaySchedule ? "Yes" : "No"}
												</Typography>
											}
										/>
										<RunPayScheduleAutomaticDialog
											warningDialog={warningDialog}
											setWarningDialog={setWarningDialog}
											setIsAutomaticPaySchedule={setIsAutomaticPaySchedule}
										/>
									</>
								)
							}
							{
								isBureau && isAutomaticPaySchedule && (
									<FreepayrollSelect
										options={automaticSubmissionDayOptions}
										value={submissionDateOffset}
										onSelect={setSubmissionDateOffset}
										label={"Submission Date*"}
									/>
								)
							}
						</div>
					</>
				)
			}
			<DayRateMethodDialog
				openInfoModal={dayRateModal}
				setOpenInfoModal={setDayRateModal}
			/>
		</div>
	);
};


/**
 * @returns {JSX.Element}
 * @constructor
 */
export const RequireClientApproval = ({
	requireClientApproval,
	authorizerName,
	authorizerEmail,
	setRequireClientApproval,
	setAuthorizerName,
	setAuthorizerEmail,
}): JSX.Element => {
	
	return (
		<div className={styles.RequireClientApprovalContainer}>
			<div style={{display: "flex", flexDirection: "column", gap: "0.5vw"}}>
				<Typography variant={"body"} size={"xmedium"} color={"black"} weight={"bold"}>
					Send pay run for client confirmation
				</Typography>
				<Typography variant={"body"} size={"medium"} color={"gray"} weight={"normal"}>
					You have the option of sending prepared pay run to the client for their review<br/>
					and confirmation. This allows the client to verify all employee details and payment<br/>
					amounts before payroll is finalised.
				</Typography>
			</div>
			<Switch
				isChecked={requireClientApproval}
				onCheckChange={setRequireClientApproval}
				label={
					<Typography variant={"body"} size={"medium"} color={"black"} weight={"bold"}>
						Require client authorisation: {requireClientApproval ? "Yes" : "No"}
					</Typography>
				}
			/>
			{
				requireClientApproval && (
					<>
						<FreepayrollTextField
							placeholder={"Type authoriser full name"}
							name={"authorizerName"}
							fieldType={"text"}
							value={authorizerName}
							onChange={setAuthorizerName}
							noGhostLabel
							noGhostHelperText
						/>
						<FreepayrollTextField
							placeholder={"Type authoriser email"}
							name={"authorizerEmail"}
							fieldType={"text"}
							value={authorizerEmail}
							onChange={setAuthorizerEmail}
							noGhostLabel
							noGhostHelperText
						/>
					</>
				)
			}
		</div>
	);
}


/**
 * @returns {JSX.Element}
 * @constructor
 */
export const UponApprovalClosePayroll = ({
     submitPayrollUponClientApproval,
     setSubmitPayrollUponClientApproval,
 }): JSX.Element => {
	
	return (
		<div className={styles.UponApprovalClosePayrollContainer}>
			<div style={{display: "flex", flexDirection: "column", gap: "0.5vw"}}>
				<Typography variant={"body"} size={"medium"} color={"black"} weight={"bold"}>
					Automatically close pay run upon client authorisation
				</Typography>
				<Typography variant={"body"} size={"small"} color={"gray"} weight={"normal"}>
					Once the client authorises the pay run, it can be automatically closed and <br/>
					finalised without further action needed from you. This ensures a seamless and<br/>
					efficient payroll process.
				</Typography>
			</div>
			<Switch
				label={
					<Typography variant={"body"} size={"medium"} color={"black"} weight={"bold"}>
						Automatically finalise pay run upon client approval: {submitPayrollUponClientApproval ? 'Yes' : 'No'}
					</Typography>
				}
				isChecked={submitPayrollUponClientApproval}
				onCheckChange={setSubmitPayrollUponClientApproval}
			/>
			<div className={styles.NoteContainer}>
				<Typography variant={"body"} size={"small"} color={"black"} weight={"normal"}>
					<strong>Note:</strong> When enabled, the pay run will be completed immediately after client
					authorisation, and payroll will be processed accordingly.
				</Typography>
			</div>
		</div>
	);
}