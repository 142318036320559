import Grid from "@mui/material/Grid";
import { Divider, InputLabel, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enGb from "date-fns/locale/en-GB";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { parseISO } from "date-fns";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import { useMemo } from "react";
import countryList from "react-select-country-list";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

export const PersonalDetails = ({
	employeeInformation,
	setEmployeeInformation,
	ni_categories,
}) => {

	const countries = useMemo(() => countryList().getData(), []);
	const ninValidator = /^([ACEHJLMOPRSWXY][A-CEGHJ-NPR-TW-Z]|B[A-CEHJ-NPR-TW-Z]|G[ACEGHJ-NPR-TW-Z]|[KT][A-CEGHJ-MPR-TW-Z]|N[A-CEGHJL-NPR-SW-Z]|Z[A-CEGHJ-NPR-TW-Y])[0-9]{6}[A-D ]$/;

	return (
		<Grid
			item
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			container
			spacing={4}
			paddingTop={2}
		>

			{/*Title, Forename and Surname*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				spacing={2}
			>
				{/*Title*/}
				<Grid
					xl={2}
					lg={2}
					md={2}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Title*
					</InputLabel>
					<FormControl
						fullWidth
					>
						<Select
							className={"default-select"}
							id={"select-title"}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 200, // Adjust the maximum height of the list
									},
								},
							}}
							onChange={(event) => {
								setEmployeeInformation({
									...employeeInformation,
									title: event.target.value,
								});
							}}
							value={employeeInformation?.title}
						>
							<MenuItem
								key={'select-title'}
								value={" "}
								disabled
							>
								Select
							</MenuItem>
							<MenuItem
								key={'Mr'}
								value={"Mr"}
							>
								Mr
							</MenuItem>
							<MenuItem
								key={'Miss'}
								value={"Miss"}
							>
								Miss
							</MenuItem>
							<MenuItem
								key={'Mrs'}
								value={"Mrs"}
							>
								Mrs
							</MenuItem>
							<MenuItem
								key={'Ms'}
								value={"Ms"}
							>
								Ms
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>

				{/*First Name*/}
				<Grid
					xl={5}
					lg={5}
					md={5}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Forename*
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								forename: event.target.value,
							});
						}}
						value={employeeInformation.forename}
					/>
				</Grid>

				{/*Surname*/}
				<Grid
					xl={5}
					lg={5}
					md={5}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Surname*
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								surname: event.target.value,
							});
						}}
						value={employeeInformation.surname}
					/>
				</Grid>
			</Grid>

			{/*Gender and Birthdate*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				spacing={2}
				container
			>
				{/*Gender*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Gender*
					</InputLabel>
					<FormControl
						fullWidth
					>
						<Select
							className={"default-select"}
							id={"select-gender"}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 200, // Adjust the maximum height of the list
									},
								},
							}}
							onChange={(event) => {
								setEmployeeInformation({
									...employeeInformation,
									gender: event.target.value,
								});
							}}
							value={employeeInformation.gender}
						>
							<MenuItem
								key={'select-title'}
								value={" "}
								disabled
							>
								Select
							</MenuItem>
							<MenuItem
								key={'Male'}
								value={"M"}
							>
								Male
							</MenuItem>
							<MenuItem
								key={'Female'}
								value={"F"}
							>
								Female
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>

				{/*Date of Birth*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Date of Birth*
					</InputLabel>
					<LocalizationProvider
						dateAdapter={AdapterDateFns}
						adapterLocale={enGb}
					>
						<DesktopDatePicker
							className={"date-picker"}
							onChange={(newValue) => {
								if (newValue === null) {
									setEmployeeInformation({
										...employeeInformation,
										birthdate: null,
									});
									return;
								}

								if (newValue instanceof Date && isNaN(newValue.getTime())) {
									return; // Exit if the date is invalid
								}
								setEmployeeInformation({
									...employeeInformation,
									birthdate: newValue.toISOString().split('T')[0],
								});
							}}
							onError={(error) => {
								if (error !== null) {
									setEmployeeInformation({
										...employeeInformation,
										birthdate_error: true,
									});
								} else {
									setEmployeeInformation({
										...employeeInformation,
										birthdate_error: false,
									});
								}
							}}
							slotProps={{ field: { readOnly: true } }}
							enableAccessibleFieldDOMStructure
							value={employeeInformation?.birthdate ? parseISO(employeeInformation?.birthdate) : null}
						/>
					</LocalizationProvider>
				</Grid>
			</Grid>

			{/*Email and Phone*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				spacing={2}
				container
			>
				{/*Email*/}
				<Grid
					xl={6}
					lg={6}
					md={6}
					sm={6}
					xs={6}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Work Email*
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								email: event.target.value.toLowerCase(),
							});
						}}
						value={employeeInformation.email}
					/>
				</Grid>

				{/*Telephone*/}
				<Grid
					xl={6}
					lg={6}
					md={6}
					sm={6}
					xs={6}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Telephone (Optional)
					</InputLabel>
					<MuiTelInput
						className={"default-phone-input"}
						defaultCountry={"GB"}
						flagSize={"medium"}
						forceCallingCode
						value={employeeInformation?.telephone ?? ""}
						error={
							(employeeInformation.telephone !== null && employeeInformation.telephone !== "") &&
							!matchIsValidTel(employeeInformation.telephone ?? "")
						}
						onChange={(value) => setEmployeeInformation({
							...employeeInformation,
							telephone: value,
						})}
					/>
				</Grid>
			</Grid>

			{/*NI Category*/}
			<Grid
				xl={4}
				lg={4}
				md={4}
				sm={12}
				xs={12}
				item
			>
				<InputLabel
					className={"default-input-label"}
				>
					NI Category*
				</InputLabel>
				<FormControl
					fullWidth
				>
					<Select
						className={"default-select"}
						id={"select-gender"}
						MenuProps={{
							PaperProps: {
								style: {
									maxHeight: 200, // Adjust the maximum height of the list
									maxWidth: 300,
									overflowX: 'scroll',
								},
							},
						}}
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								ni_category: event.target.value,
							});
						}}
						value={employeeInformation.ni_category}
					>
						<MenuItem
							key={'select-title'}
							value={" "}
							disabled
							sx={{
								fontSize: '0.9028vw'
							}}
						>
							Select
						</MenuItem>
						{
							ni_categories && ni_categories.map((category) => {
								return (
									<MenuItem
										key={category.id}
										value={category.category.toString()}
										sx={{
											fontSize: '0.9028vw'
										}}
									>
										{category.category} - {category.description}
									</MenuItem>
								)
							})
						}
					</Select>
				</FormControl>
			</Grid>

			{/*National Insurance Number*/}
			<Grid
				xl={4}
				lg={4}
				md={4}
				sm={12}
				xs={12}
				item
			>
				<InputLabel
					className={"default-input-label"}
				>
					National Insurance Number (Optional)
				</InputLabel>
				<TextField
					fullWidth
					className={"default-text-field"}
					onChange={(event) => {
						setEmployeeInformation({
							...employeeInformation,
							national_insurance_number: event.target.value,
						});
					}}
					value={employeeInformation?.national_insurance_number ?? ""}
					error={
						(employeeInformation.national_insurance_number !== null && employeeInformation.national_insurance_number !== '') &&
						!ninValidator.test(employeeInformation.national_insurance_number)
					}
				/>
			</Grid>

			{/*Address*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				spacing={4}
			>
				{/*Section title*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={15}
						color={"#000000"}
					>
						Address
					</StyleTypography>
					<Divider />
				</Grid>

				{/*Postcode*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Postcode*
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								address: {
									...employeeInformation.address,
									postal_code: event.target.value,
								},
							});
						}}
						value={employeeInformation.address?.postal_code}
					/>
				</Grid>

				{/*Address line 1*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Address Line 1*
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								address: {
									...employeeInformation.address,
									address_line_1: event.target.value,
								}
							});
						}}
						value={employeeInformation.address?.address_line_1}
					/>
				</Grid>

				{/*Address line 2*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Address Line 2 (Optional)
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								address: {
									...employeeInformation.address,
									address_line_2: event.target.value,
								}
							});
						}}
						value={employeeInformation.address?.address_line_2 ?? ""}
					/>
				</Grid>

				{/*City*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						City*
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								address: {
									...employeeInformation.address,
									city: event.target.value,
								}
							});
						}}
						value={employeeInformation.address?.city}
					/>
				</Grid>

				{/*Country*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Country*
					</InputLabel>
					<FormControl
						fullWidth
					>
						<Autocomplete
							id={"country-select-demo"}
							className={"default-select"}
							options={countries}
							getOptionLabel={(option) => option.label !== null ? option.label : "Select a country"}
							renderOption={(props, option) => (
								<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
									<img
										loading="lazy"
										width="20"
										src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
										srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
										alt=""
									/>
									{option.label}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									inputProps={{
										...params.inputProps,
										autoComplete: 'new-password', // disable autocomplete and autofill
									}}
								/>
							)}
							onChange={(event, value) => {
								setEmployeeInformation({
									...employeeInformation,
									address: {
										...employeeInformation.address,
										country: value?.label,
									}
								});
							}}
							value={countries.filter(country => country.label === employeeInformation.address?.country)[0] ?? null}
						/>
					</FormControl>
				</Grid>
			</Grid>
		</Grid>
	);
};