import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";

export const CreateNewMandateModal = ({
	open,
	setOpen,
	createOrRetrieveToken,
}): JSX.Element => {

	return (
		<Dialog
			open={open}
			fullWidth={true}
			maxWidth={'md'}
			id={'direct-debit-guarantee-dialog'}
		>
			<DialogTitle>
				<Typography className={'dialog-title'}>
					You need to complete your mandate signing
				</Typography>
				<Typography className={'dialog-title-subtitle'}>
					We now need you to sign a direct debit
				</Typography>
			</DialogTitle>
			<DialogContent>
				<ul>
					<li>
						We require your authorisation to collect the outstanding pension
						contributions for your workforce because of Automatic Enrolment.
						This is a UK legal requirement.
					</li>
					<li>
						When you sign the Direct Debit mandate, you understand you grant
						Collegia Partners Limited, which is regulated by the FCA FRN
						922214, the authority to collect those pension contributions from
						your corporate bank account.
					</li>
					<li>
						This Guarantee is offered by all banks and building societies that
						accept instructions to pay Direct Debits.
					</li>
					<li>
						If an error is made in the payment of your Direct Debit by Nuapay
						re Collegia Partners Trustees Limited T/A Collegia or your bank or
						building society, you are entitled to a full and immediate refund
						of the amount paid from your band or building society.
					</li>
					<li>
						If you receive a refund you are not entitled to, you must pay it
						back when Nuapay re Collegia Partners Trustees Limited T/A
						Collegia asks you to.
					</li>
					<li>
						You can cancel a Direct Debit at any time by simply contacting
						your bank or building society. Written confirmation may be
						required. Please also notify us.
					</li>
				</ul>
			</DialogContent>
			<DialogActions>
				<Button
					className={'default-black-button'}
					onClick={() => {
						createOrRetrieveToken();
						setOpen(false);
					}}
				>
					Complete Mandate
				</Button>
			</DialogActions>
		</Dialog>
	)
}