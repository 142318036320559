import Grid from "@mui/material/Grid";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle, InputLabel,
} from "@mui/material";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import {CurrencyInputCustom} from "../../../StyledComponents/CurrencyInputCustom";
import StyleButton from "../../../StyledComponents/StyleButton";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";

export const ApprenticeModal = ({
	open,
	setOpen,
	employerRecord,
	setEmployerRecord,
	employer,
	updateApprenticeLevy,
}): JSX.Element => {

	const [shareAllowance, setShareAllowance] = useState(false);

	return (
		<Dialog
			onClose={() => setOpen(false)}
			open={open}
			maxWidth={'sm'}
			id={"salary-modal"}
		>
			<DialogTitle>
				<StyleTypography
					fontSize={35}
					fontSizeMedium={28}
					fontWeight={'bold'}
				>
					Apprenticeship Levy allowance
				</StyleTypography>
				<StyleTypography
					fontSize={22}
					fontSizeMedium={16}
					color={'#000000'}
				>
					If you have an annual pay bill of more than £3 million, you are required to pay the Apprenticeship Levy. You can use the Apprenticeship Levy each month.
				</StyleTypography>
			</DialogTitle>
			<DialogContent>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					{/*Pay bill YTD*/}
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Pay bill YTD
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							InputProps={{
								endAdornment: "£",
								inputComponent: CurrencyInputCustom,
								inputProps: {prefix: ""},
							}}
							value={employerRecord.active_employer_ytd_figure?.pay_bill_ytd}
							onChange={(e) => {
								setEmployerRecord({
									...employerRecord,
									active_employer_ytd_figure: {
										...employerRecord.active_employer_ytd_figure,
										pay_bill_ytd: e.target.value,
									}
								})
							}}
						/>
					</Grid>

					{/*Levy due YTD*/}
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Levy due YTD
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							InputProps={{
								endAdornment: "£",
								inputComponent: CurrencyInputCustom,
								inputProps: {prefix: ""},
							}}
							value={employerRecord.active_employer_ytd_figure?.levy_due_ytd}
							onChange={(e) => {
								setEmployerRecord({
									...employerRecord,
									active_employer_ytd_figure: {
										...employerRecord.active_employer_ytd_figure,
										levy_due_ytd: e.target.value,
									}
								})
							}}
						/>
					</Grid>

					{/*Company share allowance ?*/}
					<Grid
						item
						container
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<FormControlLabel
							control={
								<Checkbox
									value={shareAllowance}
									checked={shareAllowance}
									icon={<CheckBoxOutlineBlankSharpIcon/>}
									checkedIcon={<CheckBoxSharpIcon/>}
									onChange={() =>
										setShareAllowance(!shareAllowance)
									}
								/>
							}
							label={
								<StyleTypography
									color={"black"}
									fontSize={16}
									fontSizeMedium={12}
									className={"checkbox-label"}
								>
									My company shares apprentice levy allowance with other companies.
								</StyleTypography>
							}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<StyleButton
							onClick={() => {
								setEmployerRecord(employer);
								setOpen(false);
							}}
							isWhiteButton={true}
						>
							Cancel
						</StyleButton>
					</Grid>

					{
						!employer.apprentice_levy_due ? (
							<Grid
								item
								xl={6}
								lg={6}
								md={6}
								sm={6}
								xs={6}
							>
								<StyleButton
									fullWidth
									onClick={() => {
										updateApprenticeLevy({
											"employers": {
												action: 'enable',
												levy_due_ytd: employerRecord.active_employer_ytd_figure.levy_due_ytd,
												pay_bill_ytd: employerRecord.active_employer_ytd_figure.pay_bill_ytd,
											}
										});
										setOpen(false);
									}}
								>
									Enable
								</StyleButton>
							</Grid>
						) : (
							<Grid
								item
								xl={6}
								lg={6}
								md={6}
								sm={6}
								xs={6}
							>
								<StyleButton
									fullWidth
									onClick={() => {
										updateApprenticeLevy({
											"employers": {
												action: 'disable',
												levy_due_ytd: employerRecord.active_employer_ytd_figure.levy_due_ytd,
												pay_bill_ytd: employerRecord.active_employer_ytd_figure.pay_bill_ytd,
											}
										});
										setOpen(false);
									}}
								>
									Stop
								</StyleButton>
							</Grid>
						)
					}

				</Grid>
			</DialogActions>
		</Dialog>
	)
}