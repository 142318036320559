import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button, Checkbox, FormGroup, InputLabel} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import TextField from "@mui/material/TextField";
import Help from "../../../../../images/svg/help-icon.svg";
import React from "react";

export const InnerStepZero = ({
	stepOne,
	setStepOne,
}) => {

	const regex_office_reference = /^[0-9]{3}P[A-Z][0-9]{7}[0-9X]$/;
	const regex_paye_reference = /^\d{3}\/.*$/i;

	return (
		<Grid
			direction={"row"}
			container
			id={"inner-step-zero"}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={"main-title-container"}
			>
				<Typography
					className={"main-title"}
				>
					HMRC Settings
				</Typography>
				<Typography
					className={"main-subtitle"}
				>
					We need the following information to properly calculate taxes due and report them to HMRC.
				</Typography>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={"body-sub-container"}
			>
				<InputLabel
					className={"default-input-label"}
				>
					Accounts Office reference*
				</InputLabel>
				<Grid
					xl={6}
					lg={6}
					md={6}
					sm={6}
					xs={6}
					item
				>
					<TextField
						className={"default-text-field"}
						placeholder={"Example: 123PA1234567X"}
						value={stepOne.accounts_office_reference}
						error={!regex_office_reference.test(stepOne.accounts_office_reference)}
						helperText={
							!regex_office_reference.test(stepOne.accounts_office_reference) &&
							"This is a 13 character reference number that can be found on your HMRC letter."
						}
						onChange={(e) => {
							setStepOne({...stepOne, accounts_office_reference: e.target.value.toUpperCase()})
						}}
						fullWidth
					/>
				</Grid>
			</Grid>

			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={"body-sub-container"}
			>
				<InputLabel
					className={"default-input-label"}
				>
					Employer PAYE reference*
				</InputLabel>
				<Grid
					xl={6}
					lg={6}
					md={6}
					sm={6}
					xs={6}
					item
				>
					<TextField
						className={"default-text-field"}
						value={stepOne.employer_paye_reference}
						onChange={(e) => {
							setStepOne({...stepOne, employer_paye_reference: e.target.value.toUpperCase()})
						}}
						error={!regex_paye_reference.test(stepOne.employer_paye_reference)}
						helperText={
							!regex_paye_reference.test(stepOne.employer_paye_reference) &&
							"The reference number consists of two parts: a three-digit HMRC office number, and a reference number unique to your business."
						}
						placeholder={"Example: 123/AB12345"}
						fullWidth
					/>
				</Grid>
			</Grid>

			<Grid
				xl={6}
				lg={6}
				md={6}
				sm={6}
				xs={6}
				container
				item
				className={"body-sub-container"}
			>
				<InputLabel
					className={"default-input-label"}
				>
					Unique Taxpayer reference (Optional)
				</InputLabel>
				<img
					src={Help}
					alt={"help"}
					style={{
						width: "17px",
						height: "17px",
						marginLeft: "7px",
						cursor: "pointer",
					}}
					onClick={() => {
						window.open(
							"https://www.gov.uk/find-utr-number",
							"_blank"
						);
					}}
				/>
				<TextField
					className={"default-text-field custom-height"}
					placeholder={"Example: 12345 67890"}
					value={stepOne.unique_taxpayer_reference}
					onChange={(e) => {
						setStepOne({...stepOne, unique_taxpayer_reference: e.target.value})
					}}
					fullWidth
				/>
			</Grid>

			<Grid
				item
				className={"body-sub-container-1"}
			>
				<Typography
					className={"body-subtitle-1"}
				>
					Is your company eligible to the Employment Allowance or the Small Employer’s Relief? Check the boxes below, if applicable.
				</Typography>
				<Typography
					className={"body-subtitle-2"}
				>
					The former reduces your National Insurance liability while the latter allows you to claim 103% of your employees’ statutory payments.
				</Typography>
				<FormGroup row={true}>
					<FormControlLabel
						control={
							<Checkbox
								defaultValue={false}
								icon={<CheckBoxOutlineBlankSharpIcon/>}
								checkedIcon={<CheckBoxSharpIcon/>}
								onChange={(e) =>
									setStepOne({
										...stepOne,
										employment_allowance: e.target.checked,
										aquaculture_sector: false,
										agriculture_sector: false,
										road_transport_sector: false,
										other_sector: false,
									})}
							/>
						}
						label={
							<Typography
								className={"checkbox-label"}
							>
								Employment Allowance <br/>
								<span
									className={"understand-more"}
									onClick={(event) => {
										event.preventDefault();
										window.open(
											"https://www.gov.uk/claim-employment-allowance",
											"_blank"
										);
									}}
								>
									Understand how it works
								</span>
							</Typography>
						}
					/>
					<FormControlLabel
						control={
							<Checkbox
								defaultValue={false}
								icon={<CheckBoxOutlineBlankSharpIcon/>}
								checkedIcon={<CheckBoxSharpIcon/>}
								onChange={(e) => setStepOne({...stepOne, small_employer_relief: e.target.checked})}
							/>
						}
						label={
							<Typography
								className={"checkbox-label"}
							>
								Small Employer’s Relief <br/>
								<span
									className={"understand-more"}
									onClick={(event) => {
										event.preventDefault();
										window.open(
											"https://www.gov.uk/recover-statutory-payments",
											"_blank"
										);
									}}
								>
									Understand how it works
								</span>
							</Typography>
						}
					/>
				</FormGroup>
			</Grid>

			{
				stepOne.employment_allowance && (
					<Grid
						item
						className={"body-sub-container-2"}
					>
						<Typography
							className={"body-subtitle-1"}
						>
							What is your company’s business sector?
						</Typography>
						<FormGroup row={true}>
							<FormControlLabel
								control={
									<Checkbox
										defaultValue={false}
										icon={<CheckBoxOutlineBlankSharpIcon/>}
										checkedIcon={<CheckBoxSharpIcon/>}
										onChange={(e) => {
											setStepOne({
												...stepOne,
												agriculture_sector: e.target.checked
											});
										}}
									/>
								}
								label={"Agriculture products sector"}
							/>
							<FormControlLabel
								control={
									<Checkbox
										defaultValue={false}
										icon={<CheckBoxOutlineBlankSharpIcon/>}
										checkedIcon={<CheckBoxSharpIcon/>}
										onChange={(e) => {
											setStepOne({
												...stepOne,
												aquaculture_sector: e.target.checked
											});
										}}
									/>
								}
								label={"Fisheries and Aquaculture sector"}
							/>
							<FormControlLabel
								control={
									<Checkbox
										defaultValue={false}
										icon={<CheckBoxOutlineBlankSharpIcon/>}
										checkedIcon={<CheckBoxSharpIcon/>}
										onChange={(e) => {
											setStepOne({
												...stepOne,
												road_transport_sector: e.target.checked
											});
										}}
									/>
								}
								label={"Road transport sector"}
							/>
							<FormControlLabel
								control={
									<Checkbox
										defaultValue={false}
										icon={<CheckBoxOutlineBlankSharpIcon/>}
										checkedIcon={<CheckBoxSharpIcon/>}
										onChange={(e) => {
											setStepOne({
												...stepOne,
												other_sector: e.target.checked
											});
										}}
									/>
								}
								label={"Industrial/other sector"}
							/>
						</FormGroup>
					</Grid>
				)
			}

			<Grid
				container
				item
				className={"button-container"}
			>
				<Button
					className={"default-black-button"}
					disabled={
						!stepOne.accounts_office_reference ||
						!stepOne.employer_paye_reference ||
						!regex_office_reference.test(stepOne.accounts_office_reference) ||
						!regex_paye_reference.test(stepOne.employer_paye_reference) ||
						(
							stepOne.employment_allowance &&
							(!stepOne.other_sector && !stepOne.aquaculture_sector && !stepOne.agriculture_sector && !stepOne.road_transport_sector)
						)
					}
					onClick={() => setStepOne({
						...stepOne,
						inner_step: 1,
					})}
				>
					Continue
				</Button>
			</Grid>

		</Grid>
	);
}