import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";


const renderDivider = () => {
	return (
		<Grid
			paddingTop={"10px"}
			paddingBottom={"10px"}
			width={"100%"}
		>
			<Divider/>
		</Grid>
	)
}
const renderGrids = (title, subtitle, lastComponent = false) => {
	return (
		<Grid>
			<Grid container direction={"column"}>
				<Grid item xs={6}>
					<Typography className={"team-member-typography-title"}>
						{title}
					</Typography>
				</Grid>
				<Grid item xs={6} paddingTop={"10px"}>
					<Typography className={"team-member-typography-subtitle"}>
						{subtitle}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
}

const EmployeeInformation = ({
	member = []
}): JSX.Element => {
	return (
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			item
			container
			paddingTop={"50px"}
		>
			<Typography className={"team-member-typography-title"}>
				Personal information
			</Typography>
			{renderDivider()}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
			>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{renderGrids("Gender", member?.gender ?? "N/A")}
				</Grid>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{renderGrids("Birthdate", member?.birthdate ?? "N/A")}
				</Grid>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{renderGrids("Phone number", member?.telephone ?? "N/A")}
				</Grid>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{renderGrids("E-mail", member?.email ?? "N/A")}
				</Grid>
			</Grid>
			{renderDivider()}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
			>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{renderGrids("National Insurance Number", member?.national_insurance_number ?? "N/A")}
				</Grid>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{renderGrids("Tax Code", member?.tax_code ?? "N/A")}
				</Grid>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{renderGrids("NI Category", member?.ni_category ?? "N/A")}
				</Grid>
			</Grid>
			{renderDivider()}
			<Grid paddingTop={"40px"}>
				<Typography className={"team-member-typography-title"}>
					Personal information
				</Typography>
			</Grid>
			{renderDivider()}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
			>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{renderGrids("Employee Id", member?.payroll_id ?? "N/A")}
				</Grid>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{renderGrids("Date Hired", member?.join_date ?? "N/A")}
				</Grid>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{renderGrids("Payment Frequency", member?.pay_schedule?.frequency_code ?? "N/A")}
				</Grid>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{renderGrids("Annual Basic", member?.salary?.annual_salary ?? "N/A")}
				</Grid>
			</Grid>
			{renderDivider()}
			<Grid paddingTop={"40px"}>
				<Typography className={"team-member-typography-title"}>
					Pension Details
				</Typography>
			</Grid>
			{renderDivider()}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
			>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{renderGrids("AE Category", member?.pension_assessment.worker_type ?? "N/A")}
				</Grid>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{renderGrids("AE Status", member?.pension_assessment.ae_status ?? "N/A")}
				</Grid>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{renderGrids("Enrolment Date", member?.pension_assessment.enrolment_date ?? "N/A")}
				</Grid>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					{renderGrids("Inactivation Date", member?.pension_assessment.inactivation_date ?? "N/A")}
				</Grid>
			</Grid>
		</Grid>
	);
}


export const EmployeeSummaryModal = ({
	open,
	setOpen,
	setSelectedEmployee,
	selectedEmployee,
}) => {
	return (
		<Dialog
			id={"team-member-dialog"}
			open={open}
			onClose={() => {
				setSelectedEmployee(null);
				setOpen(false);
			}}
			sx={{
				"& .MuiDialog-paper": {
					borderRadius: "0",
					width: "1220px",
					maxWidth: "none",
					height: "700px",
					paddingTop: "35px",
					paddingLeft: "55px",
					paddingRight: "55px",
					paddingBottom: "30px"
				},
			}}
		>
			<DialogTitle>
				<Grid
					container
					direction={"row"}
					alignItems={"center"}
					item
				>
					<Grid
						item
						paddingRight={"10px"}
					>
						<Typography fontSize={"35px"} fontWeight={"bold"}>
							{selectedEmployee?.forename}
						</Typography>
					</Grid>
					<Grid
						paddingTop={"7px"}
						paddingLeft={"10px"}
					>
						<Grid item className={"active-style"}>
							Active
						</Grid>
					</Grid>
					<Grid
						item
						paddingTop={"15px"}
						className={"close-icon-button"}
					>
						<CloseIcon
							onClick={() => {
								setOpen(false);
								setSelectedEmployee(null);
							}}
						/>
					</Grid>
				</Grid>
				<Typography fontSize={"16px"} color={"#707070"}>
					{selectedEmployee?.role}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<EmployeeInformation
					member={selectedEmployee}
				/>
			</DialogContent>
		</Dialog>
	)
}