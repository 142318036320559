import Axios from 'axios';
import {toast} from "react-toastify";

/**
 * Request options.
 *
 * @type {{baseURL: *, withCredentials: boolean, timeout: number}}
 */
const options = {
	baseURL: process.env.REACT_APP_API,
	withCredentials: false,
	timeout: 960000,
	headers: {
		Authorization: 'Bearer ' + localStorage.getItem('fpEmployerToken'),
	}
};

// creating the axios instance.
const Request = Axios.create(options);

/**
 * Creating custom function to parse the params.
 *
 *
 * @example: Create like url?parameter=value&parameter2=valor&parameterArray=[...values]
 * @param params
 * @return {string}
 */
Request.parseParams = (params) => {
	return Object.keys(params).map(function (key) {
		return [key, params[key]].map(encodeURIComponent).join("=");
	}).join("&");
};

Request.interceptors.response.use(
	function(response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	function(error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		if (error.response && error.response.status === 500) {
			toast.error("An unexpected error occurred. Please contact support."); // Show unexpected error
		}
		if (error.response && error.response.status === 401) {
			localStorage.clear();
			sessionStorage.clear();
			window.location.href = '/login';
		}
		return Promise.reject(error);
	}
);

export default Request;
