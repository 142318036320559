import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import React from "react";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const  OneOffAndRecurring = ({
	setStep = () => {
	},
}): JSX.Element => {
	return (
		<Grid
			container
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				justifyContent={"space-between"}
				alignItems={"center"}
			>
				<Grid>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={30}
						fontWeight={"bold"}
					>
						Pay items
					</StyleTypography>
				</Grid>
				<Grid
					container
				>
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
						container
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							paddingTop={"10px"}
						>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								color={"#ABAEB7"}
							>
								Pay items allow you to set up custom payments and track each payment in the payroll journal
							</StyleTypography>
						</Grid>
						<Grid
							className={"pay-items-divider"}
						>
							<Divider/>
						</Grid>
						<Grid>
							<Grid
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								item
								container
								className={"pay-items-type-container"}
								justifyContent={"space-between"}
							>
								<Grid
									xl={5.5}
									lg={5.5}
									md={5.5}
									sm={5.5}
									xs={5.5}
									item
									container
									alignContent={"flex-start"}
								>
									<Grid
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										item
										marginBottom={"10px"}
									>
										<StyleTypography
											fontSize={22}
											fontSizeMedium={16}
											fontWeight={'bold'}
										>
											One-off
										</StyleTypography>
									</Grid>

									<Grid
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										item
										className={"pay-items-type-description"}
									>
										<StyleTypography
											fontSize={16}
											color={"#ABAEB7"}
											fontSizeMedium={12}
										>
											Create One-off pay items that you can apply and reuse in every payroll,<br/>
											for every employee. E.g. Sales bonus, commissions
										</StyleTypography>
									</Grid>
									<Grid
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										item
									>
										<Button
											className={"create-pay-item"}
											onClick={() => setStep(1)}
										>
											<StyleTypography
												fontSize={14}
												color={"white"}
												fontWeight={"bold"}
												fontSizeMedium={11}
											>
												Create One-off
											</StyleTypography>
										</Button>
									</Grid>
								</Grid>

								<Grid
									xl={5.5}
									lg={5.5}
									md={5.5}
									sm={5.5}
									xs={5.5}
									item
									container
									alignContent={"flex-start"}
								>
									<Grid
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										item
										marginBottom={"9.5px"}
										container
										direction={"row"}
									>
										<Grid paddingRight={"10px"}>
											<StyleTypography
												fontSize={22}
												fontSizeMedium={16}
												fontWeight={'bold'}
											>
												Recurring
											</StyleTypography>
										</Grid>
										<Grid className={"pay-items-available-soon"}>
											<StyleTypography
												fontSize={22}
												fontSizeMedium={16}
												fontWeight={'bold'}
												color={"white"}
											>
												Available soon
											</StyleTypography>
										</Grid>
									</Grid>

									<Grid
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										item
										className={"pay-items-type-description"}
									>
										<StyleTypography
											fontSize={16}
											fontSizeMedium={12}
											color={"#ABAEB7"}
										>
											Create recurring pay items that you can apply to employees and have<br/>
											them automatically be added to future payrolls. ( Runs until cancelled )
										</StyleTypography>
									</Grid>
									<Grid
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										item
									>
										<Button
											className={"create-pay-item"}
											onClick={() => setStep(2)}
											disabled
										>
											<StyleTypography
												fontSize={14}
												fontWeight={"bold"}
												color={"white"}
												fontSizeMedium={11}
											>
												Create Recurring
											</StyleTypography>
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default OneOffAndRecurring