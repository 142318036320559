import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { me } from "../../actions/Profile";
import { LeftMenu } from "../../components/LeftMenu";
import { StyleAppBar } from "../../components/StyleAppBar";
import Loading from "../../components/Loading";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Divider from "@mui/material/Divider";
import DownloadIcon from "../../images/svg/ReportsIcon/download-icon.svg";
import { format, parseISO } from "date-fns";
import { downloadEPS, getEpsReport, submitEPS } from "../../actions/Reports";
import { findEmployer } from "../../actions/Employer";
import StyleButton from "../../components/StyledComponents/StyleButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Select from "@mui/material/Select";
import {FreepayrollSelect, Typography, Chip, FreepayrollButton} from "@collegia-partners/ui-kit";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsEps = ({
	me = () => {},
	user = {},
	user_loading = false,
	report_loading = false,
	eps_reports = [],
	getEpsReport = () => {},
	downloadEPS = () => {},
	submitEPS = () => {},
	employer = {},
	employer_loading = false,
	findEmployer = () => {},
}): JSX.Element => {
	const taxYearStart =
		"20" + employer?.tax_year?.formated_tax_year.split("-")[0];
	const taxYearEnd = "20" + employer?.tax_year?.formated_tax_year.split("-")[1];

	const taxMonths = {
		1: `Month 1: 6th April ${taxYearStart} - 5th May ${taxYearStart}`,
		2: `Month 2: 6th May ${taxYearStart} - 5th June ${taxYearStart}`,
		3: `Month 3: 6th June ${taxYearStart} - 5th July ${taxYearStart}`,
		4: `Month 4: 6th July ${taxYearStart} - 5th August ${taxYearStart}`,
		5: `Month 5: 6th August ${taxYearStart} - 5th September ${taxYearStart}`,
		6: `Month 6: 6th September ${taxYearStart} - 5th October ${taxYearStart}`,
		7: `Month 7: 6th October ${taxYearStart} - 5th November ${taxYearStart}`,
		8: `Month 8: 6th November ${taxYearStart} - 5th December ${taxYearStart}`,
		9: `Month 9: 6th December ${taxYearStart} - 5th January ${taxYearEnd}`,
		10: `Month 10: 6th January ${taxYearEnd} - 5th February ${taxYearEnd}`,
		11: `Month 11: 6th February ${taxYearEnd} - 5th March ${taxYearEnd}`,
		12: `Month 12: 6th March ${taxYearEnd} - 5th April ${taxYearEnd}`,
	};

	const [openEpsModal, setOpenEpsModal] = useState(false);
	const [epsMode, setEpsMode] = useState(""),
		[noPaymentPeriod, setNoPaymentPeriod] = useState(0);
	const [selectedTaxYearId, setSelectedTaxYearId] = useState(0);
	const [selectedTaxYear, setSelectedTaxYear] = useState(null);

	const [popOver, setPopOver] = useState(false),
		[anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		findEmployer(["tax_year"]);
		me();
		getEpsReport();
		document.documentElement.style.setProperty(
			"--scroll-height",
			`${document.body.scrollHeight}`
		);
	}, [findEmployer, getEpsReport, me]);

	useEffect(() => {
		if (!selectedTaxYearId) {
			setSelectedTaxYearId(eps_reports[0]?.id || 0);
			return;
		}

		setSelectedTaxYear(
			eps_reports.find((e) => e.id === selectedTaxYearId) || null
		);
	}, [selectedTaxYearId, eps_reports]);

	if (user_loading || report_loading || employer_loading) {
		return <Loading />;
	}

	return (
		<Grid container justifyContent={"center"} id={"reports-eps"}>
			<StyleAppBar showBackButton={false} user={user} employer={employer} />
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu activePage={"Reports"} activeSubpage={""} />
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid container className={"reports-eps-item"}>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							justifyContent={"space-between"}
							alignItems={"center"}
						>
							<StyleTypography
								fontSize={40}
								fontSizeMedium={30}
								fontWeight={"bold"}
							>
								Employer Payment Summary (EPS)
							</StyleTypography>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								color={"#ABAEB7"}
							>
								Monthly reports with the employer payment summary which contain
								values that <br />
								can’t be included in the full payment submission (FPS).
							</StyleTypography>
							<Grid className={"reports-eps-divider"}>
								<Divider />
							</Grid>
							<Grid xs={2} item marginBottom={3}>
								<FreepayrollButton
									onClick={(event) => {
										setAnchorEl(event.currentTarget);
										setPopOver(true);
									}}
									size={"small"}
								    variant={"primary"}
								>
									New EPS Submission
								</FreepayrollButton>

								<Menu
									className={"team-member-menu"}
									open={popOver}
									anchorEl={anchorEl}
									onClose={() => {
										setPopOver(false);
									}}
								>
									<MenuItem
										className={"team-member-menu-item"}
										onClick={() => {
											setOpenEpsModal(true);
											setAnchorEl(null);
											setPopOver(false);
											setEpsMode("no_payment");
										}}
									>
										<StyleTypography
											fontSize={15}
											fontSizeMedium={13}
											color={"#000"}
										>
											+ No payment submission
										</StyleTypography>
									</MenuItem>
									<MenuItem
										className={"team-member-menu-item"}
										onClick={() => {
											setAnchorEl(null);
											setPopOver(false);
										}}
										disabled
									>
										<StyleTypography
											fontSize={15}
											fontSizeMedium={13}
											color={"#000"}
										>
											+ Bank details submission
										</StyleTypography>
									</MenuItem>
								</Menu>
							</Grid>
							{eps_reports.length > 0 && (
								<div className={"eps-table-reports"}>
									<div className={"eps-table-row"}>
										
										<div className={"eps-table-columns"}>
											<Typography variant="subtitle2" color="black">
												Submission Date
											</Typography>

											<Typography variant="subtitle2" color="black">
												Status
											</Typography>

											<Typography variant="subtitle2" color="black">
												Submission Type
											</Typography>

											<Typography variant="subtitle2" color="black">
												Tax month
											</Typography>

										</div>
										<div className={"eps-table-item"}>
											<FreepayrollSelect
												variant="calendar"
												options={eps_reports.map((e) => ({
													value: e.id,
													label: e.formated_tax_year,
												}))}
												onSelect={(e) => {
													if (!e || !e.value) return;
													setSelectedTaxYearId(e.value);
												}}
												value={selectedTaxYearId || 0}
												defaultValue={selectedTaxYearId || 0}
												noGhostHelperText
												noGhostLabel
											/>
										</div>
									</div>

									<div className="eps-table-divider-wrapper">
										<Divider />
									</div>

									{!selectedTaxYear?.employer_eps_submissions?.length && (
										<Typography variant="body">
											No EPS reports found for this tax year
										</Typography>
									)}

									{selectedTaxYear?.employer_eps_submissions?.map(
										(employer_eps_submission) => {
											const status =
												!employer_eps_submission.is_error &&
												employer_eps_submission.is_success
													? "Pending"
													: employer_eps_submission.is_error
													? "Error"
													: "Accepted";

											const statusColor = {
												Pending: "#FD8A03",
												Error: "#FD0303",
												Accepted: "#0360FD",
											}[status];

											const submissionType =
												employer_eps_submission.tax_month_submission
													? "Employer Payment Summary"
													: employer_eps_submission.scheme_ceased_submission
													? "Close Paye Scheme"
													: employer_eps_submission.bank_details_submission
													? "Bank Details Submission"
													: employer_eps_submission.employment_allowance_submission
													? "Employment Allowance Claim"
													: "No Payment Submission";

											return (
												<div
													className={"eps-table-row"}
													key={employer_eps_submission.id}
												>
													<div style={{ width: "100%" }}>
														<div className={"eps-table-columns"}>
															<Typography variant="body" color="black">
																{format(
																	parseISO(employer_eps_submission.created_at),
																	"dd MMM yy"
																)}
															</Typography>

															<Chip color={statusColor}>{status}</Chip>

															<Typography variant="body" color="black">
																{submissionType}
															</Typography>

															<Typography variant="body" color="black">
																{employer_eps_submission.tax_month_submission &&
																employer_eps_submission.employer_payment_summary
																	?.tax_month
																	? `${
																			employer_eps_submission
																				.employer_payment_summary?.tax_month
																	  } (${format(
																			parseISO(
																				employer_eps_submission
																					.employer_payment_summary?.start_date
																			),
																			"dd MMM yy"
																	  )} - ${format(
																			parseISO(
																				employer_eps_submission
																					.employer_payment_summary?.end_date
																			),
																			"dd MMM yy"
																	  )})`
																	: "N/A"}
															</Typography>

															<button
																className={"download-button"}
																onClick={() => {
																	downloadEPS({
																		eps_submissions: {
																			eps_report_id: employer_eps_submission.id,
																		},
																	});
																}}
															>
																<img src={DownloadIcon} alt="Download" />
															</button>
														</div>

														<div className="eps-table-divider-wrapper">
															<Divider />
														</div>
													</div>
													<div className={"eps-table-item"} />

												</div>
											);
										}
									)}
								</div>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Dialog
				fullWidth={true}
				maxWidth={"sm"}
				open={openEpsModal}
				onClose={() => {
					setOpenEpsModal(false);
					setNoPaymentPeriod(0);
					setEpsMode("");
				}}
			>
				<DialogContent>
					<StyleTypography
						fontSize={28}
						fontSizeMedium={24}
						color={"#0160FD"}
						fontWeight={"bold"}
					>
						CONFIRM EPS SUBMISSION
					</StyleTypography>

					<Grid
						marginTop={3}
						display={epsMode === "no_payment" ? "grid" : "none"}
					>
						<Select
							className={"default-select details-company-select"}
							fullWidth
							required
							value={noPaymentPeriod}
							onChange={(e) => setNoPaymentPeriod(e.target.value)}
						>
							<MenuItem key={0} value={0} disabled>
								Select no payment period
							</MenuItem>

							{Object.entries(taxMonths).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</Select>
					</Grid>

					<Grid marginTop={2}>
						<StyleTypography
							fontSize={22}
							fontSizeMedium={16}
							color={"#000000"}
						>
							Do you confirm that you want to submit EPS ?
							<ul>
								<li>Tax Year: {employer?.tax_year?.formated_tax_year}</li>
								{epsMode === "no_payment" && (
									<>
										<li>No Payment submission</li>
										<li>Period: {taxMonths[noPaymentPeriod]}</li>
									</>
								)}
							</ul>
							Once you click on confirm, we will send automatically the required
							data to HMRC for RTI purposes.
						</StyleTypography>
					</Grid>
				</DialogContent>
				<DialogActions>
					<StyleButton
						fullWidth={false}
						onClick={() => {
							setOpenEpsModal(false);
							setEpsMode("");
							setNoPaymentPeriod(0);
						}}
						isWhiteButton={true}
					>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={16}
							fontWeight={"bold"}
							color={"#000000"}
						>
							CANCEL
						</StyleTypography>
					</StyleButton>
					<StyleButton
						disabled={epsMode === "no_payment" && noPaymentPeriod === 0}
						fullWidth={false}
						onClick={() => {
							submitEPS({
								pay_schedule_runs: {
									eps_mode: epsMode,
									no_payment_period: noPaymentPeriod,
								},
							});
							setEpsMode("");
							setNoPaymentPeriod(0);
							setOpenEpsModal(false);
						}}
					>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={16}
							fontWeight={"bold"}
							color={"#FFFFFF"}
						>
							CONFIRM
						</StyleTypography>
					</StyleButton>
				</DialogActions>
			</Dialog>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	const { Profile, Reports, Employer } = state;

	return {
		...Profile,
		...Reports,
		...Employer,
	};
};

const mapDispatchToProps = (dispatch) => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	getEpsReport: () => dispatch(getEpsReport()),
	downloadEPS: (data) => dispatch(downloadEPS(data)),
	submitEPS: (data) => dispatch(submitEPS(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsEps);
