import React from 'react';
import MUIDataTable from "mui-datatables";
import StyleTypography from "./StyleTypography";

const defaultOptions = {
	selectableRowsHeader: false,
	selectableRows: "none",
	responsive: "simple",
	enableNestedDataAccess: ".",
	selectToolbarPlacement: "none",
	searchAlwaysOpen: false,
	print: false,
	download: false,
	search: true,
	viewColumns: false,
	serverSide: true,
	elevation: 0,
	filter: true,
	searchProps: {
		placeholder: "Search",
		classes: {
			input: "default-text-field"
		}
	},
};

const StyleTable = ({
	title = "",
	data,
	columns,
	options = {},
	addCustomButtons = false,
	customButtons,
	removeToolbar = false,
	removeFooter = false,
}) => {

	let classnames = "mui-table";

	// Merge default options and passed options
	const mergedOptions = { ...defaultOptions, ...options };

	if (addCustomButtons) {
		mergedOptions.customToolbar = () => customButtons;
	}

	if (removeToolbar) {
		classnames += " mui-table-no-toolbar";
	}

	if (removeFooter) {
		classnames += " mui-table-no-footer";
	}

	return (
		<MUIDataTable
			title={
				<StyleTypography
					fontSize={30}
					fontSizeMedium={22}
					fontWeight={"bold"}
					color={"#000000"}
				>
					{title}
				</StyleTypography>
			}
			columns={columns}
			data={data ?? []}
			className={classnames}
			options={mergedOptions}
			pagination
			autoHeight
		/>
	);
};

export default StyleTable;