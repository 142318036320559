import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {StyleAppBar} from "../../components/StyleAppBar";
import {LeftMenu} from "../../components/LeftMenu";
import {me} from "../../actions/Profile";
import {connect} from "react-redux";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import money from "../../images/svg/money-add-icon.svg";
import SalaryItem from "../../components/Pages/Company/Salaries/SalaryItem";
import Button from "@mui/material/Button";
import {Add} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import {createSalaryItem, findEmployer, removeSalaryItem} from "../../actions/Employer";
import {
	FreepayrollButton,
	FreepayrollSelect,
	FreepayrollTextField,
	Modal,
	Typography
} from "@collegia-partners/ui-kit";

function AddSalaryModal ({
	open,
	setOpen,
	createSalaryItem,
	employer_loading
}) {
	const [salary, setSalary] = useState({
		name: "",
		code: "",
		type: "",
		pay_method: "",
		multiplier: "",
		rate: ""
	});

	const RateTypes = [
		{
			value: "multiplier",
			label: "Multiplier Rate"
		},
		{
			value: "hourly",
			label: "Hourly Rate"
		},
		{
			value: "unit",
			label: "Rate per Unit"
		}
	];

	return (
		<Modal isOpen={open} onClose={() => setOpen(false)} size={"md"} >
			<div style={{display: "flex", flexDirection: "column", gap: "1vw"}}>
				<Typography
					variant={"title"}
				>
					Add Salary
				</Typography>
				<Typography
					variant={"label"}
				>
					Choose a name for this custom rate and choose
					if it should be calculated per hour or per unit.
				</Typography>
				<div style={{display: "flex", gap: "1.2vw"}}>
					<FreepayrollTextField
						fieldType={"text"}
						label={"Rate Name"}
						value={salary.name}
						onChange={(e) => {
							setSalary({
								...salary,
								name: e.target.value
							});
						}}
						name={"rate_name"}
					/>
					<FreepayrollTextField
						fieldType={"text"}
						label={"Account Code (Optional)"}
						value={salary.code}
						onChange={(e) => {
							setSalary({
								...salary,
								code: e.target.value
							});
						}}
						helperText={"This code will be the code displayed on your journal"}
						name={"account_code"}
					/>
				</div>
				<div style={{display: "flex", gap: "1.2vw"}}>
					<FreepayrollSelect
						options={RateTypes}
						label={"Rate Type"}
						value={salary.type}
						onSelect={(option) => {
							setSalary({
								...salary,
								multiplier: "",
								rate: "",
								type: option.value,
								pay_method: ""
							});
						}}
					/>
					<FreepayrollSelect
						options={[
							{
								value: "custom",
								label: "Custom for each employee",
								disabled: salary.type === "multiplier"
							},
							{
								value: "fixed",
								label: "Fixed value for all employees"
							}
						]}
						label={"Pay Method"}
						value={salary.pay_method}
						onSelect={(option) => {
							setSalary({
								...salary,
								rate: "",
								pay_method: option.value
							});
						}}
					/>
				</div>
				{
					salary.type === "multiplier" && salary.pay_method === 'fixed' && (
						<FreepayrollTextField
							fieldType={"number"}
							label={"Multiplier"}
							value={salary.multiplier}
							onChange={(e) => {
								setSalary({
									...salary,
									multiplier: e.target.value
								});
							}}
							name={"multiplier"}
							maxDecimalPlaces={4}
							customStartAdornment={
								<Typography variant={"subtitle"} color={"black"} weight={"bold"}>
									X
								</Typography>
							}
						/>
					)
				}
				{
					(salary.type === "hourly" || salary.type === 'unit') && salary.pay_method === 'fixed' && (
						<FreepayrollTextField
							fieldType={"number"}
							label={
								salary.type === 'hourly' ?
									"Salary Rate" : "Rate per Unit"
							}
							value={salary.rate}
							onChange={(e) => {
								setSalary({
									...salary,
									rate: e.target.value
								});
							}}
							name={"rate"}
							maxDecimalPlaces={2}
							customStartAdornment={
								<Typography variant={"subtitle"} color={"black"} weight={"bold"}>
									£
								</Typography>
							}
						/>
					)
				}
				<div style={{display: "flex", gap: "1.2vw"}}>
					<FreepayrollButton
						variant={"white-button"}
						isLoading={employer_loading}
						onClick={() => {
							setOpen(false);
						}}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						onClick={() => {
							createSalaryItem({
								employers: {
									name: salary.name,
									code: salary.code,
									type: salary.type,
									pay_method: salary.pay_method,
									multiplier: salary.multiplier,
									rate: salary.rate
								}
							});
							setOpen(false);
						}}
						disabled={
							!salary.name ||
							!salary.type ||
							!salary.pay_method ||
							(
								salary.pay_method === "fixed" &&
								((salary.type === "multiplier" && !salary.multiplier) ||
								((salary.type === "hourly" || salary.type === "unit") && !salary.rate))
							)
						}
						isLoading={employer_loading}
					>
						Save
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
);
}

const Salaries = ({
	me = () => {},
	user = {},
	createSalaryItem = () => {},
	findEmployer = () => {},
	employer = {
		salary_items: {}
	},
	employer_loading = false,
	user_loading = false,
	removeSalaryItem = () => {}
}): JSX.Element => {
	useEffect(() => {
		me();
		findEmployer(["salary_items"]);
	}, [me, findEmployer])

	const [open, setOpen] = useState(false);

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"company-salary-page"}
		>
			<StyleAppBar
				showBackButton={false}
				backLink={"/main"}
				user={user}
				employer={employer}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Company"}
						activeSubpage={"Salaries"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
						>
							<Grid>
								<StyleTypography
									fontSize={40}
									fontSizeMedium={30}
									fontWeight={"bold"}
								>
									Salaries
								</StyleTypography>
							</Grid>
							<Grid
								className={"sub-title-container"}
							>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={"#ABAEB7"}
									component={"h2"}
								>
									Here you can set custom salaries for your employees.
								</StyleTypography>
							</Grid>
						</Grid>
						<Divider className={"divider"}/>
						<Grid
							container
							id={"salaries-container"}
						>
							{
								employer?.salary_items?.length > 0 &&
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									sx={{
										marginBottom: "10px",
									}}
								>
									<StyleTypography
										color={"#000"}
										fontSize={16}
										fontSizeMedium={16}
									>
										Salary Type
									</StyleTypography>
								</Grid>
							}
							<Grid
								item
								xs={4.9}
								sm={4.9}
								md={4.9}
								lg={4.9}
								xl={4.9}
								id={"left-container"}
							>
								{
									employer?.salary_items?.length > 0 &&
									<Grid
										id={"salary-items-box"}
									>
										{
											Object.values(employer?.salary_items).map((item, key) => (
												<SalaryItem
													salary_item={item}
													last={key === employer?.salary_items?.length - 1}
													key={key}
													setOpen={setOpen}
													removeSalaryItem={removeSalaryItem}
												/>
											))
										}
									</Grid>
								}
								<Button
									className={"add-company-salary-button"}
									onClick={() => setOpen(!open)}
									disabled={
										employer_loading ||
										user_loading
									}
								>
									<Add className={"add-icon"}/>
									<StyleTypography
										fontSize={16}
										fontSizeMedium={12}
										color={"#FFF"}
										fontWeight={"bold"}
									>
										Create Salary Type
									</StyleTypography>
								</Button>
							</Grid>
							<Grid
								item
								xs={4.9}
								sm={4.9}
								md={4.9}
								lg={4.9}
								xl={4.9}
								id={"right-container"}
							>
								<Grid
									id={"company-salaries-information-container"}
								>
									<img src={money} alt={"Add salary item"}/>

									<StyleTypography
										color={"#AAAEB7"}
										fontSize={18}
										fontSizeMedium={13}
									>
										Set up Company specific salaries and choose if they should
										be calculated on hours or units.
									</StyleTypography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<AddSalaryModal
				open={open}
				setOpen={setOpen}
				createSalaryItem={createSalaryItem}
				employer_loading={employer_loading}
			/>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Employer
	} = state;

	return {
		...Profile,
		...Employer
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	createSalaryItem: (salary) => dispatch(createSalaryItem(salary)),
	removeSalaryItem: (id) => dispatch(removeSalaryItem(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Salaries);