import Grid from "@mui/material/Grid";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import PlusWhite from "../../../../images/enhanced-policies/plus-white.svg";
import StyleTable from "../../../StyledComponents/StyleTable";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {Popover} from "@mui/material";
import Typography from "@mui/material/Typography";
import BlackClock from "../../../../images/svg/SickLeave/black_clock.svg";
import OkTickBlue from "../../../../images/svg/SickLeave/ok_tick_blue.svg";
import {format, parseISO} from "date-fns";

/**
 * @returns {JSX.Element}
 * @constructor
 */
export const SickLeaveMain = (
	{
		leaversEmployees = [],
		setStep = () => {},
		deleteSickLeaveEmployee = () => {},
		employee = {}
	}
): JSX.Element => {
	const [popOver, setPopOver] = useState(false),
		[anchorEl, setAnchorEl] = useState(null),
		[selectedLeave, setSelectedLeave] = useState(null);
	const columns = [
		{
			name: "employee_leave.leave_start_date",
			label: "FIRST DAY",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{format(parseISO(value), "dd/MM/yyyy")}
					</StyleTypography>
				)
			},
		},
		{
			name: "employee_leave.leave_end_date",
			label: "LAST DAY",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => value && (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{format(parseISO(value), "dd/MM/yyyy")}
					</StyleTypography>
				)
			},
		},
		{
			name: "status",
			label: "STATUS",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => {
					return (
						<Grid>
							{
								value
									? <Grid
										container
										direction={"row"}
										alignItems={"center"}
									>
										<img
											src={BlackClock}
											alt={"BlackClock"}
											className={"sick-leave-table-img"}
										/>
										<StyleTypography
											fontSize={18}
											fontSizeMedium={13}
											color={"#000000"}
											fontWeight={"bold"}
										>
											Active
										</StyleTypography>
									</Grid>
									: <Grid
										container
										direction={"row"}
										alignItems={"center"}
									>
										<img
											src={OkTickBlue}
											alt={"OkTickBlue"}
											className={"sick-leave-table-img"}
										/>
										<StyleTypography
											fontSize={18}
											fontSizeMedium={13}
											color={"#0360FD"}
											fontWeight={"bold"}
										>
											Completed
										</StyleTypography>
									</Grid>
							}
						</Grid>
					)
				}
			},
		},
		{
			name: "deducted_days",
			label: "DEDUCTED DAY",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => {
					return (
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={"#000000"}
							fontWeight={"bold"}
						>
							{value}
						</StyleTypography>
					);
				}
			},
		},
		{
			name: "payable_days",
			label: "SSP PAYABLE DAYS",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => {
					return (
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={"#000000"}
							fontWeight={"bold"}
						>
							{value}
						</StyleTypography>
					);
				}
			},
		},
		{
			name: "employee_leave.id",
			label: "ACTIONS",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) =>
					<Grid>
						<IconButton
							onClick={(event) => {
								setAnchorEl(event.currentTarget);
								setPopOver(true);
								setSelectedLeave(value);
							}}
						>
							<MoreHorizIcon/>
						</IconButton>
						<Popover
							id={"popover"}
							open={popOver}
							anchorEl={anchorEl}
							onClose={() => {
								setAnchorEl(null);
								setPopOver(false);
								setSelectedLeave(null);
							}}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							<Grid
								container
								onClick={() => deleteSickLeaveEmployee({
									employees: {
										employee_id: employee.id,
										leave_id: selectedLeave,
										pay_schedule_run_id:employee.pay_schedule?.active_pay_run?.id,
										period_start_date: employee?.pay_schedule?.active_pay_run?.period_start_date,
										period_end_date: employee?.pay_schedule?.active_pay_run?.period_end_date,
									}
								})}
								className={"popover-list-grid"}
							>
								<Typography>Remove Leave</Typography>
							</Grid>
						</Popover>
					</Grid>
			},
		},
	];

	return (
		<Grid
			className={"sick-leave-main"}
			width={"100%"}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<StyleTypography
					fontSize={40}
					fontSizeMedium={29}
					fontWeight={'bold'}
					component={"h1"}
				>
					Sick leave
				</StyleTypography>
			</Grid>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				className={"sick-leave-subtitle-margin"}
			>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={13}
					color={"#ABAEB7"}
				>
					If an employee is unable to work due to illness, they might be entitled to Statutory Sick Pay.
				</StyleTypography>
			</Grid>
			{
				<Grid
					width={"100%"}
				>
					<StyleTable
						data={leaversEmployees}
						columns={columns}
						title={""}
						options={{
							selectableRowsHeader: false,
							selectableRows: "none",
							responsive: "simple",
							enableNestedDataAccess: ".",
							selectToolbarPlacement: "none",
							searchAlwaysOpen: false,
							print: false,
							download: false,
							search: false,
							viewColumns: false,
							elevation: 0,
							filter: false,
							searchProps: {
								placeholder: "Search",
								classes: {
									input: "default-text-field"
								}
							},
						}}
						removeToolbar={true}
					/>
				</Grid>
			}
			<Button
				className={"sick-leave-button"}
				variant={"contained"}
				fullWidth
				onClick={() => setStep(1)}
			>
				<img
					src={PlusWhite}
					alt={"logo"}
					className={"sick-leave-plus-white"}
				/>
				<StyleTypography
					fontSize={16}
					color={"white"}
					fontSizeMedium={11}
					fontWeight={"bold"}
				>
					Add Sick pay leave
				</StyleTypography>
			</Button>
		</Grid>
	)
}