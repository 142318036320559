import React from 'react';
import { generateRandomString } from '../../utils/Helpers';

const NewTextField = ({
	label,
	className,
	error,
	helperText,
	InputProps,
	disabled,
	...rest
}) => {
	const uniqueComponentId = generateRandomString(10);

	return (
		<div className="text-field-wrapper">
			{label && (
				<label
					htmlFor={uniqueComponentId}
					className="text-field-label"
				>
					{label}
				</label>
			)}
			<div
				className={`text-field-content ${error && 'error'} ${
					disabled && 'text-field-disabled'
				} ${className}`}
			>
				<input
					{...rest}
					id={uniqueComponentId}
					className="text-field-input"
					disabled={disabled}
				/>
				{InputProps && InputProps.endAdornment && (
					<div className="text-field-end-adornment">
						{InputProps.endAdornment}
					</div>
				)}
			</div>
			{helperText && (
				<span
					className={`text-field-helper-text ${
						error && 'text-field-helper-text-error'
					}`}
				>
					{helperText}
				</span>
			)}
		</div>
	);
};

export default NewTextField;
