import {
	ADD_EMPLOYEE,
	ADD_PARENTAL_STATUTORY,
	CLEAR_EMPLOYEE,
	FETCH_EMPLOYEES,
	GET_PAYROLL_AND_EMAIL,
	RETRIEVE_CATEGORIES,
	UPDATE_EMPLOYEE,
} from './types';
import Request from '../../utils/Request';
import {toast} from 'react-toastify';

export const addEmployee = (data, isOnboarding, push) => (dispatch) => {
	dispatch({
		type: ADD_EMPLOYEE,
		add_employee_loading: true,
	});

	return new Request.post('/api/employees/', data)
		.then((response) => {
			if (response.data.success) {
				if (isOnboarding) {
					push('/onboarding/add-employee-method');
				} else {
					push('/main/people/team-members');
				}
				dispatch({
					type: ADD_EMPLOYEE,
					add_employee_loading: false,
				});
			}
		})
		.catch((error) => {
			if (error.response && error.response.status === 422) {
				toast.error(error.response.data.message);
			}

			console.error(error);
			dispatch({
				type: ADD_EMPLOYEE,
				add_employee_loading: false,
			});
		});
};

export const bulkEmployeeImport = (file, setUploadedData) => (dispatch) => {
	dispatch({
		type: ADD_EMPLOYEE,
		add_employee_loading: true,
	});

	const formData = new FormData();
	formData.append('file', file);

	Request.post('/api/employees/bulk-add-import', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: ADD_EMPLOYEE,
					add_employee_loading: false,
				});

				setUploadedData(response.data.data);
			}
		})
		.catch((error) => {
			if (error.response.status === 422) {
				toast.error(error.response.data.message);
			}

			console.error(error);
			dispatch({
				type: ADD_EMPLOYEE,
				add_employee_loading: false,
			});
		});
};

export const bulkEmployeeSave =
	(data, isMainPage, setSubmissionErrors, push) => (dispatch) => {
		dispatch({
			type: ADD_EMPLOYEE,
			add_employee_loading: true,
		});

		return new Request.post('/api/employees/bulk-add-save', data)
			.then((response) => {
				if (response.data.success) {
					if (isMainPage) {
						push('/main/people/team-members');
					} else {
						push('/onboarding/add-employee-method');
					}
				}
			})
			.catch((error) => {
				if (error.response.status === 400) {
					setSubmissionErrors(error.response.data.errors);
					dispatch({
						type: ADD_EMPLOYEE,
						add_employee_loading: false,
					});
					toast.error(error.response.data.message);
				}

				console.error(error);
				dispatch({
					type: ADD_EMPLOYEE,
					add_employee_loading: false,
				});
			});
	};

export const bulkImportCsv = (data, isMainPage, push) => (dispatch) => {
	dispatch({
		type: ADD_EMPLOYEE,
		add_employee_loading: true,
	});

	return new Request.post('/api/employees/bulk-import-csv', data)
		.then((response) => {
			if (response.data.success) {
				if (isMainPage) {
					push('/main/people/team-members');
				} else {
					push('/onboarding/add-employee-method');
				}
			}
		})
		.catch((error) => {
			if (error.response.status === 400) {
				dispatch({
					type: ADD_EMPLOYEE,
					add_employee_loading: false,
				});
				toast.error(error.response.data.message);
			}

			console.error(error);
			dispatch({
				type: ADD_EMPLOYEE,
				add_employee_loading: false,
			});
		});
};

export const findEmployee = (id, relations = []) =>
	(dispatch) => {
		dispatch({
			type: ADD_EMPLOYEE,
			employee_loading: true,
		});

		let queryString = '';
		if (relations.length) {
			// Create a string like 'relations[]=relation1&relations[]=relation2'
			queryString = relations
				.map((relation) => `relations[]=${relation}`)
				.join('&');
		}

		return new Request.get(`/api/employees/${id}?${queryString}`)
			.then((response) => {
				if (response.status === 200) {
					dispatch({
						type: ADD_EMPLOYEE,
						employee_loading: false,
						employee: response.data,
					});
				}
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: ADD_EMPLOYEE,
					employee_loading: false,
				});
			});
	};

export const updateEmployee = (id, data, redirect) => (dispatch) => {
	dispatch({
		type: UPDATE_EMPLOYEE,
		employee_loading: true,
	});

	return new Request.put(`/api/employees/${id}`, data)
		.then((response) => {
			if (redirect) {
				window.location.href = '/main/people/team-members';
			}

			if (response.status === 200) {
				dispatch({
					type: UPDATE_EMPLOYEE,
					employee_loading: false,
					employee: response.data.data,
				});

				toast.success('Employee updated successfully.');
			}
		})
		.catch((error) => {
			console.error(error);
			if (error.response.status !== 500) {
				toast.error(error.response.data.message ?? 'Something went wrong! Please contact support.');
			}
			dispatch({
				type: UPDATE_EMPLOYEE,
				employee_loading: false,
			});
		});
};

export const paymentAfterLeaving = (data) => (dispatch) => {
	dispatch({
		type: UPDATE_EMPLOYEE,
		employee_loading: true,
	});

	return new Request.post(`/api/employees/payment-after-leaving`, data)
		.then((response) => {
			if (response.status === 200) {
				dispatch({
					type: UPDATE_EMPLOYEE,
					employee_loading: false,
					employee: response.data.data,
				});

				toast.success('Employee updated successfully.');
			}
		})
		.catch((error) => {
			console.error(error);
			if (error.response.status !== 500) {
				toast.error("An unexpected error occurred. Please contact support.");
			}
			dispatch({
				type: UPDATE_EMPLOYEE,
				employee_loading: false,
			});
		});
};

export const bankDetails = (data) => (dispatch) => {
	dispatch({
		type: UPDATE_EMPLOYEE,
		employee_loading: true,
	});

	return new Request.post(`/api/employees/bank-details`, data)
		.then((response) => {
			if (response.status === 200) {
				dispatch({
					type: UPDATE_EMPLOYEE,
					employee_loading: false,
					employee: response.data.data,
				});

				toast.success('Employee updated successfully.');
			}
		})
		.catch((error) => {
			console.error(error);
			if (error.response.status !== 500) {
				toast.error("An unexpected error occurred. Please contact support.");
			}
			dispatch({
				type: UPDATE_EMPLOYEE,
				employee_loading: false,
			});
		});
};

export const updateStudentLoan = (data) => (dispatch) => {
	dispatch({
		type: UPDATE_EMPLOYEE,
		employee_loading: true,
	});

	return new Request.post(`/api/employees/student-loan/update-plan`, data)
		.then((response) => {
			if (response.status === 200) {
				dispatch({
					type: UPDATE_EMPLOYEE,
					employee_loading: false,
					employee: response.data.data,
				});

				toast.success('Employee updated successfully.');
			}
		})
		.catch((error) => {
			console.error(error);
			if (error.response.status !== 500) {
				toast.error('An unexpected error occurred. Please contact support.');
			}
			dispatch({
				type: UPDATE_EMPLOYEE,
				employee_loading: false,
			});
		});
};

export const completeEmployeeSetup = (data, push) => (dispatch) => {
	dispatch({
		type: UPDATE_EMPLOYEE,
		employees_loading: true,
	});

	return new Request.post(`/api/employees/complete-setup`, data)
		.then((response) => {
			if (response.status === 200) {
				dispatch({
					type: UPDATE_EMPLOYEE,
					employees_loading: false,
				});
				push('/main/people/team-members');
				toast.success('Employee updated successfully.');
			}
		})
		.catch((error) => {
			dispatch({
				type: UPDATE_EMPLOYEE,
				employees_loading: false,
			});
			console.error(error);
			if (error.response.status !== 500) {
				toast.error('An unexpected error occurred. Please contact support.');
			}
		});
};

export const getEmployeesPerPaySchedule = (data, tablePagination) => (dispatch) => {
	dispatch({
		type: FETCH_EMPLOYEES,
		employees_loading: true,
		employees: []
	});
	
	let queryString = '?';
	
	if (tablePagination) {
		if (tablePagination.search) {
			queryString += `search=${tablePagination.search}&`;
		}
		
		if (tablePagination.rowsPerPage) {
			queryString += `per_page=${tablePagination.rowsPerPage}&`;
		}
		
		if (tablePagination.page) {
			queryString += `page=${tablePagination.page}&`;
		}
	}
	
	return new Request.post(`/api/employees/get-employees-per-pay-schedule${queryString}`, data)
		.then((response) => {
			if (response.status === 200) {
				dispatch({
					type: FETCH_EMPLOYEES,
					employees_loading: false,
					employees: response.data.data.data,
					paginationData: response.data.data,
				});
			}
		})
		.catch((error) => {
			console.error(error);
			dispatch({
				type: FETCH_EMPLOYEES,
				employees_loading: false,
			});
		});
	
}

export const getEmployees = (tablePagination) => (dispatch) => {
	dispatch({
		type: FETCH_EMPLOYEES,
		employees_loading: true,
		employees: []
	});

	let queryString = '?';

	if (tablePagination) {
		if (tablePagination.search) {
			queryString += `search=${tablePagination.search}&`;
		}

		if (tablePagination.rowsPerPage) {
			queryString += `per_page=${tablePagination.rowsPerPage}&`;
		}

		if (tablePagination.page) {
			queryString += `page=${tablePagination.page}&`;
		}
	}

	return new Request.get(`/api/employees/get-employees${queryString}`)
		.then((response) => {
			if (response.status === 200) {
				dispatch({
					type: FETCH_EMPLOYEES,
					employees_loading: false,
					employees: response.data.data.data,
					paginationData: response.data.data,
				});
			}
		})
		.catch((error) => {
			console.error(error);
			dispatch({
				type: ADD_EMPLOYEE,
				add_employee_loading: false,
			});
		});
};

export const getNiCategories = () => (dispatch) => {
	dispatch({
		type: RETRIEVE_CATEGORIES,
		ni_categories_loading: true,
	});

	return new Request.get(`/api/employees/ni-categories`)
		.then((response) => {
			if (response.status === 200) {
				dispatch({
					type: RETRIEVE_CATEGORIES,
					ni_categories_loading: false,
					ni_categories: response.data.data,
				});
			}
		})
		.catch((error) => {
			console.error(error);
			dispatch({
				type: RETRIEVE_CATEGORIES,
				ni_categories_loading: false,
			});
		});
};

export const clearEmployee = () => (dispatch) => {
	dispatch({
		type: CLEAR_EMPLOYEE,
		employee: {
			id: null
		},
	});
};

export const addParentalStatutory = (data) => (dispatch) => {
	dispatch({
		type: ADD_PARENTAL_STATUTORY,
		employee_loading: true,
	});

	return new Request.post('/api/employees/add-parental-statutory', data)
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: ADD_PARENTAL_STATUTORY,
					employee_loading: false,
				});
			}
		})
		.catch((error) => {
			if (error.response.status === 422) {
				toast.error(error.response.data.message);
			}

			console.error(error);
			dispatch({
				type: ADD_PARENTAL_STATUTORY,
				employee_loading: false,
			});
		});
};

export const getPayrollAndEmail = () => (dispatch) => {
	dispatch({
		type: GET_PAYROLL_AND_EMAIL,
		employee_loading: true,
		employees_email: [],
		employees_payroll: [],
	});

	return new Request.get('/api/employees/get-payroll-and-email')
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: GET_PAYROLL_AND_EMAIL,
					employee_loading: false,
					employees_email: response.data.data.employees_email,
					employees_payroll: response.data.data.employees_payroll,
				});
			}
		})
		.catch((error) => {
			if (error.response.status === 422) {
				toast.error(error.response.data.message);
			}

			console.error(error);
			dispatch({
				type: GET_PAYROLL_AND_EMAIL,
				employee_loading: false,
				employees_email: [],
				employees_payroll: [],
			});
		});
};

export const deleteEmployee = (id) => (dispatch) => {
	dispatch({
		type: FETCH_EMPLOYEES,
		employees_loading: true,
	});

	if (id) {
		return new Request.delete(`/api/employees/delete-employee/${id}`)
			.then((response) => {
				if (response.status === 200) {
					dispatch({
						type: FETCH_EMPLOYEES,
						employees_loading: false,
					});
					window.location.reload();
				}
			})
			.catch((error) => {
				console.error(error);
				if (error.response.status === 422 || error.response.data.message === 400) {
					toast.error(error.response.data.message);
				}
				dispatch({
					type: FETCH_EMPLOYEES,
					employees_loading: false,
				});
			});
	}
};
