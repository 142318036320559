import {resolveReduxState} from "../../utils/Helpers";
import {DOWNLOAD_PAYMENT_BREAKDOWN, GET_PENSION_PAYMENTS} from "../../actions/AutoEnrolment/types";

const initial_state = {
	pension_payments: [],
	pension_payments_loading: false,
}

const AutoEnrolment = (state = initial_state, action = false) => {
	if (action) {
		switch(action.type) {

			case GET_PENSION_PAYMENTS:
			case DOWNLOAD_PAYMENT_BREAKDOWN:
				return resolveReduxState(state, action);

			default:
				return state;
		}
	}

	return state;
}

export default AutoEnrolment;