import Grid from "@mui/material/Grid";
import React, {useRef, useState} from "react";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import {Download} from "@mui/icons-material";
import {ReviewDataModal} from "../Pages/People/ReviewDataModal"

const BulkEmployeesFps = ({
	isOnboarding = false,
	openFps = false,
	uploadedData = [],
	uploadMethod = " ",
	setOpenFps = () => {},
	bulkEmployeeSave = () => {},
	bulkEmployeeImport = () => {},
	setUploadedData = () => {},
	setUploadMethod = () => {},
}): JSX.Element => {

	const [submissionErrors, setSubmissionErrors] = useState([]);

	const fileInputRef = useRef(null);

	const handleFileInputClick = () => {
		fileInputRef.current.click();
	};
	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			const fileName = file.name;
			const fileExtension = fileName.split('.').pop().toLowerCase();
			if ((uploadMethod === "fps_ytd" || uploadMethod === "fps")) {
				if (fileExtension === 'xml') {
					bulkEmployeeImport(file, setUploadedData);
				} else {
					// Handle the error for unsupported file types
					alert("The import method selected, requires a XML file.");
				}
			}
		}
	};

	const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
		const newRows = uploadedData.slice();
		for (let i = fromRow; i <= toRow; i++) {
			newRows[i] = { ...newRows[i], ...updated };
		}
		setUploadedData(newRows);
	};

	return (
		<Grid
			container
		>
			<Grid
				container
			>
				{
					openFps &&
						<Grid
							container
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							id={"bulk-employees-container"}
							justifyContent={"space-between"}
						>
							<Grid
								container
								direction={"column"}
								justifyContent={"center"}
								alignItems={"center"}
								id={"bulk-employees-upload-box"}
								item
								xs={12}
								sm={12}
								md={6}
								lg={6}
								xl={6}
								onClick={handleFileInputClick}
							>
								<Download/>
								<StyleTypography
									color={"#000"}
									fontSize={30}
									fontSizeMedium={25}
									fontWeight={"bold"}
									textAlign={"center"}
								>
									Drag and drop your files here or chose file
								</StyleTypography>

								<input
									type="file"
									ref={fileInputRef}
									onChange={handleFileChange}
									style={{ display: 'none' }}
								/>

								<StyleTypography
									color={"#ABAEB7"}
									fontSize={18}
									fontSizeMedium={15}
								>
									Upload files to this project
								</StyleTypography>
							</Grid>

						</Grid>
				}
			</Grid>
			<ReviewDataModal
				open={uploadedData.length > 0}
				setOpen={() => {}}
				setOpenFps={setOpenFps}
				uploadedData={uploadedData}
				onGridRowsUpdated={onGridRowsUpdated}
				bulkEmployeeSave={bulkEmployeeSave}
				submissionErrors={submissionErrors}
				setSubmissionErrors={setSubmissionErrors}
				isMainPage={!isOnboarding}
				uploadMethod={uploadMethod}
				setUploadedData={setUploadedData}
				setUploadedMethod={setUploadMethod}
			/>
		</Grid>
	);
}

export default BulkEmployeesFps;