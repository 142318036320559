import Grid from "@mui/material/Grid";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle, FormHelperText,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import Button from "@mui/material/Button";
import StyleTypography from "../../StyledComponents/StyleTypography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {CurrencyInputCustom} from "../../StyledComponents/CurrencyInputCustom";

export const PensionModal = ({
	open,
	setOpen,
	employeeRecord,
	setEmployeeRecord,
	employee,
	updateEmployee,
}): JSX.Element => {

	return (
		<Dialog
			onClose={() => setOpen(false)}
			open={open}
			fullWidth={true}
			maxWidth={'sm'}
			id={"salary-modal"}
		>
			<DialogTitle>
				<StyleTypography
					fontSize={35}
					fontSizeMedium={28}
					fontWeight={'bold'}
				>
					Edit
				</StyleTypography>
			</DialogTitle>
			<DialogContent>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>

					{/*Pension Type*/}
					<Grid
						item
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Pension type
						</InputLabel>
						<FormControl
							fullWidth
						>
							<Select
								className={"default-select"}
								id={"select-gender"}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 200, // Adjust the maximum height of the list
										},
									},
								}}
								value={employeeRecord.pension_setting?.relief_at_source}
								onChange={(e) => {
									setEmployeeRecord({
										...employeeRecord,
										pension_setting: {
											...employeeRecord.pension_setting,
											relief_at_source: e.target.value,
											salary_sacrifice: e.target.value === "true" ? "false" : "true"
										}
									})
								}}
							>
								<MenuItem
									key={'select-title'}
									value={"true"}
								>
									Relief at source
								</MenuItem>
								<MenuItem
									key={'select-title'}
									value={"false"}
								>
									Salary sacrifice
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					{/*Pension Calculation*/}
					<Grid
						item
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Pension calculation
						</InputLabel>
						<FormControl
							fullWidth
						>
							<Select
								className={"default-select"}
								id={"select-gender"}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 200, // Adjust the maximum height of the list
										},
									},
								}}
								onChange={(e) => {
									setEmployeeRecord({
										...employeeRecord,
										pension_setting: {
											...employeeRecord.pension_setting,
											pensionable_earnings: e.target.value,
											qualifying_earnings: e.target.value === "true" ? "false" : "true"
										}
									})
								}}
								value={employeeRecord.pension_setting?.pensionable_earnings}
							>
								<MenuItem
									key={'select-title'}
									value={"true"}
								>
									Pensionable Earnings
								</MenuItem>
								<MenuItem
									key={'select-title'}
									value={"false"}
								>
									Qualifying Earnings
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					{/*Employee contribution*/}
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Employee contribution*
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							InputProps={{
								endAdornment: <InputAdornment position={"end"}>
									<StyleTypography fontSize={16} color={"black"} fontWeight={"bold"}>
										%
									</StyleTypography>
								</InputAdornment>,
								inputComponent: CurrencyInputCustom,
								inputProps: {prefix: ""},
							}}
							value={employeeRecord.pension_setting?.employee_rate_percentage ?? "N/A"}
							onChange={(e) => {
								setEmployeeRecord({
									...employeeRecord,
									pension_setting: {
										...employeeRecord.pension_setting,
										employee_rate_percentage: e.target.value,
										employee_rate: Number(e.target.value) / 100
									}
								})
							}}
							error={employeeRecord.pension_setting?.employee_rate_percentage < 5}
							helperText={employeeRecord.pension_setting?.employee_rate_percentage < 5 ? "Minimum 5%" : ""}
						/>
					</Grid>

					{/*Employer contribution*/}
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<InputLabel
							className={"default-input-label"}
						>
							Employer contribution*
						</InputLabel>
						<TextField
							fullWidth
							className={"default-text-field"}
							InputProps={{
								endAdornment: <InputAdornment position={"end"}>
									<StyleTypography fontSize={16} color={"black"} fontWeight={"bold"}>
										%
									</StyleTypography>
								</InputAdornment>,
								inputComponent: CurrencyInputCustom,
								inputProps: {prefix: ""},
							}}
							value={employeeRecord.pension_setting?.employer_rate_percentage ?? "N/A"}
							onChange={(e) => {
								setEmployeeRecord({
									...employeeRecord,
									pension_setting: {
										...employeeRecord.pension_setting,
										employer_rate_percentage: e.target.value,
										employer_rate: Number(e.target.value) / 100
									}
								})
							}}
							error={employeeRecord.pension_setting?.employer_rate_percentage < 3}
							helperText={employeeRecord.pension_setting?.employer_rate_percentage < 3 ? "Minimum 3%" : ""}
						/>
					</Grid>

					<Grid
						item
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
					>
						{
							Number(employeeRecord.pension_setting?.employer_rate_percentage) + Number(employeeRecord.pension_setting?.employee_rate_percentage) < 8 && (
								<FormHelperText error>*Minimum combined contribution amount is 8% (employee + employer).</FormHelperText>
							)
						}

						{
							Number(employeeRecord.pension_setting?.employer_rate_percentage) + Number(employeeRecord.pension_setting?.employee_rate_percentage) > 100 && (
								<FormHelperText error>*Maximum combined contribution amount is 100% (employee + employer).</FormHelperText>
							)
						}
					</Grid>

				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					item
					container
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					spacing={2}
				>
					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<Button
							fullWidth
							className={"default-black-button"}
							onClick={() => {
								updateEmployee(employee.safe_id, {
									employees: {
										'action': 'pension_settings',
										pension_settings: employeeRecord.pension_setting,
										pay_schedule_run_id:employee.pay_schedule.active_pay_run.id
									}
								})
							}}
							disabled={
								Number(employeeRecord.pension_setting?.employer_rate_percentage) < 3 ||
								Number(employeeRecord.pension_setting?.employee_rate_percentage) < 5 ||
								(
									Number(employeeRecord.pension_setting?.employer_rate_percentage) + Number(employeeRecord.pension_setting?.employee_rate_percentage) > 100 ||
									Number(employeeRecord.pension_setting?.employer_rate_percentage) + Number(employeeRecord.pension_setting?.employee_rate_percentage) < 8
								)
							}
						>
							Save
						</Button>
					</Grid>

					<Grid
						item
						xl={6}
						lg={6}
						md={6}
						sm={6}
						xs={6}
					>
						<Button
							fullWidth
							className={"default-white-button"}
							onClick={() => {
								setOpen(false);
								setEmployeeRecord(employee);
							}}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}