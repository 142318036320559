import Grid from "@mui/material/Grid";
import StyleTypography from "../../StyledComponents/StyleTypography";
import React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

/**
 * @returns {JSX.Element}
 * @constructor
 */
export const BankDetails = ({
	employeeRecord,
	setEmployeeRecord,
    employee,
    bankDetails
}): JSX.Element => {

	return (
		<Grid
			xl={11}
			lg={11}
			md={12}
			sm={12}
			xs={12}
			container
			item
			justifyContent={"space-between"}
			paddingTop={3}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<StyleTypography
					fontSize={30}
					fontWeight={'bold'}
					component={"h1"}
				>
					Bank details
				</StyleTypography>
			</Grid>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<StyleTypography
					fontSize={18}
					color={"#ABAEB7"}
				>
					Here you can store your employee’s bank details
				</StyleTypography>
			</Grid>
			<Grid
				xl={6}
				lg={6}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"bank-details-container"}
				spacing={4}
			>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					container
					item
					alignItems={"center"}
				>
					<InputLabel
						className={"default-input-label"}
					>
						Bank Name
					</InputLabel>
					<FormControl
						fullWidth
					>
						<TextField
							className={"default-text-field"}
							fullWidth
							value={employeeRecord?.bank_detail?.bank_name}
							onChange={(e) => {
								setEmployeeRecord({
									...employeeRecord,
									'bank_detail': {
										...employeeRecord?.bank_detail,
										'bank_name': e.target.value
									}
								})
							}}
						/>
					</FormControl>
				</Grid>

				<Grid
					item
					xs={6}
					sm={6}
					md={6}
					lg={6}
					xl={6}
				>
					<InputLabel
						className={"default-input-label"}
					>
						Sort Code
					</InputLabel>
					<FormControl
						fullWidth
					>
						<TextField
							className={"default-text-field"}
							fullWidth
							value={employeeRecord?.bank_detail?.sort_code}
							onChange={(e) => {
								setEmployeeRecord({
									...employeeRecord,
									'bank_detail': {
										...employeeRecord?.bank_detail,
										'sort_code': e.target.value
									}
								})
							}}
						/>
					</FormControl>
				</Grid>

				<Grid
					item
					xs={6}
					sm={6}
					md={6}
					lg={6}
					xl={6}
				>
					<InputLabel
						className={"default-input-label"}
					>
						Account Number
					</InputLabel>
					<FormControl
						fullWidth
					>
						<TextField
							className={"default-text-field"}
							fullWidth
							onChange={(e) => {
								setEmployeeRecord({
									...employeeRecord,
									'bank_detail': {
										...employeeRecord?.bank_detail,
										'account_number': e.target.value
									}
								})
							}}
							value={employeeRecord?.bank_detail?.account_number}
						/>
					</FormControl>
				</Grid>
			</Grid>

			<Grid
				container
				id={"bank-details-buttons-container"}
			>
				<Button
					className={"default-black-button"}
					onClick={() => {
						bankDetails({
							'bank_details': {
								'safe_id': employee?.safe_id,
								'bank_name': employeeRecord?.bank_detail?.bank_name,
								'sort_code': employeeRecord?.bank_detail?.sort_code,
								'account_number': employeeRecord?.bank_detail?.account_number
							},
						})
					}}
					disabled={
						employeeRecord?.bank_detail?.bank_name === '' ||
						employeeRecord?.bank_detail?.sort_code === '' ||
						employeeRecord?.bank_detail?.account_number === '' ||
						employeeRecord?.bank_detail?.bank_name === undefined ||
						employeeRecord?.bank_detail?.sort_code === undefined ||
						employeeRecord?.bank_detail?.account_number === undefined
					}
				>
					<StyleTypography
						color={"#fff"}
						fontWeight={"bold"}
						fontSize={16}
						fontSizeMedium={13}
					>
						Save
					</StyleTypography>
				</Button>

				<Button
					className={"default-white-button"}
				>
					<StyleTypography
						color={"#000"}
						fontWeight={"bold"}
						fontSize={16}
						fontSizeMedium={13}
					>
						Discard
					</StyleTypography>
				</Button>
			</Grid>
		</Grid>
	)
}