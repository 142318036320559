import OnboardingDashboard from "./OnboardingDashboard";
import CompanyDetails from "./CompanyDetails";
import BasicSetup from "./BasicSetup";

const OnboardingPages = {
	OnboardingDashboard,
	CompanyDetails,
	BasicSetup,
}

export default OnboardingPages;