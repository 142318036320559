import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PinInput from "react-pin-input";
import {useEffect, useState} from "react";
import {confirmEmail, me, sendEmail} from "../actions/Profile";
import {connect} from "react-redux";
import Loading from "../components/Loading";
import StyleTypography from "../components/StyledComponents/StyleTypography";
import {useNavigate} from "react-router-dom";

/**
 * @param me
 * @param user
 * @param sendEmail
 * @param user_loading
 * @param confirmEmail
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmEmail = ({
	me = () => {},
	user = {
		id: null,
		temporary_password: false
	},
	sendEmail = () => {},
	user_loading = false,
	confirmEmail = () => {},
}): JSX.Element => {

	const [pin, setPin] = useState("");
	const push = useNavigate();

	useEffect(() => {
		if (!user?.id) {
			me();
		}

		if (user.temporary_password) {
			sendEmail();
		}

	}, [me, sendEmail, user?.id, user.temporary_password]);

	if (user_loading) {
		return (
			<Loading/>
		);
	}

	return (
		<Grid
			container
			item
			xs={12}
			md={12}
			lg={12}
			xl={12}
			sm={12}
		>
			<Grid
				container
				id={"confirm-email-image"}
				alignItems={"center"}
				justifyContent={"center"}
				direction={"column"}
				item
				md={6}
			/>

			<Grid
				id={"confirm-email-container"}
				alignContent={"flex-start"}
				container
				item
				md={6}
			>
				<Grid
					xs={12}
					item
					textAlign={"center"}
					paddingBottom={'13px'}
				>
					<StyleTypography
						fontSize={50}
						fontSizeMedium={36}
						fontWeight={"bold"}
					>
						Enter the code <br/>
						to confirm your email
					</StyleTypography>
				</Grid>

				<Grid
					xs={12}
					item
					textAlign={"center"}
				>
					<StyleTypography
						fontSize={22}
						fontSizeMedium={15}
						color={"#000000"}
					>
						Enter the code we sent to the address <b>{user?.email}</b> to confirm your email
					</StyleTypography>
				</Grid>

				<Grid
					xs={12}
					item
				>
					<PinInput
						className={"pin-input"}
						length={6}
						initialValue={pin}
						type={"numeric"}
						onChange={(value) => setPin(value)}
						onComplete={(value) => setPin(value)}
					/>
				</Grid>

				<Grid>

				</Grid>

				<Typography
					id={"resend"}
					className={"typography-p"}
					onClick={() => sendEmail()}
				>
					Didn't receive it? <span className={'resend-sub'}>Click here to resend it</span>.
				</Typography>

				<Button
					className={"default-black-button"}
					fullWidth
					disabled={
						pin.length < 6
					}
					onClick={() => {
						confirmEmail({
							"users": {
								pin,
							}
						},push);
						setPin("");
					}}
				>
					CONFIRM
				</Button>
			</Grid>
		</Grid>
	)
};

const mapStateToProps = state => {
	const {
		Profile,
	} = state;

	return {
		...Profile,
	}
}

const mapDispatchToProps = dispatch => ({
	me:  () => dispatch(me()),
	sendEmail:  () => dispatch(sendEmail()),
	confirmEmail:  (pin,push) => dispatch(confirmEmail(pin,push)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail)