import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import Loading from "../../components/Loading";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from "@mui/material/Divider";
import {SyntheticEvent} from "react";
import {useNavigate} from "react-router-dom";
import FolderReport from "../../images/svg/ReportsIcon/folder-report.svg";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsList = ({
	me = () => {},
	user = {},
	user_loading = false,
}): JSX.Element => {
	const [expanded, setExpanded] = useState(false),
		push = useNavigate();
	const handleChange =
		(panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	useEffect(() => {
		if (!user.id) {
			me();
		}
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [me, user?.id]);

	if (user_loading) {
		return <Loading/>;
	}
	return (
		<Grid
			container
			justifyContent={"center"}
			id={"reports"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Reports"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
						paddingLeft={"5%"}
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							justifyContent={"space-between"}
							alignItems={"center"}
						>
							<StyleTypography
								fontSize={40}
								fontSizeMedium={30}
								fontWeight={"bold"}
							>
								Reports
							</StyleTypography>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								color={"#ABAEB7"}
							>
								Download standard reports or create custom data exports
							</StyleTypography>
						</Grid>

						<Grid
							className={"reports-list-item"}
						>
							<Grid>
								<Accordion
									className={"accordion-style"}
									expanded={expanded === 'panel1'}
									onChange={handleChange('panel1')}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={"panel1bh-content"}
									>
										<Grid container direction={"row"} alignItems={"center"}>
											<Grid item xs={1}>
												<img
													className={"img"}
													src={FolderReport}
													alt={"FolderReport"}
												/>
											</Grid>
											<Grid item>
												<StyleTypography
													fontSize={20}
													fontSizeMedium={15}
													fontWeight={"bold"}
													color={"black"}
												>
													HMRC Submissions
												</StyleTypography>
											</Grid>
										</Grid>
									</AccordionSummary>
									<AccordionDetails>
										<Grid
											className={"accordion-details"}
											onClick={() => push("/main/reports/fps")}
											marginLeft={"8.2%"}
										>
											<StyleTypography
												color={"#919196"}
												fontSize={15}
												fontSizeMedium={11}
											>
												Full Payment Submission (FPS)
											</StyleTypography>
										</Grid>
										<Grid
											onClick={() => push("/main/reports/eps")}
											marginLeft={"8.2%"}
											className={"mouse-pointer"}
										>
											<StyleTypography
												color={"#919196"}
												fontSize={15}
												fontSizeMedium={11}
											>
												Employer Payment Summary (EPS)
											</StyleTypography>
										</Grid>

									</AccordionDetails>
								</Accordion>
							</Grid>
							<Grid
								className={"reports-divider"}
							>
								<Divider/>
							</Grid>
							<Grid>
								<Accordion
									className={"accordion-style"}
									expanded={expanded === 'panel2'}
									onChange={handleChange('panel2')}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={"panel1bh-content"}
										disabled={true}
									>
										<Grid container direction={"row"} alignItems={"center"}>
											<Grid item xs={1}>
												<img
													className={"img"}
													src={FolderReport}
													alt={"FolderReport"}
												/>
											</Grid>
											<Grid item>
												<StyleTypography
													fontSize={20}
													fontSizeMedium={15}
													fontWeight={"bold"}
													color={"black"}
												>
													Year End Reports
												</StyleTypography>
											</Grid>
										</Grid>
									</AccordionSummary>
									<AccordionDetails>
										<Grid
											className={"accordion-details"}
											onClick={() => push("/main/reports/p60")}
											marginLeft={"8.2%"}
										>
											<StyleTypography
												color={"#919196"}
												fontSize={15}
												fontSizeMedium={11}
											>
												P60
											</StyleTypography>
										</Grid>
										<Grid
											onClick={() => push("/main/reports/p11")}
											marginLeft={"8.2%"}
											className={"mouse-pointer"}
										>
											<StyleTypography
												color={"#919196"}
												fontSize={15}
												fontSizeMedium={11}
											>
												P11Ds and P11D(b)
											</StyleTypography>
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Grid>
							<Grid
								className={"reports-divider"}
							>
								<Divider/>
							</Grid>
							<Grid
								onClick={() => push("/main/reports/taxes-filings")}
							>
								<Grid container direction={"row"} alignItems={"center"}>
									<Grid item xs={1}>
										<img
											className={"img"}
											src={FolderReport}
											alt={"FolderReport"}
										/>
									</Grid>
									<Grid item className={"mouse-pointer"}>
										<StyleTypography
											fontSize={20}
											fontSizeMedium={15}
											fontWeight={"bold"}
											color={"black"}
										>
											P32 - Taxes and Filings
										</StyleTypography>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								className={"reports-divider"}
							>
								<Divider/>
							</Grid>
							<Grid
								onClick={() => push("/main/reports/payroll-journals")}
							>
								<Grid container direction={"row"} alignItems={"center"}>
									<Grid item xs={1}>
										<img
											className={"img"}
											src={FolderReport}
											alt={"FolderReport"}
										/>
									</Grid>
									<Grid item className={"mouse-pointer"}>
										<StyleTypography
											fontSize={20}
											fontSizeMedium={15}
											fontWeight={"bold"}
											color={"black"}
										>
											Payroll Journals
										</StyleTypography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
	} = state;

	return {
		...Profile,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsList)