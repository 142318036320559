import Grid from "@mui/material/Grid";
import WarningIcon from "../../../images/svg/ReportsIcon/p60-warning.svg";
import StyleTypography from "../../StyledComponents/StyleTypography";
import React from "react";

export const FailedReportNotification = ({
	payScheduleRun = [],
}): JSX.Element => {
	return (
		<Grid className={"main-page-fail-report"}>
			<Grid marginTop={"10px"}>
				{
					payScheduleRun?.resubmission_needed &&
					<Grid
						className={"reports-fps-warning-box"}
						container
						direction={"row"}
						alignItems={"center"}
					>
						<Grid item className={"reports-fps-warning-box-margin-right"}>
							<img
								className={"reports-fps-warning-icon"}
								src={WarningIcon}
								alt={"WarningIcon"}
							/>
						</Grid>
						<Grid
							item
						>
							<Grid container direction={"row"}>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={"black"}
									fontWeight={"bold"}
								>
									{
										`Your FPS submission for pay schedule ${payScheduleRun?.pay_schedule?.name}, tax period ${payScheduleRun?.tax_period} has failed. Please click`
									}
									<Grid
										marginLeft={"5px"}
										marginRight={"5px"}
									>
										<a
											className="link-color"
											href={
												`/main/reports/fps?employerPayScheduleId=${payScheduleRun?.id}&payScheduleId=${payScheduleRun?.pay_schedule?.id}`
											}
										>
											{" here "}
										</a>
									</Grid>
									{
										"to resubmit."
									}
								</StyleTypography>
							</Grid>
						</Grid>
					</Grid>
				}
			</Grid>
		</Grid>
	)
}