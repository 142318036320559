import CurrencyInput from "react-currency-input-field";
import {forwardRef} from "react";

export const CurrencyInputCustom = forwardRef((
	props,
	ref,
) => {

	const { onChange, prefix = '£',...other } = props;

	const handleChange = (value) => {
		// convert to event format
		onChange({
			target: {
				name: props.name,
				value: value !== undefined ? value : "", // Use empty string if value is undefined
			},
		});
	};

	return (
		<CurrencyInput
			{...other}
			onValueChange={handleChange}
			allowNegativeValue={false}
			prefix={prefix}
			decimalScale={2}
			className={"custom-input"}
			decimalSeparator={"."}
			groupSeparator={","}
		/>
	);
});