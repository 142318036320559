import {resolveReduxState} from "../../utils/Helpers";
import {GET_EMPLOYER_PAYMENT_SUMMARY} from "../../actions/EmployerPaymentSummary/types";

const initial_state = {
	employer_payment_summary: {},
	employer_payment_summaries: [],
	employer_payment_summary_loading: false,
}

const EmployerPaymentSummary = (state = initial_state, action = false) => {
	if (action) {
		switch(action.type) {

			case GET_EMPLOYER_PAYMENT_SUMMARY:
				return resolveReduxState(state, action);

			default:
				return state;
		}
	}

	return state;
}

export default EmployerPaymentSummary;