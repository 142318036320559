import {
	GET_MANDATE_INFO,
	RETRIEVE_TOKEN,
} from "./types";
import Request from "../../utils/Request";
import {toast} from "react-toastify";

export const retrieveToken = () => dispatch => {

	Request.get('/api/employers/retrieve-mandate-info')
		.then(response => {

			if (response.data.data?.id) {
				dispatch({
					type: RETRIEVE_TOKEN,
					mandate_flow_initiated: false,
					token_loading: false,
				});

				window.location.reload();
			}

		})
		.catch(error => {
			console.error(error);
		})
};

export const getMandateInfo = () => dispatch => {
	dispatch({
		type: GET_MANDATE_INFO,
		mandate_loading: true,
	});

	Request.get(`/api/employers/auto-enrolment/get-mandate-info`)
		.then(response => {
			if (response.data?.data) {
				dispatch({
					type: GET_MANDATE_INFO,
					mandate_loading: false,
					mandate_info: {
						...response.data.data
					},
					token_loading: false,
				});
			} else {
				dispatch({
					type: GET_MANDATE_INFO,
					mandate_loading: false,
					mandate_info: {
						...response.data.data
					},
				});
			}

		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: GET_MANDATE_INFO,
				mandate_loading: false,
			});
		})
}

export const createOrRetrieveToken = () => dispatch => {
	dispatch({
		type: GET_MANDATE_INFO,
		mandate_loading: true,
	});
	Request.get('/api/employers/auto-enrolment/create-token-or-retrieve-token')
		.then(response => {
			dispatch({
				type: GET_MANDATE_INFO,
				token_loading: true,
			});
			let token = response.data.token;
			let link;
			if (process.env.REACT_APP_ENV === 'production') {
				link =
					'https://api.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=' +
					token;
			} else {
				link =
					'https://sandbox.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=' +
					token;
			}
			window.open(link, '_blank');

		})
		.catch(error => {
			dispatch({
				type: GET_MANDATE_INFO,
				mandate_loading: false,
			});
			toast.error(error.response.data.message);
			console.error(error);
		})
};
