import Grid from "@mui/material/Grid";
import {StyleAppBar} from "../../components/StyleAppBar";
import React, {useEffect, useState} from "react";
import {LeftMenu} from "../../components/LeftMenu";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Divider from "@mui/material/Divider";
import {closeAccount, editInfo, me, sendVerifyAccountCode} from "../../actions/Profile";
import {connect} from "react-redux";
import Loading from "../../components/Loading";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {useNavigate} from "react-router-dom";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import StyleButton from "../../components/StyledComponents/StyleButton";

const EditPersonalInfo = ({
	user = {
		id: null,
		employer_id: null,
		is_account_owner: false,
	},
	user_loading = false,
	me = () => {},
	editInfo = () => {},
	edit_info = "",
	sendVerifyAccountCode = () => {},
	closeAccount = () => {},
}): JSX.Element => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	
	useEffect(() => {
		if (!user.id) {
			me();
		}
	});
	
	if (user_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"onboarding-dashboard-page"}
		>
			<StyleAppBar
				backLink={"/main"}
				user={user}
			/>
			<Grid
				xl={11}
				lg={11}
				md={11}
				sm={11}
				xs={11}
				item
				container
				id={"main-content-container"}
			>
				<Grid
					xl={3}
					lg={3}
					md={3}
					sm={3}
					xs={3}
					item
				>
					<LeftMenu/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "79px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
						>
							<Grid>
								<StyleTypography
									fontSize={40}
									fontSizeMedium={31}
									fontWeight={"bold"}
									component={"h1"}
								>
									Edit Personal Info
								</StyleTypography>
							</Grid>
							<Grid
								className={"sub-title-container"}
							>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={14}
									color={"#ABAEB7"}
									component={"h2"}
								>
									Here you can change your  credentials for your FreePayroll account.
								</StyleTypography>
							</Grid>
						</Grid>

						<Divider className={"divider"}/>

						<Grid
							container
							item
							xs={12}
							sm={12}
							md={12}
							lg={6}
							xl={6}
							id={"edit-personal-info-container"}
							direction={"column"}
						>
							<Grid
								container
								item
								justifyContent={"space-evenly"}
								alignItems={"center"}
								direction={"row"}
								className={"edit-info-item"}
								onClick={() => {
									editInfo("email");
									sendVerifyAccountCode({
										"users": {
											...user,
											edit_info: "email",
										}
									});
									navigate("/main/settings/verify-account")
								}}
							>
								<Grid
									item
									xs={11}
									sm={11}
									md={11}
									lg={11}
									xl={11}
								>
									<StyleTypography
										fontSize={18}
										fontSizeMedium={16}
										color={"#000"}
									>
										Change my email
									</StyleTypography>
								</Grid>

								<Grid
									item
									xs={1}
									sm={1}
									md={1}
									lg={1}
									xl={1}
								>
									<KeyboardArrowRight/>
								</Grid>
							</Grid>

							<Divider className={"divider divider-style"}/>

							<Grid
								container
								justifyContent={"center"}
								alignItems={"center"}
								direction={"row"}
								className={"edit-info-item"}
								onClick={() => {
									editInfo("password");
									sendVerifyAccountCode({
										"users": {
											...user,
											edit_info: "password",
										}
									});
									navigate("/main/settings/verify-account");
								}}
							>
								<Grid
									item
									xs={11}
									sm={11}
									md={11}
									lg={11}
									xl={11}
								>
									<StyleTypography
										fontSize={18}
										fontSizeMedium={16}
										color={"#000"}
									>
										Change my password
									</StyleTypography>
								</Grid>

								<Grid
									item
									xs={1}
									sm={1}
									md={1}
									lg={1}
									xl={1}
								>
									<KeyboardArrowRight/>
								</Grid>
							</Grid>
							
							
							{
								user.is_account_owner &&
								<>
									<Divider className={"divider divider-style"}/>
									
									<Grid
										container
										justifyContent={"center"}
										alignItems={"center"}
										direction={"row"}
										className={"edit-info-item"}
										onClick={() => {
											setOpen(true);
										}}
									>
										<Grid
											item
											xs={11}
											sm={11}
											md={11}
											lg={11}
											xl={11}
										>
											<StyleTypography
												fontSize={18}
												fontSizeMedium={16}
												color={"#FF0000"}
											>
												Close account
											</StyleTypography>
										</Grid>
										
										<Grid
											item
											xs={1}
											sm={1}
											md={1}
											lg={1}
											xl={1}
										>
											<KeyboardArrowRight
												className={"red-text"}
											/>
										</Grid>
									</Grid>
								</>
							}
						</Grid>
						
						<Dialog
							onClose={() => {
								setOpen(false);
							}}
							open={open}
							id={"close-account-dialog"}
							fullWidth
						>
							<DialogTitle>
								<StyleTypography
									fontSize={35}
									fontSizeMedium={28}
									fontWeight={'bold'}
									color={"#FF0000"}
								>
									Close Account
								</StyleTypography>
							</DialogTitle>
							
							<DialogContent>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={16}
									color={"#000"}
								>
									If you want to deactivate your account, proceed with caution.
									However, if you ever wish to reactivate it, you’ll need to contact the support team.
								</StyleTypography>
							</DialogContent>
							
							<DialogActions>
								<Grid
									container
									justifyContent={"space-between"}
								>
									<Grid
										item
										container
										justifyContent={"flex-start"}
										xs={6}
										sm={6}
										md={6}
										lg={6}
										xl={6}
									>
										<StyleButton
											fullWidth={false}
											onClick={() => setOpen(false)}
										>
											<StyleTypography
												fontSize={16}
												fontSizeMedium={12}
												color={"#fff"}
											>
												Cancel
											</StyleTypography>
										</StyleButton>
									</Grid>
									
									<Grid
										container
										justifyContent={"flex-end"}
										item
										xs={6}
										sm={6}
										md={6}
										lg={6}
										xl={6}
									>
										<StyleButton
											fullWidth={false}
											backgroundColor={'#FF0000'}
											border={'#FF0000'}
											onClick={() => {
												closeAccount({
													"users": {
														employer_id: user.employer_id
													}
												})
											}}
										>
											<StyleTypography
												fontSize={16}
												fontSizeMedium={12}
												color={"#fff"}
											>
												Close Account
											</StyleTypography>
										</StyleButton>
									</Grid>
								</Grid>
							</DialogActions>
						</Dialog>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
	} = state;

	return {
		...Profile,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	editInfo: (data) => dispatch(editInfo(data)),
	sendVerifyAccountCode: (data) => dispatch(sendVerifyAccountCode(data)),
	closeAccount: (data) => dispatch(closeAccount(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPersonalInfo);