import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { useReducer, useState } from 'react';
import Geocode from 'react-geocode';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import { connect } from 'react-redux';
import { createEmployer } from '../../../actions/Employer';
import Loading from '../../Loading';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import { company_types } from '../../../utils/Helpers';
import {verifyFullName} from "../SignUp/CreateAccountCard";

const _defaultReducer = (state, updates) => {
	if (typeof updates === 'function') {
		updates = updates(state);
	}

	return { ...state, ...updates };
};

/**
 * @param value
 * @param addressComponents
 * @param setAddressComponents
 * @param event
 * @param setLoadingAddress
 * @param setAutoCompleteMessage
 * @param setCompanyDetails
 * @private
 */
const _postCodeChange = (
	value,
	addressComponents,
	setAddressComponents,
	event,
	setLoadingAddress = () => {},
	setAutoCompleteMessage = () => {},
	setCompanyDetails = () => {}
) => {
	if (value) {
		Geocode?.setApiKey(process.env.REACT_APP_GEOCODE_API_KEY);
		if (Geocode !== undefined) {
			Geocode?.fromAddress(value)
				.then(
					(
						response = {
							status: '',
							results: [
								{
									address_components: [],
									formatted_address: '',
								},
							],
						}
					) => {
						const { status = 'OK' } = response;
						if (status === 'OK') {
							if (response?.results[0]) {
								const address_components =
									response.results[0].address_components ?? [];
								let addressLine1 = '',
									addressLine2 = '',
									addressLine3 = '',
									postcode = '';

								if (address_components.length > 0) {
									address_components.forEach(
										(
											ac = {
												long_name: '',
												types: [],
											}
										) => {
											if (ac.types.filter((t) => t === 'route').length > 0) {
												addressLine1 = ac.long_name;
											}
											if (
												ac.types.filter(
													(t) => t === 'administrative_area_level_2'
												).length > 0
											) {
												addressLine2 = ac.long_name;
											}
											if (
												ac.types.filter((t) => t === 'postal_town').length > 0
											) {
												addressLine3 = ac.long_name;
											}
											if (
												ac.types.filter((t) => t === 'postal_code').length > 0
											) {
												postcode = event.target.value.toUpperCase();
											}
										}
									);
								}
								setAddressComponents((oldArray) => [
									...oldArray,
									{
										address: addressLine1 + ", " + addressLine2 + ", " + addressLine3 + ", " + postcode,
										components: response?.results[0]?.address_components,
									},
								]);
								if (setCompanyDetails) {
									setCompanyDetails({
										addressLine1,
										addressLine2,
										addressLine3,
										postcode,
									});
								}
							}
						}
					},
					(error) => {
						setTimeout(() => {
							setAutoCompleteMessage("No results for this address");
						}, 3000);

						console.error(error);
					}
				)
				.catch((error) => {
					console.error(error);
				});
		}
	}
};

const _onChange = (
	event,
	addressComponents,
	setAddressComponents,
	setCompanyDetails,
	setLoadingAddress,
	setAutoCompleteMessage
) => {
	if (event.target.value === '') {
		setCompanyDetails({
			postcode: '',
			addressLine1: '',
			addressLine2: '',
			addressLine3: '',
		});
		setLoadingAddress(false);
		setAutoCompleteMessage("Please type an address");
		setAddressComponents([]);
	} else {
		setLoadingAddress(true);
		setAutoCompleteMessage("Searching...");
	}
	_postCodeChange(
		event.target.value,
		addressComponents,
		setAddressComponents,
		event,
		setLoadingAddress,
		setAutoCompleteMessage
	);
};

/**
 * @param value
 * @param registeredAddress
 * @param setCompanyDetails
 * @param setRegisteredAddress
 * @param checkboxes
 * @private
 */
const _optionSelected = (
	value,
	registeredAddress,
	setCompanyDetails,
	setRegisteredAddress,
	checkboxes
) => {
	const components = value?.components ?? [];

	let postalCode = '',
		address1 = '',
		address2 = '',
		address3 = '';

	if (components.length > 0) {
		components.forEach(
			(
				c = {
					short_name: '',
					long_name: '',
					types: [],
				}
			) => {
				if (c.types.filter((t) => t === 'postal_code').length > 0) {
					postalCode = c.short_name;
				}
				if (c.types.filter((t) => t === 'route').length > 0) {
					address1 = c.long_name;
				}
				if (
					c.types.filter((t) => t === 'administrative_area_level_2').length > 0
				) {
					address2 = c.long_name;
				}
				if (c.types.filter((t) => t === 'postal_town').length > 0) {
					address3 = c.long_name;
				}
			}
		);
	}

	value?.components?.forEach((v, index) => {
		if (v) {
			if (v.types.filter((t) => t === 'country').length > 0) {
				delete value.components[index];
			}
		}
	});

	setCompanyDetails({
		postcode: postalCode,
		addressLine1: address1,
		addressLine2: address2,
		addressLine3: address3,
	});

	if (checkboxes.sameAddress) {
		setRegisteredAddress({
			postcode: postalCode,
			addressLine1: address1,
			addressLine2: address2,
			addressLine3: address3,
		});
	}
};

/**
 * @param isSameAddress
 * @param field
 * @param value
 * @param defaultCallBackFunction
 * @param sameAddressCallBackFunction
 * @private
 */
const _updateRegisteredAddress = (
	isSameAddress = false,
	field = '',
	value = '',
	defaultCallBackFunction = () => false,
	sameAddressCallBackFunction = () => false
) => {
	if (isSameAddress) {
		sameAddressCallBackFunction((prev) => ({
			...prev,
			[field]: value,
		}));
	}

	defaultCallBackFunction((prev) => ({
		...prev,
		[field]: value,
	}));
};

const CompanyDetailsForm = ({
	signupType = 'companiesHouse',
	director = '',
	setDirector = () => {},
	company = {
		registered_office_address: {
			postal_code: '',
			address_line_1: '',
			address_line_2: '',
			address_line_3: '',
			locality: '',
		},
		company_name: '',
		company_type: '',
		company_number: '',
	},
	createEmployer = () => {},
	employer_loading = false,
}): JSX.Element => {
	const [companyDetails, setCompanyDetails] = useReducer(_defaultReducer, {
		companyName: '',
		legalStructure: '',
		postcode: '',
		addressLine1: '',
		addressLine2: '',
		addressLine3: '',
	});

	const [registeredAddress, setRegisteredAddress] = useReducer(
		_defaultReducer,
		{
			postcode: '',
			addressLine1: '',
			addressLine2: '',
			addressLine3: '',
		}
	);

	const [checkboxes, setCheckboxes] = useReducer(_defaultReducer, {
		sameAddress: false,
		authorized: false,
		termsAndCondition: false,
	});

	const [addressComponents, setAddressComponents] = useState([]);
	const [addressComponentsRegisteredAddress, setAddressComponentsRegisteredAddress] = useState([]);
	const [loadingAddress, setLoadingAddress] = useState(true);
	const [autoCompleteMessage, setAutoCompleteMessage] = useState("Please type an address");
	const [loadingAddress2, setLoadingAddress2] = useState(true);
	const [autoCompleteMessage2, setAutoCompleteMessage2] = useState("Please type an address");

	if (signupType === 'companiesHouse' && companyDetails.companyName === '') {
		setCompanyDetails({
			companyName: company.company_name,
			postcode: company.registered_office_address.postal_code,
			addressLine1: company.registered_office_address.address_line_1 ?? '',
			addressLine2: company.registered_office_address.address_line_2 ?? '',
			addressLine3: company.registered_office_address.locality ?? '',
			legalStructure: company_types[company.company_type] ?? '',
		});
	}

	if (employer_loading) {
		return <Loading />;
	}

	return (
		<Grid
			id={'company-details-content'}
			container
			justifyContent={'flex-start'}
			alignItems={'flex-start'}
			direction={'column'}
		>
			<Typography className={'typography-black-header'}>
				Fill your company details
			</Typography>
			<small>All fields with * are required</small>

			<Grid
				className={'form-content'}
				container
				justifyContent={'flex-start'}
				alignItems={'flex-start'}
				spacing={4}
			>
				<Grid
					item
					xl={4.5}
					lg={4.5}
					md={6}
					sm={12}
					xs={12}
				>
					<InputLabel className={'default-input-label'}>
						Company / Business name *
					</InputLabel>
					<FormControl fullWidth>
						<TextField
							className={'default-text-field'}
							fullWidth
							required
							value={companyDetails.companyName}
							onChange={(e) =>
								setCompanyDetails({
									companyName: e.target.value,
								})
							}
							disabled={signupType === 'companiesHouse'}
						/>
					</FormControl>
				</Grid>

				<Grid
					item
					xl={4.5}
					lg={4.5}
					md={6}
					sm={12}
					xs={12}
				>
					<InputLabel className={'default-input-label'}>
						Legal Structure *
					</InputLabel>
					<Select
						className={'default-select'}
						fullWidth
						placeholder={'Legal Structure'}
						required
						value={companyDetails.legalStructure}
						onChange={(e) =>
							setCompanyDetails({
								legalStructure: e.target.value,
							})
						}
					>
						{Object.keys(company_types)
							.sort()
							.map((key) => (
								<MenuItem
									value={company_types[key]}
									key={key}
								>
									{`${key.toUpperCase()} - ${company_types[key]}`}
								</MenuItem>
							))}
					</Select>
				</Grid>

				<Grid
					item
					xl={4.5}
					lg={4.5}
					md={6}
					sm={12}
					xs={12}
				>
					<InputLabel className={'default-input-label'}>
						Type your postcode
					</InputLabel>
					<Autocomplete
						getOptionLabel={(addressComponents) =>
							addressComponents.address ?? ''
						}
						options={addressComponents}
						onChange={(event, value) =>
							_optionSelected(
								value,
								companyDetails,
								setCompanyDetails,
								setRegisteredAddress,
								checkboxes
							)
						}
						loading={loadingAddress}
						loadingText={autoCompleteMessage}
						renderInput={(params) => (
							<TextField
								className={'default-text-field'}
								sx={{ input: { marginTop: '-6px' } }}
								{...params}
								fullWidth
								required
								onChange={(event) =>
									_onChange(
										event,
										addressComponents,
										setAddressComponents,
										setCompanyDetails,
										setLoadingAddress,
										setAutoCompleteMessage
									)
								}
							/>
						)}
						freeSolo
						disableClearable
					/>
				</Grid>
				<Grid
					item
					xl={4.5}
					lg={4.5}
					md={6}
					sm={12}
					xs={12}
				>
					<InputLabel className={'default-input-label'}>Postcode*</InputLabel>
					<FormControl fullWidth>
						<TextField
							className={'default-text-field'}
							fullWidth
							required
							value={companyDetails.postcode}
							onChange={(e) =>
								_updateRegisteredAddress(
									checkboxes?.sameAddress ?? false,
									'postcode',
									e.target.value,
									setCompanyDetails,
									setRegisteredAddress
								)
							}
						/>
					</FormControl>
				</Grid>

				<Grid
					item
					xl={4.5}
					lg={4.5}
					md={6}
					sm={12}
					xs={12}
				>
					<InputLabel className={'default-input-label'}>
						Address line 1*
					</InputLabel>
					<FormControl fullWidth>
						<TextField
							className={'default-text-field'}
							fullWidth
							required
							value={companyDetails.addressLine1}
							onChange={(e) =>
								_updateRegisteredAddress(
									checkboxes.sameAddress,
									'addressLine1',
									e.target.value,
									setCompanyDetails,
									setRegisteredAddress
								)
							}
						/>
					</FormControl>
				</Grid>

				<Grid
					item
					xl={4.5}
					lg={4.5}
					md={6}
					sm={12}
					xs={12}
				>
					<InputLabel className={'default-input-label'}>
						Address Line 2
					</InputLabel>
					<FormControl fullWidth>
						<TextField
							className={'default-text-field'}
							fullWidth
							value={companyDetails.addressLine2}
							onChange={(e) =>
								_updateRegisteredAddress(
									checkboxes.sameAddress,
									'addressLine2',
									e.target.value,
									setCompanyDetails,
									setRegisteredAddress
								)
							}
						/>
					</FormControl>
				</Grid>

				<Grid
					item
					xl={4.5}
					lg={4.5}
					md={6}
					sm={12}
					xs={12}
				>
					<InputLabel className={'default-input-label'}>City*</InputLabel>
					<FormControl fullWidth>
						<TextField
							className={'default-text-field'}
							fullWidth
							value={companyDetails.addressLine3}
							onChange={(e) =>
								_updateRegisteredAddress(
									checkboxes.sameAddress,
									'addressLine3',
									e.target.value,
									setCompanyDetails,
									setRegisteredAddress
								)
							}
						/>
					</FormControl>
				</Grid>
			</Grid>

			<FormControlLabel
				control={
					<Checkbox
						onChange={(e) => {
							setCheckboxes({
								sameAddress: e.target.checked,
							});
							if (e.target.checked) {
								setRegisteredAddress({
									postcode: companyDetails.postcode,
									addressLine1: companyDetails.addressLine1,
									addressLine2: companyDetails.addressLine2,
									addressLine3: companyDetails.addressLine3,
								});
							} else {
								setRegisteredAddress({
									postcode: '',
									addressLine1: '',
									addressLine2: '',
									addressLine3: '',
								});
							}
						}}
						icon={<CheckBoxOutlineBlankSharpIcon />}
						checkedIcon={<CheckBoxSharpIcon />}
					/>
				}
				label={'My registered address is the same of my trading address'}
			/>


			<Grid
				className={'form-content'}
				container
				justifyContent={'flex-start'}
				alignItems={'flex-start'}
				spacing={4}
			>
				<Grid
					item
					xl={4.5}
					lg={4.5}
					md={6}
					sm={12}
					xs={12}
				>
					<InputLabel className={'default-input-label'}>
						Type your postcode
					</InputLabel>
					<Autocomplete
						getOptionLabel={(addressComponents) =>
							addressComponents.address ?? ''
						}
						disabled={checkboxes.sameAddress}
						options={addressComponentsRegisteredAddress}
						onChange={(event, value) =>
							_optionSelected(
								value,
								companyDetails,
								setRegisteredAddress,
								setRegisteredAddress,
								checkboxes
							)
						}
						loading={loadingAddress2}
						loadingText={autoCompleteMessage2}
						renderInput={(params) => (
							<TextField
								className={'default-text-field'}
								sx={{ input: { marginTop: '-6px' } }}
								{...params}
								fullWidth
								required
								onChange={(event) =>
									_onChange(
										event,
										addressComponentsRegisteredAddress,
										setAddressComponentsRegisteredAddress,
										setRegisteredAddress,
										setLoadingAddress2,
										setAutoCompleteMessage2
									)
								}
							/>
						)}
						freeSolo
						disableClearable
					/>
				</Grid>
				<Grid
					item
					xl={4.5}
					lg={4.5}
					md={6}
					sm={12}
					xs={12}
				>
					<InputLabel className={'default-input-label'}>Postcode*</InputLabel>
					<FormControl fullWidth>
						<TextField
							className={'default-text-field'}
							fullWidth
							required
							onChange={(e) =>
								!checkboxes.sameAddress
									? setRegisteredAddress({
											postcode: e.target.value,
									  })
									: false
							}
							value={registeredAddress.postcode}
							disabled={checkboxes.sameAddress}
						/>
					</FormControl>
				</Grid>

				<Grid
					item
					xl={4.5}
					lg={4.5}
					md={6}
					sm={12}
					xs={12}
				>
					<InputLabel className={'default-input-label'}>
						Address line 1*
					</InputLabel>
					<FormControl fullWidth>
						<TextField
							className={'default-text-field'}
							fullWidth
							required
							onChange={(e) =>
								!checkboxes.sameAddress
									? setRegisteredAddress({
											addressLine1: e.target.value,
									  })
									: false
							}
							value={
								registeredAddress.addressLine1 ?? companyDetails.addressLine1
							}
							disabled={checkboxes.sameAddress}
						/>
					</FormControl>
				</Grid>

				<Grid
					item
					xl={4.5}
					lg={4.5}
					md={6}
					sm={12}
					xs={12}
				>
					<InputLabel className={'default-input-label'}>
						Address Line 2
					</InputLabel>
					<FormControl fullWidth>
						<TextField
							className={'default-text-field'}
							fullWidth
							onChange={(e) =>
								!checkboxes.sameAddress
									? setRegisteredAddress({
											addressLine2: e.target.value,
									  })
									: false
							}
							value={registeredAddress.addressLine2}
							disabled={checkboxes.sameAddress}
						/>
					</FormControl>
				</Grid>

				<Grid
					item
					xl={4.5}
					lg={4.5}
					md={6}
					sm={12}
					xs={12}
				>
					<InputLabel className={'default-input-label'}>City*</InputLabel>
					<FormControl fullWidth>
						<TextField
							className={'default-text-field'}
							fullWidth
							onChange={(e) =>
								!checkboxes.sameAddress
									? setRegisteredAddress({
											addressLine3: e.target.value,
									  })
									: false
							}
							value={registeredAddress.addressLine3}
							disabled={checkboxes.sameAddress}
						/>
					</FormControl>
				</Grid>
			</Grid>

			<Typography className={'typography-black-header director'}>
				Director / Authorised Signatory
			</Typography>

			<Typography className={'typography-black-subheader pb-0'}>
				Which Director or Authorised Signatory is signing up on behalf of the
				company?
			</Typography>

			<Grid
				className={'form-content'}
				container
				justifyContent={'flex-start'}
				alignItems={'flex-start'}
				spacing={4}
			>
				<Grid
					item
					xl={4.5}
					lg={4.5}
					md={6}
					sm={12}
					xs={12}
				>
					<InputLabel className={'default-input-label'}>Full Name*</InputLabel>
					<TextField
						className={'default-text-field'}
						fullWidth
						required
						disabled={signupType === 'companiesHouse'}
						value={director}
						error={director !== "" && verifyFullName(director)}
						onChange={(e) => setDirector(e.target.value)}
					/>
				</Grid>
			</Grid>

			<Typography className={'typography-black-subheader pb-0'}>
				You can only setup an account for a company that you lawfully represent
				or work for.
			</Typography>

			<Grid
				className={'company-details-checkboxes'}
				container
				direction={'column'}
			>
				<FormControlLabel
					control={
						<Checkbox
							onChange={(e) =>
								setCheckboxes({
									authorized: e.target.checked,
								})
							}
							icon={<CheckBoxOutlineBlankSharpIcon />}
							checkedIcon={<CheckBoxSharpIcon />}
						/>
					}
					label={
						'I confirm that I’m authorised to act on behalf of the company'
					}
				/>

				<FormControlLabel
					control={
						<Checkbox
							onChange={(e) =>
								setCheckboxes({
									termsAndCondition: e.target.checked,
								})
							}
							icon={<CheckBoxOutlineBlankSharpIcon />}
							checkedIcon={<CheckBoxSharpIcon />}
						/>
					}
					label={
						<>
							I confirm that I have read and agree to the
							<a
								href={
									'https://s3.eu-west-2.amazonaws.com/freepayroll.uk/FreePayroll%2BTerms%2Band%2BConditions%2Bexecutedv2.pdf'
								}
								target={'_blank'}
								rel={'noreferrer'}
							>
								{' '}
								Terms and Conditions
							</a>
						</>
					}
				/>
			</Grid>
			<Button
				className={'default-black-button'}
				disabled={
					!companyDetails.companyName ||
					!companyDetails.postcode ||
					!companyDetails.addressLine1 ||
					!companyDetails.addressLine3 ||
					!companyDetails.legalStructure ||
					!registeredAddress.postcode ||
					!registeredAddress.addressLine1 ||
					!registeredAddress.addressLine3 ||
					!director ||
					!checkboxes.authorized ||
					!checkboxes.termsAndCondition ||
					verifyFullName(director)
				}
				onClick={() =>
					createEmployer({
						employers: {
							company: company,
							company_number:
								signupType === 'manual' ? null : company.company_number,
							company_details: companyDetails,
							director: {
								fullName: director,
							},
							checkboxes: checkboxes,
							registered_address: registeredAddress,
							is_manual_creation: signupType === 'manual',
						},
					})
				}
				id={'company-details-continue-button'}
			>
				Continue
			</Button>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	const { SignUp, Employer } = state;

	return {
		...SignUp,
		...Employer,
	};
};

const mapDispatchToProps = (dispatch) => ({
	createEmployer: (data) => dispatch(createEmployer(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsForm);
