import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";

export const CompanyDetailsCover = (
	{
		setStep = () => {},
		step = 0
	}
): JSX.Element => {
	return (
		<Grid
			id={"company-details-image"}
			container
		>
			<Grid
				id={"company-details-image-container"}
				container
				item
				justifyContent={"center"}
				alignItems={"center"}
				direction={"column"}
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}
			>
				<Typography
					className={"typography-h1 white"}
					variant={"h1"}
				>
					Let’s set you up in FreePayroll
				</Typography>

				<Typography
					className={"typography-p white"}
				>
					Now, we’ll ask you for more details about your company, team, and taxes.
				</Typography>

				<hr className={"white-line"}/>

				<Button
					className={"default-white-button"}
					onClick={() => setStep(step + 1)}
				>
					Let's go
				</Button>
			</Grid>
		</Grid>
	);
}