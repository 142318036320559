import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {me} from "../actions/Profile";
import {LeftMenu} from "../components/LeftMenu";
import {StyleAppBar} from "../components/StyleAppBar";
import Loading from "../components/Loading";
import {useNavigate} from "react-router-dom";
import {closeTaxYear, findEmployer} from "../actions/Employer";
import CloseTaxYearWarning from "../components/CloseTaxYear/CloseTaxYearWarning";
import CloseTaxYearCarryOver from "../components/CloseTaxYear/CloseTaxCarryOver";

const _renderPage = (step = 0, setStep = () => {}, push, closeTaxYear) => {
	switch (step) {
		case 0:
			return (
				<CloseTaxYearWarning
					setStep={setStep}
					push={push}
				/>
			)
		case 1:
			return (
				<CloseTaxYearCarryOver
					setStep={setStep}
					closeTaxYear={closeTaxYear}
				/>
			)
		default:
			return (<></>)
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const CloseTaxYear = ({
	me = () => {},
	user = {
		id: null,
		temporary_password: false,
	},
	user_loading = false,
	findEmployer = () => {},
	employer = {},
	employer_loading,
	closeTaxYear = () => {},
}): JSX.Element => {

	const push = useNavigate(),
		[step, setStep] = useState(0);

	useEffect(() => {
		me();
		findEmployer(["rti_key"]);
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [findEmployer, me]);

	useEffect(() => {
		if (user.temporary_password) {
			push("/main/settings/edit-password");
		}
	}, [user.temporary_password, push]);

	if (user_loading || employer_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"main-page"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
				employer={employer}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Home"}
						lockMenuItems={[
							employer?.rti_key?.hmrc_connected === false ? 'Payroll' : null,
							employer?.rti_key?.hmrc_connected === false ? 'People' : null,
							employer?.rti_key?.hmrc_connected === false ? 'Reports' : null,
							employer?.rti_key?.hmrc_connected === false ? 'Company' : null,
							employer?.rti_key?.hmrc_connected === false ? 'Auto Enrolment' : null,
						]}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
					id={"close-tax-year"}
				>
					{_renderPage(step, setStep,push,closeTaxYear)}
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Employer
	} = state;

	return {
		...Profile,
		...Employer,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	closeTaxYear: (data = []) => dispatch(closeTaxYear(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CloseTaxYear)
